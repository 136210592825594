import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import ReviewCard from './components/ReviewCard'
import data from './components/data'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'

const UserReviews = () => {
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = React.useState<number>(0)

  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    detailsChanged(s) {
      setActiveIndex(s.track.details.rel)
    },
    initial: 0,
  })

  const breadcrumbs = (totalSlides: number, activeIndex: number) => (
    <div className="flex items-center gap-1">
      {[...Array(totalSlides)].map((_, index) => (
        <div
          key={index}
          className={`${
            index === activeIndex
              ? 'bg-primary w-2.5 h-2.5'
              : 'bg-lumepic-dark_grey opacity-80 w-2 h-2'
          }`}
          style={{ borderRadius: '50%' }}
        />
      ))}
    </div>
  )

  return (
    <div className="w-full py-10 bg-bg_details sm:py-20">
      <div className="w-full px-4 mb-2 text-center">
        <h3 className="mb-0 text-2xl font-semibold text-lumepic-grey sm:text-3xl md:text-4xl">
          {t('Reviews from our users')}
        </h3>
        <div className="hidden h-6 sm:block" />
      </div>
      <div className="justify-around hidden gap-4 sm:flex px-14">
        {data.map((user, index) => (
          <ReviewCard name={user.name} role={t(user.role)} message={t(user.message)} key={index} />
        ))}
      </div>
      <div className="block px-4 sm:hidden">
        <div ref={sliderRef} className="keen-slider">
          {data.map((user, idx) => (
            <div key={idx} className="px-2 keen-slider__slide">
              <ReviewCard name={user.name} role={t(user.role)} message={t(user.message)} />
            </div>
          ))}
        </div>
        <div className="flex justify-center w-full mt-2 sm:hidden">
          {breadcrumbs(data.length, activeIndex)}
        </div>
      </div>
    </div>
  )
}

export default observer(UserReviews)
