import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

const getFreeEventAlbumValidationSchema = () => {
  const { t } = useTranslation()

  return yup.object({
    description: yup.string().required(t('Description is required') as string),
    takenDate: yup.date().when('$takenDateNotNull', (takenDateNotNull, schema) => {
      return takenDateNotNull ? schema.required(t('Date is required') as string) : schema
    }),
    activityId: yup
      .string()
      .uuid()
      .when('$activityIdNotNull', (activityIdNotNull, schema) => {
        return activityIdNotNull ? schema.required(t('Activity is required') as string) : schema
      }),
    locationId: yup
      .string()
      .uuid()
      .when('$locationIdNotNull', (locationIdNotNull, schema) => {
        return locationIdNotNull ? schema.required(t('Location is required') as string) : schema
      }),
    eventId: yup
      .string()
      .uuid()
      .when('$eventIdNotNull', (eventIdNotNull, schema) => {
        return eventIdNotNull ? schema.required(t('Event is required') as string) : schema
      }),
  })
}

export default getFreeEventAlbumValidationSchema
