import React, { useContext, useEffect, useState } from 'react'
import Modal from '../Modal'
import { ModalButtonsDisplay, ModalSize } from '../Modal/Modal'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import { isNil } from 'lodash'
import Album from 'shared/models/Album'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Photograph } from 'shared/models/Photograph'
import storesContext from 'providers/storesContext'

type PackagePurchaseInfoModalProps = {
  open: boolean
  onClose: () => void
  handleBuyClick: (event: React.MouseEvent<HTMLSpanElement>) => void
  handleAddBurstClick: (event: React.MouseEvent<HTMLSpanElement>, price?: number) => void
  isBurstPurchase: boolean
  photographs?: Photograph[]
  album?: Album
  eventName?: string
  tagValue?: string
  isLoading: boolean
  isFaceRecognition: boolean
}

const PackagePurchaseInfoModal = ({
  open,
  onClose,
  handleBuyClick,
  handleAddBurstClick,
  isBurstPurchase,
  photographs,
  album,
  eventName,
  isLoading,
  tagValue,
  isFaceRecognition,
}: PackagePurchaseInfoModalProps) => {
  const { t } = useTranslation()
  const { cartStore } = useContext(storesContext)!

  const [isInCart, setIsInCart] = useState<boolean>(false)

  useEffect(() => {
    if (!isNil(photographs) && photographs.length > 0) {
      const packageInCart = photographs.every((photograph) => cartStore.alreadyInCart(photograph))
      if (packageInCart) {
        setIsInCart(true)
      }
    }
  }, [photographs])

  const handleAddBurstToCart = (event: React.MouseEvent<HTMLSpanElement>, price: number) => {
    handleAddBurstClick(event, price)

    setTimeout(() => {
      onClose()
    }, 1000)
  }

  const defaultText = (
    <>
      <p>
        {t(
          'Click “Buy Now” to continue with your purchase or add the package to the cart and continue shopping'
        )}
        .
      </p>
    </>
  )

  return (
    <div>
      <Modal
        modalSize={ModalSize.SMALL}
        buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
        opened={open}
        onCancel={onClose}
      >
        <div className="w-full flex flex-col justify-center p-4">
          <div className="text-xl text-center text-gray-800 font-extrabold mb-2">
            {isBurstPurchase
              ? t('Burst package')
              : isFaceRecognition
              ? t('Face recognition package')
              : t('Package')}
          </div>
          {isBurstPurchase ? (
            <div className="flex flex-col gap-2">
              <div className="flex gap-1">
                <p>{t('Burst of')}</p>
                <p>{photographs?.length}</p>
                <p>{t('photographs')}.</p>
              </div>
              <p>{defaultText}</p>
            </div>
          ) : isFaceRecognition ? (
            <div className="flex flex-col gap-2">
              {!isNil(album?.owner) && (
                <p>
                  {t('packagePriceIntroTextWithFaceRecognitionWithAlias', {
                    ownerAlias: album?.owner.alias,
                  })}
                  .
                </p>
              )}
              {!isNil(eventName) && (
                <p>
                  {t('packagePriceIntroTextWithFaceRecognitionWithEventName', {
                    eventName: eventName,
                  })}
                  .
                </p>
              )}
              {defaultText}
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              {tagValue
                ? !isNil(album?.owner) && (
                    <p>
                      {t('packagePriceIntroTextWithTagValueWithAlias', {
                        ownerAlias: album?.owner.alias,
                        tagValue: tagValue,
                      })}
                      .
                    </p>
                  )
                : !isNil(eventName) &&
                  !isNil(tagValue) && (
                    <p>
                      {t('packagePriceIntroTextWithTagValueWithEvent', {
                        eventName: eventName,
                        tagValue: tagValue,
                      })}
                      .
                    </p>
                  )}{' '}
              {!isNil(album?.owner) && (
                <p>{t('packagePriceIntroTextWithAlias', { ownerAlias: album?.owner.alias })}.</p>
              )}
              {!isNil(eventName) && (
                <p>{t('packagePriceIntroTextWithEvent', { eventName: eventName })}.</p>
              )}
              {defaultText}
            </div>
          )}
          <div className="w-full flex justify-center gap-3 pt-3">
            <Button btnType={'PrimaryAction'} onClick={handleBuyClick} isLoading={isLoading}>
              <span>{t('Buy Now')}</span>
            </Button>
            {isBurstPurchase && (
              <Button
                btnType={'PrimaryAction'}
                onClick={isInCart ? () => {} : handleAddBurstToCart}
                extraStyle={`text-sm ${isInCart && 'opacity-60 cursor-not-allowed'}`}
              >
                <div className="flex gap-1 items-center">
                  <FontAwesomeIcon icon={faCartPlus} className="text-lg" />
                  <span>{t('Add to Cart')}</span>
                </div>
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default PackagePurchaseInfoModal
