const data = [
  {
    title: 'Política de Privacidad',
    content: [
      'Lumepic (la "Compañía") respeta la privacidad de los usuarios de su Servicio. En este documento se explica cómo la Compañía recopila, usa y divulga la información relacionada con su privacidad. Cuando accede o utiliza el Servicio, el Usuario declara que acepta esta Política de privacidad. Si desea más información o tiene alguna pregunta sobre la Política de Privacidad, no dude en contactarse por correo electrónico a info@lumepic.com. Lumepic, considera la privacidad de los Usuarios muy importante. En esta Política de Privacidad se describen en detalle los tipos de información personal que el Sitio recopila y cómo lo utiliza.',
    ],
  },
  {
    title: 'Archivos de Registro',
    content: [
      'Al igual que muchos otros sitios web, Lumepic hace uso de archivos de registro. Estos archivos sólo tiene que registrarse en el sitio de los usuarios - por lo general un procedimiento estándar para empresas de alojamiento y servicios de alojamiento en parte analíticos. La información contenida en esos archivos incluye direcciones de protocolo de Internet (IP), tipo de navegador, proveedor de servicios de Internet (ISP), fecha / hora, dichas páginas / salida, y posiblemente el número de clics. Esta información se utiliza para el análisis de tendencias, administrar el sitio, rastrear el movimiento del usuario alrededor del sitio, y recopilar información demográfica. Dirección IP y otra información que no están vinculados a ninguna información que sea personalmente identificable.',
    ],
  },
  {
    title: 'Cookies e Web Beacons',
    content: [
      'Lumepic utiliza cookies para almacenar información sobre las preferencias de los usuarios para almacenar información específica sobre los Usuarios, las páginas web a las que el usuario accede o visita dentro del Sitio, y para personalizar el contenido del Sitio basado en el tipo de navegador del usuario u otra información que el Usuario envía desde el navegador.',
    ],
  },
  {
    title: 'Dart Cookie Clique Duplo',
    content: [
      'Google, como proveedor asociado, utiliza cookies para publicar anuncios en el sitio.',
      'El uso de Google de la cookie DART le permite publicar anuncios a los usuarios del sitio basándose en su visita al sitio y a otros sitios en Internet.',
      'Los usuarios pueden optar por no utilizar la cookie de DART visitando la política de privacidad de la red de anuncios y contenido de Google en la siguiente URL - http://www.google.com/privacy_ads.html.',
    ],
  },
  {
    title: 'Los socios publicitarios',
    content: [
      'Algunos de los socios publicitarios de la Compañía pueden usar cookies y web beacons en el Sitio.',
      'Cada uno de estos socios publicitarios tiene su propia Política de privacidad para su sitio.',
      'Estos servidores de anuncios o redes publicitarias de terceros utilizan tecnología en sus respectivos anuncios y enlaces que aparecen en el Sitio y que se envían directamente a su navegador. Reciben automáticamente la dirección IP del Usuario cuando esto ocurre. Otras tecnologías (como cookies, JavaScript o Web Beacons) también pueden ser utilizadas por las redes publicitarias de terceros del sitio para medir la efectividad de sus campañas publicitarias y/o personalizar el contenido publicitario que ve en el sitio.',
      'El Sitio no tiene acceso ni control sobre estas cookies que son utilizadas por los anunciantes de terceros.',
    ],
  },
  {
    title: 'Políticas de privacidad de terceros',
    content: [
      'El Usuario debe consultar las políticas de privacidad respectivas de estos servidores de anuncios de terceros para obtener información más detallada sobre sus prácticas, así como para obtener instrucciones sobre cómo optar por no participar en determinadas prácticas. La Política de Privacidad del sitio no se aplica a las actividades de otros anunciantes o sitios web. Si el Usuario desea desactivar las cookies, puede hacerlo a través de sus opciones de navegador individuales. Puede encontrar información más detallada sobre la gestión de cookies con navegadores web específicos en los respectivos sitios web de los navegadores.',
    ],
  },
  {
    title: 'Informe del niño',
    content: [
      'La Compañía cree que es importante proporcionar protección adicional para los niños en línea. Se anima a los padres y tutores a pasar tiempo en línea con sus hijos para observar, participar y/o supervisar y guiar su actividad en línea. El Sitio no recoge a sabiendas ninguna información de identificación personal de niños menores de 13 años. Si un padre o guardián cree que el Sitio tiene en su base de datos la información personalmente identificable de un niño menor de 13 años, puede comunicarse con la Compañía la cual hará los mejores esfuerzos para eliminar rápidamente dicha información de sus registros.',
    ],
  },
  {
    title: 'Política de privacidad en línea solamente',
    content: [
      'Esta política de privacidad sólo se aplica a las actividades en línea y es válida para el Usuario del Sitio y con respecto a la información compartida y/o recogida allí. Esta política no se aplica a ninguna información recopilada fuera de línea o a través de canales que no sean el Sitio.',
    ],
  },
  {
    title: 'Consentimiento',
    content: [
      'Al usar el Sitio, por el presente el Usuario da su consentimiento a esta Política de Privacidad y acepta sus términos.',
    ],
  },
  {
    title: 'Actualización',
    content: [
      'Esta política de privacidad se actualizó por última vez el: 30 de agosto de 2023.',
      'Si la compañía actualiza, modifica o realiza cambios en la Política de Privacidad, serán publicadas en esta misma dirección.',
    ],
  },
]

export default data
