import React, { useContext } from 'react'
import magnifyingGlass from '../../../assets/icons/v2/magnifyingGlass.svg'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import ConfigService, { ConfigKeys } from 'config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FeatureFlags } from 'config/constants/featureFlags'
import storesContext from 'providers/storesContext'
import { getFeatureFlagValue } from 'shared/utility'
import { Event } from 'shared/models/Event'
import { FiveDaysInMilliseconds } from 'shared/util/constants'
import { faFaceFrown } from '@fortawesome/free-regular-svg-icons'

type NoResultsFoundProps = {
  emptyEvent?: boolean
  filteredSearchEmpty?: boolean
  event?: Event
}

const NoResultsFound = ({ emptyEvent, filteredSearchEmpty, event }: NoResultsFoundProps) => {
  const { t } = useTranslation()
  const today = new Date()
  const { featureFlagsStore } = useContext(storesContext)!

  const eventPhotographsRequestFeatureFlag = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.EVENT_PHOTOGRAPHS_REQUEST,
    true
  )

  const getEventDate = (event?: Event): Date | null => {
    if (!event || !event.date) return null
    return event.date instanceof Date ? event.date : new Date(event.date)
  }

  const eventDate = getEventDate(event)
  const isEventOlderThanFiveDays = eventDate
    ? today.getTime() - eventDate.getTime() > FiveDaysInMilliseconds
    : false

  const handleRequestEventPhotographs = () => {
    const url = ConfigService.getValue(ConfigKeys.USER_EVENT_PHOTOGRAPHS__REQUEST_FORM)
    window.open(url, '_blank')
  }

  const requestPhotographsMessage = eventPhotographsRequestFeatureFlag && (
    <>
      <span className="text-sm font-light mb-2">
        {t(
          'If you prefer, you can click the following button to receive your photographs automatically once these are available!'
        )}
      </span>
      <div className="flex justify-center w-30">
        <Button
          btnType="PrimaryAction"
          onClick={handleRequestEventPhotographs}
          extraStyle="text-base"
        >
          {t('Request my photographs')}
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="text-lumepic-black ml-1.5" />
        </Button>
      </div>
    </>
  )

  const noResultsFoundMessage = (message: string) => (
    <div className="flex items-center justify-center gap-2 p-2 ml-3 sm:text-xl">
      <img src={magnifyingGlass} alt="No Results" className="w-10 h-10 sm:w-20 sm:h-20" />
      <span>{t(message)}.</span>
    </div>
  )

  return (
    <div className="flex items-center justify-center my-6 md:my-10 w-full">
      <div className="text-base rounded-md text-lumepic-light_black sm:text-xl bg-lumepic-light_grey w-full">
        {event ? (
          <>
            {emptyEvent && (
              <>
                {isEventOlderThanFiveDays ? (
                  <>{noResultsFoundMessage('This event does not have photographs')}</>
                ) : (
                  <div className="flex flex-col gap-1 p-3 text-center bg-white rounded-md">
                    <span>
                      {t('Soon you will be able to see the photographs of the event here')}...
                    </span>
                    {requestPhotographsMessage}
                  </div>
                )}
              </>
            )}
            {filteredSearchEmpty && (
              <>
                {isEventOlderThanFiveDays || event.isFree ? (
                  <>{noResultsFoundMessage('No photographs were found')}</>
                ) : (
                  <div className="flex flex-col gap-1 p-3 text-center bg-white rounded-md">
                    <span className="text-lg">
                      <FontAwesomeIcon icon={faFaceFrown} className="mr-1.5" />
                      {t(
                        'You’ve not appeared in any photos yet. There may be more albums on the way'
                      )}
                      .
                    </span>
                    {requestPhotographsMessage}
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>{noResultsFoundMessage('No results found matching your search')}</>
        )}
      </div>
    </div>
  )
}

export default NoResultsFound
