import { Autocomplete, Paper, TextField } from '@mui/material'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import '/node_modules/flag-icons/css/flag-icons.min.css'
import { isNil } from 'lodash'
import { unaccentMap } from 'shared/utility'

const CustomPaper = (props) => {
  return (
    <Paper
      {...props}
      style={{ color: '#636363', fontFamily: 'Euclid Circular A', width: '300px' }}
    />
  )
}

export type DropdownOption = {
  value: string
  label: string
  metadata?: any
}

type SearchBarDropdownProps = {
  dropdownLabel: string
  selectedValue: DropdownOption | null
  onChange: (option: DropdownOption | null) => void
  options: DropdownOption[]
  isLoading?: boolean
  disableClearable?: boolean
  renderOption?: (props: any, option: DropdownOption) => JSX.Element
}

// TODO: This may be a generic component, not searchBar specific
const SearchBarDropdown = ({
  onChange,
  dropdownLabel,
  selectedValue,
  options,
  isLoading,
  disableClearable = false,
  renderOption = undefined,
}: SearchBarDropdownProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const selectedValueTranslated = !isNil(selectedValue)
    ? { ...selectedValue, label: t(selectedValue?.label) }
    : null

  const unaccent = (str) => [...str].map((c) => unaccentMap[c] || c).join('')

  return (
    <>
      <Autocomplete
        disableClearable={disableClearable}
        renderOption={renderOption}
        filterOptions={(options, state) => {
          const unaccentedInputValue = unaccent(state.inputValue.toLowerCase())

          return options.filter((option) => {
            return (
              unaccent(option.label.toLowerCase()).includes(unaccentedInputValue) ||
              unaccent(option.value.toLowerCase()).includes(unaccentedInputValue)
            )
          })
        }}
        sx={{ width: '100%' }}
        blurOnSelect
        PaperComponent={CustomPaper}
        autoComplete
        className="border-2 rounded-md border-lumepic-grey :hover-none"
        isOptionEqualToValue={(option, value) =>
          //   TODO: Remove country condition
          dropdownLabel === t('Country')
            ? option.value === value.value
            : option.label === value.label
        }
        onChange={(e: any, v: DropdownOption | null) => {
          onChange(v)
        }}
        value={selectedValueTranslated}
        options={
          dropdownLabel === t('Event')
            ? options
            : options.sort((a, b) => -b.label[0].localeCompare(a.label[0]))
        }
        loading={isLoading}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={dropdownLabel}
            className="hover:border-0"
            sx={{
              input: {
                '&::placeholder': {
                  color: '#636363',
                  fontFamily: 'Euclid Circular A',
                  fontWeight: '500',
                  opacity: 1,
                },
              },
              '& .MuiInputBase-root': {
                padding: '1px 5px',
                background: '#ffffff',
                flexFlow: 'row',
              },
              fieldset: {
                border: '0px',
              },
            }}
            InputProps={{
              ...params.InputProps,
              // TODO: Country is hardcoded
              startAdornment: (
                <React.Fragment>
                  {dropdownLabel === t('Country') ? (
                    <span
                      className={`text-9xl h-4 -mx-1 fi fi-${selectedValue?.value.toLowerCase()}`}
                    />
                  ) : undefined}
                  {params.InputProps.startAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  )
}

export default observer(SearchBarDropdown)
