import axios from './axios'
import * as paths from './Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { AxiosResponse } from 'axios'
import { CodeDiscount } from '../shared/models/CodeDiscount'
import { ValidateCodeDiscountInterface } from './RequestInterfaces/Discount/CodeDiscount.interface'

class DiscountService {
  async findForPurchase(
    code: string,
    photographIds: string[],
    token: string
  ): Promise<[CodeDiscount, string[]]> {
    return axios
      .post<ValidateCodeDiscountInterface>(
        paths.discountByCode(code),
        {
          photographIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse<ValidateCodeDiscountInterface>): [CodeDiscount, string[]] => {
        const codeDiscount = CodeDiscount.init(response.data.discount)
        const applicablePhotographs = response.data.photographIds
        return [codeDiscount, applicablePhotographs]
      })
      .catch(parseAxiosErrorResponse)
  }

  createPersonalCodeDiscount(token: string, discountData: CodeDiscount) {
    return axios
      .post(paths.PERSONAL_CODE_DISCOUNT, discountData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default DiscountService
