import { Activity } from './Activity'
import LocationInterface from '../../services/RequestInterfaces/Location/Location.interface'
import { isNil } from 'lodash'

export class Location {
  public static init(data: LocationInterface): Location {
    const location = new Location()
    if (isNil(data)) {
      return location
    }
    location.id = data.id
    location.countryCode = data.countryCode
    location.spotName = data.spotName
    location.latitude = data.latitude
    location.longitude = data.longitude
    location.activities = !isNil(data.activities)
      ? data.activities?.map((activity) => Activity.init(activity))
      : []

    return location
  }

  id: string

  countryCode: string

  spotName: string

  latitude: number

  longitude: number

  activities?: Activity[]

  getLabel() {
    return {
      value: this.id,
      label: `${this.spotName}`,
    }
  }

  public static clone(data: Location): Location {
    const clone = new Location()
    clone.id = data.id
    clone.countryCode = data.countryCode
    clone.spotName = data.spotName
    clone.latitude = data.latitude
    clone.longitude = data.longitude
    clone.activities = data.activities?.map((activity) => Activity.clone(activity))

    return clone
  }
}
