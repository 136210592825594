import React from 'react'
import PhotographersBenefitsDesktop from './PhotographersBenefitsDesktop'
import PhotographerBenefitsMobile from './PhotographerBenefitsMobile'

const PhotographersBenefits = () => {
  return (
    <>
      <div className="hidden sm:block">
        <PhotographersBenefitsDesktop />
      </div>
      <div className="block sm:hidden">
        <PhotographerBenefitsMobile />
      </div>
    </>
  )
}

export default PhotographersBenefits
