const data = [
  {
    title: 'Privacy Policies',
    content: [
      'Lumepic (the "Company") respects the privacy of users of its Service. This document explains how the Company collects, uses, and discloses information related to your privacy. When you access or use the Service, you declare that you agree to this Privacy Policy. If you want more information or have any questions about the Privacy Policy, feel free to contact us via email at info@lumepic.com. Lumepic considers the privacy of Users very important. This Privacy Policy describes in detail the types of personal information the Site collects and how it is used.',
    ],
  },
  {
    title: 'Log Files',
    content: [
      'Like many other websites, Lumepic makes use of log files. These files simply register users’ visits to the site - usually a standard procedure for hosting companies and part of analytics services. Information contained in these files includes Internet Protocol (IP) addresses, browser type, Internet Service Provider (ISP), date/time stamp, referring/exit pages, and possibly the number of clicks. This information is used for trend analysis, site management, tracking user movement around the site, and gathering demographic information. IP addresses and other information are not linked to any personally identifiable information.',
    ],
  },
  {
    title: 'Cookies e Web Beacons',
    content: [
      'Lumepic uses cookies to store information about users’ preferences, to store specific information about Users, the web pages they access or visit within the Site, and to personalize the content of the Site based on the user’s browser type or other information that the User sends from the browser.',
    ],
  },
  {
    title: 'DoubleClick Dart Cookie',
    content: [
      'Google, as a partner provider, uses cookies to display ads on the site.',
      'Google’s use of the DART cookie enables it to serve ads to site users based on their visit to the site and other sites on the Internet.',
      'Users can opt out of using the DART cookie by visiting the Google ad network and content privacy policy at the following URL - http://www.google.com/privacy_ads.html .',
    ],
  },
  {
    title: 'Advertising Partners',
    content: [
      'Some of the Company’s advertising partners may use cookies and web beacons on the Site.',
      'Each of these advertising partners has its own Privacy Policy for its site.',
      'These third-party ad servers or ad networks use technology in their respective ads and links that appear on the Site and are sent directly to your browser. They automatically receive your IP address when this happens. Other technologies (such as cookies, JavaScript, or Web Beacons) may also be used by third-party ad networks on the site to measure the effectiveness of their advertising campaigns and/or personalize the advertising content you see on the site.',
      'The Site has no access to or control over these cookies that are used by third-party advertisers.',
    ],
  },
  {
    title: 'Third-Party Privacy Policies',
    content: [
      'El Usuario debe consultar las políticas de privacidad respectivas de estos servidores de anuncios de terceros para obtener información más detallada sobre sus prácticas, así como para obtener instrucciones sobre cómo optar por no participar en determinadas prácticas. La Política de Privacidad del sitio no se aplica a las actividades de otros anunciantes o sitios web. Si el Usuario desea desactivar las cookies, puede hacerlo a través de sus opciones de navegador individuales. Puede encontrar información más detallada sobre la gestión de cookies con navegadores web específicos en los respectivos sitios web de los navegadores.',
    ],
  },
  {
    title: 'Children’s Online Privacy Protection Act (COPPA)',
    content: [
      'The Company believes it is important to provide additional protection for children online. Parents and guardians are encouraged to spend time online with their children to observe, participate, and/or monitor and guide their online activity. The Site does not knowingly collect any personally identifiable information from children under 13 years of age. If a parent or guardian believes that the Site has personally identifiable information of a child under 13 in its database, they can contact the Company, which will make reasonable efforts to promptly remove such information from its records.',
    ],
  },
  {
    title: 'Online Privacy Policy Only',
    content: [
      'This privacy policy applies only to online activities and is valid for Users of the Site with respect to shared and/or collected information there. This policy does not apply to any information collected offline or through channels other than the Site.',
    ],
  },
  {
    title: 'Consent',
    content: [
      'By using the Site, you hereby consent to this Privacy Policy and agree to its terms.',
    ],
  },
  {
    title: 'Update',
    content: [
      'This privacy policy was last updated on: August 30, 2023.',
      'If the company updates, modifies, or makes changes to the Privacy Policy, they will be posted at the same address.',
    ],
  },
]

export default data
