import React from 'react'
import { Skeleton } from '@mui/material'

const FirstRenderFiltersViewSkeleton = () => {
  return (
    <div className="flex flex-col bg-white rounded-md mt-3">
      <div className="border-b-4 border-primary_dark">
        <div className="flex gap-2 p-2 items-center">
          <Skeleton variant="rounded" height={25} width={25} />
          <Skeleton variant="rounded" height={12} width={300} />
        </div>
      </div>
      <div className="flex flex-col p-2 gap-2 sm:hidden w-full">
        <span className="flex gap-2.5 w-full">
          <Skeleton variant="rounded" height={120} className="flex-1" />
          <Skeleton variant="rounded" height={120} className="flex-1" />
        </span>
        <span className="flex gap-2.5 w-full">
          <Skeleton variant="rounded" height={50} className="flex-1" />
        </span>
      </div>
      <div className="hidden sm:flex p-3 gap-4 w-full">
        <div className="flex flex-col gap-3 w-1/2">
          <Skeleton variant="rounded" height={60} />
          <Skeleton variant="rounded" height={60} />
          <Skeleton variant="rounded" height={60} />
        </div>
        <div className="w-1/2">
          <Skeleton variant="rounded" height={205} />
        </div>
      </div>
    </div>
  )
}

export default FirstRenderFiltersViewSkeleton
