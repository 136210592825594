import React from 'react'
import NavigationItems from '../NavigationItems'
import DrawerToggle from '../SideDrawer/DrawerToggle'
import lumepicLogo from '../../../assets/icons/v2/lumepic-logo.svg'
import { NavLink } from 'react-router-dom'
import { NavigationItemPosition } from '../NavigationItems/NavigationItems'
import LanguageSwitcherDropdown from 'components/UI/LanguageSwitcherDropdown/LanguageSwitcherDropdown'

type ToolbarProps = {
  drawerToggleClicked: () => void
}

const Toolbar = ({ drawerToggleClicked }: ToolbarProps) => {
  return (
    <header className="w-full sticky z-50 top-0 left-0 m-0 bg-white box-border shadow-md">
      <div className="container mx-auto">
        <div className="flex items-center justify-between h-full min-h-[75px]">
          <div className="hidden sm:flex items-center w-full h-full">
            <NavigationItems position={NavigationItemPosition.LEFT} />
          </div>
          <div className="block sm:hidden px-4">
            <DrawerToggle onClick={drawerToggleClicked} />
          </div>

          <div className="flex items-center justify-center w-full">
            <NavLink to={`/`} className="flex justify-center w-full">
              <div className="block">
                <img loading="lazy" src={lumepicLogo} alt="Logo" className="w-full max-w-36" />
              </div>
            </NavLink>
          </div>

          <div className="flex gap-0.5 justify-end sm:w-full px-5 sm:px-0">
            <div className="hidden md:block">
              <LanguageSwitcherDropdown />
            </div>

            <NavigationItems position={NavigationItemPosition.RIGHT} />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Toolbar
