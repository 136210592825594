import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'components/UI/Modal'
import { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { requestActivityValidationSchema } from './RequestActivityValidationSchema'
import StoresContext from '../../../../../providers/storesContext'
import { TextField } from '@mui/material'
import Button from 'components/UI/Button'

type NewSportModalProps = {
  closeModal: () => void
  handleFinish: () => void
}

const RequestActivityModal = ({ closeModal, handleFinish }: NewSportModalProps) => {
  const { t } = useTranslation()

  const { activityStore } = useContext(StoresContext)!

  const initialValues = {
    name: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: requestActivityValidationSchema,
    onSubmit: (values) => {
      activityStore.requestNewActivity(values.name.toUpperCase()).then((data) => {
        if (data) {
          handleFinish()
          toast.success(t(`New sport ${data.name} created successfully!`), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
    },
  })

  return (
    <Modal
      onCancel={closeModal}
      opened={true}
      modalSize={ModalSize.SMALL}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
      okMessage={t('Request').toString()}
      onSuccess={formik.handleSubmit}
    >
      <div className="flex flex-col gap-3 bg-gap-4 py-2 px-2 w-full">
        <div className="text-xl text-gray-800 font-extrabold mb-2">{t('Request Sport')}</div>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Sport Name *"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <div className="w-full flex justify-end pb-2">
          <Button
            btnType="PrimaryAction"
            onClick={formik.handleSubmit}
            isLoading={activityStore.isLoadingRequestActivity}
          >
            {t('Request')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default observer(RequestActivityModal)
