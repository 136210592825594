import React, { useState } from 'react'
import { observer } from 'mobx-react'
import PaymentProductRow from './PaymentProductRow'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import { Purchase } from '../../../../shared/models/Purchase'
import Button from 'components/UI/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import PaymentStore from 'containers/Payment/PaymentStore'
import { useHistory } from 'react-router'
import { PhotographUtils } from 'shared/util/photographs.utils'
import { faCalendar, faImage } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment'

type PaymentDetailProps = {
  purchase: Purchase
  store: PaymentStore
}

const PaymentDetail = ({ purchase, store }: PaymentDetailProps) => {
  const { t } = useTranslation()
  const { totalPrice } = purchase
  const history = useHistory()

  const [isLoadingMultipleDownloads, setIsLoadingMultipleDownloads] = useState(false)

  const photographsFileNames = purchase.photographs.map(
    (photograph) => `${photograph.id}.${photograph.extension}`
  )

  const handleDownloadPhotographs = async (photographsFileNames: string[]) => {
    if (isNil(purchase)) {
      history.push('/purchases')
    } else {
      setIsLoadingMultipleDownloads(true)
      await PhotographUtils.downloadPurchasedImages(
        purchase.id,
        photographsFileNames,
        store,
        t
      ).then(() => {
        setIsLoadingMultipleDownloads(false)
      })
    }
  }

  const lineItemsConditional =
    !isNil(purchase.lineItems) &&
    !isNil(purchase.lineItems[0]) &&
    !isNil(purchase.lineItems[0].photographs) &&
    !isNil(purchase.lineItems[0].photographs[0])

  return (
    <div className="relative">
      <div className="bg-lumepic-light_black h-5 rounded-full w-full absolute z-10" />
      <div className="bg-white rounded-sm border-2 border-lumepic-light_black relative z-20 top-3 md:mx-2">
        <div className="flex flex-col px-4 py-3 relative">
          <h2 className="mb-3 text-xl font-semibold text-lumepic-light_black md:text-2xl ">
            {t('Summary')}
          </h2>
          <div className="flex w-full py-4 items-center md:border-t border-bg_details">
            <div className="flex flex-col gap-2 md:border-r border-bg_details w-full">
              <div className="flex gap-1.5 items-center">
                <FontAwesomeIcon icon={faCalendar} className="text-lumepic-grey mb-1" />
                <span className="text-lumepicSm text-lumepic-grey font-light">{t('Date')}</span>
              </div>
              <span className="text-lumepic-light_black">
                {moment.utc(purchase.createdAt).format('DD-MM-YYYY')}
              </span>
            </div>
            {photographsFileNames.length > 1 && (
              <div className="mx-3 w-full hidden md:block">
                <Button
                  btnType="PrimaryAction"
                  isLoading={isLoadingMultipleDownloads}
                  onClick={() => {
                    isLoadingMultipleDownloads
                      ? null
                      : handleDownloadPhotographs(photographsFileNames)
                  }}
                  extraStyle="text-sm md:text-base w-full"
                  loadingText={t('Downloading') + '...'.toString()}
                >
                  <div className="flex gap-1.5 items-center justify-center">
                    <FontAwesomeIcon icon={faDownload} /> {t('Download all')}
                  </div>
                </Button>
              </div>
            )}
            <div className="flex flex-col items-end gap-2 border-l border-bg_details w-full">
              <div className="flex gap-1.5 items-center">
                <FontAwesomeIcon icon={faImage} className="text-lumepic-grey mb-1" />
                <span className="text-lumepicSm text-lumepic-grey font-light">
                  {t('Number of photos')}
                </span>
              </div>
              <span className="text-lumepic-light_black">{purchase.photographs.length}</span>
            </div>
          </div>
        </div>
        <div className="border-t-2 border-lumepic-medium_grey border-dashed mx-3" />
        {photographsFileNames.length > 1 && (
          <div className="mx-3 mt-3 md:hidden">
            <Button
              btnType="PrimaryAction"
              isLoading={isLoadingMultipleDownloads}
              onClick={() => {
                isLoadingMultipleDownloads ? null : handleDownloadPhotographs(photographsFileNames)
              }}
              extraStyle="text-sm md:text-base w-full"
              loadingText={t('Downloading') + '...'.toString()}
            >
              <div className="flex gap-1.5 items-center justify-center">
                <FontAwesomeIcon icon={faDownload} /> {t('Download all')}
              </div>
            </Button>
          </div>
        )}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-2 p-2 md:p-3">
          {lineItemsConditional &&
            purchase.lineItems.map((lineItem) => (
              <PaymentProductRow
                key={lineItem.id}
                purchaseId={purchase.id}
                store={store}
                lineItem={lineItem}
                isLoadingMultipleDownloads={isLoadingMultipleDownloads}
              />
            ))}
        </div>

        <div className="flex flex-col m-5 pt-4 pb-3 border-t border-lumepic-medium_grey">
          {!isNil(purchase.value) && (
            <div className="flex justify-between items-center">
              <p className="font-medium md:text-lg">{t('Subtotal')}</p>
              <p className="font-medium md:text-lg">
                {purchase.currency?.toUpperCase()} {purchase.value}
              </p>
            </div>
          )}

          {!isNil(purchase.discountTotal) && (
            <div className="flex justify-between items-center mt-1">
              <p className="text-lumepic-grey md:text-lg">{t('Discount Applied')}</p>
              <p className="text-lumepic-grey md:text-lg">
                {purchase.currency?.toUpperCase()} {purchase.discountTotal}
              </p>
            </div>
          )}
          <div className="flex justify-between items-center mt-2 pt-3 border-t border-lumepic-medium_grey">
            <p className="font-semibold text-lg md:text-xl">{t('Total')}</p>
            <p className="font-semibold text-lg md:text-xl">
              {purchase.currency?.toUpperCase()} {totalPrice}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(PaymentDetail)
