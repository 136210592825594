import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import FileInput, { FileTypes } from '../FileInput/FileInput'
import UploadCoverPhotographStore from 'containers/MyAlbums/CreateOrUpdateAlbumSteps/Steps/UploadCoverPhotograph/UploadCoverPhotographStore'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import Spinner from '../Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'

const validImageTypes = [FileTypes.PNG, FileTypes.JPG]

type CoverPhotographUploadedProps = {
  store: UploadCoverPhotographStore
  onUpload: (file: File) => void
}

const CoverPhotographUploaded = ({ store, onUpload }: CoverPhotographUploadedProps) => {
  const { t } = useTranslation()
  const { progressInfo, coverPhotographUrl } = store

  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const handleOnLine = () => setIsOnline(true)
  const handleOffLine = () => setIsOnline(false)

  useEffect(() => {
    window.addEventListener('online', handleOnLine)
    window.addEventListener('offline', handleOffLine)

    return () => {
      window.removeEventListener('online', handleOnLine)
      window.removeEventListener('offline', handleOffLine)
    }
  }, [])

  const handleFileInput = (file: File) => {
    onUpload(file)
  }

  return (
    <div className="flex flex-col gap-1 h-full">
      <div className="w-full h-full flex flex-col justify-center items-center border-2 border-lumepic-grey rounded-md shadow-md relative">
        {((!isNil(progressInfo) &&
          progressInfo.percentage >= 0 &&
          progressInfo.percentage < 100 &&
          !progressInfo.error) ||
          store.isLoading) && (
          <div className="flex items-center h-56 sm:h-[360px]">
            <Spinner noStyles size={20} />
            <p className="text-black my-10 ml-1">{t('Uploading...')}</p>
          </div>
        )}
        {!isNil(progressInfo) && progressInfo.error && (
          <div className="flex items-center justify-center h-56 sm:h-[360px]">
            <FontAwesomeIcon className="text-warning font-medium" icon={faCircleXmark} />
            <p className="text-warning my-1 ml-1 font-medium">Error</p>
          </div>
        )}
        {((!isNil(progressInfo) &&
          progressInfo.percentage === 100 &&
          !progressInfo.error &&
          !store.isLoading) ||
          (isNil(progressInfo) && coverPhotographUrl && !store.isLoading)) && (
          <img
            src={`${coverPhotographUrl}?timestamp=${new Date().getTime()}`}
            className="object-cover object-center w-full h-56 sm:h-[360px] rounded-sm"
            alt="picture"
          />
        )}
        {((!isNil(progressInfo) && progressInfo.percentage === 100 && !progressInfo.error) ||
          (isNil(progressInfo) && coverPhotographUrl)) && (
          <FileInput
            handleFile={handleFileInput}
            label={t('Change picture').toString()}
            acceptedFileTypes={validImageTypes}
            styles={'absolute left-2 bottom-2'}
          />
        )}
      </div>
      {!isOnline && <p className="text-center text-sm">{t('Connection interrupted.')}</p>}
    </div>
  )
}

export default observer(CoverPhotographUploaded)
