import React, { MouseEventHandler } from 'react'
import { Icon } from '@iconify/react'

type SideDrawerProps = {
  onClick: MouseEventHandler<HTMLDivElement>
}

const DrawerToggle = ({ onClick }: SideDrawerProps) => (
  <div
    className="w-10 h-full flex flex-col justify-center items-center cursor-pointer box-border sm:hidden"
    onClick={onClick}
  >
    <Icon icon="fa:bars" color="#333" width="22" height="24" />
  </div>
)

export default DrawerToggle
