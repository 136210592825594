import React from 'react'

// Define interface for component props/api:
export interface DropZoneProps {
  onDragStateChange?: (isDragActive: boolean) => void
  onDrag?: () => void
  onDragIn?: () => void
  onDragOut?: () => void
  onDrop?: () => void
  onFileDrop?: (file: File) => void
  onMultiFileDrop?: (files: File[]) => void
}

const DropZone = React.memo((props: React.PropsWithChildren<DropZoneProps>) => {
  const { onDragStateChange, onFileDrop, onMultiFileDrop, onDrag, onDragIn, onDragOut, onDrop } =
    props

  // Create state to keep track when dropzone is active/non-active:
  const [isDragActive, setIsDragActive] = React.useState(false)
  // Prepare ref for dropzone element:
  const dropZoneRef = React.useRef<null | HTMLDivElement>(null)

  // Create handler for dragenter event:
  const handleDragIn = React.useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      onDragIn?.()

      if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
        setIsDragActive(true)
      }
    },
    [onDragIn]
  )

  // Create handler for dragleave event:
  const handleDragOut = React.useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      onDragOut?.()

      setIsDragActive(false)
    },
    [onDragOut]
  )

  // Create handler for dragover event:
  const handleDrag = React.useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      onDrag?.()
      if (!isDragActive) {
        setIsDragActive(true)
      }
    },
    [isDragActive, onDrag]
  )

  const handleDrop = React.useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      setIsDragActive(false)
      onDrop?.()

      const files = event.dataTransfer.files

      if (onMultiFileDrop && files.length > 0) {
        onMultiFileDrop?.(Array.from(files))
      }
      if (files.length === 1 && onFileDrop) {
        const file = files[0]
        onFileDrop?.(file)
      }
    },
    [onDrop, onFileDrop, onMultiFileDrop]
  )

  // Obser active state and emit changes:
  React.useEffect(() => {
    onDragStateChange?.(isDragActive)
  }, [isDragActive])

  // Attach listeners to dropzone on mount:
  React.useEffect(() => {
    const tempZoneRef = dropZoneRef?.current
    if (tempZoneRef) {
      tempZoneRef.addEventListener('dragenter', handleDragIn)
      tempZoneRef.addEventListener('dragleave', handleDragOut)
      tempZoneRef.addEventListener('dragover', handleDrag)
      tempZoneRef.addEventListener('drop', handleDrop)
    }

    // Remove listeners from dropzone on unmount:
    return () => {
      tempZoneRef?.removeEventListener('dragenter', handleDragIn)
      tempZoneRef?.removeEventListener('dragleave', handleDragOut)
      tempZoneRef?.removeEventListener('dragover', handleDrag)
      tempZoneRef?.removeEventListener('drop', handleDrop)
    }
  }, [])

  // Render <div> with ref and children:
  return (
    <div className="h-full w-full flex justify-center align-center items-center" ref={dropZoneRef}>
      {props.children}
    </div>
  )
})

DropZone.displayName = 'DropZone'

export default DropZone
