import { Photograph } from 'shared/models/Photograph'
import { Currency } from 'shared/util/Currency'
import { CartLine, CartLineType } from './CartLine'
import { TagPackageType } from '../Cart'
import Album from '../../Album'
import { Event } from '../../Event'
import { isNil } from 'lodash'
import { formatDateShort } from '../../../utility'

/**
 * Class representing a cart line for a package of photographs.
 */
export class PackageCartLine extends CartLine {
  /** The type of package (e.g., BURST, FACE, TEXT). */
  packageType: TagPackageType
  /** The tag ID associated with the package. */
  tagId: string
  /** An array of first 5 photographs included in the package. */ //TODO: remove ???
  previewPhotographs: Photograph[]
  /** The quantity of photographs included in the package. */
  quantityOfPhotographs: number
  /** The albums associated with the package. */
  albums: Album[]
  /** The event associated with the package. */
  event?: Event

  /**
   * Creates an instance of PackageCartLine.
   * @param packageType - The type of package.
   * @param tagId - The tag ID for the package.
   * @param subtotalPrice - The price of the package before discounts.
   * @param totalPrice - The price of the package after discounts.
   * @param discountTotal - The total discount applied to the package.
   * @param currency - The currency of the package.
   * @param previewPhotographs - An array of first 5 photographs included in the package.
   * @param quantityOfPhotographs - The quantity of photographs included in the package.
   * @param albums - The albums associated with the package.
   * @param event - The event associated with the package.

   */
  constructor(
    subtotalPrice: number,
    totalPrice: number,
    discountTotal: number,
    currency: Currency,
    packageType: TagPackageType,
    tagId: string,
    previewPhotographs: Photograph[],
    quantityOfPhotographs: number,
    albums: Album[],
    event?: Event
  ) {
    if (previewPhotographs.length > 5) {
      throw new Error('Preview photographs array cannot contain more than 5 photographs')
    }
    super(CartLineType.Package, subtotalPrice, totalPrice, discountTotal, currency)
    this.packageType = packageType
    this.tagId = tagId
    this.previewPhotographs = previewPhotographs
    this.quantityOfPhotographs = quantityOfPhotographs
    this.albums = albums
    this.event = event
  }

  /**
   * Returns the title of the package.
   * @returns The title of the package.
   */
  getTitle(): string {
    return !isNil(this.event)
      ? this.event.name
      : `Album from ${formatDateShort(this.albums[0].takenDate)}`
  }

  /**
   * Returns the details of the package.
   * @returns The details of the package.
   */
  getDetails(): string {
    return !isNil(this.event)
      ? `${this.event!.location!.spotName} | ${this.event.activity!.name}`
      : `${this.albums[0].location.spotName} | ${this.albums[0].activity.name}`
  }

  getGroupKey(): string {
    return !isNil(this.event) ? `event-${this.event.id}` : `album-${this.albums[0].id}`
  }

  getKey(): string {
    return !isNil(this.event)
      ? `event-package-${this.event.id}-${this.tagId}`
      : `album-package-${this.albums[0].id}-${this.tagId}`
  }
}
