import React from 'react'
import HeroSectionDesktop from './components/HeroSectionDesktop'
import HeroSectionMobile from './components/HeroSectionMobile'

type HeroSectionProps = {
  goTo: (e: any, path: string) => void
  isAuthenticated: boolean
}

const HeroSection = ({ goTo, isAuthenticated }: HeroSectionProps) => {
  return (
    <>
      <div className="hidden sm:block">
        <HeroSectionDesktop goTo={goTo} isAuthenticated={isAuthenticated} />
      </div>
      <div className="block sm:hidden">
        <HeroSectionMobile goTo={goTo} isAuthenticated={isAuthenticated} />
      </div>
    </>
  )
}

export default HeroSection
