import { DropdownOption } from 'components/UI/Dropdown/Dropdown'
import PaymentStore from 'containers/Payment/PaymentStore'
import PurchaseStore from 'containers/Purchases/PurchaseStore'
import { isNil } from 'lodash'
import { Photograph } from 'shared/models/Photograph'
import JSZip from 'jszip'
import { toast } from 'react-toastify'

export const UnknownPhotographKey = 'Unknown'

export const BurstModeIntervalOption = { value: 'bursts', label: 'Bursts' }

export const TimeIntervalOptions: DropdownOption[] = [
  { value: '15', label: '15 minutes' },
  { value: '30', label: '30 minutes' },
  { value: '60', label: '60 minutes' },
]

export class PhotographUtils {
  public static sortIntervals = (a, b) => {
    const timeA = parseInt(a.replace(':', ''))
    const timeB = parseInt(b.replace(':', ''))
    return timeA - timeB
  }

  public static buildPhotographsBlocksByInterval = (
    photographs: Photograph[],
    selectedInterval: DropdownOption
  ): Map<string, Photograph[]> => {
    if (selectedInterval.value === BurstModeIntervalOption.value) {
      return this.groupByBursts(photographs, 3000)
    }

    const photographBlocksByInterval = new Map<string, Photograph[]>()
    for (const photograph of photographs) {
      let key = !isNil(photograph.takenDate)
        ? new Date(photograph.takenDate).getHours().toString()
        : UnknownPhotographKey

      if (key !== UnknownPhotographKey) {
        if (selectedInterval.value === '60') {
          key = key + ':00'
        }

        if (selectedInterval.value === '30') {
          if (new Date(photograph.takenDate).getMinutes() < 30) {
            key = key + ':00'
          } else if (new Date(photograph.takenDate).getMinutes() >= 30) key = key + ':30'
        }

        if (selectedInterval.value === '15') {
          if (new Date(photograph.takenDate).getMinutes() < 15) {
            key = key + ':00'
          } else if (
            new Date(photograph.takenDate).getMinutes() >= 15 &&
            new Date(photograph.takenDate).getMinutes() < 30
          ) {
            key = key + ':15'
          } else if (
            new Date(photograph.takenDate).getMinutes() >= 30 &&
            new Date(photograph.takenDate).getMinutes() < 45
          ) {
            key = key + ':30'
          } else key = key + ':45'
        }
      }
      const values = photographBlocksByInterval.get(key)
      if (isNil(values)) {
        photographBlocksByInterval.set(key, [photograph])
      } else {
        photographBlocksByInterval.set(key, values.concat(photograph))
      }
    }

    return photographBlocksByInterval
  }

  public static groupByBursts(
    photographs: Photograph[],
    timeThreshold: number
  ): Map<string, Photograph[]> {
    // Sort photographs by their takenDate in ascending order
    const sortedPhotographs = [...photographs].sort(
      (a, b) => new Date(a.takenDate).getTime() - new Date(b.takenDate).getTime()
    )

    const bursts = new Map<string, Photograph[]>()
    let currentBurst: Photograph[] = []
    let burstCounter = 1

    for (let i = 0; i < sortedPhotographs.length; i++) {
      // Get the current photograph and the next one
      const current = sortedPhotographs[i]
      const next = sortedPhotographs[i + 1]

      // Add the current photograph to the current burst
      currentBurst.push(current)

      // Check if there's no next photograph or if the time difference between
      // the current and next photograph is greater than the time threshold
      if (
        !next ||
        new Date(next.takenDate).getTime() - new Date(current.takenDate).getTime() > timeThreshold
      ) {
        // Add the current burst to the bursts Map with a unique key
        bursts.set(`Burst ${burstCounter}`, currentBurst)
        burstCounter++
        // Reset the current burst to start a new one
        currentBurst = []
      }
    }

    return bursts
  }

  public static mantainScrollPositionAfterClosingModal = (
    photographId: string | null,
    photographs: Photograph[]
  ) => {
    const index = photographs.findIndex((p) => p.id === photographId)
    if (index !== -1) {
      const photographCardContainer = document.querySelector(
        `[data-photograph-id="${photographId}"]`
      )
      const yOffset = -100
      if (photographCardContainer) {
        const offset =
          photographCardContainer.getBoundingClientRect()?.top + window.scrollY + yOffset
        window.scrollTo({ top: offset, behavior: 'smooth' })
      }
    }
  }

  public static getTimeKey = (date: Date | string, selectedInterval: string): string => {
    const dateObj = typeof date === 'string' ? new Date(date) : date
    let key = dateObj.getHours().toString()

    if (selectedInterval === '30') {
      if (dateObj.getMinutes() < 30) {
        key = key + ':00'
      } else {
        key = key + ':30'
      }
    } else if (selectedInterval === '15') {
      if (dateObj.getMinutes() < 15) {
        key = key + ':00'
      } else if (dateObj.getMinutes() < 30) {
        key = key + ':15'
      } else if (dateObj.getMinutes() < 45) {
        key = key + ':30'
      } else {
        key = key + ':45'
      }
    } else if (selectedInterval === '60') {
      key = key + ':00'
    }

    return key
  }

  public static downloadPurchasedImages = async (
    purchaseId: string,
    photographsFileNames: string[],
    store: PaymentStore | PurchaseStore,
    t: any
  ) => {
    const ids: string[] = []
    for (const photographFileName of photographsFileNames) {
      const parts = photographFileName.split('.')
      const photographId = parts[0]
      ids.push(photographId)
    }

    try {
      const urls = await store.fetchPurchasedPhotographsUrls(purchaseId, ids)

      if (photographsFileNames.length > 1) {
        const zip = new JSZip()

        let fileNameCounter = 0
        for (const url of urls) {
          const response = await fetch(url)
          const blob = await response.blob()
          const fileName = photographsFileNames[fileNameCounter]
          fileNameCounter++

          zip.file(fileName, blob)
        }

        const zipBlob = await zip.generateAsync({ type: 'blob' })
        const zipUrl = URL.createObjectURL(zipBlob)

        const link = document.createElement('a')
        link.href = zipUrl
        link.download = 'lumepic.zip'
        link.click()

        URL.revokeObjectURL(zipUrl)
      } else {
        const link = document.createElement('a')
        link.href = urls
        link.click()
      }
    } catch (e) {
      toast.error(t('Error downloading photographs'))
    }
  }
}
