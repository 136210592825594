import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag, faTrash } from '@fortawesome/free-solid-svg-icons'
import storesContext from 'providers/storesContext'
import {
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { NewCodeDiscount } from 'shared/models/NewCodeDiscount'
import i18next from 'i18next'
import CreateCodeDiscountCouponForm from './components/CreateCodeDiscountCouponForm'
import NewCodeDiscountStore from './NewCodeDiscountStore'
import DeactivateCodeDiscountModal from '../PersonalDiscountCode/DeactivateCodeDiscountModal'
import { toast } from 'react-toastify'

const PhotographerDiscountsSection = () => {
  const { t } = useTranslation()
  const { authStore } = useContext(storesContext)!
  const [newCodeDiscountStore] = useState(() => new NewCodeDiscountStore(authStore))
  const [openCreateDiscountCouponForm, setOpenCreateDiscountCouponForm] = useState(false)

  const [showDeactivateDiscountModal, setShowDeactivateDiscountModal] = useState<{
    display: boolean
    code: NewCodeDiscount | null
  }>({
    display: false,
    code: null,
  })

  const [expandedRow, setExpandedRow] = useState(null)

  const handleAccordionChange = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null)
    } else {
      setExpandedRow(rowId)
    }
  }

  function getParsedDate(date: Date) {
    const language = i18next.language
    return new Date(date).toLocaleDateString(language, { dateStyle: 'short' })
  }

  function getCompleteApplication(code: NewCodeDiscount): string {
    return `${code.amount}${code.unit === 'PERCENTAGE' ? '%' : '$'} ${t('discount applicable')}${
      !code.albumId && !code.activityId && !code.eventId && !code.buyerId
        ? ` ${t('in all my albums')}.`
        : ''
    } ${
      code.albumId
        ? `${t('in Album').toLowerCase()} ${code.album?.location.spotName} - ${getParsedDate(
            code.album!.takenDate
          )}.`
        : ''
    }
        ${code.activityId ? ` ${t('in all my albums from sport')} ${code.activity?.name}.` : ''}
    ${code.eventId ? ` ${t('in all my albums from event')} ${code.event?.name}.` : ''}${
      code.buyerId
        ? ` ${t('only in purchases from user')} ${code.buyer?.firstName} ${code.buyer?.lastName}.`
        : ''
    }${
      code.validFrom || code.expirationDate
        ? ` ${t('Valid')}${code.validFrom ? ` ${t('from')} ${getParsedDate(code.validFrom)}` : ''}${
            code.expirationDate ? ` ${t('until')} ${getParsedDate(code.expirationDate)}.` : ''
          }`
        : ''
    }`
  }

  function getApplicationColumnValue(code: NewCodeDiscount): string {
    if (code.activityId) {
      return `${t('Sport').toUpperCase()}: ${code.activity!.name}`
    } else if (code.albumId) {
      return `${t('Album').toUpperCase()}: ${code.album!.location.spotName} - ${getParsedDate(
        code.album!.takenDate
      )}
      `
    } else if (code.buyerId) {
      return `${t('User').toUpperCase()}: ${code.buyer?.firstName} ${code.buyer?.lastName}`
    } else if (code.eventId) {
      return `${t('Event').toUpperCase()}: ${code.event?.name}`
    } else {
      return `${t('All my albums')}`
    }
  }
  const afterSaveNewDiscountCode = () => {
    toast.success(t('Discount code created successfully!'), {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    authStore.getUser()
  }

  const createDiscountCode = () => {
    newCodeDiscountStore.createCodeDiscount().then((newCodeDiscount) => {
      if (newCodeDiscount) {
        setOpenCreateDiscountCouponForm(false)
        afterSaveNewDiscountCode()
        newCodeDiscountStore.fetchCodeDiscounts()
      }
    })
  }

  function handleOpenDeactivateDiscountModal(code: NewCodeDiscount) {
    setShowDeactivateDiscountModal({ code: code, display: true })
  }

  async function deactivateCodeDiscount(code: NewCodeDiscount) {
    await newCodeDiscountStore.deactivateCodeDiscount(code)
    setShowDeactivateDiscountModal({ code: null, display: false })
  }

  useEffect(() => {
    newCodeDiscountStore.fetchCodeDiscounts()
  }, [newCodeDiscountStore])

  return (
    <>
      <div className="flex flex-col p-3 my-4 bg-white border-t-4 md:px-10 border-primary">
        <div className="flex justify-between mb-2 text-gray-900">
          <div className="justify-start space-x-2">
            <span className="text-green-500">
              <i>
                <FontAwesomeIcon icon={faTag} />
              </i>
            </span>
            <span className="font-semibold tracking-wide">{t('Granted discounts')}</span>
          </div>
          <span
            className="h-auto px-2 font-normal rounded md:flex bg-primary hover: cursor-pointer"
            onClick={() => setOpenCreateDiscountCouponForm(true)}
          >
            {t('Create coupon')}
          </span>
        </div>
        <div className="-p-2">
          {newCodeDiscountStore.codeDiscounts.length > 0 ? (
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className="w-min" key="code">
                      {t('Code')}
                    </TableCell>
                    <TableCell key="amount">{t('Amount')}</TableCell>
                    <TableCell key="application">{t('Application')}</TableCell>
                    <TableCell key="expand"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newCodeDiscountStore.codeDiscounts
                    .filter((code) => {
                      return !code.isSuspended
                    })
                    .map((code, index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            className="p-2 hover:cursor-pointer"
                            onClick={() => handleAccordionChange(index)}
                          >
                            <TableCell className="">
                              <span className="p-2 md:border md:border-solid md:rounded">
                                {code.code}
                              </span>
                            </TableCell>
                            <TableCell>{`${
                              code.unit === 'PERCENTAGE' ? `${code.amount}%` : `$${code.amount}`
                            }`}</TableCell>
                            <TableCell>{`${getApplicationColumnValue(code)}`}</TableCell>
                            <TableCell className="w-1">
                              <span>{`${expandedRow === index ? '-' : '+'}`}</span>
                            </TableCell>
                          </TableRow>
                          {expandedRow === index && (
                            <TableRow className="border-none">
                              <TableCell colSpan={4}>
                                <AccordionDetails>
                                  <div className="flex justify-between gap-8">
                                    <div>
                                      <p className="my-3">
                                        <b>{t('Description')}:</b> {code.description}
                                      </p>
                                      <p>
                                        <b>{t('Application')}:</b> {getCompleteApplication(code)}
                                      </p>
                                    </div>
                                    <div className="self-center">
                                      <span
                                        className="hover:cursor-pointer"
                                        onClick={() => handleOpenDeactivateDiscountModal(code)}
                                      >
                                        <i>
                                          <FontAwesomeIcon icon={faTrash} />
                                        </i>
                                      </span>
                                    </div>
                                  </div>
                                </AccordionDetails>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className="mt-4">{t('No discount coupons created so far')}</div>
          )}
        </div>
      </div>
      <CreateCodeDiscountCouponForm
        open={openCreateDiscountCouponForm}
        handleClose={() => setOpenCreateDiscountCouponForm(false)}
        handleSave={createDiscountCode}
        store={newCodeDiscountStore}
      />
      <DeactivateCodeDiscountModal
        show={showDeactivateDiscountModal.display}
        code={showDeactivateDiscountModal.code!}
        onClose={() => setShowDeactivateDiscountModal({ display: false, code: null })}
        onConfirm={() => deactivateCodeDiscount(showDeactivateDiscountModal.code!)}
        loading={newCodeDiscountStore.isLoading}
      />
    </>
  )
}

export default observer(PhotographerDiscountsSection)
