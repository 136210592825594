import RequestActivityInterface from 'services/RequestInterfaces/RequestActivity/RequestActivity.interface'

export class RequestedActivity {
  name: string

  public static init(data: RequestActivityInterface): RequestedActivity {
    const requestedActivity = new RequestedActivity()
    if (data && data.name) {
      requestedActivity.name = data.name
    }
    return requestedActivity
  }

  getLabel() {
    return {
      label: `${this.name}`,
    }
  }

  public static clone(data: RequestActivityInterface): RequestedActivity {
    const clone = new RequestedActivity()
    if (data && data.name) {
      clone.name = data.name
    }
    return clone
  }
}
