import { makeAutoObservable, runInAction } from 'mobx'
import { Activity } from '../shared/models/Activity'
import ActivityService from '../services/ActivityService'
import AuthStore from './AuthStore'

class ActivityStore {
  private error: any
  public isLoading: boolean
  public isLoadingRequestActivity: boolean
  public activities: Activity[]
  public requestedActivity: Activity

  constructor(
    private readonly activityService: ActivityService,
    private readonly authStore: AuthStore
  ) {
    this.error = null
    this.isLoading = false
    this.activities = []
    makeAutoObservable(this)
    this.fetchActivities()
  }

  async fetchActivities(): Promise<Activity[]> {
    if (this.activities.length > 0) {
      return this.activities
    } else {
      runInAction(() => {
        this.isLoading = true
      })
      try {
        const response = await this.activityService.fetchActivities()
        runInAction(() => {
          this.activities = response
          this.isLoading = false
        })
        return this.activities
      } catch (e: any) {
        this.error = e?.response
        this.isLoading = false
        return []
      }
    }
  }

  async requestNewActivity(name: string): Promise<Activity | undefined> {
    runInAction(() => {
      this.isLoadingRequestActivity = true
    })

    try {
      const response = await this.activityService.requestNewActivity(
        {
          name,
        },
        this.authStore.getToken()
      )
      runInAction(() => {
        this.isLoadingRequestActivity = false
      })
      this.requestedActivity = response
      this.activities.push(response)

      this.activities.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })

      return response
    } catch (e: any) {
      runInAction(() => {
        if (e?.message) {
          this.error = e.message
        }
        this.isLoadingRequestActivity = false
      })
      return
    }
  }

  getActivity(activityId?: string): Activity | undefined {
    if (!activityId) {
      return undefined
    }
    return this.activities.find((activity) => activity.id === activityId)
  }
}

export default ActivityStore
