import { Purchase } from '../models/Purchase'
import FBTracker, { MetaEvents } from './FBTracker'
import GATracker from './GATracker'
import { Photograph } from '../models/Photograph'
import Cart from '../models/Cart/Cart'
import Album from '../models/Album'

/*
 * @docs https://www.facebook.com/business/help/402791146561655?id=1205376682832142
 */

export default class Tracker {
  public static pageView(page: string, title: string): void {
    FBTracker.track(MetaEvents.ViewContent)
    GATracker.pageView(page, title)
  }

  public static login() {
    GATracker.login()
  }

  public static register() {
    FBTracker.track(MetaEvents.CompleteRegistration)
    GATracker.register()
  }

  public static purchase(purchase: Purchase): void {
    FBTracker.trackPurchase(purchase)
    GATracker.trackPurchase(purchase)
  }

  public static contactUs(): void {
    FBTracker.track(MetaEvents.Contact)
    GATracker.trackLead()
  }

  public static viewCart(): void {
    // TODO: implement
    // FBTracker.track(MetaEvents.ViewCart)
    // GATracker.viewCart()
  }

  public static addToCart(photograph: Photograph): void {
    FBTracker.track(MetaEvents.AddToCart)
    GATracker.addToCart(photograph)
  }

  public static initiateCheckout(cart: Cart): void {
    FBTracker.track(MetaEvents.InitiateCheckout)
    GATracker.beginCheckout(cart)
  }

  public static addPaymentInformation(cart: Cart): void {
    FBTracker.track(MetaEvents.AddPaymentInfo)
    GATracker.addPaymentInformation(cart)
  }

  public static addPackagePaymentInformation(photographs, album): void {
    FBTracker.track(MetaEvents.AddPaymentInfo)
    GATracker.addPackagePaymentInformation(photographs, album)
  }

  static viewAlbumDetails(album: Album, photographs: Photograph[]) {
    FBTracker.track(MetaEvents.ViewContent)
    GATracker.viewItemList(album, photographs)
  }

  static downloadFreePhotograph(
    photographId: string,
    albumId?: string,
    eventId?: string,
    landingPath?: string
  ) {
    FBTracker.track(MetaEvents.DownloadFreePhotograph)
    GATracker.downloadFreePhotograph(photographId, albumId, eventId, landingPath)
  }

  static filterByTag(tagKey?: string, userId?: string | null, albumId?: string, eventId?: string) {
    FBTracker.track(MetaEvents.FilterByTag)
    GATracker.filterByTag(tagKey, userId, albumId, eventId)
  }
  static filterByFaceRecognition(
    userId?: string | null,
    albumId?: string | null,
    eventId?: string | null
  ) {
    FBTracker.track(MetaEvents.FilterByFaceRecognition)
    GATracker.filterByFaceRecognition(userId, albumId, eventId)
  }

  public static goToPhotographersRegister() {
    FBTracker.track(MetaEvents.GoToPhotographersRegister)
    GATracker.goToPhotographersRegister()
  }
}
