import { Skeleton } from '@mui/material'
import React from 'react'

const SkeletonLoaderPhotographCard = () => {
  return (
    <>
      <div className="w-full grid grid-cols-6  gap-2 mt-2 px-1.5">
        {[...Array(18)].map((_, index) => (
          <div
            className="w-full overflow-hidden flex flex-col gap-2 col-span-3 sm:col-span-2 lg:col-span-1"
            key={index}
          >
            <Skeleton variant="rounded" height={200} />
            <div className="flex gap-2 items-center justify-end">
              <Skeleton variant="rounded" height={20} width={40} />
              <Skeleton variant="circular" height={22} width={22} />
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default SkeletonLoaderPhotographCard
