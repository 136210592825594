import React from 'react'
import SalesBarChart from './components/SalesBarChart'
import SalesByTypePieChart from './components/SalesByTypePieChart'

type PhotographerSalesProps = {
  salesInsights: { year: number; month: number; salesCount: number; salesAmountInUsd: number }[]
  independentAlbumSales: { salesAmountInUsd: number; salesCount: number }
  eventAlbumsSales: { salesAmountInUsd: number; salesCount: number }
}

const PhotographerSales = ({
  salesInsights,
  independentAlbumSales,
  eventAlbumsSales,
}: PhotographerSalesProps) => {
  return (
    <div className="w-full bg-white py-4">
      <div className="flex flex-col gap-2 md:flex md:flex-row md:justify-between md:gap-4 md:mx-2">
        <div className="w-full p-4 bg-white rounded-lg shadow border border-bg_details border-rounded-lg">
          <SalesBarChart salesInsights={salesInsights} />
        </div>
        <div className="w-full p-4 flex flex-col border border-bg_details border-rounded-lg max-h-80 md:w-1/3 md:max-h-full bg-white rounded-lg shadow">
          <SalesByTypePieChart
            independentAlbumSales={independentAlbumSales}
            eventAlbumsSales={eventAlbumsSales}
          />
        </div>
      </div>
    </div>
  )
}

export default PhotographerSales
