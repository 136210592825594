import React, { MouseEventHandler, useContext, useEffect, useState } from 'react'
import { faDownload, faInfoCircle, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Album from 'shared/models/Album'
import 'moment/locale/es'
import moment from 'moment'
import { Photograph } from 'shared/models/Photograph'
import { isNil } from 'lodash'
import PhotographActions from './PhotographActions'
import imageSizeIcon from '../../assets/icons/v2/imageSize.svg'
import shareIcon from '../../assets/icons/v2/share.svg'
import fileIcon from '../../assets/icons/v2/file-type.svg'
import { Accordion, Card } from 'react-bootstrap'
import CustomToggleFull from 'components/UI/CustomToggle/CustomToggleFull'
import { useTranslation } from 'react-i18next'
import ShareModal from 'components/UI/ShareModal'
import storesContext from 'providers/storesContext'
import { observer } from 'mobx-react'
import Spinner from 'components/UI/Spinner'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { Avatar } from '@mui/material'
import DiscountProgressBar from 'components/AlbumDetails/components/QuantityDiscount/components/DiscountProgressBar'
import { QuantityDiscount } from 'shared/models/QuantityDiscount'
import Cart from '../../shared/models/Cart/Cart'

type PhotographModalMobileProps = {
  album: Album
  setImageLoaded: any
  currentPhotographToDisplay: Photograph
  imageClass: string
  imageLoaded: boolean
  goBack: () => void
  canEdit: boolean
  goNext: () => void
  isExpanded: boolean
  setIsExpanded: any
  currentCart?: Cart
  photographs: Photograph[]
  onClose: MouseEventHandler<HTMLDivElement>
  onDownloadFreePhotograph: (photographId: string) => void
  tiersEarned?: number
  quantityDiscount?: QuantityDiscount | null
}
const PhotographModalMobile = ({
  album,
  setImageLoaded,
  currentPhotographToDisplay,
  imageClass,
  imageLoaded,
  goBack,
  canEdit,
  goNext,
  isExpanded,
  setIsExpanded,
  currentCart,
  photographs,
  onClose,
  onDownloadFreePhotograph,
  tiersEarned,
  quantityDiscount,
}: PhotographModalMobileProps) => {
  const { t } = useTranslation()
  const { cartStore } = useContext(storesContext)!
  const [shareModalOpened, setShareModalOpened] = useState(false)
  const [sliderLoaded, setSliderLoaded] = useState(false)
  if (cartStore.isLoading) {
    return <Spinner />
  }
  const currentIndex = photographs.findIndex((p) => p.id === currentPhotographToDisplay.id)
  const albumImagesInCartQuantity = currentCart?.getPhotographCount(album.id, album.event?.id)
  const swipeUpdateInfo = (index: number) => {
    if (index > 0 && index === currentIndex + 1) {
      goNext()
    }
    if (index < photographs.length - 1 && index === currentIndex - 1) {
      goBack()
    }
  }
  useEffect(() => {
    setSliderLoaded(true)
  }, [])
  const [activeIndex, setActiveIndex] = React.useState<number>(0)
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: false,
    detailsChanged(s) {
      setActiveIndex(s.track.details.rel)
    },
    dragEnded(s) {
      setTimeout(() => {
        swipeUpdateInfo(s.track.details.rel)
      }, 200)
    },
    initial: currentIndex,
    renderMode: 'performance',
    slides: {
      spacing: 15,
      number: photographs.length,
    },
  })
  const breadcrumbs = (totalSlides: number, activeIndex: number) => (
    <div className="flex gap-1 items-center">
      {[...Array(totalSlides)].map((_, index) => (
        <div
          key={index}
          className={`${
            index === activeIndex
              ? 'bg-primary w-2 h-2'
              : index < activeIndex - 2 || index > activeIndex + 2
              ? 'hidden'
              : index < activeIndex - 1 || index > activeIndex + 1
              ? 'bg-lumepic-dark_grey opacity-40 w-1 h-1'
              : 'bg-lumepic-dark_grey opacity-80 w-1.5 h-1.5'
          }`}
          style={{
            borderRadius: '50%',
            transition: 'height 0.3s ease, width 0.3s ease-in-out, background 0.3s ease',
          }}
        />
      ))}
    </div>
  )
  if (!sliderLoaded) {
    return <Spinner />
  }

  return (
    <>
      <div ref={sliderRef} className="keen-slider mt-14 items-end">
        {photographs.map((p, idx) => (
          <div key={idx} className="keen-slider__slide">
            <div className="w-full relative z-40">
              <div className="absolute z-40 flex h-full w-full">
                <div
                  className="h-full w-1/2"
                  onClick={(e) => {
                    e.stopPropagation()
                    instanceRef.current?.prev()
                  }}
                ></div>
                <div
                  className="h-full w-1/2"
                  onClick={(e) => {
                    e.stopPropagation()
                    instanceRef.current?.next()
                  }}
                ></div>
              </div>
              <div onClick={onClose} className="absolute z-50 right-2 top-3">
                <div
                  className="flex items-center justify-center w-5 h-5 bg-black"
                  style={{ borderRadius: '50%' }}
                >
                  <FontAwesomeIcon className="text-white" icon={faXmark} />
                </div>
              </div>
              <img
                onLoad={() => setImageLoaded(true)}
                src={p?.url}
                alt={p ? 'Photograph' : 'loading...'}
                className={`object-contain ${imageClass} ${!imageLoaded && 'hidden'}`}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="bg-white rounded-0">
        {photographs.length > 1 && (
          <div className="flex items-center justify-between bg-white">
            <div className="pt-2 w-full flex items-center justify-center">
              {breadcrumbs(photographs.length, activeIndex)}
            </div>
          </div>
        )}
        <div className="mx-2 pt-2">
          {!isNil(currentPhotographToDisplay) && (
            <PhotographActions
              onDownloadFreePhotograph={onDownloadFreePhotograph}
              album={album}
              photograph={currentPhotographToDisplay}
              isFree={album.isFree}
              canEdit={canEdit}
            />
          )}
        </div>
        <div className="flex items-start gap-1.5 mx-3 pt-2.5">
          <FontAwesomeIcon icon={faInfoCircle} className="mt-1 text-sm text-lumepic-grey" />
          <span className="font-light text-xs mt-0.5">
            {album.isFree
              ? t('Click “Download” to access the photograph in high quality.')
              : t(
                  'By purchasing pictures, you’ll have access to them in high quality and without Lumepic watermarks.'
                )}
          </span>
        </div>
        <div className="flex w-full items-start mt-1.5">
          <div className="pt-1 rounded-lg w-1/2">
            <Accordion>
              <Card className="p-0 border-0">
                <Card.Header className="p-0 rounded-0 bg-white border-0">
                  <CustomToggleFull
                    eventKey="0"
                    opened={isExpanded}
                    onClick={() => {
                      setIsExpanded(!isExpanded)
                      window.scrollTo(0, document.body.scrollHeight)
                    }}
                    extraClassNames="flex justify-between py-2.5 px-3"
                    angleToggle
                  >
                    <span className="ml-1">{t('Information')}</span>
                  </CustomToggleFull>
                </Card.Header>
                {isExpanded && (
                  <Accordion.Collapse eventKey="0" className="w-screen">
                    <div
                      className="border-b border-t"
                      style={{ borderWidth: '1px', borderColor: '#f3f3f3' }}
                    >
                      <div className="flex items-center gap-2 px-3 py-2">
                        {!isNil(album.owner.profilePictureUrl) ? (
                          <Avatar
                            src={album.owner.profilePictureUrl}
                            sx={{ width: 45, height: 45 }}
                          />
                        ) : (
                          <Avatar sx={{ bgcolor: 'text.secondary', width: 45, height: 45 }}>
                            {album.owner.firstName?.charAt(0) + album.owner.lastName?.charAt(0)}
                          </Avatar>
                        )}
                        <div className="flex flex-col justify-center">
                          {album.owner && <span className="text-black">{album.owner.alias}</span>}
                          <div className="flex items-center gap-2">
                            <span className="text-lumepic-grey text-sm">
                              {album.location.spotName}
                            </span>
                            <span className="text-lumepic_grey">|</span>
                            <span className="text-lumepic-grey text-sm">
                              {moment.utc(album.takenDate).format('DD/MM/YYYY')}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-start gap-3 items-center px-3 pt-2 pb-1">
                        {currentPhotographToDisplay?.extension && (
                          <div className="mb-1 flex items-center">
                            <span>
                              <img
                                loading="lazy"
                                alt="File Type"
                                src={fileIcon}
                                className="w-3 ml-1.5"
                              />
                            </span>
                            <span className="ml-2.5 uppercase text-sm text-lumepic-grey">
                              {currentPhotographToDisplay?.extension}
                            </span>
                          </div>
                        )}
                        {currentPhotographToDisplay?.width &&
                          currentPhotographToDisplay?.height && (
                            <div className="mb-1 flex items-center">
                              <span>
                                <img
                                  loading="lazy"
                                  alt="imageSizeIcon"
                                  src={imageSizeIcon}
                                  className="w-4 ml-1 mr-px"
                                />
                              </span>
                              <span className="ml-2 text-sm text-lumepic-grey">
                                {currentPhotographToDisplay.width} x{' '}
                                {currentPhotographToDisplay.height} pixels
                              </span>
                            </div>
                          )}

                        {currentPhotographToDisplay?.size && (
                          <div className="mb-1 flex items-center">
                            <span className="ml-0.5">
                              <FontAwesomeIcon
                                icon={faDownload}
                                className="text-lumepic-grey text-lg mx-px"
                              />
                            </span>
                            <span className="ml-2 text-sm text-lumepic-grey">
                              {(currentPhotographToDisplay?.size * 0.000001).toFixed(2) + ' mb'}
                            </span>
                          </div>
                        )}
                      </div>
                      {tiersEarned && quantityDiscount && !album.isFree ? (
                        <div className="px-4" style={{ backgroundColor: '#f3f3f3' }}>
                          <p className="text-black text-sm py-2">
                            <span className="font-light">{t('You have')}</span>
                            <span className="mx-1 font-medium text-lg underline">
                              {albumImagesInCartQuantity}
                            </span>
                            <span className="font-light">{t('photographs from this')}</span>
                            <span className="font-light mx-1">
                              {!isNil(album.event?.id) ? t('event') : t('album')}
                            </span>
                            <span className="font-light">
                              {t('in your cart. Save when you buy more.')}
                            </span>
                          </p>

                          <div className="py-2.5">
                            <DiscountProgressBar
                              tiersEarned={tiersEarned}
                              quantityDiscount={quantityDiscount!}
                              album={album}
                              event={album.event}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Accordion.Collapse>
                )}
              </Card>
            </Accordion>
          </div>
          <div
            className="flex justify-end pr-3 mt-2.5 pt-1 items-center gap-3 w-1/2"
            onClick={() => setShareModalOpened(true)}
          >
            <span>
              <img src={shareIcon} alt="Share" className="w-5" />
            </span>
            <span>{t('Share picture')}</span>
          </div>
        </div>
      </div>
      <ShareModal
        isModalOpened={shareModalOpened}
        closeModal={() => setShareModalOpened(false)}
        photographId={currentPhotographToDisplay.id}
        albumId={album.id}
      />
    </>
  )
}
export default observer(PhotographModalMobile)
