import React, { useState, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import LoginRegisterStore from '../LoginRegisterStore'
import StoresContext from '../../../providers/storesContext'
import 'moment/locale/es'
import LoginRegisterForm from '../components/LoginRegisterForm'
import Button from 'components/UI/Button'
import { FORGOTPASSWORD } from '../../../routing/Paths'
import ServerError from '../../../components/UI/ServerError'
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../../services/axios'
import Tracker from '../../../shared/tracking'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import LoginBackground from '../../../assets/images/LoginBackground.svg'
import logoColored from '../../../assets/icons/v2/logo-colored.svg'
import Spinner from 'components/UI/Spinner'
import { SetPageTitle } from '../../../shared/utility'

const SIGN_IN_MODE = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
}

type LoginProps = {
  setSignInMode: React.Dispatch<React.SetStateAction<string>>
  googleLoginSuccess: (credentialResponse: CredentialResponse) => void
  googleLoginError: () => void
}

const Login = ({ setSignInMode, googleLoginSuccess, googleLoginError }: LoginProps) => {
  SetPageTitle('Login')
  const history = useHistory()
  const { authStore, cartStore, locationStore } = useContext(StoresContext)!
  const { t } = useTranslation()
  const [loginRegisterStore] = useState(
    () => new LoginRegisterStore(authStore, cartStore, locationStore)
  )
  const { isLoading } = loginRegisterStore

  const handleLoginSubmit = async () => {
    await loginRegisterStore.login()
    Tracker.login()
  }

  const goToForgotPassword = () => {
    history.push(FORGOTPASSWORD)
  }

  useEffect(() => {
    return () => {
      authStore.clearErrors()
    }
  }, [])

  if (isLoading) {
    return (
      <Spinner divStyles="flex justify-center h-primaryMobile md:h-primaryDesktop items-center" />
    )
  }

  return (
    <div className="w-full">
      <div className="relative md:hidden">
        <img src={LoginBackground} className="border-t-lg" alt="background" />
        <div className="absolute flex items-center gap-1.5 left-7" style={{ bottom: '47%' }}>
          <img src={logoColored} className="w-8 h-8" alt="lumepic-logo" />
          <h2 className="mb-0 text-2xl font-semibold text-lumepic-light_black">
            {t('Welcome back')}
          </h2>
        </div>
      </div>
      <div className="w-full px-4 md:px-8 py-4">
        <h2 className="hidden mb-1 text-4xl font-medium text-lumepic-light_black md:block">
          {t('Login')}
        </h2>
        <p className="hidden mb-2 text-lumepic-light_black md:block">
          {t('Select a method to log in.')}
        </p>
        {authStore.error && <ServerError message={t(`${authStore.error}`).toString()} />}
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleLoginSubmit()
          }}
        >
          <LoginRegisterForm loginRegisterStore={loginRegisterStore} isLogin={SIGN_IN_MODE.LOGIN} />
          <div className="flex justify-end mt-1.5">
            <span
              className="text-xs border-b-2 border-lumepic-black text-lumepic-black hover:cursor-pointer"
              onClick={goToForgotPassword}
            >
              {t('Forgot my password')}
            </span>
          </div>
          <Button btnType="PrimaryAction" extraStyle="w-full mt-4" onClick={() => {}}>
            {t('Login')}
          </Button>
        </form>
        <div className="flex items-center my-2">
          <div className="w-full border-b border-lumepic-dark_grey" />
          <span className="px-2 text-xs text-lumepic-dark_grey">{t('Or')}</span>
          <div className="w-full border-b border-lumepic-dark_grey" />
        </div>
        <div className="flex flex-col items-center justify-center gap-4">
          <div
            className="text-lumepic-grey bg-lumepic-light_grey hover:shadow-lg duration-100 border rounded-lg p-1.5 w-full text-center h-10 cursor-pointer"
            onClick={() => setSignInMode(SIGN_IN_MODE.REGISTER)}
          >
            {t('Register')}
          </div>
          <GoogleLogin
            size="large"
            width="150px"
            useOneTap
            onSuccess={googleLoginSuccess}
            onError={googleLoginError}
          />
        </div>
      </div>
    </div>
  )
}

export default withErrorHandler(observer(Login), axios)
