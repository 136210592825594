import { makeAutoObservable, runInAction } from 'mobx'
import Album from 'shared/models/Album'
import AuthStore from 'stores/AuthStore'
import AlbumService from '../../../services/AlbumService'
import PhotographsService from '../../../services/PhotographsService'
import { Photograph } from '../../../shared/models/Photograph'

class MyAlbumDetailsStore {
  private readonly albumService: AlbumService
  private readonly photographsService: PhotographsService
  public album: Album
  public photographs: Photograph[]
  public isLoadingAlbum: boolean
  public isLoadingPhotographs: boolean
  public error: boolean
  public page = 1
  public photographsPerPage = 50
  public photographCount = 0
  constructor(id: string, private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.albumService = new AlbumService()
    this.photographsService = new PhotographsService()
    this.authStore = authStore
  }

  reset() {
    this.album = new Album()
    this.photographs = []
    this.isLoadingAlbum = false
    this.isLoadingPhotographs = false
    this.page = 1
    this.photographsPerPage = 20
    this.error = false
  }

  async fetchAlbum(albumId: string): Promise<void> {
    this.isLoadingAlbum = true

    try {
      const albumResponse = await this.albumService.fetchAlbum(albumId, this.authStore.getToken())
      runInAction(() => {
        this.isLoadingAlbum = false
        this.album = Album.init(albumResponse)
      })
    } catch (e) {
      this.isLoadingAlbum = false
    }
  }

  async fetchAlbumPhotographs(albumId: string): Promise<void> {
    this.isLoadingPhotographs = true
    const limit = this.photographsPerPage
    const skip = (this.page - 1) * this.photographsPerPage
    try {
      const response = await this.photographsService.fetchAlbumPhotographs(
        albumId,
        limit,
        skip,
        this.authStore.getToken()
      )
      runInAction(() => {
        this.isLoadingPhotographs = false
        this.photographs = response.items
        this.photographCount = response.count
      })
    } catch (e) {
      this.isLoadingPhotographs = false
    }
  }
  setPage(page: number) {
    this.page = page
  }

  async deletePhotograph(id: string): Promise<void> {
    this.isLoadingPhotographs = true
    try {
      await this.photographsService.deletePhotograph(id, this.authStore.getToken())
      runInAction(() => {
        this.isLoadingPhotographs = false
      })
    } catch (e) {
      runInAction(() => {
        this.isLoadingPhotographs = false
      })
    }
  }
}

export default MyAlbumDetailsStore
