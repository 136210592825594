import React from 'react'
import {
  faClock,
  faCloudArrowUp,
  faGlobeAmericas,
  faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons'
import Button from 'components/UI/Button'
import { useTranslation } from 'react-i18next'
import BenefitsFrameDesktop from './components/BenefitsFrameDesktop'
import { scrollToElement } from 'containers/PhotographersLanding/utils/utils'
import Tracker from 'shared/tracking'

const PhotographersBenefitsDesktop = () => {
  const { t } = useTranslation()
  return (
    <div className="bg-white">
      <div className="py-48 container mx-auto flex flex-col gap-6">
        <div className="w-full flex justify-between items-center">
          <h3 className="text-2xl md:text-3xl lg:text-4xl text-lumepic-light_black font-bold">
            {t('Benefits')}
          </h3>
          <Button
            btnType="SecondaryAction"
            onClick={() => {
              Tracker.goToPhotographersRegister()
              scrollToElement('photographer-register')
            }}
          >
            {t('Start selling for free')}
          </Button>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <BenefitsFrameDesktop icon={faCloudArrowUp} title="Upload albums for free" />
          <BenefitsFrameDesktop icon={faGlobeAmericas} title="Sell from anywhere in the world" />
          <BenefitsFrameDesktop icon={faWandMagicSparkles} title="Face and text recognition" />
          <BenefitsFrameDesktop icon={faClock} title="Save time and focus on what matters" />
        </div>
      </div>
    </div>
  )
}

export default PhotographersBenefitsDesktop
