import React, { useEffect } from 'react'
import styles from '../../../Home.module.scss'
import { useTranslation } from 'react-i18next'
import OtherSports from './OtherSports'
import { LandingConfigInterface } from 'config/interfaces/config.interface'

type PhotoGalleryDesktopProps = {
  onClick: (activityId: string) => void
  config: LandingConfigInterface
}

const PhotoGalleryDesktop = ({ onClick, config }: PhotoGalleryDesktopProps) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {}, [i18n.language])

  return (
    <div className="py-10">
      <div className="flex items-end gap-3.5 pl-6 mb-3">
        <h2 className="mb-0 text-2xl font-semibold text-lumepic-light_black">
          {t('Our most popular sports')}
        </h2>
        <span
          className="text-lg font-extrabold cursor-pointer text-primary hover:underline"
          onClick={() => onClick('')}
        >
          {t('See more')}
        </span>
      </div>
      <div className="flex w-full gap-3 pl-6 pr-10">
        <img
          src={
            i18n.language === 'en'
              ? config.FEATURED_ACTIVITY_1_EN.desktopAssetName
              : config.FEATURED_ACTIVITY_1_ES.desktopAssetName
          }
          className={
            styles.popularSports +
            ' w-1/5 h-70vh lg:h-landing1 object-cover object-top cursor-pointer'
          }
          alt="Athletics"
          onClick={() => onClick(config.FEATURED_ACTIVITY_1_EN.activityId)}
        />
        <div className="flex flex-col w-4/5 gap-3 h-70vh">
          <div className="flex w-full gap-3 h-1/2">
            <img
              src={config.FEATURED_ACTIVITY_2.desktopAssetName}
              className={styles.popularSports + ' w-2/3 object-cover cursor-pointer'}
              style={{ objectPosition: '50% 0%' }}
              alt="Surf"
              onClick={() => onClick(config.FEATURED_ACTIVITY_2.activityId)}
            />
            <img
              src={config.FEATURED_ACTIVITY_3.desktopAssetName}
              className={styles.popularSports + ' w-1/3 object-cover cursor-pointer'}
              style={{ objectPosition: '50% 10%' }}
              alt="Kitesurf"
              onClick={() => onClick(config.FEATURED_ACTIVITY_3.activityId)}
            />
          </div>
          <div className="flex w-full gap-3 h-1/2">
            <img
              src={config.FEATURED_ACTIVITY_4.desktopAssetName}
              className={styles.popularSports + ' w-1/2 object-cover object-top cursor-pointer'}
              alt="Rugby"
              onClick={() => onClick(config.FEATURED_ACTIVITY_4.activityId)}
            />
            <img
              src={config.FEATURED_ACTIVITY_5.desktopAssetName}
              className={styles.popularSports + ' w-1/2 object-cover cursor-pointer'}
              style={{ objectPosition: '50% 25%' }}
              alt="Motocross"
              onClick={() => onClick(config.FEATURED_ACTIVITY_5.activityId)}
            />
          </div>
        </div>
      </div>
      <div className="px-6 mt-4">
        <OtherSports slidesPerView={4} onClick={onClick} config={config} />
      </div>
    </div>
  )
}

export default PhotoGalleryDesktop
