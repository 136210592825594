import React from 'react'
import c from 'classnames'

const inputDefaultClasses = [
  'text-lumepic-black',
  'bg-lumepic-white',
  'box-border',
  'border-2',
  'focus:outline-none',
  'focus:shadow-md',
  'placeholder-lumepic-grey',
]

const inputInvertedClasses = [
  'bg-gray-600',
  'text-white',
  'focus:ring-gray-800',
  'focus:ring-opacity-80',
]

const inputErrorClasses = ['border-warning']

const inputSuccessClasses = ['border-success']

type InputProps = {
  password?: boolean
  onChange: Function
  value: any
  onPressEnter?: Function | null
  inputRef: string | null
  inverted: boolean
  error: boolean
  success: boolean
  onlyNumeric?: boolean
  onlyInteger?: boolean
  extraClassNames?: string
}

const Input = ({
  password = false,
  onChange,
  value,
  onPressEnter = null,
  inputRef = null,
  inverted = false,
  error = false,
  success = false,
  onlyNumeric = false,
  onlyInteger = false,
  extraClassNames = '',
  ...props
}: InputProps) => {
  const inputStyleClasses = !inverted ? inputDefaultClasses : inputInvertedClasses
  const errorClasses = error ? inputErrorClasses : ''
  const successClasses = success ? inputSuccessClasses : ''
  const inputBaseClasses =
    success || error
      ? ['p-2.5', 'rounded-md', 'w-full']
      : ['p-2.5', 'rounded-md', 'w-full', 'border-lumepic-grey']
  const inputClasses = [
    ...successClasses,
    ...errorClasses,
    ...inputBaseClasses,
    ...inputStyleClasses,
    extraClassNames,
  ]

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && onPressEnter !== null) {
      onPressEnter()
    }
  }

  const handleChange = (e: any) => {
    if (onlyNumeric && !/^-?\d*[.]?\d{0,2}$/.test(e.target.value)) {
      return false
    }

    if (onlyInteger && !/^-?\d*$/.test(e.target.value)) {
      return false
    }

    onChange(e.target.value)
  }

  return (
    <input
      {...props}
      onKeyPress={handleKeyPress}
      className={c(inputClasses)}
      onChange={handleChange}
      ref={inputRef}
      value={value}
      type={!password ? 'text' : 'password'}
    />
  )
}

export default Input
