export const GET_LOCATIONS = '/locations'
export const GET_COUNTRIES = '/locations/countries'
export const REQUEST_LOCATION = `${GET_LOCATIONS}/request`

const prefix = '/locations'
export const ADMIN_LOCATIONS = `${prefix}/admin`
export const getLocationsByCountryCode = (countryCode: string) => {
  return `${prefix}/${countryCode}`
}
export const deleteLocationById = (locationId: string) => {
  return `${prefix}/${locationId}`
}
export const updateLocationById = (locationId: string) => {
  return `${prefix}/${locationId}`
}
