import { useEffect } from 'react'
import { isNil } from 'lodash'

const useHideChatBot = () => {
  useEffect(() => {
    const chatbotElements = document.getElementsByName('Botmaker')

    if (!isNil(chatbotElements) && chatbotElements.length > 0) {
      chatbotElements.forEach((element) => {
        element.style.visibility = 'hidden'
      })
    }
    return () => {
      if (!isNil(chatbotElements)) {
        chatbotElements.forEach((element) => {
          element.style.visibility = 'visible'
        })
      }
    }
  }, [])
}

export default useHideChatBot
