import React from 'react'
import AlbumDetailsStore from '../../AlbumDetailsStore'
import { observer } from 'mobx-react'
import PrivateAlbumHeader from './components/PrivateAlbumHeader'
import PrivateAlbumInformation from './components/PrivateAlbumInformation'
import PrivateAlbumLogin from './components/PrivateAlbumAuth/PrivateAlbumLogin'

type PrivateAlbumSectionProps = {
  store: AlbumDetailsStore
  initialRender: React.MutableRefObject<boolean>
  albumId: string
  photographId?: string
}

const PrivateAlbumSection = ({
  store,
  initialRender,
  albumId,
  photographId,
}: PrivateAlbumSectionProps) => {
  const { album } = store

  return (
    <>
      <PrivateAlbumHeader />
      <PrivateAlbumInformation album={album} />
      <PrivateAlbumLogin
        initialRender={initialRender}
        photographId={photographId}
        albumId={albumId}
        store={store}
      />
    </>
  )
}

export default observer(PrivateAlbumSection)
