import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import Modal from '../../../../components/UI/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { ModalButtonsDisplay, ModalSize } from '../../../../components/UI/Modal/Modal'
import { useTranslation } from 'react-i18next'
import Button from 'components/UI/Button'
import storesContext from 'providers/storesContext'
import { LinearProgress } from '@mui/material'
import { PhotographCartLine } from '../../../../shared/models/Cart/CartLine/PhotographCartLine'

type CartLineProps = {
  line: PhotographCartLine
}

const PhotographCartLineComponent = ({ line }: CartLineProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { cartStore } = useContext(storesContext)!
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [showImageModal, setShowImageModal] = useState(false)
  const [progress, setProgress] = useState(0)
  const [clearTimeoutTool, setClearTimeoutTool] = useState(0)

  useEffect(() => {
    const interval = 20
    const steps = 115 / (4000 / interval)
    const timer = setInterval(() => {
      setProgress((prevProgress) => Math.max(0, prevProgress - steps))
    }, interval)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const onImageClicked = () => {
    setShowImageModal(true)
  }

  const goToAlbum = () => {
    history.push(`/feed/albums/${album?.id}`)
  }

  const goToEvent = () => {
    history.push(`/events/${album?.event.landingPath}`)
  }

  const hideImageModal = () => {
    setShowImageModal(false)
  }

  const removeFromCart = () => {
    cartStore.removePhotograph(photograph.id)
    setDeleteConfirmation(false)
  }

  let deletePictureTimeout
  const deleteItem = () => {
    setProgress(100)
    setDeleteConfirmation(true)
    deletePictureTimeout = setTimeout(removeFromCart, 4000)
    setClearTimeoutTool(deletePictureTimeout)
  }

  const undoDelete = () => {
    if (clearTimeoutTool !== null) {
      clearTimeout(clearTimeoutTool)
      setClearTimeoutTool(0)
    }
    setDeleteConfirmation(false)
  }

  const photograph = line.photograph
  const album = cartStore.albums.find((album) => album.id === photograph.albumId)
  const currency = album?.event.currency?.toUpperCase() || album?.currency?.toUpperCase() || 'UYU'

  const lineActionsContent = (
    <div className="w-full flex flex-col justify-between ml-2 md:ml-6 md:mb-3">
      <div className="flex flex-col items-end font-semibold pt-2 pr-2 md:pl-2">
        <div className="w-full flex-col md:flex md:items-end">
          <div className="flex flex-col items-end">
            {line.discountTotal > 0 && (
              <span className="font-light text-sm justify-end text-lumepic-grey line-through">
                {currency} {line.subtotalPrice.toFixed(2)}
              </span>
            )}
            {currency} {line.totalPrice.toFixed(2)}
          </div>
        </div>
      </div>

      <div className="flex flex-col items-start justify-end gap-2 mt-4">
        <div
          onClick={album?.event.landingPath ? goToEvent : goToAlbum}
          className="flex items-center gap-2 cursor-pointer"
        >
          <i className="text-lumepic-grey">
            <FontAwesomeIcon icon={faImages} />
          </i>
          <span className="text-sm text-lumepic-grey lg:text-base">
            {album?.event.landingPath ? t('Go to event') : t('Go to album')}
          </span>
        </div>
        <div onClick={() => deleteItem()} className="ml-0.5 flex gap-2 items-center cursor-pointer">
          <i className="text-lumepic-grey">
            <FontAwesomeIcon icon={faTrashAlt} />
          </i>
          <span className="text-sm text-lumepic-grey lg:text-base">{t('Delete')}</span>
        </div>
      </div>
    </div>
  )

  return (
    <>
      {!deleteConfirmation && (
        <div className="w-full flex py-2.5 border-b relative bg-white border-b-lg md:rounded-b-lg">
          <div className="w-1/2 pl-2 md:p-0 md:ml-1 h-full" onClick={onImageClicked}>
            <img
              loading="lazy"
              src={photograph.thumbnailUrl}
              alt={photograph.id}
              className="object-cover object-center w-full max-h-48 cursor-zoom-in md:rounded-md"
            />
          </div>

          {lineActionsContent}
        </div>
      )}

      {deleteConfirmation && (
        <div className="w-full bg-bg_details">
          <LinearProgress variant="determinate" color="primary" value={progress} />
          <div className="flex flex-col items-center justify-center gap-2 px-5 h-40">
            <p className="mb-0 font-medium">{t('Picture deleted')}</p>
            <Button btnType="TertiaryAction" onClick={() => undoDelete()}>
              {t('Undo action')}
            </Button>
          </div>
        </div>
      )}
      <Modal
        onCancel={hideImageModal}
        opened={showImageModal}
        modalSize={ModalSize.MEDIUM}
        buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
        displayCloseIcon={false}
      >
        <div className="flex items-center justify-center" style={{ backdropFilter: 'blur(8px)' }}>
          <div style={{ display: 'inline-block', maxWidth: '100%', maxHeight: '100%' }}>
            <img
              loading="lazy"
              src={photograph.url}
              alt=""
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default observer(PhotographCartLineComponent)
