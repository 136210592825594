const data = [
  {
    title: 'Introduction',
    content: [
      'Welcome to Lumepic. The following "Terms & Conditions" regulate the use of Lumepic (the "Service" or the "Company") and apply when a person (the "User") accesses or uses the Service through the platform located at http://www.lumepic.com (the "Site").',
      'By accessing or using the Service, the individual declares their agreement with these Terms & Conditions and becomes a User.',
      'If you do not agree with these Terms & Conditions, you should not access or use the Service.',
    ],
  },
  {
    title: 'About the service',
    content: [
      'Lumepic is a virtual platform and marketplace for Photographers ("Photographers") and Athletes ("Athletes") from around the world.',
      'The platform provides a space for photographers to share and sell their images.',
      'Photographs published and marketed on Lumepic are exclusively owned by the photographers and are referred to as "Intellectual Works."',
      'Users must upload Intellectual Works related only to sports to the Site in order to sell them to other users for personal use, including posting the photos on social networks. Buyers must not use the photographs for commercial purposes.',
      'The Lumepic platform is free to use and makes its profits by charging a 20% commission on photographers´ sales, as long as no other amount is specified.',
    ],
  },
  {
    title: 'User conduct and service usage registration',
    content: [
      'When subscribing to the Service, the user will create a personalized account, including a unique username and password to access the Service and receive messages from the Company.',
      'The User agrees to immediately notify unauthorized access to their password and/or account. The Company will not be responsible for any loss or damage resulting from unauthorized use of their name, password, and/or account.',
    ],
  },
  {
    title: 'Usage restrictions',
    content: [
      'Your permission to use this Site is subject to the following usage and conduct restrictions:',
      'The User agrees under no circumstances to:',
      'Post any material unrelated to sports or that is abusive, threatening, obscene, defamatory, racial, sexual, religious, or objectionable and offensive.',
      'Use the Service for any illegal purpose or for the promotion of illegal activities.',
      'Attempt to harass, abuse, or harm another person or group.',
      'Use another user’s account without permission.',
      'Provide false or inaccurate information when registering.',
      'Interfere or attempt to interfere with the proper functioning of the Service.',
      'Use an automated system or take any action that imposes or potentially imposes an unreasonable or disproportionate load on our servers or network infrastructure.',
      'Ignore robot exclusion headers or other measures we take to restrict access to the Service or use any software, technology, or device to damage, scrape, or track the Service or to collect and manipulate data.',
    ],
  },
  {
    title: 'Behavioral restrictions',
    content: [
      'Cuando el Usuario crea su propia cuenta personalizada, es el único responsable del uso del Servicio. La Compañía se reserva el derecho de eliminarlo del Servicio a su discreción.',
      'By using the Service, the User agrees they are solely responsible for their account and the activity that occurs when they log in or use their account.',
      'The User acknowledges that Lumepic has the right to determine if their use of the Service is appropriate and in accordance with these Terms & Conditions and to cancel their account with or without notice.',
      'The User understands and agrees that any liability, loss, or damage resulting from the use of the Service is their sole responsibility. The Company is not responsible for the misuse of the Service.',
      'The Company does not predict or monitor all uses, nor can it do so. However, at its own discretion, manually or using technology, it can monitor and/or record user interactions with the Service.',
    ],
  },
  {
    title: 'Intellectual Works',
    content: [
      'The term "Intellectual Work" refers to any photographic material that the User uploads to the Site.',
      'The User grants the Company a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, and publish their Intellectual Work for promotional purposes. The Company also has the right to sublicense these rights to its business partners to drive traffic to the Site.',
      'The Company is completely free to apply promotions and discounts of any value to promote higher sales within the platform. Whether these discounts are for promotional actions on commercial and seasonal dates, an increase in photos sold per cart, or any action the Company deems necessary for promoting sales within the Site and Platform, with the total discount transfer or not to the User owning the Intellectual Work.',
      'The Intellectual Work must not be unlawful or illegal, must not infringe on the legal rights of third parties, and must not be capable of giving rise to legal action against the User or the Company or a third party (in each case under applicable law).',
      'The User must not submit any Intellectual Work to the Site that has been the subject of a threatened, actual, or similar legal claim.',
      'The Company reserves the right to edit or remove any material submitted to the Site, or stored on its servers, or hosted or published on the Site.',
    ],
  },
  {
    title: 'Legal disclaimer of content',
    content: [
      'The Company does not guarantee the accuracy, completeness, or usefulness of any information on the Service and is not responsible for the accuracy or reliability of any opinions, advice, or statements made by parties other than the Company. In no event shall the Company be liable for losses or damages resulting from someone’s reliance on information on the Service.',
      'The Company reserves the right, but has no obligation, to monitor the use of the Service or to limit or deny a user’s access to the Service or to take other appropriate actions if a user violates these Terms of Service or engages in any activity that violates the rights of any person or entity or is deemed unlawful, offensive, abusive, harmful, or malicious.',
      'Unauthorized use may result in criminal and/or civil prosecution. If you become aware of the misuse of our Service, please contact info@lumepic.com.',
    ],
  },
  {
    title: 'Links to other sites and/or materials',
    content: [
      'As part of the Service, the Company may provide convenient links to third-party websites ("Third-Party Sites").',
      'These links are provided as a courtesy to Service subscribers. The Company has no control over Third-Party Sites and Third-Party Applications, Software, or Content or the promotions, materials, information, goods, or services available on those Third-Party Sites or Third-Party Applications, Software, or Content.',
      'Such Third-Party Sites and Third-Party Applications, Software, or Content are not investigated, monitored, or verified for accuracy, suitability, or completeness by the Company, and the Company is not responsible for any Third-Party Site accessed through the Site or any Third-Party Application, Software, or Content posted, available through the Site, or installed from it, including the content, accuracy, criminality, opinions, reliability, privacy practices, or other policies or content on Third-Party Sites or Third-Party Applications, Software, or Content.',
      'Inclusion, linking to, or permitting the use or installation of any Third-Party Site or any Third-Party Application, Software, or Content does not imply the endorsement or approval of the Company.',
      'If you decide to leave the Site and access Third-Party Sites or use or install Third-Party Applications, Software, or Content, you do so at your own risk and should be aware that these Terms & Conditions no longer govern.',
      'The User should review applicable terms and policies, including privacy and data collection practices, of any website they navigate from the Site or related to any application they use or install from the Site.',
    ],
  },
  {
    title: 'Closure of recurrent infringed accounts',
    content: [
      'The Company respects the intellectual property rights of third parties and requests that users do the same. The Company has adopted and implemented a policy providing for the termination, in appropriate circumstances, of Service users who are recurrent infringers and the Company may terminate access for Users.',
    ],
  },
  {
    title: 'Intellectual property',
    content: [
      'The User acknowledges and agrees that the Service owns all intellectual property rights of any kind related to the Service, including copyrights, trademarks, and other proprietary rights. Other products and company names mentioned in the Service may be trademarks of their respective owners. The Service reserves all rights not expressly granted under these Terms & Conditions.',
    ],
  },
  {
    title: 'Communications via email',
    content: [
      'Communications made through the email system and Service messages will not constitute legal notice to the Company or any of its directors, employees, agents, or representatives in any situation where notification to the Company is required by contract or any law or regulation.',
    ],
  },
  {
    title: 'User consent to receive electronic communications',
    content: [
      'For contractual purposes, the User agrees to receive communications from the Company electronically through the email address they registered; and agrees that all Terms & Conditions, contracts, notices, disclosures, and other communications provided by the Company electronically satisfy any legal requirement that such communications would fulfill if in writing. This does not affect your non-waiver rights.',
      'The Service may also use the user’s email address to send other messages, including information about the Company and special offers.',
      'The user can disable this email by changing their account settings or sending an email to info@lumepic.com.',
      'Opting out may prevent you from receiving messages about the Company or special offers.',
    ],
  },
  {
    title: 'Disclaimer of warranty',
    content: [
      'The Service is provided without any warranty.',
      'Without limiting the foregoing, the Company expressly disclaims all warranties, whether express, implied, or statutory, related to the Service, including, among others, any implied warranties of merchantability, fitness for a particular purpose, title, security, accuracy, and non-infringement.',
      'Without limiting the foregoing, the Company does not warrant or represent that access or operation of the service will be uninterrupted or error-free. The User assumes all responsibility and risk for loss resulting from their transfer and/or use of files, information, content, or other material obtained from the Service.',
    ],
  },
  {
    title: 'Limitation of damages; Release',
    content: [
      'To the extent permitted by applicable law, under no circumstances shall the Company, its affiliates, directors, employees, licensors, or partners be liable for any loss of profits, use, or data, or for any incidental, indirect, special, consequential, or exemplary damages.',
    ],
  },
  {
    title: 'Modification of Terms of Use',
    content: [
      'The Company may change the Terms & Conditions at any time. It is the User’s responsibility to check the Site for any changes to these Terms & Conditions. However, material changes to the terms will be notified by posting a notice on the Site and/or sending an email to the email address provided by the User at the time of registration. For this additional reason, the User should keep their contact and profile information up to date. Any changes to these Terms & Conditions (except as provided in this clause) or the waiver of the Company’s rights herein shall not be valid or effective except in a written contract with the physical signature of a director of the Company.',
    ],
  },
  {
    title: 'General terms',
    content: [
      'If any part of this Agreement is determined to be invalid or unenforceable, that part of the Agreement shall be interpreted in accordance with applicable law. The remaining portions shall remain in full force and effect. Any failure by the Company to enforce any provision of these Terms & Conditions shall not be deemed a waiver of the Company’s right to enforce such provision. The Company’s rights under these Terms & Conditions shall survive any termination of the Service. The User agrees that any cause of action related to or arising from their relationship with the Company must begin within one (1) year after the cause of action has accrued. Otherwise, this cause of action is permanently barred. These Terms & Conditions and the User’s use of the Site are governed by the laws of Uruguay. The Company may assign or delegate these Terms & Conditions and/or the Company’s Privacy Policy, in whole or in part, to any person or entity at any time, with or without your consent. The User may not assign or delegate any rights or obligations under these Terms & Conditions or the Privacy Policy without the Company’s prior written consent, and any unauthorized assignment and delegation by the User is void. The User acknowledges that they have read these Terms & Conditions, understand them, and will be bound by them. Furthermore, the User acknowledges that these Terms & Conditions along with the Privacy Policy represent the complete and exclusive statement of the agreement between them and the Company and supersede any prior proposal or agreement, oral or written, and any other communication between them regarding the subject of this agreement.',
    ],
  },
]

export default data
