import EventInterface from 'services/RequestInterfaces/Event/Event.interface'
import { Tag } from './Tag'
import { isNil } from 'lodash'
import { TagKeys } from 'services/RequestInterfaces/Tag/Tag.interface'
import { QuantityDiscount } from './QuantityDiscount'
import { Activity } from './Activity'
import { Location } from './Location'
import { Currency } from '../util/Currency'

export class Event {
  constructor() {
    this.tags = []
  }
  public static init(data?: EventInterface): Event {
    if (isNil(data)) {
      return new Event()
    }
    const event = new Event()
    event.id = data.id
    event.name = data.name
    event.description = data.description
    event.locationId = data.locationId
    event.activityId = data.activityId
    event.coverPhotographSrc = data.coverPhotographSrc
    event.landingPath = data.landingPath
    event.date = data.date
    event.ownerId = data.ownerId
    event.isFree = data.isFree
    event.quantityDiscountId = data.quantityDiscountId
    event.isFree = data.isFree
    event.accreditationsEnabled = data.accreditationsEnabled
    event.accreditationApproved = data.accreditationsApproved
    event.customBannerSrc = data.customBannerSrc
    event.faceRecognitionFilter = data.faceRecognitionFilter
    event.textFilter = data.textFilter
    event.filtersView = data.filtersView
    event.albumsView = data.albumsView
    event.firstPhotographDate = data.firstPhotographDate
    event.lastPhotographDate = data.lastPhotographDate
    event.isPrivate = data.isPrivate
    event.password = data.password

    if (!isNil(data.defaultImagePrice)) {
      event.defaultImagePrice = data.defaultImagePrice
    }
    if (!isNil(data.defaultPackagePrice)) {
      event.defaultPackagePrice = parseFloat(data.defaultPackagePrice.toString())
    }
    if (!isNil(data.burstPrice)) {
      event.burstPrice = data.burstPrice
    }
    if (!isNil(data.quantityDiscount)) {
      event.quantityDiscount = QuantityDiscount.init(data.quantityDiscount)
    }
    if (!isNil(data.commission)) {
      event.commission = data.commission
    }
    if (!isNil(data.currency)) {
      event.currency = data.currency as Currency
    }
    if (!isNil(data.activity)) {
      event.activity = Activity.init(data.activity)
    }
    if (!isNil(data.location)) {
      event.location = Location.init(data.location)
    }
    event.tags = !isNil(data.tags) ? data.tags.map(Tag.init) : null

    return event
  }
  id: string
  name: string
  description: string
  locationId: string
  location?: Location | null
  activityId: string
  activity?: Activity | null
  coverPhotographSrc: string
  landingPath: string
  date: Date
  ownerId: string
  isFree: boolean
  defaultImagePrice?: number | null
  defaultPackagePrice?: number | null
  burstPrice?: number | null
  quantityDiscount?: QuantityDiscount | null
  quantityDiscountId?: string | null
  commission?: number | null
  currency: Currency
  tags: Tag[] | null
  accreditationsEnabled: boolean
  accreditationApproved: boolean
  customBannerSrc: string
  faceRecognitionFilter: boolean
  textFilter: boolean
  filtersView: boolean
  albumsView: boolean
  firstPhotographDate: Date
  lastPhotographDate: Date
  isPrivate?: boolean
  password?: string

  getLabel() {
    return {
      value: this.id,
      label: this.name,
    }
  }

  createTagMap() {
    const tagMap = new Map<TagKeys, Tag[]>()

    if (this.tags) {
      for (const tag of this.tags) {
        const { key } = tag
        const tagList = tagMap.get(key) || []
        tagList.push(tag)
        tagMap.set(key, tagList)
      }

      return tagMap
    }
  }

  public static clone(data: Event): Event {
    const clone = new Event()
    clone.id = data.id
    clone.name = data.name
    clone.description = data.description
    clone.locationId = data.locationId
    clone.activityId = data.activityId
    clone.coverPhotographSrc = data.coverPhotographSrc
    clone.date = data.date
    clone.ownerId = data.ownerId
    clone.isFree = data.isFree
    clone.firstPhotographDate = data.firstPhotographDate
    clone.lastPhotographDate = data.lastPhotographDate
    clone.accreditationsEnabled = data.accreditationsEnabled
    clone.accreditationApproved = data.accreditationApproved
    clone.isPrivate = data.isPrivate
    clone.password = data.password
    if (!isNil(data.currency)) {
      clone.currency = data.currency
    }
    if (!isNil(data.defaultImagePrice)) {
      clone.defaultImagePrice = data.defaultImagePrice
    }
    if (!isNil(data.defaultPackagePrice)) {
      clone.defaultPackagePrice = data.defaultPackagePrice
    }
    if (!isNil(data.burstPrice)) {
      clone.burstPrice = data.burstPrice
    }
    if (!isNil(data.quantityDiscount)) {
      clone.quantityDiscount = QuantityDiscount.init(data.quantityDiscount)
    }
    if (!isNil(data.commission)) {
      clone.commission = data.commission
    }

    return clone
  }
}
