import moment from 'moment-timezone'

const { timeZone: localTZ } = Intl.DateTimeFormat().resolvedOptions()
const apiTZ = 'utc'

export const getApiDate = (value: Date | null) => {
  return value ? moment(value).tz(apiTZ).tz(localTZ, true).toDate() : null
}

export const getLocalDate = (value: Date | null) => {
  return value ? moment(value).tz(localTZ).tz(apiTZ, true).toDate() : null
}
