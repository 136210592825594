import { makeAutoObservable, runInAction } from 'mobx'
import { number } from 'yup'
import InputStore from '../../../../../shared/store/InputStore'
import { Photograph } from '../../../../../shared/models/Photograph'
import PhotographsService from '../../../../../services/PhotographsService'
import AuthStore from '../../../../../stores/AuthStore'

class EditPriceStore {
  public price: InputStore<number>
  public isSaving: boolean
  public photograph: Photograph
  public error: any
  private photographService: PhotographsService
  constructor(public readonly authStore: AuthStore, photograph: Photograph) {
    this.reset()
    this.photograph = photograph
    makeAutoObservable(this)
    this.photographService = new PhotographsService()
  }

  reset() {
    this.price = new InputStore<number>(
      number().positive('El precio debe ser positivo').required('El precio es requerido')
    )
    this.isSaving = false
    this.photograph = new Photograph()
    this.error = null
  }

  changePrice(value: number) {
    this.price.setValue(value)
  }

  clearErrors() {
    this.price.clearError()
  }

  async validate() {
    this.clearErrors()
    let isValid = true

    if (!(await this.price.validate())) {
      isValid = false
    }

    return isValid
  }

  async updatePrice(photographId: string) {
    if (await this.validate()) {
      runInAction(() => {
        this.isSaving = true
      })

      try {
        await this.photographService.updatePhotographPrice(
          photographId,
          Number(this.price.value),
          this.authStore.getToken()
        )

        runInAction(() => {
          this.isSaving = false
        })
      } catch (e: any) {
        runInAction(() => {
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.error = 'Something went wrong, please check the provided data and try again.'
          }

          if (e?.message) {
            this.error = e.message
          }

          this.isSaving = false
        })
      }
    } else {
      throw new Error('Invalid price')
    }
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'price':
          this.price.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }
}

export default EditPriceStore
