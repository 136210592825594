import React from 'react'
import { observer } from 'mobx-react'
import { PackageCartLine } from '../../../../../../shared/models/Cart/CartLine/PackageCartLine'
import { useTranslation } from 'react-i18next'

type MiniCartPackageLineProps = {
  line: PackageCartLine
  packagesDiscountAmount: number
}

const MiniCartPackageLine = ({ line, packagesDiscountAmount }: MiniCartPackageLineProps) => {
  const { t } = useTranslation()

  const originalPrice = line.previewPhotographs[0].price * line.quantityOfPhotographs
  const hasDiscount = packagesDiscountAmount > 0

  return (
    <div className="flex p-3 border-b border-gray-100 hover:bg-gray-50">
      <div className="relative w-1/2 aspect-square">
        {line.previewPhotographs.slice(0, 3).map((photo, index) => (
          <img
            key={photo.id}
            src={photo.thumbnailUrl}
            alt="cart-item"
            className={`absolute w-full h-full object-cover rounded-md shadow-sm transition-transform ${
              index === 0
                ? 'z-30'
                : index === 1
                ? 'z-20 translate-y-1 translate-x-1'
                : 'z-10 translate-y-2 translate-x-2'
            }`}
          />
        ))}
        <div className="absolute bottom-2 right-2 bg-white/90 px-2 py-1 rounded-md text-xs shadow-sm">
          {line.quantityOfPhotographs} {t('photos')}
        </div>
      </div>

      <div className="w-1/2 pl-3 flex flex-col justify-between">
        <div>
          <p className="font-medium text-sm text-gray-900 line-clamp-2">
            {line.event?.name || line.albums[0].location.spotName}
          </p>
          <div className="flex items-center gap-1 mt-1">
            <span className="text-xs text-lumepic-dark_grey">
              {t('package_with_photos_count', { count: line.quantityOfPhotographs })}
            </span>
          </div>
        </div>
        <div className="text-right">
          {hasDiscount && (
            <p className="text-xs text-lumepic-grey line-through">
              {line.currency?.toUpperCase()} {originalPrice.toFixed(2)}
            </p>
          )}
          <p className="text-sm font-bold text-gray-900">
            {line.currency?.toUpperCase()} {line.totalPrice.toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default observer(MiniCartPackageLine)
