import React, { useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import storesContext from '../../../providers/storesContext'

const Logout = () => {
  const { authStore, cartStore } = useContext(storesContext)!

  useEffect(() => {
    authStore.logout()
    cartStore.fetchCart()
  }, [authStore.getLoggedUser()])

  return <Redirect to="/" />
}

export default observer(Logout)
