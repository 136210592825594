import {
  faBolt,
  faChartSimple,
  faCrown,
  faImage,
  faLock,
  faTag,
} from '@fortawesome/free-solid-svg-icons'

const data = [
  [
    {
      icon: faTag,
      title: 'Personalized discounts',
    },
    {
      icon: faChartSimple,
      title: 'Sales Dashboard',
    },
    {
      icon: faCrown,
      title: 'Fidelity code',
    },
  ],
  [
    {
      icon: faBolt,
      title: 'Photography community',
    },
    {
      icon: faLock,
      title: 'Private albums',
    },
    {
      icon: faImage,
      title: 'Albums associated to events',
    },
  ],
]

export default data
