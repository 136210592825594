import ConfigService, { AssetNames } from '../../../../../config'

const data = [
  {
    src: ConfigService.getAsset(AssetNames.LANDING_BENEFITS_ATHLETE),
    title: 'Are you an athlete?',
    content: 'Find all your photos on Lumepic and save memories for a lifetime!',
  },
  {
    src: ConfigService.getAsset(AssetNames.LANDING_BENEFITS_PHOTOGRAPHER),
    title: 'Are you a photographer?',
    content: 'Sell ​​your photos through Lumepic and generate additional income.',
    isPhotographer: true,
  },
  {
    src: ConfigService.getAsset(AssetNames.LANDING_BENEFITS_SECURE_PAYMENT),
    title: 'Safe payment method',
    content: 'Buy and sell through guaranteed safe and secure payment methods.',
  },
]

export default data
