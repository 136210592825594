import React from 'react'
import { useTranslation } from 'react-i18next'
import magnifyingGlass from '../../../../assets/icons/v2/magnifyingGlass.svg'

const AlbumDetailsNoResult = () => {
  const { t } = useTranslation()
  return (
    <div className="bg-white w-full mt-6 rounded-b text-lumepic-light_black text-base sm:text-xl py-4 shadow-md flex items-center gap-2 justify-center">
      <img src={magnifyingGlass} alt="No Results" className="w-10 h-10 sm:w-16 sm:h-16" />
      <span className="font-medium">{t('No photographs were found')}</span>
    </div>
  )
}

export default AlbumDetailsNoResult
