import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../../../components/UI/Modal'
import { ModalSize } from '../../../../../../components/UI/Modal/Modal'

type CancelAlbumUploadProps = {
  open: boolean
  handleConfirm: () => void
  hideConfirmModal: () => void
}
const CancelAlbumUploadModal = ({
  open = false,
  handleConfirm,
  hideConfirmModal,
}: CancelAlbumUploadProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Modal
        onCancel={hideConfirmModal}
        opened={open}
        onSuccess={handleConfirm}
        okMessage={t('Confirm').toString()}
        isDeleteConfirmation
        modalSize={ModalSize.SMALL}
      >
        <div className="w-full grid grid-rows-1 gap-2 px-4 pt-4">
          <div className="text-gray-600">
            {t('Are you sure you want to interrupt the photographs upload?')}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default observer(CancelAlbumUploadModal)
