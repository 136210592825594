import { makeAutoObservable, runInAction } from 'mobx'
import PurchaseService from '../../services/PurchaseService'
import PhotographsService from '../../services/PhotographsService'
import AlbumService from '../../services/AlbumService'
import { toast } from 'react-toastify'
import AuthStore from '../../stores/AuthStore'
import { Purchase, PurchaseStatus } from '../../shared/models/Purchase'
import Album from '../../shared/models/Album'

const DefaultPurchasesPerPage = 20
class PurchaseStore {
  private readonly purchaseService: PurchaseService
  private readonly albumService: AlbumService
  private readonly photographService: PhotographsService
  public userPurchases: Purchase[]
  public purchaseCount: number
  public isLoading: boolean
  public isDownloadingPhotograph: boolean
  public error: any
  public album: Album
  public page: number
  public purchasesPerPage: number
  constructor(private readonly authStore: AuthStore) {
    this.reset()

    makeAutoObservable(this)

    this.purchaseService = new PurchaseService()
    this.photographService = new PhotographsService()
    this.albumService = new AlbumService()
    this.authStore = authStore
  }

  reset() {
    this.userPurchases = []
    this.purchaseCount = 0
    this.isLoading = false
    this.isDownloadingPhotograph = false
    this.error = false
    this.page = 1
    this.purchasesPerPage = DefaultPurchasesPerPage
  }

  async fetchPurchases(status?: PurchaseStatus, sellerId?: string) {
    this.startLoading()
    const limit = this.purchasesPerPage
    const offset = (this.page - 1) * this.purchasesPerPage
    try {
      const response = await this.purchaseService.purchases(
        this.authStore.getToken(),
        limit,
        offset,
        status,
        sellerId
      )
      runInAction(() => {
        this.userPurchases.push(...response.items)
        this.purchaseCount = response.count
        this.error = null
        this.stopLoading()
      })
    } catch (e) {
      this.error = e
      this.stopLoading()
    }
  }

  async fetchPurchasedPhotographsUrls(purchaseId: string, photographIds: string[]) {
    this.startDownloadingPhotograph()
    try {
      const photographUrls = await this.photographService.getPurchasedPhotographDownloadUrl(
        this.authStore.getToken(),
        purchaseId,
        photographIds
      )

      runInAction(() => {
        this.error = null
        this.stopDownloadingPhotograph()
      })
      return photographUrls
    } catch (e: any) {
      this.error = e
      if (e?.message) {
        toast.error(e.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      this.stopDownloadingPhotograph()
    }
  }

  async deletePendingPurchase(purchaseId: string) {
    this.startLoading()
    try {
      await this.purchaseService.deletePendingPurchase(purchaseId, this.authStore.getToken())
      runInAction(() => {
        this.error = null
        this.stopLoading()
      })
    } catch (e: any) {
      this.error = e
      if (e?.message) {
        toast.error(e.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      this.stopLoading()
    }
  }

  startLoading() {
    this.isLoading = true
  }

  stopLoading() {
    this.isLoading = false
  }

  setPage(page: number, type: string) {
    if (page < 1) {
      throw Error(`Page number can't be less than 1`)
    }
    this.page = page
    if (type === 'sales') {
      this.fetchPurchases(PurchaseStatus.APPROVED, this.authStore.getLoggedUser().id)
    } else {
      this.fetchPurchases(PurchaseStatus.APPROVED)
    }
  }

  fetchNextPage(type: string) {
    this.setPage(this.page + 1, type)
  }

  hasMorePages() {
    const pageCount = Math.ceil(this.purchaseCount / this.purchasesPerPage)
    return this.page < pageCount
  }

  startDownloadingPhotograph() {
    this.isDownloadingPhotograph = true
  }

  stopDownloadingPhotograph() {
    this.isDownloadingPhotograph = false
  }

  setPurchases(userPurchases: Purchase[]) {
    this.userPurchases = userPurchases
  }
}

export default PurchaseStore
