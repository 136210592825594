import axios from './axios'
import { AxiosResponse } from 'axios'
import exifr from 'exifr'

class FileService {
  public parseMetadata = async (
    file: File
  ): Promise<{ width: number; height: number; takenDate: Date; extraInfo: any } | null> => {
    const reader = new FileReader()
    if (file) {
      await reader.readAsDataURL(file)
      try {
        const info = await exifr.parse(file)
        const takenDate = info.DateTimeOriginal
        const width = info.ExifImageWidth
        const height = info.ExifImageHeight
        return {
          width,
          height,
          takenDate,
          extraInfo: info,
        }
      } catch (e) {
        console.error(JSON.stringify(e))
        return null
      }
    } else {
      throw new Error('Error extracting file. No file provided.')
    }
  }

  async uploadFileToUrl(
    image: File,
    uploadUrl: string,
    uploadKey: string,
    onUploadProgress: (e: any) => void,
    onError: (e: any) => void
  ): Promise<string> {
    const data = new FormData()
    data.append('file', image, `${uploadKey}`)
    return axios({
      baseURL: uploadUrl,
      method: 'put',
      onUploadProgress,
      data: image,
    })
      .then((response: AxiosResponse) => {
        return response.data.message
      })
      .catch((e) => {
        onError(e)
      })
  }
}

export default FileService
