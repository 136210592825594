import { Skeleton } from '@mui/material'
import React from 'react'
import { isMobileDevice } from 'shared/utility'

const SkeletonLoaderMetrics = () => {
  return (
    <>
      <div className="w-full justify-center gap-1 grid grid-cols-4 grid-rows-2 md:gap-4 p-2">
        {[...Array(8)].map((_, index) => (
          <div className="w-full overflow-hidden flex flex-col" key={index}>
            <Skeleton variant="rounded" height={150} width={isMobileDevice() ? 100 : 300} />
          </div>
        ))}
      </div>
    </>
  )
}

export default SkeletonLoaderMetrics
