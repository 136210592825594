import React, { useContext, useState } from 'react'
import i18next from 'i18next'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import StoresContext from '../../../providers/storesContext'
import NavigationItem from './NavigationItem'
import AuxHoc from '../../../hoc/AuxHocs/AuxHoc'
import { useTranslation } from 'react-i18next'
import MiniCart from '../../../containers/Cart/components/MiniCart'
import * as paths from '../../../routing/Paths'
import home from '../../../assets/icons/v2/home-darkGray.svg'
import image from '../../../assets/icons/v2/image.svg'
import albumsIcon from '../../../assets/icons/v2/albumsIcon.svg'
import purchasesIcon from '../../../assets/icons/v2/purchases.svg'
import salesIcon from '../../../assets/icons/v2/sales.svg'
import logoutIcon from '../../../assets/icons/v2/logout-gray.svg'
import loginIcon from '../../../assets/icons/v2/login.svg'
import userDesktopIcon from '../../../assets/icons/v2/user-icon.svg'
import Button from 'components/UI/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCameraRetro, faGear } from '@fortawesome/free-solid-svg-icons'
import LoginRegisterStore from 'containers/Auth/LoginRegisterStore'
import { toast } from 'react-toastify'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { Avatar, Popover } from '@mui/material'
import { isNil } from 'lodash'
import { LanguageOptions } from 'components/UI/LanguageSwitcherDropdown/LanguageSwitcherDropdown'
import CountrySwitcherModal from 'components/UI/CountrySwitcherModal/CountrySwitcherModal'
import { Icon } from '@iconify/react'
import { DropdownOption } from 'components/UI/Dropdown/Dropdown'
import '/node_modules/flag-icons/css/flag-icons.min.css'

export enum NavigationItemPosition {
  RIGHT = 'right',
  LEFT = 'left',
  DRAWER = 'drawer',
}

type NavigationItemsProps = {
  position: NavigationItemPosition
}

const NavigationItems = ({ position }: NavigationItemsProps) => {
  const history = useHistory()
  const { authStore, cartStore, locationStore } = useContext(StoresContext)!
  const { t } = useTranslation()
  const countryCode = locationStore.userLocation.country.code || undefined

  const currentLanguage = i18next.language
  const handleLanguageChange = (selectedOption: DropdownOption) => {
    i18next.changeLanguage(selectedOption.value)
    localStorage.setItem('language', selectedOption.value)
  }
  const flagIcon = (language: string) => {
    return language === 'es' ? (
      <Icon icon="emojione-v1:flag-for-spain" width="20" height="20" />
    ) : (
      <Icon icon="twemoji:flag-united-kingdom" width="20" height="20" />
    )
  }

  const isAuthenticated = authStore.isAuthenticated()
  const isPhotographer = isAuthenticated && authStore.getLoggedUser().isPhotographer

  const goTo = (path: string) => {
    history.push(path)
  }

  const [openCountrySwitcherModal, setOpenCountrySwitcherModal] = useState(false)
  const [openActions, setOpenActions] = useState<HTMLDivElement | null>(null)
  const openPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setOpenActions(event.currentTarget)
  }

  const [loginRegisterStore] = useState(
    () => new LoginRegisterStore(authStore, cartStore, locationStore)
  )

  const googleLoginSuccess = (credentialResponse: CredentialResponse) => {
    return loginRegisterStore.googleAuth(credentialResponse)
  }
  const googleLoginError = () => {
    toast.error(t('Error on login'), {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  }

  const countryCodeParam = `${!isNil(countryCode) ? `?countryCode=${countryCode}` : ''}`

  let content
  if (position === NavigationItemPosition.LEFT) {
    content = (
      <div className="flex gap-2">
        <NavigationItem activeClasses="hidden sm:block" link={`/feed/albums${countryCodeParam}`}>
          <span className="text-lumepic-dark_grey text-lumepicSm font-lumepicRegular hover:text-lumepic-light_black transition-all duration-100 ease-in-out">
            {t('Albums')}
          </span>
        </NavigationItem>
        <NavigationItem activeClasses="hidden sm:block" link={`/events${countryCodeParam}`}>
          <span className="text-lumepic-dark_grey text-lumepicSm font-lumepicRegular hover:text-lumepic-light_black transition-all duration-100 ease-in-out">
            {t('Events')}
          </span>
        </NavigationItem>
      </div>
    )
  } else if (position === NavigationItemPosition.RIGHT) {
    content = (
      <ul className="list-none flex items-center">
        {isAuthenticated && !isPhotographer && (
          <NavigationItem activeClasses="hidden sm:block" link="/my-account?isPhotographer=true">
            <span className="mr-2 text-lumepic-dark_grey font-lumepicRegular text-lumepicSm hover:text-lumepic-light_black transition-colors duration-100 ease-in-out">
              {t("I'm a Photographer")}
            </span>
          </NavigationItem>
        )}
        <NavigationItem link="#">
          <MiniCart />
        </NavigationItem>
        {isAuthenticated ? (
          <>
            <div className="flex items-center mt-px gap-1 cursor-pointer" onClick={openPopover}>
              <img
                src={userDesktopIcon}
                alt="User icon"
                style={{ width: '20px', height: '24px' }}
              />
            </div>
            <Popover
              open={!isNil(openActions)}
              onClose={() => setOpenActions(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              anchorEl={openActions}
            >
              <div className="flex flex-col items-start gap-1 py-2.5 min-w-40">
                <div
                  className="w-full font-lumepicRegular text-lumepicSm px-5 py-0.5 bg-transparent hover:bg-lumepic-medium_grey active:bg-lumepic-light_black active:text-white transition-colors duration-100 cursor-pointer"
                  onClick={() => {
                    setOpenActions(null)
                    goTo(paths.MY_ACCOUNT)
                  }}
                >
                  {t('My Account')}
                </div>
                {isPhotographer && (
                  <div
                    className="w-full font-lumepicRegular text-lumepicSm px-5 py-0.5 bg-transparent hover:bg-lumepic-medium_grey active:bg-lumepic-light_black active:text-white transition-colors duration-100 cursor-pointer"
                    onClick={() => {
                      setOpenActions(null)
                      goTo(paths.MY_ALBUMS)
                    }}
                  >
                    {t('My Albums')}
                  </div>
                )}
                <div
                  className="w-full font-lumepicRegular text-lumepicSm px-5 py-0.5 bg-transparent hover:bg-lumepic-medium_grey active:bg-lumepic-light_black active:text-white transition-colors duration-100 cursor-pointer"
                  onClick={() => {
                    setOpenActions(null)
                    goTo(paths.PURCHASES)
                  }}
                >
                  {t('Purchases')}
                </div>

                {isPhotographer && (
                  <div
                    className="w-full font-lumepicRegular text-lumepicSm px-5 py-0.5 bg-transparent hover:bg-lumepic-medium_grey active:bg-lumepic-light_black active:text-white transition-colors duration-100 cursor-pointer"
                    onClick={() => {
                      setOpenActions(null)
                      goTo(paths.SALES)
                    }}
                  >
                    {t('Sales')}
                  </div>
                )}

                <div
                  className="w-full font-lumepicRegular text-lumepicSm px-5 py-0.5 bg-transparent hover:bg-lumepic-medium_grey active:bg-lumepic-light_black active:text-white transition-colors duration-100 cursor-pointer"
                  onClick={() => {
                    setOpenActions(null)
                    goTo(paths.LOGOUT)
                  }}
                >
                  {t('Logout')}
                </div>
              </div>
            </Popover>
          </>
        ) : (
          <NavigationItem activeClasses="hidden sm:block" link="/auth">
            <span>
              <Button btnType={'SecondaryAction'} onClick={() => {}}>
                {t('Login')}
              </Button>
            </span>
            {!authStore.isAuthenticated() && (
              <div className="hidden">
                <GoogleLogin
                  size="large"
                  width="150px"
                  useOneTap
                  onSuccess={googleLoginSuccess}
                  onError={googleLoginError}
                />
              </div>
            )}
          </NavigationItem>
        )}
      </ul>
    )
  } else if (position === NavigationItemPosition.DRAWER) {
    content = (
      <div className="h-full p-2 flex flex-col justify-between sm:hidden">
        <ul className="list-none">
          <NavigationItem link={`${countryCodeParam}`} exact>
            <div className="flex items-center gap-1.5 rounded-sm active:bg-lumepic-light_black px-2 py-1 mb-1">
              <img src={home} alt="home" className="w-8" />
              <span className="text-bg_details font-lumepicRegular mt-0.5">{t('Home')}</span>
            </div>
          </NavigationItem>
          <NavigationItem link={`/feed/albums${countryCodeParam}`} exact>
            <div className="flex items-center gap-1.5 rounded-sm active:bg-lumepic-light_black px-2 py-1 mb-1">
              <img src={image} alt="albums" className="w-7 ml-0.5" />
              <span className="text-bg_details font-lumepicRegular mt-0.5">{t('Albums')}</span>
            </div>
          </NavigationItem>
          <NavigationItem link={`/events${countryCodeParam}`} exact>
            <div className="flex items-center gap-1.5 rounded-sm active:bg-lumepic-light_black px-2 py-1 mb-1">
              <img src={image} alt="albums" className="w-7 ml-0.5" />
              <span className="text-bg_details font-lumepicRegular mt-0.5">{t('Events')}</span>
            </div>
          </NavigationItem>
          {isPhotographer && (
            <>
              <NavigationItem link={paths.MY_ALBUMS} exact>
                <div className="flex items-center gap-1.5 rounded-sm active:bg-lumepic-light_black px-2 py-1 mb-1">
                  <img src={albumsIcon} alt="albums" className="w-7 ml-0.5" />
                  <span className="text-bg_details font-lumepicRegular mt-0.5">
                    {t('My Albums')}
                  </span>
                </div>
              </NavigationItem>
            </>
          )}
          {authStore.isAuthenticated() && (
            <NavigationItem link={paths.PURCHASES} exact>
              <div className="flex gap-1.5 rounded-sm active:bg-lumepic-light_black px-2 py-1 mb-1">
                <img src={purchasesIcon} alt="home" className="w-7 ml-0.5" />
                <span className="text-bg_details font-lumepicRegular mt-0.5">{t('Purchases')}</span>
              </div>
            </NavigationItem>
          )}
          {isPhotographer && (
            <NavigationItem link={paths.SALES} exact>
              <div className="flex gap-1.5 rounded-sm active:bg-lumepic-light_black px-2 py-1 mb-1">
                <img src={salesIcon} alt="home" className="w-6 ml-1" />
                <span className="text-bg_details font-lumepicRegular ml-1 mt-0.5">
                  {t('Sales')}
                </span>
              </div>
            </NavigationItem>
          )}
          {isAuthenticated && !isPhotographer && (
            <>
              <hr className="border-lumepic-grey mx-2.5 my-4" />
              <NavigationItem exact link="/my-account?isPhotographer=true">
                <div className="flex items-center gap-1.5 rounded-sm active:bg-lumepic-light_black px-2 mb-1">
                  <FontAwesomeIcon
                    icon={faCameraRetro}
                    className="mx-1 text-2xl text-lumepic-dark_grey"
                  />
                  <span className="text-bg_details font-lumepicRegular mt-0.5">
                    {t("I'm a Photographer")}
                  </span>
                </div>
              </NavigationItem>
            </>
          )}
          <hr className="border-px mx-2.5 mt-3 mb-2.5 border-lumepic-grey" />
          {isAuthenticated ? (
            <NavigationItem link="/logout" exact>
              <div className="flex gap-1.5 rounded-sm active:bg-lumepic-light_black px-2 py-1 mb-1">
                <img src={logoutIcon} alt="home" className="w-5 ml-1" />
                <span className="text-bg_details font-lumepicRegular mt-0.5">{t('Logout')}</span>
              </div>
            </NavigationItem>
          ) : (
            <NavigationItem link="/auth" exact>
              <div className="flex gap-1.5 rounded-sm active:bg-lumepic-light_black px-2 py-1 mb-1">
                <img src={loginIcon} alt="home" className="w-6 ml-1" />
                <span className="text-bg_details font-lumepicRegular mt-0.5">{t('Sign in')}</span>
              </div>
            </NavigationItem>
          )}
        </ul>
        <div>
          <div className="flex justify-between mb-3 mx-1.5 list-none">
            <div className="w-full flex flex-col items-start">
              <span className="mb-1.5 text-lumepic-dark_grey text-lumepicSm font-medium">
                {t('Country')}
              </span>
              <div className="flex gap-0.5 items-end mb-1 p-1 bg-lumepic-light_black border border-lumepic-grey rounded-md">
                <span
                  className={`mb-1 w-3 h-3 fi fi-${locationStore.userLocation.country.code.toLowerCase()}`}
                />
                <span
                  className="text-lumepic-medium_grey text-lumepicSm"
                  onClick={() => setOpenCountrySwitcherModal(true)}
                >
                  {t(locationStore.userLocation.country.name).toUpperCase()}
                </span>
              </div>
            </div>
            <CountrySwitcherModal
              open={openCountrySwitcherModal}
              onClose={() => setOpenCountrySwitcherModal(false)}
            />
            <div className="flex flex-col items-end">
              <span className="mb-1.5 text-lumepic-dark_grey text-lumepicSm font-medium">
                {t('Language')}
              </span>
              <div className="flex">
                <div
                  className={`flex items-center justify-center px-2 gap-1 py-1 cursor-pointer border-y border-l border-lumepic-grey rounded-l-md ${
                    currentLanguage === 'es'
                      ? 'bg-lumepic-medium_grey text-black'
                      : 'bg-lumepic-light_black text-white'
                  }`}
                  onClick={() => {
                    handleLanguageChange(LanguageOptions.SPANISH)
                  }}
                >
                  <span>{flagIcon('es')}</span>
                  <span className="text-lumepicSm font-medium">ES</span>
                </div>
                <div
                  className={`flex items-center justify-center px-2 gap-1 py-1 cursor-pointer border-y border-r border-lumepic-grey rounded-r-md ${
                    currentLanguage === 'en'
                      ? 'bg-lumepic-medium_grey text-black'
                      : 'bg-lumepic-light_black text-white'
                  }`}
                  onClick={() => {
                    handleLanguageChange(LanguageOptions.ENGLISH)
                  }}
                >
                  <span>{flagIcon('en')}</span>
                  <span className="text-lumepicSm font-medium">EN</span>
                </div>
              </div>
            </div>
          </div>

          {isAuthenticated && (
            <div
              className="flex justify-between items-center rounded-full pl-2 pr-4 py-2 mb-2 mx-2 bg-lumepic-grey active:opacity-80 transition-opacity duration-100 cursor-pointer"
              onClick={() => goTo(paths.MY_ACCOUNT)}
            >
              <div className="flex items-center gap-2">
                {isAuthenticated && !isNil(authStore.getLoggedUser().profilePictureUrl) ? (
                  <Avatar
                    src={authStore.getLoggedUser().profilePictureUrl}
                    sx={{ width: 50, height: 50 }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      bgcolor: 'secondary.main',
                      width: 50,
                      height: 50,
                      fontSize: '25px',
                      color: 'text.secondary',
                    }}
                  >
                    {authStore.getLoggedUser().firstName?.charAt(0) +
                      authStore.getLoggedUser().lastName?.charAt(0)}
                  </Avatar>
                )}
                <div className="flex flex-col">
                  <span className="text-white">
                    {authStore.getLoggedUser().firstName + ' ' + authStore.getLoggedUser().lastName}
                  </span>
                  <span className="text-xs text-lumepic-dark_grey">{t('View profile')}</span>
                </div>
              </div>
              <FontAwesomeIcon icon={faGear} className="text-2xl text-lumepic-dark_grey" />
            </div>
          )}
        </div>
      </div>
    )
  }

  return <AuxHoc>{content}</AuxHoc>
}

export default observer(NavigationItems)
