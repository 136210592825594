import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import englishQuestions from './components/en.data'
import spanishQuestions from './components/es.data'
import FAQAccordion from './components/faqAccordion'
import Tracker from '../../shared/tracking'
import StoresContext from '../../providers/storesContext'
import LoginRegisterStore from 'containers/Auth/LoginRegisterStore'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { toast } from 'react-toastify'
import i18next from 'i18next'
import { SetPageTitle } from '../../shared/utility'

const FAQ = () => {
  SetPageTitle('FAQ')
  const { t } = useTranslation()
  Tracker.pageView('/FAQ', 'Frequently Asked Questions')

  const { authStore, cartStore, locationStore } = useContext(StoresContext)!
  const currentLanguage = i18next.language
  const questions = currentLanguage === 'es' ? spanishQuestions : englishQuestions

  const [loginRegisterStore] = useState(
    () => new LoginRegisterStore(authStore, cartStore, locationStore)
  )

  const googleLoginSuccess = (credentialResponse: CredentialResponse) => {
    return loginRegisterStore.googleAuth(credentialResponse)
  }
  const googleLoginError = () => {
    toast.error(t('Error on login'), {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  }

  return (
    <>
      <div className="bg-bg_section_titles border-b mb-2 pb-3 pt-4">
        <div className="container mx-auto">
          <h2 className="text-2xl pt-3 font-extrabold md:text-4xl text-lumepic-light_black ml-4 sm:ml-0">
            {t('Frequently Asked Questions')}
          </h2>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="mb-20 mt-10">
          <div className="p-4 bg-white">
            <FAQAccordion
              key={'generics'}
              id={'generics'}
              title={t('GENERICS')}
              content={
                <div>
                  {questions
                    .filter((question) => question.section === 0)
                    .map((question) => (
                      <FAQAccordion
                        key={question.id}
                        id={question.id}
                        title={question.title}
                        content={question.content}
                        type="question"
                      />
                    ))}
                </div>
              }
              openOnInit={true}
              type="section"
            />
            <FAQAccordion
              key={'fotografos'}
              id={'fotografos'}
              title={t('PHOTOGRAPHERS')}
              content={
                <div>
                  {questions
                    .filter((question) => question.section === 1)
                    .map((question) => (
                      <FAQAccordion
                        key={question.id}
                        id={question.id}
                        title={question.title}
                        content={question.content}
                        type="question"
                      />
                    ))}
                </div>
              }
              type="section"
            />
            <FAQAccordion
              key={'deportistas'}
              id={'deportistas'}
              title={t('ATHLETES')}
              content={
                <div>
                  {questions
                    .filter((question) => question.section === 2)
                    .map((question) => (
                      <FAQAccordion
                        key={question.id}
                        id={question.id}
                        title={question.title}
                        content={question.content}
                        type="question"
                      />
                    ))}
                </div>
              }
              type="section"
            />
          </div>
        </div>
      </div>
      {!authStore.isAuthenticated() && (
        <div className="hidden">
          <GoogleLogin
            size="large"
            width="150px"
            useOneTap
            onSuccess={googleLoginSuccess}
            onError={googleLoginError}
          />
        </div>
      )}
    </>
  )
}

export default FAQ
