import React from 'react'
import MuiModal from '../../../../components/UI/Modal/MuiModal'
import { ModalSize } from '../../../../components/UI/Modal/MuiModal'

interface EventCustomBannerProps {
  bannerUrl: string
  eventId: string
  eventName: string
}
const EventCustomBanner = (props: EventCustomBannerProps) => {
  const [open, setOpen] = React.useState(true)

  return (
    <>
      <MuiModal
        key={props.eventId}
        open={open}
        size={ModalSize.LARGE}
        handleClose={() => {
          setOpen(false)
        }}
      >
        <img
          className="max-w-full cursor-pointer"
          alt={'Banner for ' + props.eventName}
          src={props.bannerUrl}
          onClick={() => setOpen(false)}
        />
      </MuiModal>
    </>
  )
}

export default EventCustomBanner
