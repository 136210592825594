import React, { Dispatch, SetStateAction } from 'react'
import { filterTypes } from '../EventDetailsFirstRenderDesktop'
import bibIcon from '../../../../../../assets/icons/v2/bibIcon.svg'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

type TextRecognitionButtonDesktopProps = {
  selectedFilter: string
  setSelectedFilter: Dispatch<SetStateAction<filterTypes>>
}

const TextRecognitionButtonDesktop = ({
  selectedFilter,
  setSelectedFilter,
}: TextRecognitionButtonDesktopProps) => {
  const { t } = useTranslation()
  return (
    <div
      className={`w-full rounded-lg flex items-center gap-4 p-3 border relative cursor-pointer transition duration-300 animate-[fadeIn_0.1s_ease-in-out] ${
        selectedFilter === filterTypes.TEXT ? 'bg-lumepic-light_black' : 'bg-bg_details'
      }`}
      onClick={() => setSelectedFilter(filterTypes.TEXT)}
    >
      {selectedFilter === filterTypes.TEXT && (
        <div className="absolute right-3 top-3 bg-primary_dark flex items-center justify-center rounded-full p-1">
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
      <div
        className={`rounded-full h-30 flex items-center justify-center p-4 border mb-2 ${
          selectedFilter === filterTypes.TEXT
            ? 'bg-primary_dark border-lumepic-light_black'
            : 'bg-lumepic-medium_grey border-lumepic-dark_grey'
        }`}
      >
        <img src={bibIcon} alt="bib" />
      </div>
      <div className="flex flex-col items-start">
        <p
          className={`font-medium text-xl mb-0 mt-0.5 ${
            selectedFilter === filterTypes.TEXT ? 'text-white' : 'text-black'
          }`}
        >
          {t('Text recognition')}
        </p>
        <p
          className={`text-sm text-center text-lumepic-grey ${
            selectedFilter === filterTypes.TEXT ? 'text-lumepic-medium_grey' : 'text-lumepic-grey'
          }`}
        >
          {t('Upload your BIB or jersey number')}
        </p>
      </div>
    </div>
  )
}

export default TextRecognitionButtonDesktop
