import { Skeleton } from '@mui/material'
import React from 'react'

const SkeletonSection = () => (
  <div className="flex flex-col gap-2 md:flex md:flex-row md:justify-between md:gap-4 p-2">
    <div className="w-full">
      <Skeleton variant="rounded" width="100%" height={300} />
    </div>

    <div className="w-full md:w-1/3">
      <Skeleton variant="rounded" width="100%" height={300} />
    </div>
  </div>
)
export default SkeletonSection
