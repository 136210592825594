import React from 'react'
import Button from 'components/UI/Button'
import ConfigService, { AssetNames } from 'config'
import { useTranslation } from 'react-i18next'
import { AUTH } from '../../../../../routing/Paths'
import { useLocation } from 'react-router'
import { isNil } from 'lodash'

type HeroSectionDesktopProps = {
  goTo: (e: any, path: string) => void
  isAuthenticated: boolean
}

const HeroSectionDesktop = ({ goTo, isAuthenticated }: HeroSectionDesktopProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const countryCode = queryParams.get('countryCode')

  const feedAlbumsWithCountryCode = `/feed/albums${
    !isNil(countryCode) && countryCode !== 'UY' ? `?countryCode=${countryCode}` : ''
  }`

  return (
    <div className="w-full h-full relative">
      <div className="absolute z-20 w-1/2 h-full flex flex-col justify-center pl-10 lg:pl-20 py-2">
        <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-lumepic-light_black font-semibold">
          {t('The global sports photography platform') + '.'}
        </h1>
        <div className="h-3 sm:h-5 md:h-7 lg:h-10" />
        <p className="mb-1 text-xs md:text-sm xl:text-base lg:text-lg">
          {t('We help athletes to immortalize incredible moments.')}
        </p>
        <p className="mb-0 text-xs md:text-sm xl:text-base lg:text-lg">
          {t('We encourage photographers to turn their hobby into a profession.')}
        </p>
        <div className="h-3 sm:h-5 md:h-7 lg:h-10" />
        <div className="flex gap-3">
          <Button
            btnType="PrimaryAction"
            extraStyle="w-auto"
            onClick={(e: any) => goTo(e, feedAlbumsWithCountryCode)}
          >
            <span className="text-xs sm:text-sm lg:text-base">{t('See albums')}</span>
          </Button>
          {!isAuthenticated && (
            <span
              className="w-auto px-10 bg-bg_details border-2 border-lumepic-light_black hover:shadow-lg ease-out duration-150 opacity-80 cursor-pointer rounded-lg py-1 flex items-center justify-center text-sm lg:text-base"
              onClick={(e: any) => goTo(e, AUTH)}
            >
              {t('Login')}
            </span>
          )}
        </div>
      </div>
      <img
        src={ConfigService.getAsset(AssetNames.LANDING_HERO_DESKTOP)}
        className="object-cover object-center w-full h-full z-10"
        alt="Homepage photographer image"
      />
    </div>
  )
}

export default HeroSectionDesktop
