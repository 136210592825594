import React from 'react'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMinus,
  faPlus,
  faAngleRight,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons'
import { isNil } from 'lodash'

type CustomToggleFullProps = {
  eventKey: any
  opened: boolean
  onClick: Function
  children?: any
  extraClassNames?: string
  childrenClasses?: string
  rightAngleIcon?: boolean
  angleIconClasses?: string
  plusToggle?: boolean
  angleToggle?: boolean
  toggleIconClasses?: string
  descriptionHeader?: any
}

const CustomToggleFull = ({
  eventKey,
  opened,
  onClick,
  children,
  extraClassNames,
  childrenClasses,
  rightAngleIcon,
  angleIconClasses,
  plusToggle,
  angleToggle,
  toggleIconClasses,
  descriptionHeader,
}: CustomToggleFullProps) => {
  const decoratedOnClick = useAccordionButton(eventKey)

  const clicked = (eventKey: any) => {
    decoratedOnClick(eventKey)
    if (!isNil(onClick)) {
      onClick(eventKey)
    }
  }

  return (
    <>
      <div onClick={clicked} className={extraClassNames}>
        <div className={childrenClasses}>
          {rightAngleIcon && <FontAwesomeIcon icon={faAngleRight} className={angleIconClasses} />}
          {children}
        </div>
        {plusToggle && (
          <i>
            {opened ? (
              <FontAwesomeIcon className={toggleIconClasses} icon={faMinus} />
            ) : (
              <FontAwesomeIcon className={toggleIconClasses} icon={faPlus} />
            )}
          </i>
        )}
        {angleToggle && (
          <i>
            {opened ? (
              <FontAwesomeIcon className={toggleIconClasses} icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon className={toggleIconClasses} icon={faAngleDown} />
            )}
          </i>
        )}
      </div>
      {descriptionHeader && <div onClick={clicked}>{descriptionHeader}</div>}
    </>
  )
}

export default CustomToggleFull
