import React from 'react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import MuiCheckbox from '@mui/material/Checkbox'
import checkIcon from '../../../assets/icons/v2/checkIcon.svg'
import checkBoxOutline from '../../../assets/icons/v2/checkBoxOutline.svg'

interface CheckboxProps {
  id: string
  label: string | React.ReactNode
  value: boolean
  onChange: (value: boolean) => void
  fontColor?: string
  fontSize?: string
  fontWeight?: string
}

const Checkbox = ({
  id,
  label,
  value,
  onChange,
  fontColor = 'black',
  fontSize = '0.9rem',
  fontWeight = '500',
}: CheckboxProps) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  return (
    <FormGroup className="flex flex-row justify-center items-center">
      <FormControlLabel
        control={
          <MuiCheckbox
            id={id}
            onChange={handleCheckboxChange}
            sx={{
              color: 'transparent',
            }}
            value={value}
            checked={value}
            checkedIcon={
              <span>
                <img
                  src={checkIcon}
                  alt="Check-icon"
                  style={{ width: '24px', height: '24px' }}
                  className="border-2 border-primary rounded p-1 bg-primary"
                />
              </span>
            }
            icon={
              <span>
                <img
                  src={checkBoxOutline}
                  alt="Check-icon"
                  style={{ width: '24px', height: '24px' }}
                />
              </span>
            }
          />
        }
        label={label}
        sx={{
          '& .css-13d9eez-MuiTypography-root': {
            color: fontColor,
            fontSize: fontSize,
            fontWeight: fontWeight,
          },
        }}
      />
    </FormGroup>
  )
}

export default Checkbox
