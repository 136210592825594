import { makeAutoObservable, runInAction } from 'mobx'
import InputStore from '../../../../../shared/store/InputStore'
import AlbumService from '../../../../../services/AlbumService'
import * as yup from 'yup'
import UserService from '../../../../../services/UserService'
import AuthStore from 'stores/AuthStore'
import DiscountPercentage from 'shared/models/QuantityDiscount'
import { QuantityDiscountDisplay } from './AddDiscountModal'
import i18n from 'i18n'

class DiscountModalStore {
  private readonly albumService: AlbumService
  private readonly userService: UserService
  public discountRanges: InputStore<DiscountPercentage[]>
  public isLoading: boolean
  public avoidDiscounts: boolean
  public error: any

  constructor(private readonly authStore: AuthStore) {
    this.authStore = authStore
    this.reset()
    makeAutoObservable(this)
    this.albumService = new AlbumService()
    this.userService = new UserService()
  }

  reset() {
    this.discountRanges = new InputStore(
      yup
        .array()
        .of(
          yup.object().shape({
            numberOfPictures: yup
              .number()
              .moreThan(0, () => i18n.t('The number of pictures must be greater than 0'))
              .positive(() => i18n.t('The default number of pictures must be positive'))
              .required(() => i18n.t('El número de imágenes es requerido')),
            discountPercentage: yup
              .number()
              .moreThan(0, () => i18n.t('The percentage must be greater than 0'))
              .lessThan(101, () => i18n.t('The percentage must be less than 100'))
              .positive(() => i18n.t('The percentage must be positive'))
              .required(() => i18n.t('The percentage is required')),
          })
        )
        .required(() =>
          i18n.t('To save, there must be at least 1 discount range per number of images')
        )
    )
    this.isLoading = false
    this.error = null
    this.avoidDiscounts = false
  }

  changeDiscountRange(val: DiscountPercentage[]) {
    this.discountRanges.setValue(val)
  }

  changeAvoidDiscounts() {
    this.avoidDiscounts = !this.avoidDiscounts
  }

  clearErrors() {
    this.discountRanges.clearError()
    this.error = null
  }

  validateDiscountRangeDuplicates() {
    let valid = true
    const uniqueNumberOfPictures = new Set<number>([])
    this.discountRanges.value.forEach((discountRange) => {
      if (!uniqueNumberOfPictures.has(discountRange.numberOfPictures)) {
        uniqueNumberOfPictures.add(discountRange.numberOfPictures)
      } else {
        valid = false
      }
    })
    return valid
  }

  validateAtLeastOneDiscountRange() {
    const valid = true
    if (this.discountRanges.value.length <= 0 && !this.avoidDiscounts) return false
    return valid
  }

  validateDiscountRanges() {
    const valid = true
    const sortedDiscountPercentages = this.discountRanges.value.sort(
      (a, b) => a.numberOfPictures - b.numberOfPictures
    )

    for (let i = 1; i < sortedDiscountPercentages.length; i++) {
      if (
        sortedDiscountPercentages[i].discountPercentage <=
        sortedDiscountPercentages[i - 1].discountPercentage
      ) {
        return false
      }
    }
    return valid
  }

  async validate() {
    this.clearErrors()
    let isValid = true

    if (!this.validateAtLeastOneDiscountRange()) {
      this.discountRanges.errorMessage = i18n.t(
        'To save, there must be at least 1 discount range per number of images'
      )
      isValid = false
      return
    }

    if (!(await this.discountRanges.validate())) {
      this.discountRanges.errorMessage = i18n.t(
        'The number of pictures must be positive greater than 0 and the discount percentage must be positive between 0 and 100'
      )
      isValid = false
    }

    if (!this.validateDiscountRangeDuplicates()) {
      this.discountRanges.errorMessage = i18n.t(
        'There cannot be more than one range with the same number of pictures'
      )
      isValid = false
    }

    if (!this.validateDiscountRanges()) {
      this.discountRanges.errorMessage = i18n.t('Discount ranges must be incremental')
      isValid = false
    }
    return isValid
  }

  async createQuantityDiscount(albumId: string, display: QuantityDiscountDisplay) {
    if (await this.validate()) {
      runInAction(() => {
        this.isLoading = true
      })

      try {
        await this.albumService.createAlbumQuantityDiscount(
          this.discountRanges.value,
          albumId,
          this.authStore.getToken(),
          display
        )

        runInAction(() => {
          this.isLoading = false
        })

        return true
      } catch (e) {
        runInAction(() => {
          this.isLoading = false
        })

        return false
      }
    }
  }

  async clearDiscount(albumId: string) {
    this.isLoading = true
    try {
      await this.albumService.clearAlbumQuantityDiscount(albumId, this.authStore.getToken())

      runInAction(() => {
        this.isLoading = false
      })

      return true
    } catch (e) {
      runInAction(() => {
        this.isLoading = false
      })
      return false
    }
  }
}

export default DiscountModalStore
