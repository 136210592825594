import { isNil } from 'lodash'
import Album from '../shared/models/Album'
import { Event } from '../shared/models/Event'
import {
  AlbumTagPackageLineItemDto,
  BurstPackageLineItemDto,
  EventTagPackageLineItemDto,
  PurchaseLineItemPackageType,
  PurchasePackageLineItemDto,
} from '../services/RequestInterfaces/Purchase/PurchaseRequestInterfaces'

export class LineItemBuilder {
  static BuildTagPackageLineItem(
    tagId: string,
    albums?: Album[],
    event?: Event,
    photographIds?: string[]
  ): PurchasePackageLineItemDto {
    if (!isNil(event)) {
      return this.BuildEventTagPackageLineItem(tagId, event.id, photographIds)
    }

    if (isNil(albums) || albums.length !== 1) {
      throw new Error('Error building Package Line Item: albums must be defined for Album packages')
    }

    const [album] = albums

    return this.BuildAlbumTagPackageLineItem(tagId, album.id, photographIds)
  }

  public static BuildBurstPackageLineItem(
    burstId: string,
    albums?: Album[],
    event?: Event,
    photographIds?: string[]
  ): BurstPackageLineItemDto {
    if (!isNil(event)) {
      return {
        packageType: PurchaseLineItemPackageType.Burst,
        burstId,
        eventId: event.id,
        albumId: undefined,
        photographIds,
      }
    }

    if (isNil(albums) || albums.length !== 1) {
      throw new Error('Error building Package Line Item: albums must be defined for Album packages')
    }

    const [album] = albums

    return {
      packageType: PurchaseLineItemPackageType.Burst,
      burstId,
      eventId: undefined,
      albumId: album.id,
      photographIds,
    }
  }

  private static BuildEventTagPackageLineItem(
    tagId: string,
    eventId: string,
    photographIds?: string[]
  ): EventTagPackageLineItemDto {
    return {
      packageType: PurchaseLineItemPackageType.Tag,
      tagId,
      eventId,
      photographIds,
    }
  }

  private static BuildAlbumTagPackageLineItem(
    tagId: string,
    albumId: string,
    photographIds?: string[]
  ): AlbumTagPackageLineItemDto {
    return {
      packageType: PurchaseLineItemPackageType.Tag,
      tagId,
      albumId,
      photographIds,
    }
  }
}
