import React, { useState } from 'react'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBasketballBall,
  faCar,
  faChevronLeft,
  faChevronRight,
  faDumbbell,
  faFutbol,
  faPersonBiking,
  faSwimmer,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { LandingConfigInterface } from 'config/interfaces/config.interface'

type OtherSportsProps = {
  slidesPerView: number
  onClick: (activityId: string) => void
  config: LandingConfigInterface
}
const OtherSports = ({ slidesPerView, onClick, config }: OtherSportsProps) => {
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = React.useState<number>(0)
  const [loaded, setLoaded] = useState(false)

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    created() {
      setLoaded(true)
    },
    slides: {
      perView: slidesPerView,
    },
    loop: true,
    detailsChanged(s) {
      setActiveIndex(s.track.details.rel)
    },
  })

  const breadcrumbs = (totalSlides: number, activeIndex: number) => (
    <div className="flex gap-1 items-center">
      {[...Array(totalSlides)].map((_, index) => (
        <div
          key={index}
          className={`${
            index === activeIndex
              ? 'bg-primary w-2.5 h-2.5'
              : 'bg-lumepic-dark_grey opacity-80 w-2 h-2'
          }`}
          style={{ borderRadius: '50%' }}
        />
      ))}
    </div>
  )

  const cardClasses =
    'cursor-pointer text-lumepic-light_black border-2 border-lumepic-dark_grey rounded-lg py-3 flex flex-col items-center gap-2 mr-1 sm:mr-2 transition ease-in-out delay-50 bg-bg_details hover:text-bg_details hover:bg-lumepic-light_black duration-200'

  const getIcon = (activityName: string) => {
    if (activityName == config.OTHER_FEATURED_ACTIVITIES[0].name) {
      return <FontAwesomeIcon icon={faPersonBiking} className="text-3xl sm:text-7xl" />
    } else if (activityName == config.OTHER_FEATURED_ACTIVITIES[1].name) {
      return <FontAwesomeIcon icon={faFutbol} className="text-3xl sm:text-7xl" />
    } else if (activityName == config.OTHER_FEATURED_ACTIVITIES[2].name) {
      return <FontAwesomeIcon icon={faCar} className="text-3xl sm:text-7xl" />
    } else if (activityName == config.OTHER_FEATURED_ACTIVITIES[3].name) {
      return <FontAwesomeIcon icon={faDumbbell} className="text-3xl sm:text-7xl" />
    } else if (activityName == config.OTHER_FEATURED_ACTIVITIES[4].name) {
      return <FontAwesomeIcon icon={faBasketballBall} className="text-3xl sm:text-7xl" />
    } else if (activityName == config.OTHER_FEATURED_ACTIVITIES[5].name) {
      return <FontAwesomeIcon icon={faSwimmer} className="text-3xl sm:text-7xl" />
    }
  }

  return (
    <>
      <div ref={sliderRef} className="keen-slider">
        {config.OTHER_FEATURED_ACTIVITIES.map((featuredActivity, idx) => (
          <div className="keen-slider__slide p-1" key={idx}>
            <div className={cardClasses} onClick={() => onClick(featuredActivity.activityId)}>
              {getIcon(featuredActivity.name)}
              <span className="text-sm sm:text-xl font-medium sm:font-semibold">
                {t(featuredActivity.name)}
              </span>
            </div>
          </div>
        ))}
      </div>
      {loaded && instanceRef.current && (
        <div className="hidden w-full sm:flex justify-center mt-4 gap-1.5 pr-3 pb-3">
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="text-4xl cursor-pointer text-lumepic-grey"
            onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()}
          />
          <FontAwesomeIcon
            icon={faChevronRight}
            className="text-4xl cursor-pointer text-lumepic-grey"
            onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
          />
        </div>
      )}
      <div className="sm:hidden w-full flex justify-center mt-3">
        {breadcrumbs(config.OTHER_FEATURED_ACTIVITIES.length, activeIndex)}
      </div>
    </>
  )
}

export default observer(OtherSports)
