export enum TagSource {
  Admin = 'ADMIN',
  Photographer = 'PHOTOGRAPHER',
  RecognitionService = 'RECOGNITION_SERVICE',
}

export enum TagKeys {
  Number = 'number',
  Text = 'text',
  User = 'user',
  Face = 'face',
}

export default interface TagInterface {
  id: string
  key: TagKeys
  value: string
  source: TagSource
  taggedUserId?: string
  createdAt?: Date
  photographId: string
}
