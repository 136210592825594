import React from 'react'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

type BenefitsFrameDesktopProps = {
  icon: IconDefinition
  title: string
}

const BenefitsFrameDesktop = ({ icon, title }: BenefitsFrameDesktopProps) => {
  const { t } = useTranslation()
  return (
    <div className="rounded-lg sm:bg-lumepic-light_black px-2 sm:px-4 lg:px-6 xl:px-8 py-3 sm:py-6 lg:py-8 xl:py-10 flex flex-col items-center justify-center gap-3 sm:shadow-lg">
      <FontAwesomeIcon
        icon={icon}
        className="text-primary_dark text-4xl sm:text-5xl lg:text-7xl xl:text-8xl"
      />
      <p className="text-black sm:text-white text-center text-sm sm:text-base lg:text-lg xl:text-xl ">
        {t(title)}
      </p>
    </div>
  )
}

export default BenefitsFrameDesktop
