import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import LoginRegisterStore from '../LoginRegisterStore'
import StoresContext from '../../../providers/storesContext'
import 'moment/locale/es'
import LoginRegisterForm from '../components/LoginRegisterForm'
import Button from 'components/UI/Button'
import ServerError from '../../../components/UI/ServerError'
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../../services/axios'
import Tracker from '../../../shared/tracking'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import logoColored from '../../../assets/icons/v2/logo-colored.svg'
import RegisterBackground from '../../../assets/images/RegisterBackground.svg'
import Spinner from 'components/UI/Spinner'
import { PRIVACY_POLICIES, TERMS_AND_CONDITIONS } from 'routing/Paths'
import { SetPageTitle } from '../../../shared/utility'

const SIGN_IN_MODE = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
}

type RegisterProps = {
  setSignInMode: React.Dispatch<React.SetStateAction<string>>
  googleLoginSuccess: (credentialResponse: CredentialResponse) => void
  googleLoginError: () => void
}

const Register = ({ setSignInMode, googleLoginSuccess, googleLoginError }: RegisterProps) => {
  SetPageTitle('Register')
  const { t } = useTranslation()
  const { authStore, cartStore, locationStore } = useContext(StoresContext)!
  const [loginRegisterStore] = useState(
    () => new LoginRegisterStore(authStore, cartStore, locationStore)
  )
  const { isLoading } = loginRegisterStore

  const handleRegisterSubmit = async () => {
    Tracker.register()
    await loginRegisterStore.register()
  }

  useEffect(() => {
    return () => {
      authStore.clearErrors()
    }
  }, [authStore])

  if (isLoading) {
    return (
      <Spinner divStyles="flex justify-center h-primaryMobile md:h-primaryDesktop items-center" />
    )
  }

  const termsAndConditionsLabel = (
    <a
      target="_blank"
      href={TERMS_AND_CONDITIONS}
      rel="noreferrer"
      className="text-xs no-underline text-lumepic-light_black hover:no-underline hover:text-lumepic-light_black"
    >
      {t('Terms & Conditions')}
    </a>
  )

  const privacyPolicyLabel = (
    <a
      target="_blank"
      href={PRIVACY_POLICIES}
      rel="noreferrer"
      className="text-xs no-underline text-lumepic-light_black hover:no-underline hover:text-lumepic-light_black"
    >
      {t('Privacy Policy')}
    </a>
  )

  return (
    <div className="w-full">
      <div className="relative md:hidden">
        <img src={RegisterBackground} className="border-t-lg" alt="background" />
        <div className="absolute flex items-center gap-1.5 left-8" style={{ bottom: '45%' }}>
          <img src={logoColored} className="w-8 h-8" alt="logo" />
          <h2 className="mb-0 text-3xl font-semibold text-lumepic-light_black">
            {t('Create an account')}
          </h2>
        </div>
      </div>
      <div className="px-4 md:px-8 py-4">
        <h2 className="hidden mb-1 text-4xl font-medium md:block text-lumepic-light_black">
          {t('Register')}
        </h2>
        <p className="hidden mb-2 text-lumepic-light_black md:block">{`${t(
          'Fill in the fields below to create a new account.'
        )}`}</p>

        {authStore.error && <ServerError message={t(`${authStore.error}`).toString()} />}
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleRegisterSubmit()
          }}
        >
          <LoginRegisterForm
            loginRegisterStore={loginRegisterStore}
            isLogin={SIGN_IN_MODE.REGISTER}
          />

          <Button onClick={handleRegisterSubmit} btnType="PrimaryAction" extraStyle="w-full mt-4">
            {t('Register')}
          </Button>
        </form>
        <div className="w-full mt-3 text-xs text-center">
          <span className="mr-1 text-xs font-light">{t('By signing up, you agree to the')}</span>
          {termsAndConditionsLabel}
          <span className="mx-1 text-xs font-light">{t('and the')}</span>
          {privacyPolicyLabel}.
        </div>
        <div className="flex items-center mt-3 mb-2">
          <div className="w-full border-b border-lumepic-dark_grey" />
          <span className="px-2 text-xs text-lumepic-dark_grey">{t('Or')}</span>
          <div className="w-full border-b border-lumepic-dark_grey" />
        </div>
        <div className="mt-3 flex flex-col sm:flex-row justify-center items-center gap-2.5">
          <GoogleLogin
            size="large"
            width="150px"
            text="signup_with"
            useOneTap
            onSuccess={googleLoginSuccess}
            onError={googleLoginError}
          />
        </div>
        <div className="flex gap-1.5 justify-center items-center mb-1 mt-3">
          <p className="mb-0 text-sm text-lumepic-light_black">{t('Already have an account?')}</p>
          <p
            className="px-1 mb-0 font-semibold rounded-sm cursor-pointer text-primary"
            style={{ backgroundColor: 'rgba(212, 197, 129, 0.05)' }}
            onClick={() => setSignInMode(SIGN_IN_MODE.LOGIN)}
          >
            {t('Login')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default withErrorHandler(observer(Register), axios)
