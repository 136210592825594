import React from 'react'
import c from 'classnames'
import { isNil } from 'lodash'
import moment from 'moment'

const inputDefaultClasses = [
  'p-2',
  'rounded-md',
  'w-full',
  'border-2',
  'text-lumepic-grey',
  'focus:outline-none',
  'focus:shadow-md',
]

const inputInvertedClasses = [
  'bg-gray-600',
  'text-white',
  'focus:ring-gray-800',
  'focus:ring-opacity-80',
]

type DatePickerProps = {
  onChange: (value: string) => void
  value: Date
  onPressEnter?: () => void
  inputRef?: React.RefObject<HTMLInputElement> | null
  inverted?: boolean
  error?: boolean
  extraClassNames?: string
}

const DatePicker = ({
  onChange,
  value,
  onPressEnter = () => {},
  inputRef = null,
  inverted = false,
  error = false,
  extraClassNames = '',
}: DatePickerProps) => {
  const inputStyleClasses = !inverted ? inputDefaultClasses : inputInvertedClasses
  const errorClasses = error ? ['border-warning'] : ['border-lumepic-grey']
  const inputClasses = [...inputStyleClasses, ...errorClasses, extraClassNames]

  const handleKeyPress = (e: { key: string }) => {
    if (e.key === 'Enter' && onPressEnter !== null) {
      onPressEnter()
    }
  }

  const handleChange = (e: { target: { value: string } }) => {
    onChange(e.target.value)
  }

  const renderValue = !isNil(value) ? moment.utc(value).format('yyyy-MM-DD') : ''

  if (!isNil(value)) {
    inputClasses.push('text-lumepic-black')
  }
  return (
    <input
      className={c(inputClasses)}
      onKeyPress={handleKeyPress}
      onChange={handleChange}
      ref={inputRef}
      value={renderValue}
      type="date"
    />
  )
}

export default DatePicker
