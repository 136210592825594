import React from 'react'
import { Skeleton } from '@mui/material'

const SkeletonLoaderPurchases = () => {
  return (
    <div className="flex flex-col md:flex md:flex-row md:w-full">
      <div className="w-full flex overflow-hidden px-2 py-2 bg-white md:hidden">
        <Skeleton variant="rounded" height={25} width={200} />
      </div>
      <div className="w-full flex flex-col gap-2 md:w-1/2 md:gap-4">
        {[...Array(4)].map((_, index) => (
          <>
            <div
              className="w-full flex overflow-hidden px-2 bg-white mb-2 justify-between md:w-3/4 md:flex md:gap-16 md:rounded-lg"
              key={index}
            >
              <div className="flex flex-col gap-2 py-3 md:hidden">
                <Skeleton variant="rounded" height={20} width={75} />
                <Skeleton variant="rounded" height={20} width={100} />
                <Skeleton variant="rounded" height={20} width={110} />
              </div>
              <div className="flex flex-col gap-2 py-3 md:hidden">
                <Skeleton variant="rounded" height={20} width={75} />
                <Skeleton variant="rounded" height={20} width={100} />
                <Skeleton variant="rounded" height={20} width={100} />
              </div>
              <div className="hidden md:w-full md:flex md:justify-between md:py-3">
                <div className="flex flex-col gap-2 py-3">
                  <Skeleton variant="rounded" height={20} width={75} />
                  <Skeleton variant="rounded" height={20} width={100} />
                  <Skeleton variant="rounded" height={20} width={110} />
                </div>
                <div className="flex flex-col gap-2 py-3">
                  <Skeleton variant="rounded" height={20} width={75} />
                  <Skeleton variant="rounded" height={20} width={100} />
                  <Skeleton variant="rounded" height={20} width={100} />
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      <div className="hidden md:flex md:flex-col md:justify-between md:w-full md:mb-3 md:rounded-lg">
        <div className="w-full flex flex-col gap-4 p-3 bg-white rounded-lg">
          <div className="w-full flex justify-between">
            <Skeleton variant="rounded" height={35} width={200} />
            <Skeleton variant="rounded" height={30} width={180} />
          </div>
          {[...Array(2)].map((_, index) => (
            <>
              <div className="w-full flex gap-3" key={index}>
                <div className="flex flex-col gap-3">
                  <Skeleton variant="rounded" height={180} width={220} />
                </div>
                <div className="w-full flex flex-col justify-between">
                  <div className="w-full flex justify-between">
                    <Skeleton variant="rounded" height={30} width={200} />
                    <Skeleton variant="rounded" height={30} width={50} />
                  </div>
                  <div className="flex flex-col gap-3">
                    <Skeleton variant="rounded" height={25} width={100} />
                    <Skeleton variant="rounded" height={25} width={100} />
                  </div>
                </div>
              </div>
              <div className="w-1/4 flex justify-center">
                <Skeleton variant="rounded" height={30} width={180} />
              </div>
            </>
          ))}
        </div>
        <div className="w-full bg-white flex flex-col mt-4 p-3 rounded-lg">
          <div className="py-3">
            <Skeleton variant="rounded" height={30} width={220} />
          </div>
          <div className="w-full flex justify-between">
            <div className="flex flex-col gap-4">
              <Skeleton variant="rounded" height={25} width={80} />
              <Skeleton variant="rounded" height={25} width={80} />
              <Skeleton variant="rounded" height={25} width={80} />
              <Skeleton variant="rounded" height={25} width={80} />
            </div>
            <div className="flex flex-col gap-4">
              <Skeleton variant="rounded" height={25} width={80} />
              <Skeleton variant="rounded" height={25} width={80} />
              <Skeleton variant="rounded" height={25} width={80} />
              <Skeleton variant="rounded" height={25} width={80} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SkeletonLoaderPurchases
