import React from 'react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'

export enum DisplayMode {
  USD = 'usd',
  COUNT = 'count',
}

type ToggleProps = {
  displayMode: DisplayMode
  setDisplayMode: React.Dispatch<React.SetStateAction<DisplayMode>>
}

const Toggle = ({ displayMode, setDisplayMode }: ToggleProps) => {
  const { t } = useTranslation()

  const commonStyles = {
    width: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
  }

  const selectedStyles = {
    backgroundColor: '#4A4A4A !important',
    color: 'white !important',
    '&:hover': {
      backgroundColor: '#4A4A4A !important',
      color: 'white !important',
    },
  }

  return (
    <ToggleButtonGroup
      value={displayMode}
      exclusive
      onChange={(_, newMode) => newMode && setDisplayMode(newMode)}
      size="small"
      className="self-center"
    >
      <ToggleButton
        value={DisplayMode.USD}
        sx={{
          ...commonStyles,
          ...(displayMode === DisplayMode.USD && selectedStyles),
        }}
      >
        {t('$ (USD)')}
      </ToggleButton>
      <ToggleButton
        value={DisplayMode.COUNT}
        sx={{
          ...commonStyles,
          ...(displayMode === DisplayMode.COUNT && selectedStyles),
        }}
      >
        {t('Quantity')}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default Toggle
