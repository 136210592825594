import { makeAutoObservable, runInAction } from 'mobx'
import FeedService, { FeedOrderFields, FeedOrderSorts } from 'services/FeedService'
import Album from 'shared/models/Album'
import SearchBarStore from '../../components/UI/SearchBar/SearchBarStore'

const DefaultAlbumsPerPage = 9

export default class PhotographerProfileStore {
  public albums: Album[]
  public firstName: string
  public lastName: string
  public alias: string
  public profilePictureUrl: string
  public albumCount: number
  public publishedAlbums: number
  public isLoadingProfile: boolean
  public isLoadingAlbums: boolean
  public error: any
  public ownerId: string
  public page: number
  public albumsPerPage: number
  public pageCount: number
  private readonly feedService: FeedService

  constructor(private readonly searchBarStore: SearchBarStore, alias: string) {
    this.resetStore()
    this.alias = alias
    makeAutoObservable(this)
    this.feedService = new FeedService()
    this.fetchPhotographerProfileInfo(alias)
  }

  resetStore() {
    this.albums = []
    this.albumCount = 0
    this.isLoadingProfile = false
    this.isLoadingAlbums = false
    this.error = false
    this.page = 0
    this.pageCount = 1
    this.albumsPerPage = DefaultAlbumsPerPage
    this.publishedAlbums = 0
  }

  async fetchAlbums(isSearching = false) {
    runInAction(() => {
      this.isLoadingAlbums = true
    })
    if (isSearching) {
      this.page = 0
      this.albums = []
    }
    try {
      const response = await this.feedService.fetchFeedAlbums({
        limit: this.albumsPerPage,
        offset: this.page * this.albumsPerPage,
        order: {
          field: FeedOrderFields.TAKEN_DATE,
          sort: FeedOrderSorts.DESC,
        },
        ownerId: this.ownerId,
        locationId: this.searchBarStore.location?.id,
        activityId: this.searchBarStore.activity?.id,
        dateFrom: this.searchBarStore.dateRange[0],
        dateTo: this.searchBarStore.dateRange[1],
      })
      runInAction(() => {
        if (isSearching) {
          this.albums = response.albums
        } else {
          this.albums.push(...response.albums)
        }

        const { count } = response
        this.albumCount = count
        this.pageCount = Math.ceil(count / this.albumsPerPage)
        this.isLoadingAlbums = false
      })
    } catch (e) {
      this.isLoadingAlbums = false
      this.error = e
    }
  }

  async fetchPhotographerProfileInfo(alias: string) {
    runInAction(() => {
      this.isLoadingProfile = true
    })
    try {
      const response = await this.feedService.fetchPhotographerProfileInfo(alias)
      runInAction(() => {
        this.publishedAlbums = response.publishedAlbumsCount
        this.firstName = response.firstName
        this.lastName = response.lastName
        this.alias = response.alias
        this.profilePictureUrl = response.profilePictureUrl
        this.ownerId = response.id
        this.isLoadingProfile = false
      })
      return response
    } catch (e: any) {
      runInAction(() => {
        this.error = e
        this.isLoadingProfile = true
      })
      if (e.message === 'User not found') {
        window.location.href = '/'
      }
    }
  }

  setPage(page: number) {
    if (page < 1) {
      throw Error(`Page number can't be less than 1`)
    }
    runInAction(() => {
      this.page = page
    })
  }

  fetchNextPage() {
    this.setPage(this.page + 1)
  }
  hasMorePages() {
    const pageCount = Math.ceil(this.albumCount / this.albumsPerPage)
    return this.page < pageCount
  }
}
