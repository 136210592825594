import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Purchase } from '../../../shared/models/Purchase'
import Button from 'components/UI/Button'
import SaleItemDetails from './SaleItemDetails'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { isNil } from 'lodash'
import StoresContext from 'providers/storesContext'
import { getFeesAmountGenerator } from 'shared/utility'

type SaleItemProps = {
  sale: Purchase
  saleDesktopDetailsOpened: Purchase | null
  setSaleDesktopDetailsOpened: React.Dispatch<React.SetStateAction<Purchase | null>>
  index: number
}

const SaleItem = ({
  sale,
  saleDesktopDetailsOpened,
  setSaleDesktopDetailsOpened,
  index,
}: SaleItemProps) => {
  const { t } = useTranslation()
  const [saleDetailsOpened, setSaleDetailsOpened] = useState(false)
  const { authStore } = useContext(StoresContext)!
  const loggedUser = authStore.getLoggedUser()

  const parseAlbumTakenDate = (takenDate: Date) => {
    const date = moment.utc(takenDate)
    return date.format('DD/MM/YYYY')
  }

  const handleOpenDesktopDetails = () => {
    const isMdScreen = window.innerWidth > 768
    if (isMdScreen) {
      setSaleDesktopDetailsOpened(sale)
      window.scrollTo(0, 0)
    }
  }

  const discounts =
    !isNil(sale.totalPrice) &&
    sale.status === 'approved' &&
    (sale.lineItems.reduce((acc, lineItem) => acc + lineItem.discountTotal, 0) || 0)

  const subTotal = sale.lineItems.reduce((acc, lineItem) => acc + lineItem.subtotalPrice, 0)

  const totalEarnings = subTotal - (discounts || 0) - getFeesAmountGenerator(loggedUser.id, sale)

  return (
    <>
      <div className="relative cursor-pointer bg-white" onClick={handleOpenDesktopDetails}>
        {saleDetailsOpened && (
          <div className="absolute top-1/3 left-0 transform -translate-x-1/2 md:hidden">
            <div className="w-3 h-12 bg-primary rounded-md" />
          </div>
        )}
        {sale === saleDesktopDetailsOpened && (
          <div className="hidden absolute top1/4 left-0 transform -translate-x-1/2 md:flex md:top-1/4">
            <div className="w-3 h-16 bg-primary rounded-md" />
          </div>
        )}
        <div className="flex justify-between w-full py-4 md:w-auto md:text-base">
          <div className="flex flex-col gap-2 px-3 md:w-48">
            <span
              className={`text-xl font-semibold ${
                saleDetailsOpened || sale === saleDesktopDetailsOpened
                  ? 'text-primary'
                  : 'text-lumepic-black'
              }`}
            >
              #{index}
            </span>
            <span className="text-base">
              {sale.currency.toUpperCase()} ${totalEarnings.toFixed(2)}
            </span>
            <span className="text-sm text-lumepic-dark_grey font-regular">
              {sale.photographs.length} {''}
              {sale.photographs.length === 1 ? t('Photograph') : t('Photographs')}
            </span>
          </div>
          <div className="flex flex-col gap-1 w-auto mt-2 px-3 md:text-sm">
            <span className="flex">
              <FontAwesomeIcon
                icon={faCheck}
                style={{ color: '#03a64a', transform: 'rotate(-9.84deg)' }}
                size="lg"
              />
              <span className="text-success text-base">{t('Approved')}</span>
            </span>
            <span className="text-lumepic-dark_grey font-regular text-sm flex justify-end">
              {parseAlbumTakenDate(sale.createdAt)}
            </span>
            <span className="pt-2 flex justify-end md:hidden">
              <Button
                btnType={'TertiaryAction'}
                onClick={() => setSaleDetailsOpened(!saleDetailsOpened)}
                extraStyle="text-sm"
              >
                {!saleDetailsOpened ? t('View details') : t('Close details')}
              </Button>
            </span>
          </div>
        </div>
        <div className="mx-3 md:hidden">
          {!saleDetailsOpened && <div className="w-full flex justify-center border-t" />}
        </div>
        <div className="hidden md:flex md:mx-3">
          {index !== 1 && <div className="w-full flex justify-center border-t" />}
        </div>
      </div>
      <div className="px-3 md:hidden">
        <SaleItemDetails
          sale={sale}
          loggedUser={loggedUser}
          index={index}
          isDetailsOpened={saleDetailsOpened}
          saleDesktopDetailsOpened={saleDesktopDetailsOpened}
        />
      </div>
    </>
  )
}

export default observer(SaleItem)
