import React, { useContext, useEffect, useState } from 'react'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import ProfileStore from '../../../containers/MyAccount/components/UpdateUser/ProfileStore'
import Spinner from 'components/UI/Spinner'
import FileInput from 'components/UI/FileInput'
import { FileTypes } from 'components/UI/FileInput/FileInput'
import { isNil } from 'lodash'
import { observer } from 'mobx-react'
import storesContext from 'providers/storesContext'
import Checkbox from '../Checkbox'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'

const validImageTypes = [FileTypes.PNG, FileTypes.JPG]

type ImageUploadedProps = {
  store: ProfileStore
  onUpload: (file: File) => void
  isSelfie?: boolean
  selectedAsProfilePicture?: boolean
  setSelectedAsProfilePicture?: React.Dispatch<React.SetStateAction<boolean>>
}

const ImageUploaded = ({
  store,
  onUpload,
  isSelfie,
  selectedAsProfilePicture,
  setSelectedAsProfilePicture,
}: ImageUploadedProps) => {
  const { t } = useTranslation()
  const { authStore } = useContext(storesContext)!
  const { progressInfo } = store

  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const [loadingImage, setLoadingImage] = useState(true)

  useEffect(() => {
    const img = new Image()
    img.src = isSelfie
      ? `${authStore.recognitionImageUrl}?timestamp=${new Date().getTime()}`
      : `${store.profilePictureUrl}?timestamp=${new Date().getTime()}`

    img.onload = handleImageLoad

    if (img.complete) {
      handleImageLoad()
    }

    return () => {
      img.onload = null
    }
  }, [isSelfie, authStore.recognitionImageUrl, store.profilePictureUrl])

  const handleImageLoad = () => {
    setLoadingImage(false)
  }

  const handleOnLine = () => {
    setIsOnline(true)
  }
  const handleOffLine = () => {
    setIsOnline(false)
  }

  useEffect(() => {
    window.addEventListener('online', handleOnLine)
    window.addEventListener('offline', handleOffLine)

    return () => {
      window.removeEventListener('online', handleOnLine)
      window.removeEventListener('offline', handleOffLine)
    }
  }, [])

  const handleFileInput = (file: File) => {
    onUpload(file)
    setLoadingImage(true)
  }

  const selfieClassName = 'w-full flex flex-col justify-center items-center rounded-md'
  const profilePictureClassName = 'w-full h-full flex justify-center items-center h-auto'

  return (
    <div className="flex flex-col gap-1">
      <div className={isSelfie ? selfieClassName : profilePictureClassName}>
        {!isNil(progressInfo) &&
          progressInfo.percentage >= 0 &&
          progressInfo.percentage < 100 &&
          !progressInfo.error && (
            <div
              className={`flex items-center justify-center px-2 ${
                isSelfie
                  ? 'w-48 h-60 md:w-full md:h-96'
                  : 'w-full h-72 rounded-sm border-2 border-lumepic-grey shadow-md'
              }`}
            >
              <Spinner noStyles size={20} />
              <p className="text-black my-10 ml-1">
                {t('Uploading...') + ' ' + progressInfo.percentage + '%'}
              </p>
            </div>
          )}
        {!isNil(progressInfo) && progressInfo.error && (
          <div
            className={`flex items-center justify-center px-2 ${
              isSelfie
                ? 'w-full h-60 rounded-sm shadow-md'
                : 'w-full h-72 rounded-sm border-2 border-lumepic-grey shadow-md'
            }`}
          >
            <FontAwesomeIcon className="text-warning font-medium" icon={faCircleXmark} />
            <p className="text-warning my-1 ml-1 font-medium">Error</p>
          </div>
        )}
        <div className="relative">
          {((!isNil(progressInfo) && progressInfo.percentage === 100 && !progressInfo.error) ||
            (isNil(progressInfo) &&
              (store.profilePictureUrl || authStore.recognitionImageUrl))) && (
            <>
              {loadingImage && (
                <div
                  className={`flex items-center justify-center absolute px-2 ${
                    isSelfie
                      ? 'w-48 h-60 rounded-sm md:w-full md:h-96'
                      : 'w-full h-72 rounded-sm border-2 border-lumepic-grey'
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="text-lumepic-success_dark_green mr-0.5"
                  />
                  <p className="text-black ml-1">{t('Image uploaded successfully')}</p>
                </div>
              )}
              <img
                onLoad={handleImageLoad}
                src={
                  isSelfie
                    ? `${authStore.recognitionImageUrl}?timestamp=${new Date().getTime()}`
                    : `${store.profilePictureUrl}?timestamp=${new Date().getTime()}`
                }
                className={`
                ${
                  isSelfie
                    ? 'object-cover object-center w-48 h-60 rounded-sm md:w-full md:h-96 shadow-md'
                    : 'object-cover object-center w-full h-72 rounded-sm border-2 border-lumepic-grey shadow-md'
                }
                ${loadingImage ? 'opacity-0' : 'opacity-100'}
              `}
                alt="picture"
              />
            </>
          )}
          {((!isNil(progressInfo) &&
            progressInfo.percentage === 100 &&
            !progressInfo.error &&
            !loadingImage) ||
            (isNil(progressInfo) &&
              (store.profilePictureUrl || authStore.recognitionImageUrl) &&
              !loadingImage)) && (
            <FileInput
              handleFile={handleFileInput}
              label={t('Change picture').toString()}
              acceptedFileTypes={validImageTypes}
              styles={'absolute top-1 right-1'}
              editPencilStyle
            />
          )}
        </div>
      </div>
      {authStore.recognitionImageUrl &&
        authStore.isAuthenticated() &&
        !isNil(selectedAsProfilePicture) &&
        !isNil(setSelectedAsProfilePicture) && (
          <Checkbox
            id="burst-mode"
            label={t('Select as profile picture')}
            value={selectedAsProfilePicture}
            onChange={() => setSelectedAsProfilePicture(!selectedAsProfilePicture)}
          />
        )}
      {!isOnline && <p className="text-center text-sm">{t('Connection interrupted.')}</p>}
    </div>
  )
}

export default observer(ImageUploaded)
