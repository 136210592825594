import React, { useState } from 'react'
import { Photograph } from '../../../../../shared/models/Photograph'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faCaretUp,
  faInfoCircle,
  faPenToSquare,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Pagination, Popover, Stack } from '@mui/material'
import Album from '../../../../../shared/models/Album'
import Spinner from 'components/UI/Spinner'
import { isNil } from 'lodash'

type MyAlbumDetailsPhotographListProps = {
  photographs: Photograph[]
  albumDetails: Album
  isLoadingPhotographs: boolean
  page: number
  setPage: (page: number) => void
  pageCount: number
  setPhotograph: (albumId: string, photographId: string) => void
  setPhotographToEdit: (photograph: Photograph) => void
  setPhotographToDelete: (photograph: Photograph) => void
}

const MyAlbumDetailsPhotographList = ({
  photographs,
  albumDetails,
  isLoadingPhotographs,
  page,
  setPage,
  pageCount,
  setPhotograph,
  setPhotographToEdit,
  setPhotographToDelete,
}: MyAlbumDetailsPhotographListProps) => {
  const { t } = useTranslation()

  const [openActions, setOpenActions] = useState<HTMLDivElement | null>(null)
  const [openInfo, setOpenInfo] = useState<HTMLDivElement | null>(null)

  const openPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setOpenActions(event.currentTarget)
  }
  const openInfoPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setOpenInfo(event.currentTarget)
  }

  const onChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    window.scrollTo(0, 0)
  }

  return (
    <>
      <div className="grid grid-cols-4 gap-2">
        {photographs.map((photograph: Photograph, i: number) => {
          return (
            <div
              data-photograph-id={photograph.id}
              key={i}
              className="grid col-span-2 grid-rows-2 md:col-span-1"
            >
              <div
                className="row-span-5 bg-center bg-no-repeat bg-cover rounded-lg h-44 hover:cursor-pointer"
                style={{ backgroundImage: `url(${photograph.thumbnailUrl})` }}
                onClick={() => {
                  setPhotograph(albumDetails.id, photograph.id)
                }}
              ></div>
              <div className="flex flex-row justify-between items-center mx-1 my-1.5">
                <div
                  id={photograph.id}
                  onClick={openInfoPopover}
                  className="flex items-center gap-1 cursor-pointer"
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="text-sm text-lumepic-grey" />
                  <span className="text-sm">Info</span>
                </div>
                <Popover
                  id={photograph.id}
                  open={openInfo?.id === photograph.id}
                  anchorEl={openInfo}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={() => setOpenInfo(null)}
                >
                  <div className="p-2">
                    <p className="m-0">
                      <strong>{t('Price')}: </strong>
                      {albumDetails.currency?.toUpperCase()} {photograph.price}
                    </p>

                    <p className="m-0">
                      <strong>{t('File name')}: </strong>
                      {photograph.originalFileName}
                    </p>
                  </div>
                </Popover>
                <div
                  className="flex items-center gap-1 cursor-pointer"
                  onClick={openPopover}
                  id={photograph.id}
                >
                  <span className="text-sm">{t('Actions')}</span>
                  {openActions?.id === photograph.id ? (
                    <FontAwesomeIcon icon={faCaretUp} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretDown} />
                  )}
                </div>
                <Popover
                  id={photograph.id}
                  open={openActions?.id === photograph.id}
                  onClose={() => setOpenActions(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  anchorEl={openActions}
                >
                  <div className="flex flex-col items-start gap-1 p-1.5">
                    {isNil(albumDetails.event.defaultImagePrice) && (
                      <div
                        onClick={() => {
                          setOpenActions(null)
                          setPhotographToEdit(photograph)
                        }}
                        className="flex items-center gap-1.5 hover:bg-lumepic-light_grey px-1.5 py-0.5 rounded-sm w-full cursor-pointer transition duration-200"
                      >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          className="text-lumepic-light_black text-sm"
                        />
                        <p className="pt-px m-0 font-medium">{t('Edit price')}</p>
                      </div>
                    )}
                    <div
                      onClick={() => {
                        setOpenActions(null)
                        setPhotographToDelete(photograph)
                      }}
                      className="flex items-center gap-1.5 hover:bg-lumepic-light_grey px-1.5 py-0.5 rounded-sm w-full cursor-pointer transition duration-200"
                    >
                      <FontAwesomeIcon icon={faTrash} className="text-warning text-sm" />
                      <p className="pt-px pl-px m-0 font-medium text-warning">{t('Delete')}</p>
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
          )
        })}
      </div>
      {isLoadingPhotographs && (
        <div className="flex justify-center pt-16">
          <Spinner noStyles />
        </div>
      )}
      <div className="flex justify-center w-full pt-16">
        <Stack spacing={2}>
          <Pagination count={pageCount} page={page} onChange={onChangePage} />
        </Stack>
      </div>
    </>
  )
}

export default observer(MyAlbumDetailsPhotographList)
