import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

const getAssignEventSchema = () => {
  const { t } = useTranslation()

  return Yup.object().shape({
    isEventAlbum: Yup.boolean(),
    // eventId is required only if isAlbumEvent is true:
    eventId: Yup.string().when('isEventAlbum', {
      is: true,
      then: (schema) => schema.required(t('Event is required') as string),
      otherwise: (schema) => schema.optional(),
    }),
  })
}

export default getAssignEventSchema
