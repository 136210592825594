import React from 'react'
import MuiModal, { ModalSize } from '../Modal/MuiModal'
import { ActivePopup } from '../../../config/popups'
import ConfigService from '../../../config'
import { useHistory } from 'react-router'

const Banner = () => {
  const shouldDisplay = localStorage.getItem(ActivePopup.key) !== 'true' && ActivePopup.isActive
  const history = useHistory()
  const [open, setOpen] = React.useState(true)

  const albumId = ActivePopup.albumId || null

  const redirectTo = ActivePopup.redirectTo || null

  const goToAlbum = () => {
    localStorage.setItem(ActivePopup.key, 'true')
    setOpen(false)
    history.push(`/feed/albums/${albumId}`)
  }

  const redirectToPage = () => {
    localStorage.setItem(ActivePopup.key, 'true')
    setOpen(false)
    history.push(`${redirectTo}`)
  }

  if (!shouldDisplay) {
    return null
  }
  return (
    <>
      <MuiModal
        key={ActivePopup.key}
        open={open}
        size={ModalSize.LARGE}
        handleClose={() => {
          localStorage.setItem(ActivePopup.key, 'true')
          setOpen(false)
        }}
      >
        <img
          className="max-w-full cursor-pointer"
          alt={ActivePopup.label}
          src={ConfigService.getBanner(ActivePopup.fileName)}
          onClick={albumId ? goToAlbum : redirectToPage}
        />
      </MuiModal>
    </>
  )
}

export default Banner
