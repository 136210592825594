const data = [
  {
    name: 'Francisco',
    role: 'Photographer',
    message: 'user_review_1',
  },
  {
    name: 'Luis',
    role: 'Sportsman',
    message: 'user_review_2',
  },
  {
    name: 'Juan Pablo',
    role: 'Photographer',
    message: 'user_review_3',
  },
]

export default data
