import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { Popover } from '@mui/material'
import Album from '../../../../../shared/models/Album'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { isNil } from 'lodash'
import ShareModal from 'components/UI/ShareModal'
import { User } from 'shared/models/User'
import ConfigService, { ConfigKeys } from 'config'

type AlbumTableRowActionsProps = {
  album: Album
  showSuspendConfirmModal: (album: Album) => void
  handlePublishAlbum: (album: Album) => void
  handleUpdateCoverPhotograph: (album: Album) => void
  handleEditAlbum: (album: Album) => void
  handleAddPhotoButton: (album: Album) => void
  handleAddDiscountButton: (album: Album) => void
  goToAlbumDetail: (album: Album) => void
  goToFeedAlbumDetail: (album: Album) => void
  loggedUser: User
}

const AlbumTableRowActions = ({
  album,
  showSuspendConfirmModal,
  handlePublishAlbum,
  handleUpdateCoverPhotograph,
  handleEditAlbum,
  handleAddPhotoButton,
  handleAddDiscountButton,
  goToAlbumDetail,
  goToFeedAlbumDetail,
  loggedUser,
}: AlbumTableRowActionsProps) => {
  const { t } = useTranslation()

  const [openActions, setOpenActions] = useState<HTMLDivElement | null>(null)
  const openPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setOpenActions(event.currentTarget)
  }
  const [shareModalOpened, setShareModalOpened] = useState(false)

  const photographCountLimit = !isNil(album.event.id)
    ? parseInt(ConfigService.getValue(ConfigKeys.EVENT_ALBUM_PHOTOGRAPHS_LIMIT))
    : parseInt(ConfigService.getValue(ConfigKeys.INDEPENDENT_ALBUM_PHOTOGRAPHS_LIMIT))
  const isPhotographCountLimitReached = album.photographCount >= photographCountLimit

  return (
    <>
      <div className="flex items-center gap-1 cursor-pointer" onClick={openPopover} id={album.id}>
        <span className="text-sm">{t('Actions')}</span>
        {openActions?.id === album.id ? (
          <FontAwesomeIcon icon={faCaretUp} />
        ) : (
          <FontAwesomeIcon icon={faCaretDown} />
        )}
      </div>
      <Popover
        id={album.id}
        open={openActions?.id === album.id}
        onClose={() => setOpenActions(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={openActions}
      >
        <div className="flex flex-col items-start gap-1 p-2">
          {!album.isSuspended && loggedUser.isEnabledToSell && (
            <div
              onClick={() => {
                setOpenActions(null)
                setShareModalOpened(true)
              }}
              className="w-full hover:bg-lumepic-light_grey px-1.5 py-0.5 cursor-pointer transition duration-200 rounded-sm"
            >
              <p className="p-0 m-0 font-medium">{t('Share')}</p>
            </div>
          )}

          <div
            onClick={() => {
              setOpenActions(null)
              goToAlbumDetail(album)
            }}
            className="w-full hover:bg-lumepic-light_grey px-1.5 py-0.5 cursor-pointer transition duration-200 rounded-sm"
          >
            <p className="p-0 m-0 font-medium">{t('View and edit photos')}</p>
          </div>
          {!album.isSuspended && loggedUser.isEnabledToSell && (
            <div
              onClick={() => {
                setOpenActions(null)
                goToFeedAlbumDetail(album)
              }}
              className="w-full hover:bg-lumepic-light_grey px-1.5 py-0.5 cursor-pointer transition duration-200 rounded-sm"
            >
              <p className="p-0 m-0 font-medium">{t('Buyer view')}</p>
            </div>
          )}
          <div
            onClick={() => {
              setOpenActions(null)
              handleEditAlbum(album)
            }}
            className="w-full hover:bg-lumepic-light_grey px-1.5 py-0.5 cursor-pointer transition duration-200 rounded-sm"
          >
            <p className="p-0 m-0 font-medium">{t('Edit information')}</p>
          </div>
          {!isPhotographCountLimitReached && (
            <div
              onClick={() => {
                setOpenActions(null)
                handleAddPhotoButton(album)
              }}
              className="w-full hover:bg-lumepic-light_grey px-1.5 py-0.5 cursor-pointer transition duration-200 rounded-sm"
            >
              {album.photographCount > 0 ? (
                <p className="p-0 m-0 font-medium">{t('Add photographs')}</p>
              ) : (
                <p className="p-0 m-0 font-medium flex justify-between items-center gap-2">
                  <span className="font-medium">{t('Add photographs')}</span>
                  <span
                    className="font-medium text-white text-xs w-4 h-4 bg-warning flex items-center justify-center"
                    style={{ borderRadius: '50%' }}
                  >
                    !
                  </span>
                </p>
              )}
            </div>
          )}
          {isNil(album.event.quantityDiscountId) && (
            <div
              onClick={() => {
                setOpenActions(null)
                handleAddDiscountButton(album)
              }}
              className="w-full hover:bg-lumepic-light_grey px-1.5 py-0.5 cursor-pointer transition duration-200 rounded-sm"
            >
              <p className="p-0 m-0 font-medium">
                {!isNil(album?.quantityDiscount) ? t('Update discount') : t('Add discount')}
              </p>
            </div>
          )}
          <div
            onClick={() => {
              setOpenActions(null)
              handleUpdateCoverPhotograph(album)
            }}
            className="w-full hover:bg-lumepic-light_grey px-1.5 py-0.5 cursor-pointer transition duration-200 rounded-sm"
          >
            {!isNil(album.coverPhotographSrc) ? (
              <p className="p-0 m-0 font-medium">{t('Update cover photograph')}</p>
            ) : (
              <p className="p-o m-0 font-medium flex justify-between items-center gap-2">
                <span className="font-medium">{t('Add cover photograph')}</span>
                <span
                  className="font-medium text-white text-xs w-4 h-4 bg-warning flex items-center justify-center"
                  style={{ borderRadius: '50%' }}
                >
                  !
                </span>
              </p>
            )}
          </div>
          {album.isSuspended && (
            <div
              onClick={
                !isNil(album.coverPhotographSrc) && album.photographCount > 0
                  ? () => {
                      setOpenActions(null)
                      handlePublishAlbum(album)
                    }
                  : () => {}
              }
              className={`w-full ${
                !isNil(album.coverPhotographSrc) && album.photographCount > 0
                  ? 'hover:bg-lumepic-light_grey cursor-pointer text-primary'
                  : 'bg-lumepic-medium_grey cursor-not-allowed opacity-50 text-lumepic-light_black'
              }  px-1.5 py-0.5 transition duration-200 rounded-sm`}
            >
              <p className="p-0 m-0 font-medium">{t('Publish')}</p>
            </div>
          )}

          {!album.isSuspended && (
            <div
              onClick={() => {
                setOpenActions(null)
                showSuspendConfirmModal(album)
              }}
              className="hover:bg-lumepic-light_grey px-1.5 py-0.5 cursor-pointer transition duration-200 rounded-sm"
            >
              <p className="p-0 m-0 font-medium text-warning">{t('Suspend')}</p>
            </div>
          )}
        </div>
      </Popover>
      <ShareModal
        albumId={album.id}
        closeModal={() => setShareModalOpened(false)}
        isModalOpened={shareModalOpened}
      />
    </>
  )
}

export default observer(AlbumTableRowActions)
