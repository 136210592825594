import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import * as paths from '../../../routing/Paths'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { User } from '../../../shared/models/User'
import ProfileStore from './UpdateUser/ProfileStore'
import userAvatarIcon from '../../../assets/icons/v2/user-avatar-filled.svg'
import { isNil } from 'lodash'
import Button from 'components/UI/Button'
import UpdateProfileModal from './UpdateUser/UpdateProfileModal'
import { useQuery } from 'hooks/useQuery'
import { useHistory } from 'react-router'

type ProfileCardProps = {
  user: User
  profileStore: ProfileStore
  requestPhotographerRole: () => void
}

const ProfileCard = ({ user, profileStore, requestPhotographerRole }: ProfileCardProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [showRequestPhotographerProfileModal, setShowRequestPhotographerProfileModal] =
    useState(false)
  const query = useQuery()

  const removePhotographerQueryParam = () => {
    const isPhotographer = query.get('isPhotographer') === 'true'
    if (isPhotographer) {
      setShowRequestPhotographerProfileModal(true)
      window.history.replaceState({}, '', window.location.pathname)
    } else {
      setShowRequestPhotographerProfileModal(false)
    }
  }

  useEffect(() => {
    removePhotographerQueryParam()
  }, [window.location.search])

  const parseUserCreatedAtDate = (date: Date) => {
    return moment.utc(date).format('DD/MM/YYYY')
  }

  const openPhotographerProfile = (alias: string) => {
    const photographerProfileUrl = paths.photographerProfileByAlias(alias)
    history.push(photographerProfileUrl)
  }

  const { firstName, lastName, alias, createdAt, isPhotographer, profilePictureUrl } = user

  const imageDisplayView = (
    <img
      src={
        !isNil(profilePictureUrl)
          ? `${profilePictureUrl}?timestamp=${new Date().getTime()}`
          : userAvatarIcon
      }
      alt="profile-picture"
      className="object-cover object-center w-32 h-32 mx-auto rounded-full"
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        objectFit: 'cover',
        objectPosition: 'center',
      }}
    />
  )

  return (
    <>
      <div className="p-2">
        {isPhotographer ? (
          <div className="flex flex-col gap-3">
            <div className="flex justify-between md:justify-center">
              <div className="flex items-center justify-center gap-2 md:flex-col">
                <div className="rounded-full w-28 h-28 md:w-36 md:h-36">{imageDisplayView}</div>
                <span className="font-extrabold text-center text-gray-900 text-h4 ">{alias}</span>
              </div>
            </div>

            <Button btnType={'PrimaryAction'} onClick={() => openPhotographerProfile(alias)}>
              {t('View my profile')}
            </Button>
          </div>
        ) : (
          <>
            <div className="flex flex-col justify-center items-center">
              {!isNil(profilePictureUrl) && (
                <div className="rounded-full w-28 h-28 md:w-36 md:h-36">{imageDisplayView}</div>
              )}
              <h1 className="my-1.5 ml-1 font-extrabold text-gray-900 text-h3 whitespace-nowrap">
                {firstName} {lastName}
              </h1>
            </div>
            <div className="flex flex-col gap-2 bg-gray-100 rounded-lg border p-2">
              <p className="flex flex-col items-center">
                <span className="font-light text-sm">{t('Member Since')}</span>
                <span className="font-medium text-sm text-lumepic-grey">
                  {parseUserCreatedAtDate(createdAt)}
                </span>
              </p>
              <div
                className="text-center underline cursor-pointer text-lumepic-light_black font-normal hover:text-black hover:scale-105 transition duration-200"
                onClick={() => setShowRequestPhotographerProfileModal(true)}
              >
                {t("I'm a Photographer")}
              </div>
            </div>
          </>
        )}
      </div>
      {!isPhotographer && (
        <UpdateProfileModal
          opened={showRequestPhotographerProfileModal}
          onCancel={() => setShowRequestPhotographerProfileModal(false)}
          profileStore={profileStore}
          isRequestingPhotographerRole={true}
          requestPhotographerRole={requestPhotographerRole}
          user={user}
        />
      )}
    </>
  )
}
export default observer(ProfileCard)
