import { makeAutoObservable } from 'mobx'
import QuantityDiscount from '../shared/models/QuantityDiscount'

class DiscountStore {
  private quantityDiscounts: QuantityDiscount[]
  constructor() {
    this.quantityDiscounts = []
    makeAutoObservable(this)
  }

  reset() {
    this.quantityDiscounts = []
  }
}

export default DiscountStore
