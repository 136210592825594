import axios from './axios'
import { AxiosResponse } from 'axios'
import Accreditation from 'shared/models/Accreditation'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from 'shared/utility'

export class AccreditationService {
  async fetchPhotographerAccreditations(token: string): Promise<Accreditation[]> {
    return axios
      .get(paths.ACCREDITATION_PATH, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }
  async createAccreditation(
    eventId: string,
    ownerId: string,
    token: string
  ): Promise<Accreditation> {
    const data = { eventId, ownerId }
    return axios
      .post(paths.ACCREDITATION_PATH, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }
}
