import { BarChart, barElementClasses } from '@mui/x-charts'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { axisClasses } from '@mui/x-charts/ChartsAxis'
import { Button, Tooltip } from '@mui/material'
import { format, parse, subMonths, addMonths, startOfMonth, endOfMonth, isBefore } from 'date-fns'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { isMobileDevice } from 'shared/utility'

type PublishedAlbumsBarChartProps = {
  publishedAlbums: { year: number; month: number; albumCount: number }[]
}

const PublishedAlbumsBarChart = ({ publishedAlbums }: PublishedAlbumsBarChartProps) => {
  const { t } = useTranslation()

  const [currentDateRange, setCurrentDateRange] = useState(() => {
    const end = endOfMonth(new Date())
    const start = startOfMonth(subMonths(end, 11))
    return { start, end }
  })
  const orderedPublishedAlbums = useMemo(() => {
    return publishedAlbums.sort((a, b) => {
      const dateA = parse(`${a.year}-${a.month}`, 'yyyy-MM', new Date())
      const dateB = parse(`${b.year}-${b.month}`, 'yyyy-MM', new Date())
      return dateA.getTime() - dateB.getTime()
    })
  }, [publishedAlbums])

  const firstSaleDate = useMemo(() => {
    if (orderedPublishedAlbums.length === 0) return null
    return parse(
      `${orderedPublishedAlbums[0].year}-${publishedAlbums[0].month}`,
      'yyyy-MM',
      new Date()
    )
  }, [orderedPublishedAlbums])

  const onMoveBackward = () => {
    setCurrentDateRange((prev) => {
      const newStart = subMonths(prev.start, 3)
      const newEnd = subMonths(prev.end, 3)
      if (firstSaleDate && isBefore(newStart, firstSaleDate)) {
        const adjustedStart = startOfMonth(firstSaleDate)
        return {
          start: adjustedStart,
          end: endOfMonth(addMonths(adjustedStart, 11)),
        }
      }
      return { start: newStart, end: newEnd }
    })
  }

  const onMoveForward = () => {
    setCurrentDateRange((prev) => {
      const newEnd = addMonths(prev.end, 3)
      const today = new Date()
      return {
        start: addMonths(prev.start, 3),
        end: newEnd > today ? endOfMonth(today) : newEnd,
      }
    })
  }

  const canMoveBackward = useMemo(() => {
    return firstSaleDate && isBefore(firstSaleDate, currentDateRange.start)
  }, [firstSaleDate, currentDateRange.start])

  const canMoveForward = useMemo(() => {
    return isBefore(currentDateRange.end, endOfMonth(new Date()))
  }, [currentDateRange.end])

  const monthLabels = Array.from({ length: 12 }, (_, i) => {
    const date = addMonths(currentDateRange.start, i)
    const monthName = t(`months.${format(date, 'MMM').toLowerCase()}`)
    return `${monthName}-${format(date, 'yy')}`
  })

  const filteredSalesData = useMemo(() => {
    return orderedPublishedAlbums
      .filter((insight) => {
        const insightDate = parse(`${insight.year}-${insight.month}`, 'yyyy-MM', new Date())
        return insightDate >= currentDateRange.start && insightDate <= currentDateRange.end
      })
      .map((insight) => ({
        date: parse(`${insight.year}-${insight.month}`, 'yyyy-MM', new Date()),
        value: insight.albumCount,
      }))
  }, [orderedPublishedAlbums, currentDateRange])

  const publishedAlbumsData = useMemo(() => {
    return monthLabels.map((label) => {
      const date = parse(label, 'MMM-yy', new Date())
      const matchingData = filteredSalesData.find(
        (item) =>
          item.date.getMonth() === date.getMonth() && item.date.getFullYear() === date.getFullYear()
      )
      return matchingData ? matchingData.value : 0
    })
  }, [monthLabels, filteredSalesData])

  const yAxisLabel = t('Quantity')
  return (
    <>
      <h2 className="p-2 text-center flex font-semibold text-base text-lumepic-light_black">
        {t('Monthly Published Albums')}
      </h2>
      <div className="w-full h-[calc(100%-2rem)] flex items-center justify-between pt-4">
        <Tooltip title={t('Backward')} arrow>
          <span className="w-8 flex justify-center">
            <Button
              onClick={onMoveBackward}
              disabled={!canMoveBackward}
              sx={{
                height: '50px',
                padding: 0,
                borderRadius: '100%',
                color: '#4A4A4A',
                '&.Mui-disabled': {
                  color: 'grey',
                },
              }}
            >
              <ChevronLeft />
            </Button>
          </span>
        </Tooltip>
        <div className="flex-grow h-full flex items-center justify-center">
          <BarChart
            height={isMobileDevice() ? 250 : 350}
            xAxis={[{ scaleType: 'band', data: monthLabels }]}
            yAxis={[
              {
                label: yAxisLabel,
                labelStyle: {
                  display: 'flex',
                },
              },
            ]}
            series={[
              {
                data: publishedAlbumsData,
                color: '#5AB274',
                highlightScope: { faded: 'global', highlighted: 'item' },
              },
            ]}
            sx={{
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-10px, 0)',
              },
              [`.${barElementClasses.root}`]: {
                strokeWidth: 2,
              },
              [`.${axisClasses.root}`]: {
                [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                  stroke: 'none',
                },
                [`.${axisClasses.tickLabel}`]: {
                  fill: '#4A4A4A',
                },
              },
            }}
          />
        </div>
        <Tooltip title={t('Forward')} arrow>
          <span className="w-8 flex justify-center">
            <Button
              onClick={onMoveForward}
              disabled={!canMoveForward}
              sx={{
                height: '50px',
                padding: 0,
                borderRadius: '50%',
                color: '#4A4A4A',
                '&.Mui-disabled': {
                  color: 'grey',
                },
              }}
            >
              <ChevronRight />
            </Button>
          </span>
        </Tooltip>
      </div>
    </>
  )
}

export default PublishedAlbumsBarChart
