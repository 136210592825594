import { Skeleton } from '@mui/material'
import React from 'react'

const EventInformationSkeleton = () => {
  return (
    <div className="flex flex-col bg-white rounded-md">
      <div className="border-b-4 border-primary_dark">
        <div className="flex gap-2 p-2 items-center">
          <Skeleton variant="rounded" height={25} width={25} />
          <Skeleton variant="rounded" height={12} width={300} />
        </div>
      </div>
      <div className="flex p-2 gap-2">
        <Skeleton variant="rounded" height={170} width={150} />
        <div className="flex flex-col gap-2.5">
          <Skeleton variant="rounded" height={30} width={60} className="mb-1" />
          <Skeleton variant="rounded" height={17} width={80} />
          <Skeleton variant="rounded" height={17} width={100} />
          <Skeleton variant="rounded" height={17} width={50} />
        </div>
      </div>
    </div>
  )
}

export default EventInformationSkeleton
