import axios from './axiosUsersCarts'
import { AxiosResponse } from 'axios'
import ConfigService, { ConfigKeys } from 'config'
import { isNil } from 'lodash'
import Album from 'shared/models/Album'
import { Photograph } from 'shared/models/Photograph'
import { Event } from 'shared/models/Event'
import Cart, { TagPackageType } from 'shared/models/Cart/Cart'
import { PhotographCartLine } from 'shared/models/Cart/CartLine/PhotographCartLine'
import { PackageCartLine } from 'shared/models/Cart/CartLine/PackageCartLine'
import { CartLine } from 'shared/models/Cart/CartLine/CartLine'
import CartServiceInterface from './Interfaces/CartService.Interface'

const firebaseBaseUrl = ConfigService.getValue(ConfigKeys.USER_CARTS_DATABASE_URL)
const LocalStorageCartsKey = 'localCart'
const LocalStorageUnknownCartKey = `${LocalStorageCartsKey}["UNKNOWN"]`

class CartService implements CartServiceInterface {
  private async fetchUserCart(userId: string): Promise<Cart> {
    return axios.get(`${firebaseBaseUrl}${userId}.json`).then((response: AxiosResponse) => {
      return Cart.init(response.data)
    })
  }

  private async createOrUpdateUserCart(userId: string, cart: Cart): Promise<void> {
    return axios.put(`${firebaseBaseUrl}${userId}.json`, cart.toString())
  }

  private async deleteUserCart(userId: string): Promise<void> {
    return axios.delete(`${firebaseBaseUrl}${userId}.json`)
  }

  public addPhotograph(cart: Cart, photograph: Photograph, album: Album, event?: Event): Cart {
    const newCart = Cart.clone(cart)

    const cartLine = new PhotographCartLine(
      photograph,
      photograph.price,
      photograph.price,
      0,
      album.currency,
      album,
      event
    )

    if (!newCart.alreadyInCart(photograph)) {
      newCart.lines.push(cartLine)

      const numberOfPhotographs = newCart.getSinglePhotographCount(album.id, event?.id)
      const discountPercentage = newCart.getQuantityDiscountPercentageEarned(
        numberOfPhotographs,
        album
      )

      newCart.lines.forEach((line) => {
        if (line instanceof PhotographCartLine && line.album.id === album.id) {
          line.discountTotal = (line.photograph.price * discountPercentage) / 100
          line.totalPrice = line.photograph.price - line.discountTotal
        }
      })
    }

    return newCart
  }

  public addAlbumTagPackage(
    cart: Cart,
    album: Album,
    previewPhotographs: Photograph[],
    quantityOfPhotographs: number,
    packageType: TagPackageType,
    tagId: string
  ): Cart {
    return this.addTagPackage(
      cart,
      previewPhotographs,
      quantityOfPhotographs,
      packageType,
      tagId,
      [album],
      undefined
    )
  }

  public addEventTagPackage(
    cart: Cart,
    event: Event,
    albums: Album[],
    previewPhotographs: Photograph[],
    quantityOfPhotographs: number,
    packageType: TagPackageType,
    tagId: string
  ): Cart {
    return this.addTagPackage(
      cart,
      previewPhotographs,
      quantityOfPhotographs,
      packageType,
      tagId,
      albums,
      event
    )
  }

  private addTagPackage(
    cart: Cart,
    previewPhotographs: Photograph[],
    quantityOfPhotographs: number,
    packageType: TagPackageType,
    tagId: string,
    albums: Album[],
    event?: Event
  ): Cart {
    const newCart = Cart.clone(cart)

    const packagePrice = this.extractAndValidatePackagePrice(albums, event)

    const photographPrice =
      !isNil(event) && !isNil(event.defaultImagePrice)
        ? event.defaultImagePrice
        : albums[0]!.defaultImagePrice

    const currency = !isNil(event) ? event.currency : albums[0]!.currency

    if (isNil(currency)) {
      console.error(`CartService.addPackage: Currency not found.`)
      throw new Error(`CartService.addPackage: Currency not found.`)
    }

    const subTotalPrice = quantityOfPhotographs * photographPrice

    const discountTotal = subTotalPrice - packagePrice
    const packageLine = new PackageCartLine(
      subTotalPrice,
      packagePrice, // Assuming subtotal and total are the same for simplicity
      discountTotal, // Assuming no discount total for now
      currency, // Use the current cart currency
      packageType,
      tagId,
      previewPhotographs.slice(0, 5), // Preview photographs (first 5)
      quantityOfPhotographs,
      albums,
      event
    )

    newCart.lines.push(packageLine)
    return newCart
  }

  private extractAndValidatePackagePrice(albums: Album[], event: Event | undefined): number {
    if (isNil(albums) && isNil(event)) {
      console.error(`CartService.addPackage: Album or Event must be provided.`)
      throw new Error(`CartService.addPackage: Album or Event must be provided.`)
    }

    if (!isNil(event) && isNil(event.defaultPackagePrice)) {
      console.error(`CartService.addPackage: Event defaultPackagePrice must be provided.`)
      throw new Error(`CartService.addPackage: Event defaultPackagePrice must be provided.`)
    }

    if (isNil(event) && albums.length > 1) {
      console.error(`CartService.addPackage: Multiple albums provided but no event.`)
      throw new Error(`CartService.addPackage: Multiple albums provided but no event.`)
    }

    const [album] = albums
    if (isNil(event) && !isNil(album) && isNil(album.defaultPackagePrice)) {
      console.error(`CartService.addPackage: Album defaultPackagePrice must be provided.`)
      throw new Error(`CartService.addPackage: Album defaultPackagePrice must be provided.`)
    }

    // Assuming packagePrice is calculated based on the number of photographs
    const packagePrice = !isNil(event) ? event.defaultPackagePrice : album!.defaultPackagePrice

    if (isNil(packagePrice)) {
      console.error(`CartService.addPackage: Unexpected Error! Package price not found.`)
      throw new Error(`CartService.addPackage: Unexpected Error! Package price not found.`)
    }
    return packagePrice
  }

  public async addLines(cart: Cart, lines: CartLine[]): Promise<Cart> {
    const newCart = Cart.clone(cart)
    if (isNil(cart.currency) && lines.length > 0) {
      newCart.currency = lines[0].currency
    }

    for (const line of lines) {
      const existingLineIndex = newCart.lines.findIndex((l) => l.getKey() === line.getKey()) //TODO: use groupKey instead?

      if (existingLineIndex === -1) {
        if (line instanceof PackageCartLine) {
          newCart.lines.push(line)
        } else if (line instanceof PhotographCartLine) {
          newCart.lines.push(line)
        } else {
          console.error(`CartService.addLines: Unsupported line type.`)
        }
      } else {
        newCart.lines[existingLineIndex] = line
      }
    }

    return newCart
  }

  public removePhotograph(cart: Cart, photographId: string): Cart {
    const newCart = Cart.clone(cart)

    const lineToRemove = newCart.lines.find(
      (line) => line instanceof PhotographCartLine && line.photograph.id === photographId
    ) as PhotographCartLine | undefined

    if (!lineToRemove) {
      return newCart
    }

    newCart.lines = newCart.lines.filter((line) => {
      return !(line instanceof PhotographCartLine && line.photograph.id === photographId)
    })

    const albumId = lineToRemove.album.id
    const eventId = lineToRemove.event?.id

    const numberOfPhotographs = newCart.getSinglePhotographCount(albumId, eventId)

    const discountPercentage = newCart.getQuantityDiscountPercentageEarned(
      numberOfPhotographs,
      lineToRemove.album
    )

    // Update discounts for all remaining photograph lines from the same album
    newCart.lines.forEach((line) => {
      if (line instanceof PhotographCartLine && line.album.id === albumId) {
        line.discountTotal = (line.photograph.price * discountPercentage) / 100
        line.totalPrice = line.photograph.price - line.discountTotal
      }
    })

    return newCart
  }

  public removeLine(cart: Cart, line: CartLine): Cart {
    const newCart = Cart.clone(cart)
    newCart.lines = newCart.lines.filter((l) => {
      return l.getKey() != line.getKey()
    })
    return newCart
  }

  public removePhotographs(cart: Cart, photographIds: string[]): Cart {
    const newCart = Cart.clone(cart)
    newCart.lines = newCart.lines.filter((line) => {
      return !(line instanceof PhotographCartLine && photographIds.includes(line.photograph.id))
    })
    return newCart
  }

  public async persistCart(
    cart: Cart,
    userId: string | null,
    updateFirebaseCart?: boolean
  ): Promise<void> {
    if (!isNil(userId)) {
      if (cart.lines.length === 0) {
        await this.deleteUserCart(userId) // Empty local Cart -> Delete firebase Cart
      } else if (updateFirebaseCart) {
        await this.createOrUpdateUserCart(userId, cart) // Update firebase cart (add/delete) photographs.
      }
    }

    const key = LocalStorageUnknownCartKey
    const cartString = cart.toString()
    localStorage.setItem(key, cartString)
    key !== LocalStorageUnknownCartKey && localStorage.removeItem(LocalStorageUnknownCartKey)
  }

  public async syncCart(cart: Cart, userId: string | null): Promise<Cart> {
    let newCart = Cart.clone(cart)
    if (!isNil(userId)) {
      const fetchedCart = await this.fetchUserCart(userId)
      if (!isNil(fetchedCart)) {
        newCart = await this.addLines(cart, fetchedCart.lines)
      }
    }

    const localUnknownCartString = localStorage.getItem(LocalStorageUnknownCartKey)
    if (!isNil(localUnknownCartString)) {
      const localCart = JSON.parse(localUnknownCartString)
      if (!isNil(localCart)) {
        const unknownCart = Cart.init(localCart)
        newCart = await this.addLines(newCart, Array.from(unknownCart.lines.values()))
      }
    }
    return newCart
  }
}

export default CartService
