import React from 'react'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import c from 'classnames'

type ButtonProps = {
  onClick?: any
  extraStyle?: any
}

const BackButton = ({ onClick, extraStyle }: ButtonProps) => {
  const { t } = useTranslation()
  const baseClasses = ['no-underline', 'flex', 'items-center']
  const buttonClasses = [...baseClasses, extraStyle]
  return (
    <button className={c(buttonClasses)} onClick={(e: any) => onClick(e)}>
      <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
      <span className="ml-2 mt-0.5">{t('Go Back')}</span>
    </button>
  )
}

export default observer(BackButton)
