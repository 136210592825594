import { makeAutoObservable, action } from 'mobx'
import { object, ObjectSchema, Schema } from 'yup'

class InputStore<T> {
  public value: T
  public error: any
  public errorMessage: string
  public validationSchema: ObjectSchema<{ value: any }> | null
  constructor(validationSchema?: Schema) {
    this.validationSchema = validationSchema
      ? object().shape({
          value: validationSchema,
        })
      : null
    makeAutoObservable(this)
    this.clearError()
  }

  @action
  setValue(val: T) {
    this.value = val
    this.clearError()
  }

  @action
  async validate(): Promise<boolean> {
    this.clearError()

    if (this.validationSchema !== null) {
      try {
        await this.validationSchema.validate({ value: this.value })

        return true
      } catch (e: any) {
        const [error] = e.errors

        this.setError(true, error)

        return false
      }
    }

    return true
  }

  @action
  setError(error: any, msg = '') {
    this.error = error
    this.errorMessage = msg
  }

  @action
  clearError() {
    this.setError(false)
  }
}

export default InputStore
