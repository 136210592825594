export enum DiscountType {
  PHOTOGRAPH_CODE = 'PHOTOGRAPH_CODE',
  PURCHASE_CODE = 'PURCHASE_CODE',
  PURCHASE_QUANTITY = 'PURCHASE_QUANTITY',
}

export abstract class BaseDiscount {
  id: string

  expirationDate?: Date

  isSuspended: boolean

  isActive: boolean

  type: DiscountType

  public createdAt: Date

  public updatedAt!: Date
}
