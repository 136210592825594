import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'components/UI/Modal'
import { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import LocationSearcher from 'components/UI/LocationSearcher/LocationSearcher'
import { isNil } from 'lodash'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react'
import StoresContext from '../../../../../providers/storesContext'
import { useFormik } from 'formik'
import { requestLocationValidationSchema } from './RequestLocationValidationSchema'
import { TextField } from '@mui/material'
import Button from 'components/UI/Button'
import { Location } from 'shared/models/Location'

type NewLocationModalProps = {
  closeModal: () => void
  handleFinish: (newLocation: Location) => void
}

const RequestLocationModal = ({ closeModal, handleFinish }: NewLocationModalProps) => {
  const { t } = useTranslation()
  const { locationStore } = useContext(StoresContext)!

  const initialValues = {
    spotName: '',
    latitude: locationStore.userLocation.latitude,
    longitude: locationStore.userLocation.longitude,
  }

  const handleSubmit = (values: { spotName: string; latitude: number; longitude: number }) => {
    locationStore
      .requestNewLocation(values.spotName.toUpperCase(), values.latitude, values.longitude)
      .then((data) => {
        if (data) {
          handleFinish(data)
          toast.success(t('The spot was successfully created!'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: requestLocationValidationSchema,
    onSubmit: handleSubmit,
  })

  const handleOnPlaceChanged = (place: google.maps.places.PlaceResult) => {
    const lat = place.geometry?.location?.lat()
    if (!isNil(lat)) {
      formik.setFieldValue('latitude', lat)
    }
    const lng = place.geometry?.location?.lng()
    if (!isNil(lng)) {
      formik.setFieldValue('longitude', lng)
    }
  }

  const userCountryLocations = locationStore.getLocationsForCountry(
    locationStore.userLocation.country.code
  )

  return (
    <Modal
      onCancel={closeModal}
      opened={true}
      modalSize={ModalSize.MEDIUM}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
    >
      <div className="flex flex-col w-full px-2 py-4 bg-gap-4">
        <h3 className="text-xl text-gray-800 font-extrabold mb-2">{t('New spot request')}</h3>
        <div className="flex flex-col gap-2 mt-1.5 mr-2">
          <TextField
            fullWidth
            id="spotName"
            name="spotName"
            label="Spot Name *"
            value={formik.values.spotName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.spotName && Boolean(formik.errors.spotName)}
            helperText={formik.touched.spotName && formik.errors.spotName}
          />
          <div className="flex gap-1 mb-4 sm:items-center">
            <FontAwesomeIcon icon={faCircleInfo} className="text-sm ml-0.5 opacity-80" />
            <span className="text-lumepic-black text-xs ml-px sm:ml-1">
              {t('This is the spot’s name that will be shown in the album')}
            </span>
          </div>
        </div>
        <LocationSearcher
          onPlaceChanged={handleOnPlaceChanged}
          initialCenter={{
            lat: locationStore.userLocation.latitude,
            lng: locationStore.userLocation.longitude,
          }}
          existingLocations={userCountryLocations}
        />
        <div className="flex gap-1 mt-2 mb-4 sm:items-center">
          <FontAwesomeIcon icon={faCircleInfo} className="text-sm ml-0.5 opacity-80" />
          <span className="text-lumepic-black text-xs ml-0.5 sm:ml-1">
            {t('Make sure the spot’s location is accurate before doing the request')}
          </span>
        </div>
        <div className="w-full flex justify-end pb-2 px-2">
          <Button
            btnType="PrimaryAction"
            onClick={formik.handleSubmit}
            isLoading={locationStore.isLoadingRequestLocation}
          >
            {t('Request')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default observer(RequestLocationModal)
