import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import * as paths from '../../../../../routing/Paths'

type BenefitProps = {
  src: string
  title: string
  content: string
  isPhotographer?: boolean
}

const Benefit = ({ src, title, content, isPhotographer }: BenefitProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const goTo = (path: string) => {
    history.push(path)
  }

  return (
    <>
      {isPhotographer && (
        <div className="hidden sm:block h-24 mt-10 border-l-2 border-lumepic-medium_grey" />
      )}
      <div className="flex flex-col gap-2.5 w-full text-center">
        <img src={src} alt="Lumepic benefit" className="w-full h-32 align-bottom" />
        <h5 className="text-lumepic-grey mb-0 mt-2 font-medium">{t(title)}</h5>
        <div>
          <p className="mb-1 text-sm md:px-6 lg:px-12">{t(content)}</p>
          {isPhotographer && (
            <span
              onClick={() => goTo(paths.PHOTOGRAPHERS_LANDING)}
              className="text-primary font-extrabold no-underline hover:text-primary hover:underline cursor-pointer"
            >
              + info
            </span>
          )}
        </div>
      </div>
      {isPhotographer && (
        <div className="hidden sm:block h-24 mt-10 border-l-2 border-lumepic-medium_grey" />
      )}
    </>
  )
}

export default Benefit
