import { observer } from 'mobx-react'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import StoresContext from '../../../../providers/storesContext'
import { AddToCartButtonProps } from './AddToCartButton'
import Button from 'components/UI/Button'
import { NavLink } from 'react-router-dom'
import Tracker from '../../../../shared/tracking'
import Spinner from 'components/UI/Spinner'

//TODO: include this version in the AddToCartButton component
const AddToCartButtonLong = ({ photograph, album }: AddToCartButtonProps) => {
  const { t } = useTranslation()
  if (!album) {
    throw new Error('Album must be defined')
  }

  const { cartStore } = useContext(StoresContext)!
  const [isInCart, setIsInCart] = useState(cartStore.alreadyInCart(photograph))

  useEffect(() => {
    setIsInCart(cartStore.alreadyInCart(photograph))
  })

  const addToCart = () => {
    Tracker.addToCart(photograph)
    cartStore?.addPhotograph(photograph, album)
  }

  if (cartStore.isLoading) {
    return <Spinner noStyles size={20} />
  }

  return (
    <>
      {isInCart ? (
        <NavLink to="/cart" className="w-full no-underline">
          <Button btnType="PrimaryAction" onClick={() => {}} extraStyle="w-full">
            <div className="flex items-center gap-x-4">
              <div>{t('Go to cart')}</div>
              <FontAwesomeIcon className="text-xl" icon={faArrowRight} />
            </div>
          </Button>
        </NavLink>
      ) : (
        <Button btnType="PrimaryAction" onClick={addToCart} extraStyle="w-full">
          <div className="flex items-center gap-2">
            <span>{t('Add to Cart')}</span>
            <span className="font-semibold">
              {album.currency && album.currency.toUpperCase()} {photograph.price}
            </span>
          </div>
        </Button>
      )}
    </>
  )
}

export default observer(AddToCartButtonLong)
