import React from 'react'
import { useIntersectionObserver } from './hooks'

export enum AnimationType {
  FADE = 'fade',
  SCALE = 'scale',
}

type AnimatedSectionProps = {
  children: React.ReactNode
  className?: string
  animation?: AnimationType
}

const AnimatedSection: React.FC<AnimatedSectionProps> = ({
  children,
  className = '',
  animation = AnimationType.FADE,
}) => {
  const [ref, isIntersecting] = useIntersectionObserver({
    threshold: 0.1,
    triggerOnce: true,
  })

  const getAnimationClass = () => {
    switch (animation) {
      case AnimationType.FADE:
        return isIntersecting ? 'opacity-100' : 'opacity-0'
      case AnimationType.SCALE:
        return isIntersecting ? 'scale-100 opacity-100' : 'scale-95 opacity-0'
      default:
        return isIntersecting ? 'opacity-100' : 'opacity-0'
    }
  }

  return (
    <div
      ref={ref as React.Ref<HTMLDivElement>}
      className={`transition-all duration-700 ease-out ${getAnimationClass()} ${className}`}
    >
      {children}
    </div>
  )
}

export default AnimatedSection
