import { Fee } from './Fee'

export enum PaymentStatus {
  PENDING = 'pending', //pending: The user has not concluded the payment process (for example, by generating a payment by boleto, it will be concluded at the moment in which the user makes the payment in the selected place).
  REJECTED = 'rejected', // rejected: The payment was rejected (the user can try to pay again).
  APPROVED = 'approved', // approved: The payment has been approved and credited.
  CANCELLED = 'cancelled', // cancelled: Either the payment was canceled by one of the parties or expired.
  AUTHORIZED = 'authorized', // authorized: The payment has been authorized but not captured yet.
  IN_PROCESS = 'in_process', // in_process: The payment is in analysis.
  IN_MEDITATION = 'in_meditation', // in_mediation: The user started a dispute.
  REFUNDED = 'REFUNDED', // refunded: The payment was returned to the user.
  CHARGED_BACK = 'charged_back', // charged_back: A chargeback was placed on the buyer's credit card.
}

export class Payment {
  uuid: string

  id: string

  currency: string

  dateApproved: Date

  dateCreated: Date

  description: string

  exchangeRate: number

  fees: Fee[]

  issuerId: string

  liveMode: boolean

  marketPlaceOwner: string

  merchantAccountId: string

  preferenceId: string

  merchantNumber: string

  moneyReleaseDate: Date

  operationType: string

  orderId: string

  orderType: string

  payerName: string

  payerEmail: string

  paymentMethodId: string

  paymentTypeId: string

  status: PaymentStatus

  statusDetail: string

  transactionAmount: number

  transactionNetReceivedAmount: number

  totalPayedAmount: number

  purchaseId: string

  createdAt!: Date

  updatedAt!: Date

  deletedAt?: Date
}
