import React, { MouseEventHandler, useContext, useState } from 'react'
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleXmark,
  faDownload,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Photograph } from 'shared/models/Photograph'
import Album from 'shared/models/Album'
import 'moment/locale/es'
import moment from 'moment'
import imageSizeIcon from '../../assets/icons/v2/imageSize.svg'
import shareIcon from '../../assets/icons/v2/share.svg'
import fileIcon from '../../assets/icons/v2/file-type.svg'
import { isNil } from 'lodash'
import PhotographActions from './PhotographActions'
import { useTranslation } from 'react-i18next'
import ShareModal from 'components/UI/ShareModal'
import storesContext from 'providers/storesContext'
import { observer } from 'mobx-react'
import Spinner from 'components/UI/Spinner'
import { Avatar } from '@mui/material'
import DiscountProgressBar from 'components/AlbumDetails/components/QuantityDiscount/components/DiscountProgressBar'
import { QuantityDiscount } from 'shared/models/QuantityDiscount'
import Cart from '../../shared/models/Cart/Cart'

type PhotographModalDesktopProps = {
  goBack: () => void
  goNext: () => void
  setImageLoaded: any
  currentPhotographToDisplay: Photograph
  imageClass: string
  imageLoaded: boolean
  onClose?: MouseEventHandler<HTMLDivElement>
  album: Album
  canEdit: boolean
  loadingNextPage: boolean
  currentCart?: Cart
  photographs: Photograph[]
  onDownloadFreePhotograph: (photographId: string) => void
  tiersEarned?: number
  quantityDiscount?: QuantityDiscount | null
}
const PhotographModalDesktop = ({
  goBack,
  goNext,
  setImageLoaded,
  currentPhotographToDisplay,
  imageClass,
  imageLoaded,
  onClose = () => {},
  album,
  canEdit,
  loadingNextPage,
  currentCart,
  photographs,
  tiersEarned,
  quantityDiscount,
  onDownloadFreePhotograph,
}: PhotographModalDesktopProps) => {
  const { t } = useTranslation()
  const { cartStore } = useContext(storesContext)!
  const [shareModalOpened, setShareModalOpened] = useState(false)
  const albumImagesInCartQuantity = currentCart?.getPhotographCount(album.id, album.event?.id)
  if (cartStore.isLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className="flex w-full px-1 justify-center">
        <div className="flex flex-col justify-center mr-3">
          <FontAwesomeIcon
            onClick={goBack}
            icon={faCircleChevronLeft}
            className={
              photographs[0].id === currentPhotographToDisplay.id
                ? 'cursor-pointer text-4xl opacity-40'
                : 'cursor-pointer text-4xl'
            }
          />
        </div>
        <div>
          <img
            onLoad={() => setImageLoaded(true)}
            src={currentPhotographToDisplay?.url}
            alt={currentPhotographToDisplay ? 'Photograph' : 'loading...'}
            className={`object-contain ${imageClass} ${!imageLoaded && 'hidden'}`}
          />
        </div>
        <div className="flex flex-col justify-between max-w-[40%] p-4 bg-white rounded-r-lg">
          <div>
            <div className="flex justify-between gap-2.5">
              <h2 className="text-primary font-extrabold text-3xl mb-1.5 mt-1">
                {album?.location?.spotName}
              </h2>
              <div onClick={onClose}>
                <FontAwesomeIcon icon={faCircleXmark} className="cursor-pointer text-2xl" />
              </div>
            </div>

            <p className="text-xl font-semibold text-black">
              {moment.utc(album.takenDate).format('dddd DD/MM/YYYY')?.toLocaleUpperCase()}
            </p>
            <div className="flex justify-start gap-4 pt-2">
              <div className="flex items-center gap-2 py-2">
                {!isNil(album.owner.profilePictureUrl) ? (
                  <Avatar src={album.owner.profilePictureUrl} sx={{ width: 25, height: 25 }} />
                ) : (
                  <Avatar
                    sx={{ bgcolor: 'text.secondary', width: 25, height: 25, fontSize: '12px' }}
                  >
                    {album.owner.firstName?.charAt(0) + album.owner.lastName?.charAt(0)}
                  </Avatar>
                )}
                <span className="text-lumepic-light_black">{album.owner.alias}</span>
              </div>
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => setShareModalOpened(true)}
              >
                <span>
                  <img src={shareIcon} alt="Share" className="w-5" />
                </span>
                <span className="duration-100 ease-in-out border-b-2 border-transparent hover:border-black">
                  {t('Share picture')}
                </span>
              </div>
            </div>
            <div className="flex items-start gap-1 pl-1 mt-2">
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="mt-1 text-xs text-lumepic-dark_grey"
              />
              <span className="font-light text-xs mt-0.5 text-wrap">
                {album.isFree
                  ? t('Click “Download” to access the photograph in high quality.')
                  : t(
                      'By purchasing pictures, you’ll have access to them in high quality and without Lumepic watermarks.'
                    )}
              </span>
            </div>
          </div>
          <div>
            <ul className="pl-0">
              {currentPhotographToDisplay?.extension && (
                <li className="flex items-center mb-2">
                  <span>
                    <img loading="lazy" alt="File Type" src={fileIcon} className="w-3 ml-2" />
                  </span>
                  <span className="ml-2 text-sm font-medium uppercase text-lumepic-grey">
                    {currentPhotographToDisplay?.extension}
                  </span>
                </li>
              )}
              {currentPhotographToDisplay?.width && currentPhotographToDisplay?.height && (
                <li className="flex items-center mb-2">
                  <span>
                    <img
                      loading="lazy"
                      alt="imageSizeIcon"
                      src={imageSizeIcon}
                      className="w-4 ml-1.5"
                    />
                  </span>
                  <span className="ml-2 text-sm font-medium text-lumepic-grey">
                    {currentPhotographToDisplay.width} x {currentPhotographToDisplay.height} pixels
                  </span>
                </li>
              )}
              {currentPhotographToDisplay?.size && (
                <li className="flex items-center mb-1">
                  <span className="ml-1">
                    <FontAwesomeIcon icon={faDownload} className="text-xl text-lumepic-grey" />
                  </span>
                  <span className="ml-2 text-sm font-medium text-lumepic-grey">
                    {(currentPhotographToDisplay?.size * 0.000001).toFixed(2) + ' mb'}
                  </span>
                </li>
              )}
            </ul>
          </div>
          <div className="mb-2">
            {!isNil(tiersEarned) && !isNil(quantityDiscount) && !album.isFree && (
              <>
                <p className="pb-4 text-sm text-black">
                  <span className="text-sm font-light">{t('You have')}</span>
                  <span className="mx-1 text-lg font-medium underline">
                    {albumImagesInCartQuantity}
                  </span>
                  <span className="font-light text-sm">{t('photographs from this')}</span>
                  <span className="font-light text-sm mx-1">
                    {!isNil(album.event?.id) ? t('event') : t('album')}
                  </span>
                  <span className="font-light text-sm">
                    {t('in your cart. Save when you buy more.')}
                  </span>
                </p>
                <DiscountProgressBar
                  tiersEarned={tiersEarned}
                  quantityDiscount={quantityDiscount}
                  album={album}
                  event={album.event}
                />
              </>
            )}
          </div>
          {!isNil(currentPhotographToDisplay) && (
            <PhotographActions
              onDownloadFreePhotograph={onDownloadFreePhotograph}
              album={album}
              photograph={currentPhotographToDisplay}
              isFree={album.isFree}
              canEdit={canEdit}
            />
          )}
        </div>

        <div className="flex flex-col justify-center ml-3">
          {loadingNextPage ? (
            <Spinner noStyles size={30} />
          ) : (
            <FontAwesomeIcon
              icon={faCircleChevronRight}
              onClick={goNext}
              className={
                photographs[photographs.length - 1].id === currentPhotographToDisplay.id
                  ? 'cursor-pointer text-4xl opacity-40'
                  : 'cursor-pointer text-4xl'
              }
            />
          )}
        </div>
      </div>
      <ShareModal
        isModalOpened={shareModalOpened}
        closeModal={() => setShareModalOpened(false)}
        photographId={currentPhotographToDisplay.id}
        albumId={album.id}
      />
    </>
  )
}
export default observer(PhotographModalDesktop)
