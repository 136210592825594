import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { Accordion } from 'react-bootstrap'
import CustomToggleFull from 'components/UI/CustomToggle/CustomToggleFull'

type FAQAccordionProps = {
  id: string
  title: string
  content: string | JSX.Element
  openOnInit?: boolean
  type: 'section' | 'question'
}

const FAQAccordion = ({ id, title, content, openOnInit, type }: FAQAccordionProps) => {
  const [isExpanded, setisExpanded] = useState(openOnInit || false)
  return (
    <Accordion defaultActiveKey={openOnInit ? id : 'genericas'}>
      <Card className="mb-2">
        <Card.Header className="cursor-pointer rounded-md bg-bg_details hover:bg-gray-100 transition-colors duration-100 active:bg-lumepic-medium_grey">
          <CustomToggleFull
            eventKey={id}
            opened={isExpanded}
            plusToggle
            onClick={() => setisExpanded(!isExpanded)}
            extraClassNames="flex justify-between p-3"
          >
            <div>
              <p className={`m-0 pr-2.5 ${type === 'section' ? 'font-extrabold' : null}`}>
                {title}
              </p>
            </div>
          </CustomToggleFull>
        </Card.Header>
        {isExpanded && (
          <Accordion.Collapse eventKey={id}>
            <Card.Body className="font-light text-lumepicSm p-2.5 bg-white border border-bg_details rounded-b-md">
              <div>
                <p className="m-0">{content}</p>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        )}
      </Card>
    </Accordion>
  )
}

export default FAQAccordion
