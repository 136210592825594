import * as React from 'react'
import Box from '@mui/material/Box'
import MuiModal from '@mui/material/Modal'
import { isMobileDevice } from '../../../shared/utility'

export enum ModalSize {
  SMALL,
  MEDIUM,
  LARGE,
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  boxShadow: 24,
}

const mobileStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  boxShadow: 24,
}

type ModalProps = {
  open: boolean
  handleClose: () => void
  size: ModalSize
  children: React.ReactNode
}
export const Modal = ({ open, handleClose, children }: ModalProps) => {
  return (
    <div>
      <MuiModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={isMobileDevice() ? mobileStyle : style}>{children}</Box>
      </MuiModal>
    </div>
  )
}

export default Modal
