import { makeAutoObservable, runInAction } from 'mobx'
import FeedService from 'services/FeedService'

class HomeStore {
  private readonly feedService: FeedService
  public albumCount: number
  public photographerCount: number
  public activityCount: number
  public isLoading: boolean
  public error: any
  constructor() {
    this.reset()
    makeAutoObservable(this)
    this.feedService = new FeedService()
  }

  reset() {
    this.isLoading = false
    this.error = null
  }

  clearErrors() {
    this.error = null
  }

  async fetchMetrics(): Promise<void> {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.feedService.fetchMetrics()
      runInAction(() => {
        this.albumCount = response.albumCount
        this.activityCount = response.activityCount
        this.photographerCount = response.photographerCount
        this.isLoading = false
      })
    } catch (e: any) {
      this.error = e?.response
      this.isLoading = false
    }
  }
}

export default HomeStore
