import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { DefaultTFuncReturn } from 'i18next'

type PhotographerFeatureProps = {
  icon: IconDefinition
  title: string
  description?: DefaultTFuncReturn
}

const PhotographerFeature = ({ icon, title, description }: PhotographerFeatureProps) => {
  return (
    <div className="flex flex-col sm:flex-row gap-3">
      <FontAwesomeIcon icon={icon} className="text-primary_dark text-5xl sm:text-2xl" />
      <div className="flex flex-col items-center sm:items-start">
        <p className="text-sm font-regular text-lumepic-light_black sm:text-lg sm:font-medium text-center sm:text-start w-min sm:w-full sm:text-black">
          {title}
        </p>
        <span className="text-sm sm:text-base font-light text-lumepic-grey">{description}</span>
      </div>
    </div>
  )
}

export default PhotographerFeature
