import { makeAutoObservable } from 'mobx'
import InputStore from '../../shared/store/InputStore'
import { string } from 'yup'

class CartDiscountStore {
  public discountCode: InputStore<string>
  public error: any
  constructor() {
    this.reset()
    makeAutoObservable(this)
  }
  reset() {
    this.discountCode = new InputStore(string().required('Debe ingresar un descuento valido'))
    this.discountCode.errorMessage = ''
  }

  changeDiscountCode(val: string) {
    this.discountCode.setValue(val)
  }

  async discountCodeIsValid(): Promise<boolean> {
    this.clearErrorsDiscount()
    return this.discountCode.validate()
  }

  clearErrorsDiscount() {
    this.discountCode.clearError()
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'discountCode':
          this.discountCode.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }
}

export default CartDiscountStore
