import React, { useEffect } from 'react'
import c from 'classnames'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { getApiDate, getLocalDate } from 'shared/util/dates'

type DateRangePickerProps = {
  onChange: (value: any) => void
  value?: [string | null, string | null]
  selectsRange?: boolean
  startDate?: Date | null
  endDate?: Date | null
  isClearable?: boolean
  className?: string
  placeholderText?: string
  clearInputs: boolean
}

const DateRangePicker = ({ onChange, value, clearInputs }: DateRangePickerProps) => {
  const { t } = useTranslation()

  const [startDate, endDate] = value || [null, null]

  const renderStartDate = startDate ? getApiDate(new Date(startDate)) : null
  const renderEndDate = endDate ? getApiDate(new Date(endDate)) : null

  const inputBaseClasses = [
    'p-2',
    'border-2',
    'rounded-md',
    'w-full',
    'border-lumepic-grey',
    'only:mt-4',
  ]
  const inputDefaultClasses = [
    'border-2',
    'text-lumepic-grey',
    'border-lumepic-grey',
    'focus:outline-none',
    'focus:shadow-md',
    'placeholder-lumepic-grey',
  ]
  const inputClasses = [...inputBaseClasses, ...inputDefaultClasses]

  const handleChange = (value: [Date | null, Date | null]) => {
    const [newDateStart, newDateEnd] = value
    const apiTimeFromPickerLocalDateStart = getLocalDate(newDateStart)
    const apiTimeFromPickerLocalDateEnd = getLocalDate(newDateEnd)
    apiTimeFromPickerLocalDateEnd?.setUTCHours(23, 59, 59, 999)

    onChange([apiTimeFromPickerLocalDateStart, apiTimeFromPickerLocalDateEnd])
  }

  useEffect(() => {
    if (clearInputs) {
      handleChange([null, null])
    }
  }, [clearInputs])
  return (
    <DatePicker
      selectsRange={true}
      startDate={renderStartDate}
      endDate={renderEndDate}
      onChange={handleChange}
      className={c(inputClasses)}
      dateFormat="dd/MM/yyyy"
      placeholderText={t('Date Range').toString()}
    />
  )
}
export default DateRangePicker
