import React, { useState, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import StoresContext from '../../../providers/storesContext'
import 'moment/locale/es'
import UpdatePasswordStore from './UpdatePasswordStore'
import ServerError from '../../../components/UI/ServerError'
import InputWrapper from '../../../components/UI/Forms/InputWrapper'
import BackButton from 'components/UI/Button/BackButton'
import Spinner from 'components/UI/Spinner'
import logoWhite from '../../../assets/icons/v2/logo-white.svg'
import forgotPasswordImage from '../../../assets/images/ForgotPasswordImage.svg'
import LoginBackground from '../../../assets/images/LoginBackground.svg'
import logoColored from '../../../assets/icons/v2/logo-colored.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/UI/Button'
import { toast } from 'react-toastify'

const ForgotPassword = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { authStore } = useContext(StoresContext)!
  const [updatePasswordStore] = useState(() => new UpdatePasswordStore(authStore))
  const { isLoading, error, email } = updatePasswordStore

  const handleSubmit = () => {
    updatePasswordStore.requestForgotPassword().then((result) => {
      if (result !== false) {
        toast.success(t('The link to reset the password has been sent'), {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        updatePasswordStore.reset()
        goToAuth()
      }
    })
  }

  const handleChangeEmail = (val: string) => {
    updatePasswordStore.changeEmail(val)
  }

  const goToAuth = () => {
    history.push('/auth')
  }

  useEffect(() => {
    return () => {
      updatePasswordStore.clearError()
    }
  }, [updatePasswordStore])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className="min-h-primaryMobile md:min-h-primaryDesktop bg-bg_details md:pt-3 pb-20">
        <div className="flex md:h-80vh md:px-3">
          <div className="w-5/12 hidden md:flex flex-col justify-between rounded-l-lg bg-primary">
            <img src={logoWhite} className="mt-3 ml-3" alt="Logo" width="70px" height="70px" />
            <div className="flex justify-center py-10 pl-12 lg:py-16 lg:pl-20">
              <img src={forgotPasswordImage} alt="photographer" className="w-full xl:w-3/4" />
            </div>
            <p className="mb-3 ml-3.5 text-xl font-semibold text-white">
              {t('Hey! Welcome back') + '!'}
            </p>
          </div>
          <div className="w-full md:w-7/12 flex flex-col items-center justify-start min-h-primaryMobile bg-white rounded-lg md:justify-center md:rounded-l-none md:min-h-full">
            <div className="relative md:hidden">
              <img src={LoginBackground} alt="background" />
              <div className="absolute flex items-center gap-1.5 left-7" style={{ bottom: '47%' }}>
                <img src={logoColored} className="w-8 h-8" alt="logo" />
                <h2 className="mb-0 text-2xl font-semibold text-lumepic-light_black">
                  {t('Forgot your password') + '?'}
                </h2>
              </div>
            </div>
            <div className="w-full px-8 py-4">
              <div className="w-full hidden md:flex flex-col items-start gap-3">
                <div className="rounded-md bg-bg_details py-1 px-2 cursor-pointer hover:bg-lumepic-medium_grey duration-100">
                  <BackButton onClick={goToAuth} />
                </div>
                <div className="flex gap-1.5 items-center">
                  <h2 className="mb-1 text-4xl font-medium text-lumepic-light_black">
                    {'¿' + t('Forgot your password') + '?'}
                  </h2>
                  <div className="w-8 h-8 rounded-full flex items-center justify-center bg-primary_light">
                    <FontAwesomeIcon icon={faKey} className="text-lg text-lumepic-light_black" />
                  </div>
                </div>
              </div>
              <p className="hidden mb-3 text-lumepic-light_black md:block">
                {t('Fill in your email address and we will send you a link to reset it.')}
              </p>
              {updatePasswordStore.error && <ServerError message={t(error).toString()} />}
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit()
                }}
              >
                <InputWrapper
                  placeholder={t('Email').toString()}
                  onChange={handleChangeEmail}
                  inputStore={email}
                />
                <Button btnType="PrimaryAction" onClick={() => {}} extraStyle="w-full mt-3">
                  {t('Reset Password')}
                </Button>
              </form>
              <div className="w-full flex md:hidden justify-center mt-3">
                <BackButton onClick={goToAuth} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(ForgotPassword)
