import { makeAutoObservable, runInAction } from 'mobx'
import InputStore from '../../../shared/store/InputStore'
import AuthService from '../../../services/AuthService'
import { string } from 'yup'
import { toast } from 'react-toastify'
import AuthStore from '../../../stores/AuthStore'

class UpdatePasswordStore {
  public password: InputStore<string>
  public rePassword: InputStore<string>
  public email: InputStore<string>
  public isLoading: boolean
  public token: string | null
  public error: any
  public serverError: any
  private authService: AuthService
  constructor(private readonly authStore: AuthStore) {
    this.reset()
    this.authService = new AuthService()
    this.authStore = authStore
    makeAutoObservable(this)
  }

  reset() {
    this.password = new InputStore(
      string()
        .required('La contraseña es requerida')
        .min(6, 'La contraseña debe tener al menos 6 caracteres')
    )
    this.rePassword = new InputStore(string().required('La contraseña es requerida'))
    this.email = new InputStore(string().required('Ingrese su email'))

    this.isLoading = false
    this.token = null
    this.error = null
  }

  changePassword(val: string) {
    this.password.setValue(val)
  }

  changeRePassword(val: string) {
    this.rePassword.setValue(val)
  }

  changeEmail(val: string) {
    this.email.setValue(val)
  }

  changeToken(val: string) {
    this.token = val
  }

  clearErrorsResetPassword() {
    this.password.clearError()
    this.rePassword.clearError()
  }

  clearErrorsForgotPassword() {
    this.email.clearError()
  }

  clearError() {
    this.error = null
  }

  async validateResetPasswordInputs() {
    this.clearErrorsResetPassword()
    let isValid = true

    if (!(await this.password.validate())) {
      isValid = false
    }

    if (!(await this.rePassword.validate())) {
      isValid = false
    }

    if (this.password.value !== this.rePassword.value) {
      this.rePassword.errorMessage = 'La contraseñas deben coincidir'
      isValid = false
    }

    return isValid
  }

  async validateForgotPasswordInputs() {
    this.clearErrorsForgotPassword()
    let isValid = true

    if (!(await this.email.validate())) {
      isValid = false
    }

    return isValid
  }

  async requestResetPassword(token: string) {
    if (await this.validateResetPasswordInputs()) {
      runInAction(() => {
        this.isLoading = true
      })

      try {
        const response = await this.authService.resetPassword(this.password.value, token)
        runInAction(() => {
          this.isLoading = false
        })

        return response
      } catch (e: any) {
        runInAction(() => {
          let displayedError = 'Something went wrong, please check the provided data and try again.'

          if (e.message) {
            if (e.message === 'Token not found.' || e.message === 'Invalid token.') {
              displayedError = 'Token invalido. Intente de nuevo'
            }
            if (e.message === 'Reset password token expired.') {
              displayedError = 'Token expirado. Intente de nuevo'
            }
          }
          toast.error(displayedError, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })

          runInAction(() => {
            this.isLoading = false
          })
        })
      }
    }

    return false
  }

  async requestForgotPassword() {
    if (await this.validateForgotPasswordInputs()) {
      runInAction(() => {
        this.isLoading = true
      })
      try {
        const response = await this.authService.forgotPassword(this.email.value)
        runInAction(() => {
          this.isLoading = false
        })

        return response
      } catch (e: any) {
        runInAction(() => {
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.serverError = 'Something went wrong, please check the provided data and try again.'
          }

          runInAction(() => {
            this.isLoading = false
            this.error = e?.message
          })
        })
      }
    }

    return false
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'password':
          this.password.setError(true, error)
          displayedError = true
          break

        case 'rePassword':
          this.rePassword.setError(true, error)
          displayedError = true
          break

        case 'email':
          this.email.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }
}

export default UpdatePasswordStore
