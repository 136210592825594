import axios from './axios'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { FeatureFlag } from 'shared/models/FeatureFlag'

class FeatureFlagService {
  fetchFeatureFlags(): Promise<FeatureFlag[]> {
    return axios
      .get(paths.GET_FEATURE_FLAGS)
      .then((response) => response.data.map((ff) => FeatureFlag.init(ff)))
      .catch(parseAxiosErrorResponse)
  }

  getCurrencyMinimumPhotographPrice(currencyCode: string): Promise<number | null> {
    return axios
      .get(paths.getPhotographMinimumPrice(currencyCode))
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default FeatureFlagService
