import axios from './axios'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { Paginated } from './RequestInterfaces/Paginated'
import { Photograph } from '../shared/models/Photograph'
import { AxiosResponse } from 'axios'
import PhotographInterface from './RequestInterfaces/Photograph/Photograph.interface'

class PhotographsService {
  deletePhotograph(photographId: string, token: string) {
    return axios
      .delete(paths.deletePhoto(photographId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  updatePhotographPrice(photoId: string, newPrice: number, token: string) {
    return axios
      .put(
        `${paths.PHOTOGRAPH}/${photoId}`,
        {
          price: newPrice,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch(parseAxiosErrorResponse)
  }

  getPurchasedPhotographDownloadUrl(token: string, purchaseId: string, photographIds: string[]) {
    return axios
      .post(
        paths.purchasedPhotographsDownloadUrls(purchaseId),
        {
          photographIds: photographIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }
  public async fetchAlbumPhotographs(
    albumId: string,
    limit: Number,
    skip: Number,
    token: string
  ): Promise<Paginated<Photograph>> {
    const options = {
      limit: Number(limit),
      skip: Number(skip),
      albumId,
    }
    return axios
      .get(paths.photographsByAlbum(), {
        params: options,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res: AxiosResponse<Paginated<PhotographInterface>>) => {
        const result = res.data
        const models = result.items.map((p) => Photograph.init(p))
        return {
          items: models,
          count: result.count,
        }
      })
      .catch(parseAxiosErrorResponse)
  }
}

export default PhotographsService
