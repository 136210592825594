import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNil } from 'lodash'
import photographerIcon from '../../../../../assets/icons/v2/photographer.svg'
import * as paths from '../../../../../routing/Paths'
import { formatUtcToLocaleDate } from 'shared/utility'
import {
  faArrowUpRightFromSquare,
  faCalendar,
  faCircleInfo,
  faLocationDot,
} from '@fortawesome/free-solid-svg-icons'
import Album from 'shared/models/Album'

type PrivateAlbumInformationProps = {
  album: Album
}

const PrivateAlbumInformation = ({ album }: PrivateAlbumInformationProps) => {
  const openPhotographerProfile = (alias: string) => {
    const photographerProfileUrl = paths.photographerProfileByAlias(alias)
    window.open(photographerProfileUrl, '_blank')
  }

  return (
    <section className="flex flex-col gap-1.5 mt-4 bg-white p-2.5 rounded-md">
      <p className="flex items-center mb-0 ml-1 text-black">
        <FontAwesomeIcon className="mr-1.5 text-lg text-lumepic-grey" icon={faLocationDot} />
        {album.location.spotName}
      </p>
      <p className="flex items-center mb-0 ml-0.5  text-black">
        <FontAwesomeIcon className="mr-1.5 text-lg text-lumepic-grey" icon={faCalendar} />
        {formatUtcToLocaleDate(album.takenDate)}
      </p>
      <p className="flex items-center mb-0 mt-px underline underline-offset-2 text-black">
        <img loading="lazy" alt="Photographer" src={photographerIcon} className="w-6 h-6" />
        <span
          className=" text-black ml-0.5 pb-px cursor-pointer hover:font-medium transition duration-200"
          onClick={() => openPhotographerProfile(album.owner.alias)}
        >
          {album.owner.alias}
        </span>
        {!isNil(album.owner.alias) && (
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            onClick={() => openPhotographerProfile(album.owner.alias)}
            className="ml-1 text-lumepic-black text-xs cursor-pointer"
          />
        )}
      </p>
      <p className="flex items-center mb-0 ml-0.5  text-black">
        <FontAwesomeIcon className="mr-1.5 text-lg text-lumepic-grey" icon={faCircleInfo} />
        <span className="text-ellipsis">{album.description}</span>
      </p>
    </section>
  )
}

export default PrivateAlbumInformation
