import React from 'react'

type BreadcrumbProps = {
  totalSteps: number
  completedSteps: number
}
const Breadcrumb = ({ totalSteps, completedSteps }: BreadcrumbProps) => {
  const completedColor = 'primary'
  const incompleteColor = 'lumepic-medium_grey'

  const steps: JSX.Element[] = []
  for (let step = 1; step <= totalSteps; step++) {
    const stepColor = step <= completedSteps ? completedColor : incompleteColor
    const lineColor = step < completedSteps ? completedColor : incompleteColor
    const borderOpacity = step < completedSteps ? '100' : '70'
    const activeStep = step === completedSteps ? 'font-semibold' : 'font-normal'

    steps.push(
      <div
        key={step}
        className={`bg-${stepColor} rounded-full p-2 w-8 h-8 flex items-center justify-center ${activeStep}`}
      >
        {step}
      </div>
    )
    if (step < totalSteps) {
      steps.push(
        <hr
          key={`hr-${step}`}
          className={`w-4 border-2 border-${lineColor} opacity-${borderOpacity}`}
        />
      )
    }
  }
  return <div className="flex items-center">{steps}</div>
}

export default Breadcrumb
