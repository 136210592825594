import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import StoresContext from '../../../../providers/storesContext'
import { isNil } from 'lodash'
import { formatDateShort } from 'shared/utility'

const PhotographerClients = () => {
  const { t } = useTranslation()
  const { authStore } = useContext(StoresContext)!
  const user = authStore.getLoggedUser()

  return (
    <div>
      {!isNil(user.clients) && user.clients.length > 0 && (
        <div className="mb-5 bg-white border-t-4 border-primary py-5 px-4 sm:px-10">
          <div className="text-gray-800 font-extrabold text-lg">{t('Clients')}</div>
          <span className="text-gray-600 text-sm">
            {t(
              'Here you will see all the customers who purchased using your fidelity code and therefore Lumepic will not charge you a commission for them for one year.'
            )}
          </span>
          <div className="grid grid-cols-2 sm:grid-cols-4 mt-4">
            <div className="hidden sm:block text-lumepic-light_black ml-1">{t('First Name')}</div>
            <div className="hidden sm:block text-lumepic-light_black ml-1">{t('Last Name')}</div>
            <div className="sm:hidden flex w-full text-lumepic-light_black ml-1">{t('Name')}</div>
            <div className="sm:hidden flex w-full text-lumepic-light_black">
              {t('Expiration date')}
            </div>
            <div className="hidden sm:block text-lumepic-light_black">{t('Email')}</div>
            <div className="hidden sm:block text-lumepic-light_black">{t('Valid from-to')}</div>
          </div>
          {user.clients!.map((client, index) => (
            <div
              key={index}
              className="grid grid-cols-2 sm:grid-cols-4 bg-lumepic-light_grey rounded-lg p-2 my-2"
            >
              <div className="hidden sm:block text-lumepic-light_black">{client.firstName}</div>
              <div className="hidden sm:block text-lumepic-light_black">{client.lastName}</div>
              <div className="sm:hidden w-1/2 text-lumepic-light_black mb-0.5">
                {client.firstName + ' ' + client.lastName}
              </div>
              {client.dateRegisteredAsClient && (
                <div className="sm:hidden w-1/2 text-lumepic-light_black mb-0.5">
                  {formatDateShort(
                    new Date(
                      new Date(client.dateRegisteredAsClient).setFullYear(
                        new Date().getFullYear() + 0.5
                      )
                    )
                  )}
                </div>
              )}
              <div className="hidden sm:block text-lumepic-light_black text-sm">{client.email}</div>
              {client.dateRegisteredAsClient ? (
                <div className="hidden sm:block text-lumepic-light_black text-sm ml-1">
                  {formatDateShort(client.dateRegisteredAsClient)} -{' '}
                  {formatDateShort(
                    new Date(
                      new Date(client.dateRegisteredAsClient).setFullYear(
                        new Date().getFullYear() + 1
                      )
                    )
                  )}
                </div>
              ) : (
                <span className="flex justify-center">-</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default observer(PhotographerClients)
