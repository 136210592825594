import React, { useEffect, useState, useContext, useRef } from 'react'
import { observer } from 'mobx-react'
import StoresContext from '../../providers/storesContext'
import PurchaseItems from './components/PurchaseItems'
import PurchaseStore from './PurchaseStore'
import PurchaseRowEmpty from './components/PurchaseRowEmpty'
import { useTranslation } from 'react-i18next'
import Spinner from 'components/UI/Spinner'
import { Purchase, PurchaseStatus } from 'shared/models/Purchase'
import InfiniteScroll from 'react-infinite-scroll-component'
import SkeletonLoaderPurchases from 'components/UI/Loaders/Skeleton/SkeletonLoaderPurchases'
import { isNil } from 'lodash'
import PurchaseItemDetails from './components/PurchaseItemDetails'
import { SetPageTitle } from '../../shared/utility'

const Purchases = () => {
  SetPageTitle('My Purchases')
  const { t } = useTranslation()
  const { authStore } = useContext(StoresContext)!
  const [purchaseStore] = useState(() => new PurchaseStore(authStore))
  const initialRender = useRef(true)
  const { isLoading } = purchaseStore

  const isMdScreen = window.innerWidth > 768

  const [purchaseDesktopDetailsOpened, setPurchaseDesktopDetailsOpened] = useState<Purchase | null>(
    null
  )

  const fetchNextPage = () => {
    purchaseStore.fetchNextPage('purchases')
  }

  useEffect(() => {
    if (initialRender.current && purchaseStore.userPurchases.length === 0) {
      purchaseStore.fetchPurchases(PurchaseStatus.APPROVED).then(() => {
        isMdScreen && setPurchaseDesktopDetailsOpened(purchaseStore.userPurchases[0])
      })
      initialRender.current = false
    }
  }, [])

  const loadingSpinner = (
    <div className="w-full bg-white rounded-lg mt-2">
      <Spinner divStyles="flex justify-center py-2 items-center" size={40} />
    </div>
  )

  return (
    <>
      <div className="bg-bg_section_titles border-b mb-2 pb-3 pt-4">
        <div className="container mx-auto flex gap-1.5 items-end">
          <h2 className="text-3xl pt-3 pl-3 sm:pl-0 font-extrabold md:text-4xl text-lumepic-light_black">
            {t('My Purchases')}
          </h2>
          <span className="text-lg pt-3 text-lumepic-dark_grey font-semibold sm:text-2xl">
            {!isLoading && `(${purchaseStore.purchaseCount})`}
          </span>
        </div>
      </div>
      <div className="h-full bg-bg_details min-h-primaryMobile mb-3 md:w-full md:min-h-primaryDesktop">
        <div className="container mx-auto block md:flex md:gap-5">
          <div
            className={`w-full rounded-lg ${
              !initialRender.current && purchaseStore.userPurchases.length === 0
                ? 'md:w-full'
                : 'md:w-2/5'
            } `}
          >
            <div className="w-full">
              {!isLoading && (
                <div className="text-lumepic-light_black rounded-t-lg bg-white py-2 md:mt-4">
                  <span className="px-3 font-semibold text-xl text-lumepic-light_black">
                    {t('Purchase history')}
                  </span>
                </div>
              )}
              <div>
                <InfiniteScroll
                  dataLength={purchaseStore.userPurchases.length}
                  next={fetchNextPage}
                  hasMore={purchaseStore.hasMorePages()}
                  loader={<></>}
                  scrollThreshold={0.8}
                  className="overflow-hidden"
                >
                  {purchaseStore.userPurchases.length > 0 ? (
                    <PurchaseItems
                      purchases={purchaseStore.userPurchases}
                      purchaseCount={purchaseStore.purchaseCount}
                      purchaseDesktopDetailsOpened={purchaseDesktopDetailsOpened}
                      setPurchaseDesktopDetailsOpened={setPurchaseDesktopDetailsOpened}
                      store={purchaseStore}
                    />
                  ) : !isLoading ? (
                    <PurchaseRowEmpty />
                  ) : null}
                </InfiniteScroll>

                {isLoading && purchaseStore.page > 1 && loadingSpinner}
              </div>
              {isLoading && purchaseStore.page === 1 && <SkeletonLoaderPurchases />}
            </div>
          </div>
          {isMdScreen &&
            purchaseStore.userPurchases.length > 0 &&
            !isNil(purchaseDesktopDetailsOpened) && (
              <PurchaseItemDetails
                purchase={purchaseDesktopDetailsOpened}
                purchaseDesktopDetailsOpened={purchaseDesktopDetailsOpened}
                index={
                  purchaseStore.purchaseCount -
                  1 -
                  purchaseStore.userPurchases.indexOf(purchaseDesktopDetailsOpened) +
                  1
                }
                store={purchaseStore}
              />
            )}
        </div>
      </div>
    </>
  )
}

export default observer(Purchases)
