import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/UI/Button'
import ConfigService, { AssetNames } from 'config'
import MetricFrame from './components/MetricFrame'
import { formatMetric, scrollToElement } from 'containers/PhotographersLanding/utils/utils'
import PhotographersLandingStore from 'containers/PhotographersLanding/PhotographersLandingStore'
import { isNil } from 'lodash'
import { observer } from 'mobx-react'
import Tracker from 'shared/tracking'

type PhotographersHeroSectionProps = {
  store: PhotographersLandingStore
}

const PhotographersHeroSection = ({ store }: PhotographersHeroSectionProps) => {
  const { t } = useTranslation()

  return (
    <div className="relative w-full">
      <div className="absolute w-full h-full flex justify-center top-1/4 sm:top-0 sm:justify-end sm:items-center sm:right-6 md:right-20 xl:right-28">
        <div className="flex flex-col gap-2 items-center sm:items-start">
          <h1 className="font-bold text-white text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
            {t('Sell your photos online!')}
          </h1>
          <h2 className="text-lumepic-medium_grey text-sm md:text-regular lg:text-lg xl:text-xl mb-2 sm:mb-4">
            {t('Simplify and boost your sales with Lumepic')}
          </h2>
          <Button
            btnType="PrimaryAction"
            onClick={() => {
              Tracker.goToPhotographersRegister()
              scrollToElement('photographer-register')
            }}
            extraStyle="w-fit"
          >
            <span className="text-sm md:text-regular lg:text-lg">{t('Get started now')}</span>
          </Button>
        </div>
      </div>
      <div className="hidden sm:block">
        <img
          src={ConfigService.getAsset(
            AssetNames.PHOTOGRAPHERS_LANDING_HERO_DESKTOP,
            'photographers-landing'
          )}
          className="object-cover object-center w-full z-10 min-h-[60vh]"
          alt="Photographers landing page hero section"
        />
      </div>
      <div className="sm:hidden">
        <img
          src={ConfigService.getAsset(
            AssetNames.PHOTOGRAPHERS_LANDING_HERO_MOBILE,
            'photographers-landing'
          )}
          className="object-cover object-center w-full z-10"
          alt="Photographers landing page hero section"
        />
      </div>
      <div className="w-full flex justify-center items-center">
        <img
          src={ConfigService.getAsset(
            AssetNames.PHOTOGRAPHERS_LANDING_PHOTOGRAPHER_GLOW,
            'photographers-landing'
          )}
          className="object-cover object-center absolute w-72 -bottom-6 sm:left-4 md:w-80 md:-bottom-10 md:left-6 lg:w-[27rem] lg:-bottom-12 lg:left-16 xl:w-max z-10"
          alt="Photographers landing page hero section"
        />
        {!isNil(store.metrics) && (
          <div className="absolute -bottom-24 sm:-bottom-5 md:-bottom-8 sm:right-6 md:right-20 xl:right-28 flex gap-2 w-full justify-center sm:justify-end">
            <MetricFrame
              metric={formatMetric(store.metrics?.photographCount)}
              metricLabel={t('Photos')}
            />
            <MetricFrame
              metric={formatMetric(store.metrics?.photographerCount)}
              metricLabel={t('Photographers')}
            />
            <MetricFrame
              metric={formatMetric(store.metrics?.countriesWithPhotographersCount)}
              metricLabel={t('Countries')}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(PhotographersHeroSection)
