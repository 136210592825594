import React from 'react'
import { observer } from 'mobx-react'
import { PhotographCartLine } from '../../../../../../shared/models/Cart/CartLine/PhotographCartLine'
import { formatDateShort } from '../../../../../../shared/utility'

type MiniPhotographCartLineProps = {
  line: PhotographCartLine
}

const MiniCartPhotographLine = ({ line }: MiniPhotographCartLineProps) => {
  const hasDiscount = line.subtotalPrice > line.totalPrice

  return (
    <div className="flex p-3 border-b border-gray-100 hover:bg-gray-50">
      <div className="w-1/2">
        <div className="aspect-square relative">
          <img
            loading="lazy"
            className="w-full h-full object-cover rounded-md"
            src={line.photograph.thumbnailUrl}
            alt={line.album?.location.spotName}
          />
        </div>
      </div>

      <div className="w-1/2 pl-3 flex flex-col justify-between">
        <div>
          <p className="font-medium text-sm text-gray-900 line-clamp-2">
            {line.album?.location.spotName}
          </p>
          <span className="text-xs text-lumepic-dark_grey mt-1 inline-block">
            {formatDateShort(line.album?.takenDate || line.photograph.takenDate)}
          </span>
        </div>
        <div className="text-right">
          {hasDiscount && (
            <p className="text-xs text-lumepic-grey line-through">
              {line.currency?.toUpperCase()} {line.photograph.price.toFixed(2)}
            </p>
          )}
          <p className="text-sm font-bold text-gray-900">
            {line.currency?.toUpperCase()} {line.totalPrice.toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default observer(MiniCartPhotographLine)
