import axios from './axios'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { Activity } from '../shared/models/Activity'
import RequestActivityInterface from './RequestInterfaces/RequestActivity/RequestActivity.interface'

class ActivityService {
  fetchActivities(): Promise<Activity[]> {
    return axios
      .get(paths.GET_ACTIVITIES)
      .then((response) => response.data.map((act) => Activity.init(act)))
      .catch(parseAxiosErrorResponse)
  }
  requestNewActivity(data: RequestActivityInterface, token: string): Promise<Activity> {
    return axios
      .post(paths.REQUEST_ACTIVITY, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => Activity.init(response.data))
      .catch(parseAxiosErrorResponse)
  }
}

export default ActivityService
