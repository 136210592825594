import { makeAutoObservable, runInAction } from 'mobx'
import { NewCodeDiscount } from 'shared/models/NewCodeDiscount'
import CodeDiscountService from 'services/NewCodeDiscountService'
import AuthStore from 'stores/AuthStore'
import InputStore from 'shared/store/InputStore'
import { string, object, number, date } from 'yup'
import {
  CodeDiscountApplication,
  CodeDiscountUnit,
  CodeDiscountUsability,
  PurchaseCodeDiscountValidity,
} from 'services/RequestInterfaces/Discount/CodeDiscount.interface'
import FeedService from 'services/FeedService'
import Album from 'shared/models/Album'
import AlbumService from 'services/AlbumService'
import { isNil } from 'lodash'
import UserService from 'services/UserService'
import { CreateNewCodeDiscountDto } from './CreateNewCodeDiscountDto'
import { toast } from 'react-toastify'

class NewCodeDiscountStore {
  public codeDiscounts: NewCodeDiscount[]
  public description: InputStore<string>
  public discountCode: InputStore<string>
  public discountAmount: InputStore<number>
  public application: CodeDiscountApplication
  public validity: PurchaseCodeDiscountValidity
  public expirationDate: InputStore<Date>
  public buyerEmail?: InputStore<string>
  public event?: InputStore<{ value: string; label: string }>
  public album?: InputStore<{ value: string; label: string }>
  public albums: Album[]
  public albumCount: number
  public activity?: InputStore<{ value: string; label: string }>
  public isLoading: boolean
  public isLoadingAlbums: boolean
  public error: any
  private albumService: AlbumService
  private readonly userService: UserService
  private readonly codeDiscountService: CodeDiscountService
  private readonly feedService: FeedService
  constructor(private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.albumService = new AlbumService()
    this.codeDiscountService = new CodeDiscountService()
    this.feedService = new FeedService()
    this.authStore = authStore
  }

  emailRegExp = RegExp(/^[^@ \t\r\n]+@[^@ \t\r\n]+\.[a-zA-Z]{2,3}$/)

  reset() {
    this.codeDiscounts = []
    this.description = new InputStore(
      string()
        .max(50, 'La descripción no puede tener más de 50 caracteres')
        .required('La descripción es requerida')
    )
    this.discountCode = new InputStore(
      string()
        .matches(
          /^[a-zA-Z0-9-_]*$/,
          'El código no puede contener espacios ni caracteres especiales'
        )
        .required('El código es requerido')
    )
    this.discountAmount = new InputStore(
      number()
        .moreThan(0, 'El descuento tiene que ser mayor a 0')
        .lessThan(100, 'El descuento tiene que ser menor a 100')
        .positive('El descuento tiene que ser positivo')
        .required('El descuento es requerido')
    )
    this.application = CodeDiscountApplication.PURCHASE_TOTAL
    this.validity = PurchaseCodeDiscountValidity.UNLIMITED
    this.expirationDate = new InputStore(date().required('La fecha del álbum es requerida'))
    this.album = new InputStore(
      object()
        .shape({
          label: string().optional(),
          value: string().optional(),
        })
        .typeError('Álbum no válido')
        .required('Álbum no válido')
    )
    this.albums = []
    this.albumCount = 0
    this.buyerEmail = new InputStore(
      string().matches(this.emailRegExp, 'Ingrese un formato de email válido').optional()
    )
    this.event = new InputStore(
      object()
        .shape({
          label: string().optional(),
          value: string().optional(),
        })
        .typeError('Evento no válido')
        .required('Evento no válido')
    )
    this.activity = new InputStore(
      object()
        .shape({
          label: string().optional(),
          value: string().optional(),
        })
        .typeError('Deporte no válido')
        .required('Deporte no válido')
    )
    this.isLoading = false
    this.isLoadingAlbums = false
    this.error = null
  }

  resetAlbum() {
    this.album = new InputStore(
      object()
        .shape({
          label: string().optional(),
          value: string().optional(),
        })
        .typeError('Álbum no válido')
        .required('Álbum no válido')
    )
  }

  changeDiscountCode(val: string) {
    this.discountCode.setValue(val.toLowerCase().replace(/ /g, ''))
  }

  changeDescription(val: string) {
    this.description.setValue(val)
  }

  changeDiscountAmount(val: number) {
    this.discountAmount.setValue(val)
  }

  changeExpirationDate(val: Date) {
    this.expirationDate.setValue(val)
  }

  changeAlbum(val: { value: string; label: string }) {
    this.album?.setValue(val)
  }

  changeActivity(val: { value: string; label: string }) {
    this.activity?.setValue(val)
  }

  changeEvent(val: { value: string; label: string }) {
    this.event?.setValue(val)
  }

  changeBuyerEmail(val: string) {
    this.buyerEmail?.setValue(val.trim())
  }

  async fetchCodeDiscounts() {
    try {
      const response = await this.codeDiscountService.fetchCodeDiscounts(this.authStore.getToken())
      runInAction(() => {
        this.codeDiscounts = response
      })
    } catch (e) {
      console.log(e)
    }
  }

  async createCodeDiscount() {
    if (await this.validate()) {
      runInAction(() => {
        this.isLoading = true
      })
      try {
        const discount = new CreateNewCodeDiscountDto()
        discount.code = this.discountCode.value
        discount.description = this.description.value
        discount.amount = this.discountAmount.value
        discount.expirationDate = this.expirationDate.value
        discount.application = CodeDiscountApplication.PURCHASE_TOTAL
        discount.unit = CodeDiscountUnit.PERCENTAGE
        discount.usability = CodeDiscountUsability.UNLIMITED
        discount.validity = this.album?.value
          ? PurchaseCodeDiscountValidity.ALBUM
          : this.activity?.value
          ? PurchaseCodeDiscountValidity.ACTIVITY
          : this.event?.value
          ? PurchaseCodeDiscountValidity.EVENT
          : this.buyerEmail?.value
          ? PurchaseCodeDiscountValidity.BUYER
          : PurchaseCodeDiscountValidity.ALBUM_OWNER
        discount.albumId = this.album?.value ? this.album.value.value : undefined
        discount.buyerEmail = this.buyerEmail?.value ? this.buyerEmail.value : undefined

        discount.activityId = this.activity?.value ? this.activity.value.value : undefined
        discount.eventId = this.event?.value ? this.event.value.value : undefined

        await this.codeDiscountService.createCodeDiscount(this.authStore.getToken(), discount)
        runInAction(() => {
          this.isLoading = false
        })
        return discount
      } catch (e: any) {
        runInAction(() => {
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.error = 'Something went wrong, please check the provided data and try again.'
          }

          if (e?.message) {
            this.error = e.message
          }
          this.isLoading = false
        })
        return
      }
    }
  }

  async deactivateCodeDiscount(code: NewCodeDiscount) {
    this.isLoading = true
    try {
      await this.codeDiscountService.deactivateCodeDiscount(this.authStore.getToken(), code.id)
      toast.success('Discount deactivated successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      await this.fetchCodeDiscounts()
      this.isLoading = false
    } catch (e: any) {
      if (e?.message) {
        toast.error(e.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      this.isLoading = false
    }
  }

  async fetchAlbums() {
    this.isLoadingAlbums = true
    try {
      const limit = 20
      const offset = 0
      const response = await this.albumService.fetchAlbums(limit, offset, this.authStore.getToken())

      runInAction(() => {
        this.isLoading = false
        this.albums = response.albums
        this.albumCount = response.count
        this.error = null
        this.isLoadingAlbums = false
      })
      return response.albums
    } catch (e) {
      this.error = e
      this.isLoadingAlbums = false
    }
  }

  clearErrors() {
    this.discountCode.clearError()
    this.discountAmount.clearError()
    this.expirationDate.clearError()
    this.album?.clearError()
    this.buyerEmail?.clearError()
    this.event?.clearError()
    this.activity?.clearError()
    this.error = null
  }

  async validate() {
    this.clearErrors()
    let isValid = true

    if (isNil(this.discountCode.value)) {
      this.discountCode.error = true
      this.discountCode.errorMessage = 'El código es requerido'
      isValid = false
    }
    if (!isNil(this.discountCode.value)) {
      if (!(await this.discountCode.validate())) {
        this.discountCode.error = true
        this.discountCode.errorMessage = 'El código no es válido'
        isValid = false
      }
    }

    if (isNil(this.description.value)) {
      this.description.error = true
      this.description.errorMessage = 'La descripción es requerida'
      isValid = false
    }
    if (!isNil(this.description.value)) {
      if (!(await this.description.validate())) {
        isValid = false
      }
    }
    if (isNil(this.discountAmount.value)) {
      this.discountAmount.error = true
      this.discountAmount.errorMessage = 'El porcentaje de descuento es requerido'
      isValid = false
    }
    if (!isNil(this.discountAmount.value)) {
      if (!(await this.discountAmount.validate())) {
        isValid = false
      }
    }

    if (!(await this.expirationDate.validate())) {
      isValid = false
    }

    if (isNil(this.expirationDate.value)) {
      this.expirationDate.error = true
      this.expirationDate.errorMessage = 'La fecha no es válida'
      isValid = false
    }

    const now = new Date()
    const expitationDate = new Date(this.expirationDate.value)
    if (expitationDate < now) {
      this.expirationDate.error = true
      this.expirationDate.errorMessage = 'La fecha de expiración no puede ser ser anterior a hoy'
      isValid = false
    }

    if (!(await this.activity?.validate())) {
      isValid = false
    }

    if (!(await this.event?.validate())) {
      isValid = false
    }

    if (!(await this.buyerEmail?.validate())) {
      isValid = false
    }
    return isValid
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'discountCode':
          this.discountCode.setError(true, error)
          displayedError = true
          break

        case 'description':
          this.description.setError(true, error)
          displayedError = true
          break

        case 'discountAmount':
          this.discountAmount.setError(true, error)
          displayedError = true
          break

        case 'album':
          this.album?.setError(true, error)
          displayedError = true
          break

        case 'sport':
          this.activity?.setError(true, error)
          displayedError = true
          break

        case 'event':
          this.event?.setError(true, error)
          displayedError = true
          break

        case 'buyer':
          this.buyerEmail?.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }
}

export default NewCodeDiscountStore
