import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Accordion } from 'react-bootstrap'
import { Card } from 'react-bootstrap'
import Button from 'components/UI/Button'
import InputWrapper from '../../../../../../components/UI/Forms/InputWrapper'
import ServerError from '../../../../../../components/UI/ServerError'
import Cart from '../../../../../../shared/models/Cart/Cart'
import CartDiscountStore from '../../../../CartDiscountStore'
import { useTranslation } from 'react-i18next'
import CustomToggleFull from 'components/UI/CustomToggle/CustomToggleFull'

type CodeDiscountAccordionType = {
  cart: Cart
  onSubmitDiscount: (code: string) => Promise<void>
  discountError: string
  cartDiscountStore: CartDiscountStore
  isLoadingDiscount: boolean
  isLoggedIn: boolean
}

const CodeDiscountAccordion = ({
  cart,
  onSubmitDiscount,
  discountError,
  cartDiscountStore,
  isLoadingDiscount,
  isLoggedIn,
}: CodeDiscountAccordionType) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [discountApplied, setDiscountApplied] = useState(false)
  const { t } = useTranslation()
  const { discountCode } = cartDiscountStore

  const handleChangeDiscountCode = (val: string) => {
    cartDiscountStore.changeDiscountCode(val.toLowerCase())
    if (
      cart.codeDiscounts.find((codeDiscount) => {
        return codeDiscount.code === discountCode?.value
      })
    ) {
      setDiscountApplied(true)
    } else setDiscountApplied(false)
  }

  const applyDiscount = async () => {
    onSubmitDiscount(discountCode?.value).then(() => {
      if (
        cart.codeDiscounts.find((codeDiscount) => {
          return codeDiscount.code === discountCode?.value
        })
      ) {
        setDiscountApplied(true)
      }
    })
  }

  return (
    <>
      {isLoggedIn && (
        <Accordion
          defaultActiveKey="0"
          className="mb-3.5 bg-transparent rounded border-lumepic-medium_grey"
          style={{ borderWidth: '1px' }}
        >
          <Card className="border-0">
            <CustomToggleFull
              eventKey="1"
              opened={isExpanded}
              onClick={() => setIsExpanded(!isExpanded)}
              angleToggle
              extraClassNames="flex justify-between items-center py-2 px-3 cursor-pointer"
              toggleIconClasses="text-lumepic-grey text-xl mt-1"
            >
              <p className="m-0 font-medium text-lumepic-grey">{t('Add discount code')}</p>
            </CustomToggleFull>
            {isExpanded && (
              <Accordion.Collapse eventKey="1">
                <Card.Body className="p-0">
                  <div className="w-full py-3 px-3 bg-bg_details border-t border-lumepic-light_grey rounded">
                    <div className="flex justify-between items-center gap-3">
                      <InputWrapper
                        placeholder={t('Code').toString()}
                        onChange={handleChangeDiscountCode}
                        inputStore={discountCode}
                        className="text-base text-start"
                        success={discountApplied}
                      />
                      {!discountApplied && (
                        <Button
                          onClick={() => applyDiscount()}
                          btnType="TertiaryAction"
                          isLoading={isLoadingDiscount}
                        >
                          {t('Apply')}
                        </Button>
                      )}
                    </div>
                    {discountError && (
                      <div className="col-span-2">
                        <ServerError message={t(discountError).toString()} />
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            )}
          </Card>
        </Accordion>
      )}
    </>
  )
}

export default observer(CodeDiscountAccordion)
