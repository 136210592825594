import { makeAutoObservable, runInAction } from 'mobx'
import FeedService from 'services/FeedService'
import PhotographersMetricsInterface from 'services/RequestInterfaces/Feed/PhotographersMetrics.interface'

class PhotographersLandingStore {
  private readonly feedService: FeedService
  public isLoading: boolean
  public error: any
  public metrics: PhotographersMetricsInterface | null
  constructor() {
    this.reset()
    makeAutoObservable(this)
    this.feedService = new FeedService()
  }

  reset() {
    this.isLoading = false
    this.error = null
    this.metrics = null
  }

  async fetchMetrics() {
    runInAction(() => {
      this.isLoading = true
      this.error = null
    })
    try {
      const response = await this.feedService.fetchPhotographersMetrics()
      runInAction(() => {
        this.metrics = response
        this.isLoading = false
        this.error = null
      })
    } catch (error) {
      runInAction(() => {
        this.isLoading = false
        this.error = error
      })
    }
  }
}
export default PhotographersLandingStore
