import React from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import DiscountPercentage, { QuantityDiscount } from 'shared/models/QuantityDiscount'
import Album from 'shared/models/Album'
import { QuantityDiscountDisplay } from 'containers/MyAlbums/CreateOrUpdateAlbumSteps/Steps/AddDiscountModal/AddDiscountModal'
import { StepIcon } from '@mui/material'
import { Event } from 'shared/models/Event'

type DiscountProgressBarProps = {
  tiersEarned: number
  quantityDiscount: QuantityDiscount
  album?: Album
  event?: Event
}

const CustomStepIcon = (props: any) => {
  return <StepIcon icon={props.icon} />
}

const DiscountProgressBar = ({
  tiersEarned,
  quantityDiscount,
  album,
  event,
}: DiscountProgressBarProps) => {
  const steps = quantityDiscount.discountPercentages

  const calculateDiscount = (step: DiscountPercentage) => {
    if (quantityDiscount.display === QuantityDiscountDisplay.PERCENTAGE) {
      return `${step.discountPercentage}%`
    } else {
      const currency = event?.currency ?? album?.currency ?? ''
      const price =
        step.numberOfPictures *
        (event?.defaultImagePrice ?? album?.defaultImagePrice ?? 0) *
        (1 - step.discountPercentage / 100)
      return `${currency.toUpperCase()} ${price.toFixed(0)}`
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        activeStep={tiersEarned}
        alternativeLabel
        sx={{
          '& .MuiStepConnector-line': {
            borderTopWidth: '2px',
          },
          '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
            borderColor: 'success.main',
          },
          '& .MuiStepConnector-root.Mui-active.Mui-disabled .MuiStepConnector-line': {
            borderColor: 'success.main',
          },
        }}
      >
        {steps
          .sort((a, b) => a.numberOfPictures - b.numberOfPictures)
          .map((step, index) => (
            <Step
              key={index + 1}
              sx={{
                // TEXTO cuando está completado ese step
                '& .MuiStepLabel-label.Mui-completed': {
                  fontWeight: '600',
                },
                // TEXTO cuando este step es el siguiente a completar
                '& .MuiStepLabel-label.Mui-active': {
                  fontWeight: '300',
                },
                // TEXTO cuando este step NO es el siguiente a completar
                '& .MuiStepLabel-label.Mui-disabled': {
                  fontWeight: '300',
                },
              }}
            >
              <StepLabel
                StepIconComponent={CustomStepIcon}
                icon={
                  <span
                    className={`${
                      steps.indexOf(step) < tiersEarned
                        ? 'text-white bg-success'
                        : 'text-black bg-lumepic-dark_grey opacity-80'
                    } flex justify-center items-center h-6 w-6 text-sm`}
                    style={{ borderRadius: '50%' }}
                  >
                    {step.numberOfPictures}
                  </span>
                }
              >
                <span className="text-sm text-lumepic-light_black ml-1">
                  {calculateDiscount(step)}
                </span>
              </StepLabel>
            </Step>
          ))}
      </Stepper>
    </Box>
  )
}

export default observer(DiscountProgressBar)
