import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputWrapper from 'components/UI/Forms/InputWrapper'
import Modal from 'components/UI/Modal'
import { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import { observer } from 'mobx-react'
import infoIcon from '../../../../../assets/icons/v2/information.svg'
import Dropdown from 'components/UI/Dropdown'
import { Checkbox } from '@mui/material'
import { Activity } from 'shared/models/Activity'
import NewCodeDiscountStore from '../NewCodeDiscountStore'
import Album from 'shared/models/Album'
import DatePicker from 'components/UI/Forms/DatePicker'
import { formatUtcToLocaleDate } from 'shared/utility'

type CreateCodeDiscountCouponFormProps = {
  open: boolean
  handleClose: () => void
  handleSave: () => void
  store: NewCodeDiscountStore
}

const CreateCodeDiscountCouponForm = ({
  open,
  handleClose,
  handleSave,
  store,
}: CreateCodeDiscountCouponFormProps) => {
  const { t } = useTranslation()

  const [options, setOptions] = useState<undefined | Activity[] | Event[] | Album[]>([])
  const [isSelectedAllAlbums, setIsSelectedAllAlbums] = useState(false)

  const { discountCode, description, discountAmount, album, buyerEmail, expirationDate } = store

  const handleChangeDiscountCode = (val: string) => store.changeDiscountCode(val)
  const handleChangeDescription = (val: string) => store.changeDescription(val)
  const handleChangeDiscountAmount = (val: number) => store.changeDiscountAmount(Number(val))
  const handleChangeExpirationDate = (val: Date) => {
    store.changeExpirationDate(val)
  }
  const handleChangeAlbum = (val: { value: string; label: string }) => store.changeAlbum(val)
  const handleChangeBuyerEmail = (val: string) => store.changeBuyerEmail(val)

  useEffect(() => {
    store.fetchAlbums().then((result) => {
      setOptions(result)
    })
    setIsSelectedAllAlbums(false)
  }, [album])

  return (
    <Modal
      okMessage={t('Create').toString()}
      onCancel={handleClose}
      onSuccess={handleSave}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
      modalSize={isSelectedAllAlbums ? ModalSize.MEDIUM : ModalSize.LARGE}
    >
      <div className="w-full grid grid-rows-1 gap-2 px-4 pt-4">
        <div className="text-lg text-gray-800 font-extrabold text-center">
          {t('New Discount Code').toString()}
        </div>

        <div className="w-full flex gap-3">
          <div className="w-1/2">
            <InputWrapper
              label={t('Code name').toString()}
              placeholder={t('Name').toString()}
              inputStore={discountCode}
              onChange={handleChangeDiscountCode}
            />
            <div className="flex sm:items-center mt-1.5 mb-1.5">
              <img src={infoIcon} alt="info" className="w-3 h-3 mt-0.5 sm:mt-0" />
              <span className="text-lumepic-grey text-xs ml-0.5 sm:ml-1">
                {t('The code users will enter at the purchase moment')}.
              </span>
            </div>
          </div>
          <div className="w-1/2">
            <InputWrapper
              label={`${t('Amount').toString()}` + ' (%)'}
              placeholder={t('Amount').toString()}
              inputStore={discountAmount}
              onChange={handleChangeDiscountAmount}
            />
            <div className="flex sm:items-center mt-1.5 mb-1.5">
              <img src={infoIcon} alt="info" className="w-3 h-3 mt-0.5 sm:mt-0" />
              <span className="text-lumepic-grey text-xs ml-0.5 sm:ml-1">
                {t('Discount quantity percentage of the code')}.
              </span>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col md:w-full md:flex-row md:gap-3">
          <div className="w-full md:w-1/2">
            <InputWrapper
              label={t('Description').toString()}
              placeholder={t('Description').toString()}
              inputStore={description}
              onChange={handleChangeDescription}
              extraClassNames="p-2"
            />
          </div>

          <div className="w-1/2">
            <InputWrapper
              onChange={handleChangeExpirationDate}
              selected={expirationDate}
              as={DatePicker}
              label={t('Expiration date').toString()}
              inputStore={expirationDate}
              extraClassNames="mb-1"
            />
          </div>
        </div>

        <span className="pt-2">{t('Valid for')}:</span>
        <span className="text-sm text-lumepic-grey">
          {t('Choose one of the following options')}:
        </span>
        <div>
          <span>{t('All my albums')}</span>
          <Checkbox
            onChange={() => setIsSelectedAllAlbums(!isSelectedAllAlbums)}
            className="flex"
          />
        </div>

        {!isSelectedAllAlbums && (
          <>
            <div className="w-full">
              <Dropdown
                onChange={handleChangeAlbum}
                options={store.albums?.map((album) => ({
                  value: album.id,
                  label: `${album.description} - ${formatUtcToLocaleDate(album.takenDate)} - ${
                    album.activity.name
                  }`,
                }))}
                label={t('Album').toString()}
                extraStyles={{
                  input: {
                    '&::placeholder': {
                      color: '#636363',
                      fontFamily: 'Euclid Circular A',
                      fontWeight: '500',
                      opacity: 1,
                    },
                  },
                }}
                withClearIcon
              />
              <div className="flex sm:items-center mt-1.5 mb-1.5">
                <img src={infoIcon} alt="info" className="w-3 h-3 mt-0.5 sm:mt-0" />
                <span className="text-lumepic-grey text-xs ml-0.5 sm:ml-1">
                  {t('Discount code will be valid only for the album selected')}.
                </span>
              </div>
            </div>
            <div>
              {/*TODO: AlbumFormDropdown in Create code discount ??*/}
              {/*<AlbumFormDropdown*/}
              {/*  handleChangeActivity={handleChangeActivity}*/}
              {/*  store={newAlbumStore}*/}
              {/*  dropdownLabel={t('Sport')}*/}
              {/*  isLoadingActivity={newAlbumStore.isLoadingActivity}*/}
              {/*  showError={newAlbumStore.activity.errorMessage !== '' ? true : false}*/}
              {/*/>*/}
              <div className="flex sm:items-center mt-1.5 mb-1.5">
                <img src={infoIcon} alt="info" className="w-3 h-3 mt-0.5 sm:mt-0" />
                <span className="text-lumepic-grey text-xs ml-0.5 sm:ml-1">
                  {t('Discount code will be valid only for the albums of the selected sport')}.
                </span>
              </div>
            </div>
            <div>
              {/*<AlbumFormDropdown*/}
              {/*  handleChangeEvent={handleChangeEvent}*/}
              {/*  store={newAlbumStore}*/}
              {/*  dropdownLabel={t('Event')}*/}
              {/*  isLoadingActivity={newAlbumStore.isLoadingEvent}*/}
              {/*  showError={newAlbumStore.event.errorMessage !== '' ? true : false}*/}
              {/*/>*/}
              <div className="flex sm:items-center mt-1.5 mb-1.5">
                <img src={infoIcon} alt="info" className="w-3 h-3 mt-0.5 sm:mt-0" />
                <span className="text-lumepic-grey text-xs ml-0.5 sm:ml-1">
                  {t('Discount code will be valid only for the albums of the selected event')}.
                </span>
              </div>
            </div>
            <div className="pb-3">
              <InputWrapper
                placeholder={t('Buyer').toString()}
                inputStore={buyerEmail}
                onChange={handleChangeBuyerEmail}
              />
              <div className="flex sm:items-center mt-1.5 mb-1.5">
                <img src={infoIcon} alt="info" className="w-3 h-3 mt-0.5 sm:mt-0" />
                <span className="text-lumepic-grey text-xs ml-0.5 sm:ml-1">
                  {t(
                    'Discount code will be valid for the purchases from the buyer with the email entered'
                  )}
                  .
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default observer(CreateCodeDiscountCouponForm)
