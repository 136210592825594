import React from 'react'
import { MenuItem } from '@mui/material'
import CurrencyFlag from 'react-currency-flags'

type CustomCurrencyOptionProps = {
  option: string
}

const CustomCurrencyOptionComponent: React.FC<CustomCurrencyOptionProps> = ({
  option,
  ...props
}) => {
  return (
    <MenuItem {...props} key={option} value={option}>
      <CurrencyFlag currency={`${option.toUpperCase()}`} size="sm" />
      <span className="ml-1.5">{option.toUpperCase()}</span>
    </MenuItem>
  )
}

export default CustomCurrencyOptionComponent
