import React from 'react'
import { useTranslation } from 'react-i18next'

const AlbumResultListNoResult = () => {
  const { t } = useTranslation()
  return (
    <div className="bg-white border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md w-full flex justify-center">
      <span className="font-extrabold">{t('No published albums yet')}</span>
    </div>
  )
}

export default AlbumResultListNoResult
