import i18next, { InitOptions, Resource } from 'i18next'
import { initReactI18next } from 'react-i18next'

//Import all translation files
import translationEnglish from './translations/en/common.json'
import translationSpanish from './translations/es/common.json'
import LanguageDetector from 'i18next-browser-languagedetector'

const resources: Resource = {
  en: {
    translation: translationEnglish,
  },
  es: {
    translation: translationSpanish,
  },
}

const options: InitOptions = {
  resources,
  fallbackLng: 'es',
}

i18next.use(initReactI18next).use(LanguageDetector).init(options)

export default i18next
