import React from 'react'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../../../../../assets/icons/v2/logo-colored.svg'

type ReviewCardProps = {
  name: string
  role: string
  message: string
}

const ReviewCard = ({ name, role, message }: ReviewCardProps) => {
  return (
    <>
      <div className="border bg-white rounded-md w-full mt-3 p-2.5 relative">
        <FontAwesomeIcon
          icon={faQuoteRight}
          className="absolute right-5 -top-4 text-lumepic-grey text-3xl opacity-70"
        />
        <div className="flex items-center gap-2">
          <img src={logo} alt="User Image" className="w-12 h-12 sm:w-10 sm:h-10 md:w-12 md:h-12" />
          <div className="flex flex-col">
            <p className="mb-0 text-lg font-semibold">{name}</p>
            <span className="text-lumepic-grey font-medium text-sm">{role}</span>
          </div>
        </div>
        <div className="mt-1.5 p-2 text-sm font-light">{message}</div>
      </div>
    </>
  )
}

export default ReviewCard
