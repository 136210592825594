export const NEW_ALBUM = '/albums'
export const GET_ALBUMS = '/albums'
export const PHOTOGRAPH = '/photographs'
export const TOP_TEN_LIKED_ALBUMS = 'albums/top-ten-liked-albums'

export const albumDetail = (albumId: string) => {
  return `albums/${albumId}`
}

export const deleteAlbum = (albumId: string) => {
  return `albums/${albumId}`
}
export const myAlbumAlbumDetails = (albumId: string) => {
  return `/my-albums/albums/${albumId}`
}

export const deletePhoto = (photoId: string) => {
  return `photographs/${photoId}`
}

export const createCoverPhotograph = (albumId: string) => {
  return `/photographs/albums/${albumId}/cover-photograph`
}

export const likeAlbum = (albumId: string) => {
  return `albums/${albumId}/like`
}

export const starAlbum = (albumId: string) => {
  return `albums/${albumId}/star`
}

export const clearAlbumQuantityDiscount = (albumId: string) => {
  return `albums/${albumId}/quantity-discount/clear`
}

//ADMIN ROUTES
const prefix = '/albums'
export const ADMIN_ALBUMS = `${prefix}/admin`

export const suspendAlbumById = (albumId: string) => {
  return `${prefix}/${albumId}/suspend`
}
export const activateAlbumById = (albumId: string) => {
  return `${prefix}/${albumId}/activate`
}

export const deleteAlbumById = (albumId: string) => {
  return `${prefix}/${albumId}`
}

export const updateAlbumById = (albumId: string) => {
  return `${prefix}/${albumId}`
}
