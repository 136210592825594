import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import StoresContext from '../../../../providers/storesContext'
import { Photograph } from '../../../../shared/models/Photograph'
import Album from '../../../../shared/models/Album'
import Tracker from '../../../../shared/tracking'
import Spinner from 'components/UI/Spinner'

export type AddToCartButtonProps = {
  photograph: Photograph
  album: Album
}

const AddToCartButton = ({ photograph, album }: AddToCartButtonProps) => {
  const { cartStore } = useContext(StoresContext)!
  const [isInCart, setIsInCart] = useState(cartStore.alreadyInCart(photograph))

  useEffect(() => {
    setIsInCart(cartStore.alreadyInCart(photograph))
  })

  const addToCart = () => {
    Tracker.addToCart(photograph)
    cartStore?.addPhotograph(photograph, album)
  }

  const removeFromCart = () => {
    cartStore.removePhotograph(photograph.id)
  }

  let buttonContent = (
    <div>
      <div className="w-5">
        <FontAwesomeIcon icon={faCartPlus} className="text-lg" />
      </div>
    </div>
  )
  if (isInCart) {
    buttonContent = (
      <div>
        <div className="w-5">
          <FontAwesomeIcon className="text-warning text-lg" icon={faTrashAlt} />
        </div>
      </div>
    )
  }

  if (cartStore.isLoading) {
    return <Spinner noStyles size={20} />
  }

  return (
    <button
      className="bg-transparent border-0 outline-0 cursor-pointer"
      onClick={isInCart ? removeFromCart : addToCart}
    >
      {buttonContent}
    </button>
  )
}

export default observer(AddToCartButton)
