import { Purchase } from '../models/Purchase'
import ReactGA from 'react-ga4'
import { GAPurchaseItem, GAPurchaseParameters } from './interfaces/GA4/purchase'
import { GAGenerateLeadParameters } from './interfaces/GA4/generate_lead'
import { Photograph } from '../models/Photograph'
import { GAAddToCartParameters } from './interfaces/GA4/add_to_cart'
import { GABeginCheckoutParams } from './interfaces/GA4/begin_checkout'
import Cart from 'shared/models/Cart/Cart'
import { GAAddPaymentInformationParams } from './interfaces/GA4/add_payment_information'
import { GARegisterParams } from './interfaces/GA4/register'
import { GALoginParams } from './interfaces/GA4/login'
import Album from '../models/Album'
import { GAViewItemListParams } from './interfaces/GA4/view_item_list'
import { Currency } from '../util/Currency'
import { isNil } from 'lodash'
import { CartLine, CartLineType } from '../models/Cart/CartLine/CartLine'
import { PackageCartLine } from '../models/Cart/CartLine/PackageCartLine'
import { PhotographCartLine } from '../models/Cart/CartLine/PhotographCartLine'
import { BurstCartLine } from '../models/Cart/CartLine/BurstCartLine'

// https://support.google.com/analytics/answer/9267735
export enum GAEvents {
  Login = 'login', // a user logs in
  SignUp = 'sign_up',
  Search = 'search', // a user searches your content
  Share = 'share', // a user shares content
  AddPaymentInfo = 'add_payment_info', // a user submits their payment information
  AddToCart = 'add_to_cart', // a user adds items to cart
  BeginCheckout = 'begin_checkout', // a user begins checkout
  GenerateLead = 'generate_lead', // a user submits a form or a request for information
  Purchase = 'purchase', // a user completes a purchase
  RemoveFromCart = 'remove_from_cart', // a user removes items from a cart
  ViewCart = 'view_cart', // a user views their cart
  ViewItem = 'view_item', // a user removes items from a cart
  ViewItemList = 'view_item_list', // a user sees a list of items/offerings
}

export enum AuthenticationMethods {
  WebFormLogin = 'Web Form Login',
  Google = 'Web Form Login',
}

const photographToGAItem = (photograph: Photograph, index?: number): GAPurchaseItem => {
  return {
    item_id: photograph.id,
    item_name: `Photograph ${photograph.id}`,
    index,
    item_category: `Album ${photograph.albumId}`,
    price: photograph.price,
    quantity: 1,
  }
}

const cartLineToGaItem = (cartLine: CartLine, index?: number): GAPurchaseItem => {
  switch (cartLine.type) {
    case CartLineType.Package: {
      const packageLine = cartLine as PackageCartLine
      const eventOrAlbumId = packageLine.event ? packageLine.event.id : packageLine.albums[0].id
      const itemId = `package_${packageLine.tagId}_${eventOrAlbumId}`
      const itemCategory = packageLine.event
        ? `Event ${packageLine.event.id}`
        : `Album ${packageLine.albums[0].id}`
      return {
        item_id: itemId,
        item_name: `Package ${packageLine.packageType}`,
        index,
        item_category: itemCategory,
        price: packageLine.totalPrice,
        quantity: packageLine.quantityOfPhotographs,
      }
    }
    case CartLineType.Photograph: {
      const photographLine = cartLine as PhotographCartLine
      return {
        item_id: photographLine.photograph.id,
        item_name: `Photograph ${photographLine.photograph.id}`,
        index,
        item_category: `Album ${photographLine.album.id}`,
        price: photographLine.totalPrice,
        quantity: 1,
      }
    }
    case CartLineType.Burst: {
      const burstLine = cartLine as BurstCartLine
      const itemId = burstLine.getKey()
      const itemCategory = burstLine.event
        ? `Event ${burstLine.event.id}`
        : `Album ${burstLine.albums[0].id}`
      return {
        item_id: itemId,
        item_name: `Burst Package`,
        index,
        item_category: itemCategory,
        price: burstLine.totalPrice,
        quantity: burstLine.quantityOfPhotographs,
      }
    }
  }
}

export default class GATracker {
  public static pageView(page: string, title): void {
    ReactGA.send({ hitType: 'pageview', page, title })
  }

  public static login(): void {
    const parameters: GALoginParams = {
      method: AuthenticationMethods.WebFormLogin,
    }
    ReactGA.event(GAEvents.Login, parameters)
  }

  public static register(): void {
    const parameters: GARegisterParams = {
      method: AuthenticationMethods.WebFormLogin,
    }
    ReactGA.event(GAEvents.SignUp, parameters)
  }

  public static trackPurchase(purchase: Purchase): void {
    const items: GAPurchaseItem[] = purchase.photographs.map(photographToGAItem)
    const parameters: GAPurchaseParameters = {
      currency: Currency.UYU,
      transaction_id: purchase.id,
      value: purchase.totalPrice,
      items,
    }
    ReactGA.event(GAEvents.Purchase, parameters)
  }

  public static trackLead(value?: number, currency?: Currency): void {
    // TODO: Lead value?
    const defaultLeadValue = 3
    const defaultLeadCurrency = Currency.USD
    const parameters: GAGenerateLeadParameters = {
      currency: currency || defaultLeadCurrency,
      value: value || defaultLeadValue,
    }
    ReactGA.event(GAEvents.GenerateLead, parameters)
  }

  public static addToCart(photograph: Photograph): void {
    const item: GAPurchaseItem = photographToGAItem(photograph)
    const parameters: GAAddToCartParameters = {
      currency: Currency.UYU,
      value: photograph.price,
      items: [item],
    }
    ReactGA.event(GAEvents.AddToCart, parameters)
  }

  public static beginCheckout(cart: Cart): void {
    const items = cart.lines.map(cartLineToGaItem)
    const parameters: GABeginCheckoutParams = {
      currency: Currency.UYU,
      value: cart.subTotal,
      items,
    }
    ReactGA.event(GAEvents.BeginCheckout, parameters)
  }

  public static addPaymentInformation(cart: Cart): void {
    const items = cart.lines.map((cartLine) => cartLineToGaItem(cartLine))
    const parameters: GAAddPaymentInformationParams = {
      currency: Currency.UYU,
      value: cart.subTotal,
      items: items.flat(),
    }
    ReactGA.event(GAEvents.AddPaymentInfo, parameters)
  }

  public static addPackagePaymentInformation(photographs, album): void {
    const items = photographs.map(photographToGAItem)
    const parameters: GAAddPaymentInformationParams = {
      currency: Currency.UYU,
      value: album.defaultPackagePrice,
      items,
    }
    ReactGA.event(GAEvents.AddPaymentInfo, parameters)
  }

  public static viewItemList(album: Album, photographs: Photograph[]) {
    const parameters: GAViewItemListParams = {
      item_list_id: album.id,
      items: photographs.map(photographToGAItem),
    }
    ReactGA.event(GAEvents.ViewItemList, parameters)
  }

  public static downloadFreePhotograph(
    photographId: string,
    albumId?: string,
    eventId?: string,
    landingPath?: string
  ): void {
    const eventParams: any = {
      category: eventId ? 'event' : 'album',
      label: 'ph_'.concat(photographId),
    }
    if (!isNil(albumId)) {
      eventParams.album_id = albumId
    }
    if (!isNil(eventId)) {
      eventParams.event_id = eventId
    }
    if (!isNil(landingPath)) {
      eventParams.landingPath = landingPath
    }
    ReactGA.event('download_free_photograph', eventParams)
  }

  public static filterByTag(
    tagKey?: string,
    userId?: string | null,
    albumId?: string,
    eventId?: string
  ): void {
    const params: any = {
      tagKey: !isNil(tagKey) ? tagKey : null,
      user: !isNil(userId) ? userId : null,
      album: !isNil(albumId) ? albumId : null,
      event: !isNil(eventId) ? eventId : null,
    }
    ReactGA.event('filterByTag', params)
  }

  public static filterByFaceRecognition(
    userId?: string | null,
    albumId?: string | null,
    eventId?: string | null
  ): void {
    const params: any = {
      user: !isNil(userId) ? userId : null,
      album: !isNil(albumId) ? albumId : null,
      event: !isNil(eventId) ? eventId : null,
    }
    ReactGA.event('filterByFaceRecognition', params)
  }

  public static goToPhotographersRegister(): void {
    ReactGA.event('goToPhotographersRegister')
  }
}
