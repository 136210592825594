const data = [
  {
    title: 'Introducción',
    content: [
      'Bienvenido a Lumepic. Los siguientes "Términos & Condiciones" regulan el uso de Lumepic (el "Servicio" o la "Compañía") y se aplican cuando una persona (el "Usuario") accede o utiliza el Servicio a través de la plataforma ubicada en www.lumepic.com (el "Sitio")',
      'Al acceder o utilizar el Servicio, la persona declara que está de acuerdo con estos Términos & Condiciones y se convierte en Usuario.',
      'Si no está de acuerdo con estos Términos & Condiciones, no debe acceder ni utilizar el Servicio.',
    ],
  },
  {
    title: 'Sobre el servicio',
    content: [
      'Lumepic es una plataforma virtual y un mercado para Fotógrafos (los "Fotógrafos") y Deportistas (los "Deportistas") de todo el mundo.',
      'La plataforma ofrece un espacio para que los fotógrafos puedan compartir y vender sus imágenes.',
      'Las fotografías publicadas y comercializadas en Lumepic son propiedad exclusiva de los fotógrafos y en adelante se denominan "Trabajos Intelectuales".',
      'Los usuarios deben cargar al Sitio los Trabajos Intelectuales con el fin de venderlas a otros usuarios para su uso personal, lo que incluye publicar las fotos en las redes sociales. Los compradores no deben usar las fotografías con fines comerciales.',
      'La plataforma Lumepic es de uso gratuito y obtiene sus beneficios cobrando una comisión del 20% sobre las ventas de los fotógrafos, siempre que no se especifique otro monto.',
    ],
  },
  {
    title: 'Registro de normas de conducta del usuario y uso del servicio',
    content: [
      'Al suscribirse al Servicio, el usuario creará una cuenta personalizada, que incluye un nombre de usuario y contraseña únicos para acceder al Servicio y recibir mensajes de la Compañía.',
      'El Usuario acepta notificar de inmediato el acceso no autorizado a su contraseña y/o cuenta. La Compañía no será responsable de ninguna pérdida o daño resultante del uso no autorizado de su nombre, contraseña y/o cuenta.',
    ],
  },
  {
    title: 'Restricciones de Uso',
    content: [
      'Su permiso para usar este Sitio está sujeto a las siguientes restricciones de uso y conducta: El Usuario acepta bajo ninguna circunstancia:',
      'Publicar cualquier material que no esté relacionado con deporte o que sea abusivo, amenazante, obsceno, difamatorio, racial, sexual, religioso u objetable y ofensivo;',
      'Usar el Servicio para cualquier propósito ilegal o para la promoción de actividades ilegales;',
      'Intentar hostigar, abusar o dañar a otra persona o grupo;',
      'Usar la cuenta de otro usuario sin permiso;',
      'Proporcionar información falsa o inexacta al registrarse;',
      'Interferir o intentar interferir con el correcto funcionamiento del Servicio;',
      'Hacer un uso automático del sistema, o tomar cualquier medida que creamos que imponga o potencialmente imponga una carga irrazonable o desproporcionada en nuestros servidores o infraestructura de red; o',
      'Ignorar los encabezados de exclusión de robots u otras medidas que tomemos para restringir el acceso al Servicio o utilice cualquier software, tecnología o dispositivo para dañar, arañar o rastrear el Servicio, o para recopilar y manipular datos.',
    ],
  },
  {
    title: 'Restricciones de Conducta',
    content: [
      'Cuando el Usuario crea su propia cuenta personalizada, es el único responsable del uso del Servicio. La Compañía se reserva el derecho de eliminarlo del Servicio a su discreción.',
      'Al usar el Servicio, el Usuario acepta que es el único responsable de su cuenta y de la actividad que ocurre cuando inicia sesión o usa su cuenta.',
      'El Usuario declara que Lumepic tiene el derecho de determinar si su uso del Servicio es apropiado y acorde a estos Términos & Condiciones y de cancelar su cuenta con o sin previo aviso.',
      'El Usuario comprende y acepta que cualquier responsabilidad, pérdida o daño que ocurra como resultado del uso del Servicio es su exclusiva responsabilidad. La Compañía no se hace responsable del mal uso del Servicio.',
      'La Compañía no predice ni supervisa todos los usos, ni puede hacerlo. Sin embargo, a su propia discreción, de manera manual o mediante el uso de tecnología, puede monitorear y/o registrar las interacciones del Usuario con el Servicio.',
    ],
  },
  {
    title: 'Trabajos Intelectuales',
    content: [
      'El Trabajo Intelectual se refiere a cualquier material fotográfico que el Usuario envíe/cargue al Sitio.',
      'El Usuario le otorga a la Compañía una licencia mundial, irrevocable, no exclusiva y libre de regalías para usar, reproducir, adaptar y publicar su Trabajo Intelectual con fines promocionales. También le otorga a la Compañía el derecho de sublicenciar estos derechos a sus socios comerciales con el fin de obtener tráfico hacia el Sitio.',
      'La Compañía es completamente libre de aplicar promociones y descuentos, de cualquier valor, para promover mayores ventas dentro de la plataforma. Ya sea que estos descuentos para acciones promocionales en fechas comerciales y estacionales, un aumento en las fotos vendidas por carrito o cualquier acción que la Compañía considere necesaria para la promoción de ventas dentro del Sitio y la Plataforma, con la transferencia total del descuento, o no, al Usuario propietario del Trabajo Intelectual.',
      'El Trabajo Intelectual no debe ser ilícito o ilegal, no debe infringir los derechos legales de terceros y no debe ser capaz de dar lugar a acciones legales contra el Usuario o la Compañía o un tercero (en cada caso según la ley aplicable).',
      'El Usuario no debe enviar ningún Trabajo Intelectual al Sitio que sea o haya sido objeto de una demanda amenazada, real o similar.',
      'La Compañía se reserva el derecho de editar o eliminar cualquier material enviado al Sitio, o almacenado en sus servidores, o alojado o publicado en el Sitio.',
    ],
  },
  {
    title: 'Aviso legal de Contenido',
    content: [
      'La Compañía no garantiza la precisión, integridad o utilidad de cualquier información sobre el Servicio y no adopta ni respalda, ni es responsable de la exactitud o confiabilidad de cualquier opinión, consejo o declaración hecha por otras partes que no sean parte de la Compañía. En ningún caso, la Compañía será responsable de pérdidas o daños que resulten de la confianza de alguien en la información sobre el Servicio.',
      'La Compañía se reserva el derecho, pero no tiene la obligación, de monitorear el uso del Servicio o de limitar o denegar el acceso de un usuario al Servicio o de tomar otras medidas apropiadas si un usuario viola estos Términos de Servicio o participa en cualquier actividad que viole los Derechos de cualquier persona o entidad o que se considere ilegal, ofensivo, abusivo, dañino o malicioso.',
      'El uso no autorizado puede dar lugar a acciones penales y/o civiles. Si se entera del mal uso de nuestro Servicio, comuníquese con info@lumepic.com.',
    ],
  },
  {
    title: 'Enlaces a otros sitios y/o materiales',
    content: [
      'Como parte del Servicio, la Compañía puede proporcionar enlaces convenientes a sitios web de terceros ("Sitios de terceros").',
      'Estos enlaces se proporcionan como cortesía a los suscriptores del Servicio. La Compañía no tiene control sobre Sitios de Terceros y Aplicaciones, Software o Contenido de Terceros o las promociones, materiales, información, bienes o servicios disponibles en esos Sitios de Terceros o Aplicaciones, Software o Contenido de Terceros.',
      'Dichos Sitios de Terceros y Aplicaciones, Software o Contenido de Terceros no son investigados, monitoreados o verificados por la precisión, idoneidad o integridad de la Compañía y la Compañía no es responsable de ningún Sitio de Terceros al que se acceda a través del Sitio o cualquier Aplicación de Terceros, Software o Contenido publicado, disponible a través del Sitio o instalado desde este, incluido el contenido, la precisión, el delito, las opiniones, la confiabilidad, las prácticas de privacidad u otras políticas o contenidos en los Sitios de terceros o las Aplicaciones, el Software o el Contenido de terceros.',
      'La inclusión, el enlace o el permiso para usar o instalar cualquier sitio de terceros o cualquier aplicación, software o contenido de terceros no implica la aprobación o el respaldo de la empresa.',
      'Si decide abandonar el Sitio y acceder a los Sitios de terceros o usar o instalar Aplicaciones, Software o Contenido de terceros, lo hace bajo su propio riesgo y debe tener en cuenta que estos Términos & Condiciones ya no rigen.',
      'El Usuario debe revisar los términos y políticas aplicables, incluidas las prácticas de privacidad y recopilación de datos, de cualquier sitio web al que navegue desde el Sitio o relacionado con cualquier aplicación que use o instale desde el Sitio.',
    ],
  },
  {
    title: 'Cierre de cuentas infringidas reincidentes',
    content: [
      'La Compañía respeta los derechos de propiedad intelectual de terceros y solicita que los usuarios hagan lo mismo. La Compañía ha adoptado e implementado una política que prevé la terminación, en circunstancias apropiadas, de los usuarios del Servicio que son infractores reincidentes pudiendo la Compañía rescindir el acceso de los Usuarios.',
    ],
  },
  {
    title: 'Propiedad Intelectual',
    content: [
      'El Usuario reconoce y acepta que el Servicio es propietario de todos los derechos de propiedad intelectual de cualquier tipo relacionados con el Servicio, incluidos los derechos de autor, marcas comerciales y otros derechos de propiedad. Otros productos y nombres de compañías mencionados en el Servicio pueden ser marcas comerciales de sus respectivos propietarios. El Servicio se reserva todos los derechos que no se le otorgan expresamente en virtud de estos Términos & Condiciones.',
    ],
  },
  {
    title: 'Comunicaciones a través del correo electrónico',
    content: [
      'Las comunicaciones realizadas a través del sistema de correo electrónico y los mensajes del Servicio no constituirán un aviso legal para la Compañía o cualquiera de sus directores, empleados, agentes o representantes en cualquier situación en la que se requiera notificación a la Compañía por contrato o cualquier ley o regulación.',
    ],
  },
  {
    title: 'Consentimiento del usuario para recibir comunicaciones en forma electrónica',
    content: [
      'Para fines contractuales, el Usuario acepta recibir comunicaciones de la Compañía en forma electrónica a través de la dirección de correo electrónico que registró; y acepta que todos los Términos & Condiciones, contratos, avisos, divulgaciones y otras comunicaciones que la Compañía le proporciona electrónicamente satisfacen cualquier requisito legal que tales comunicaciones cumplirían si fuera por escrito. Lo anterior no afecta sus derechos de no renuncia.',
      'El Servicio también puede utilizar la dirección de correo electrónico del Usuario para enviar otros mensajes, incluida información sobre la Compañía y ofertas especiales.',
      'El Usuario puede deshabilitar este correo electrónico cambiando la configuración de su cuenta o enviando un correo electrónico a info@lumepic.com.',
      'La exclusión puede evitar que reciba mensajes sobre la Compañía u ofertas especiales.',
    ],
  },
  {
    title: 'Descargo de responsabilidad de garantía',
    content: [
      'El Servicio se proporciona sin garantía de ningún tipo.',
      'Sin limitar lo anterior, la compañía renuncia expresamente a todas las garantías, ya sean expresas, implícitas o legales, relacionadas con el Servicio, incluidas, entre otras, cualquier garantía de comerciabilidad, idoneidad para un propósito particular, título, seguridad, precisión y no infracción.',
      'Sin limitar lo previsto, la Compañía no garantiza ni declara que el acceso u operación del servicio será ininterrumpido o estará libre de errores. El Usuario asume toda la responsabilidad y el riesgo de pérdida resultante de su transferencia y/o uso de archivos, información, contenido u otro material obtenido del servicio.',
    ],
  },
  {
    title: 'Limitación de daños; Liberación',
    content: [
      'En la medida permitida por la ley aplicable, en ningún caso la Compañía, sus afiliados, directores o empleados, o sus licenciantes o socios, serán responsables por cualquier pérdida de ganancias, uso o datos, o por cualquier incidente, indirecto, especial, consecuente o ejemplar.',
    ],
  },
  {
    title: 'Modificación de los Términos de Uso',
    content: [
      'La Compañía puede cambiar los Términos & Condiciones en cualquier momento. Es responsabilidad del Usuario verificar el Sitio para ver cualquier cambio en estos Términos & Condiciones. Sin embargo, se notificará sobre cambios materiales en los términos, publicando un aviso en el Sitio y/o enviando un correo electrónico a la dirección de correo electrónico que proporcionada por el Usuario en el momento del registro. Por este motivo adicional, el Usuario debe mantener actualizada su información de contacto y perfil. Cualquier cambio a estos Términos & Condiciones (excepto según lo establecido en esta cláusula) o la renuncia a los derechos de la Compañía en este documento no serán válidos o efectivos, excepto en un contrato escrito con la firma física de un director de la Compañía.',
    ],
  },
  {
    title: 'Términos Generales',
    content: [
      'Si se determina que alguna parte de este Acuerdo es inválida o inaplicable, esa parte del Acuerdo se interpretará de conformidad con la ley aplicable. Las porciones restantes permanecerán en pleno vigor y efecto. Cualquier incumplimiento por parte de la Compañía de hacer cumplir alguna disposición de estos Términos & Condiciones no se considerará una renuncia al derecho de la Compañía de hacer cumplir dicha disposición. Los derechos de la Compañía bajo estos Términos & Condiciones sobrevivirán a cualquier terminación del Servicio. El Usuario acepta que cualquier causa de acción relacionada o que surja de su relación con la Compañía debe comenzar dentro de un (1) año después de que se haya acumulado la causa de acción. De lo contrario, esta causa de acción está permanentemente prohibida. Estos Términos & Condiciones y su uso del Sitio se rigen por las leyes de la República Oriental del Uruguay. La Compañía puede asignar o delegar estos Términos & Condiciones y/o la Política de privacidad de la Compañía, en su totalidad o en parte, a cualquier persona o entidad, en cualquier momento, con o sin su consentimiento. El Usuario no puede asignar ni delegar ningún derecho u obligación bajo los Términos & Condiciones o la Política de privacidad sin el consentimiento previo por escrito de la Compañía, y cualquier atribución y delegación no autorizada por el Usuario es nula. El Usuario reconoce que ha leído estos Términos & Condiciones, comprende los mismos y estará sujeto a ellos. Además, el Usuario reconoce que estos Términos & Condiciones junto con la Política de Privacidad representan la declaración completa y exclusiva del acuerdo entre él y la Compañía y que reemplaza cualquier propuesta o acuerdo anterior, oral o por escrito, y cualquier otra comunicación entre ellos con respecto al tema de este acuerdo.',
    ],
  },
]

export default data
