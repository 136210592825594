import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import './index.css'
import App from './App'
import './i18n'
import AuthStore from './stores/AuthStore'
import StoresContext from './providers/storesContext'
import { observer } from 'mobx-react'
import AuthService from './services/AuthService'
import LocationService from './services/LocationService'
import LocationStore from './stores/LocationStore'
import CartStore from './stores/CartStore'
import PurchaseService from './services/PurchaseService'
import DiscountStore from './stores/DiscountStore'
import CartService from './services/CartService'
import packageJson from '../package.json'
import DiscountService from './services/DiscountService'
import { GoogleOAuthProvider } from '@react-oauth/google'
import ConfigService, { ConfigKeys } from './config'
import ActivityService from './services/ActivityService'
import ActivityStore from './stores/ActivityStore'
import EventStore from './stores/EventStore'
import { EventService } from './services/EventService'
import FeatureFlagsStore from 'stores/FeatureFlagsStore'
import FeatureFlagService from 'services/FeatureFlagService'
import FeedService from 'services/FeedService'

const environment = process.env.REACT_APP_ENV || 'local'

const verifyLocalStorageVersion = () => {
  const version = localStorage.getItem('version')
  if (version !== packageJson.version) {
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name)
        })
      })

      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload()
    }

    localStorage.clear()
    localStorage.setItem('version', packageJson.version)
  }
}
const initSentry = () => {
  if (environment !== 'local') {
    Sentry.init({
      dsn: 'https://982df71f3ae54668968b7ea623b7a9fb@o1262248.ingest.sentry.io/6441044', //TODO Transfer this to constants/env
      integrations: [new BrowserTracing()],
      environment: environment,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      // tracesSampleRate: 1.0,
    })
  }
}

const initStores = () => {
  const authService = new AuthService()
  const locationService = new LocationService()
  const activityService = new ActivityService()
  const eventService = new EventService()
  const purchaseService = new PurchaseService()
  const discountService = new DiscountService()
  const cartService = new CartService()
  const featureFlagsService = new FeatureFlagService()
  const feedService = new FeedService()

  const authStore = new AuthStore(authService)
  const locationStore = new LocationStore(locationService, authStore)
  const activityStore = new ActivityStore(activityService, authStore)
  const eventStore = new EventStore(eventService, locationStore, authStore)
  const discountStore = new DiscountStore()
  const cartStore = new CartStore(
    authStore,
    purchaseService,
    discountService,
    cartService,
    feedService
  )
  const featureFlagsStore = new FeatureFlagsStore(featureFlagsService)

  return {
    authStore,
    locationStore,
    cartStore,
    discountStore,
    activityStore,
    eventStore,
    featureFlagsStore,
  }
}

verifyLocalStorageVersion()
initSentry()
const sharedStores = initStores()

const Main = observer(() => {
  return (
    <GoogleOAuthProvider clientId={ConfigService.getValue(ConfigKeys.GOOGLE_CLIENT_ID)}>
      <StoresContext.Provider value={sharedStores}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </StoresContext.Provider>
    </GoogleOAuthProvider>
  )
})
const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(<Main />)
