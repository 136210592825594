import React from 'react'
import { TwitterShareButton as ReactShareTwitterButton, XIcon } from 'react-share'

type XShareButtonProps = {
  url: string
  iconSize: number
}

const XShareButton = (props: XShareButtonProps) => (
  <ReactShareTwitterButton url={props.url}>
    <XIcon size={props.iconSize} round={true} enableBackground="true" />
  </ReactShareTwitterButton>
)

export default XShareButton
