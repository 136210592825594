import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/UI/Button'
import { scrollToElement } from 'containers/PhotographersLanding/utils/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Tracker from 'shared/tracking'

const PhotographersPricing = () => {
  const { t } = useTranslation()
  return (
    <div className="bg-gradient-to-b from-lumepic-light_black to-lumepic-black py-24 sm:py-44 px-2">
      <div className="sm:container mx-auto w-full flex flex-col items-center justify-center">
        <h3 className="text-2xl md:text-3xl lg:text-4xl text-white font-bold mb-10">
          {t('Price')}
        </h3>
        <div className="rounded-lg bg-lumepic-medium_grey w-full md:w-1/2 p-2">
          <div className="rounded-lg bg-white p-4 flex flex-col gap-2.5">
            <div className="rounded-lg bg-lumepic-medium_grey p-3 flex justify-between items-stretch">
              <div className="flex flex-col">
                <span>{t('Monthly')}</span>
                <div className="flex gap-2 items-end">
                  <span className="text-4xl sm:text-5xl lg:text-6xl text-lumepic-light_black font-bold">
                    $0
                  </span>
                  <span className="mb-1 text-lumepic-grey">{`/ ${t('month')}`}</span>
                </div>
              </div>
              <div className="self-stretch w-0.5 bg-lumepic-dark_grey my-2 opacity-80" />
              <div className="flex flex-col">
                <span>{t('Variable')}</span>
                <div className="flex gap-2 items-end">
                  <span className="text-4xl sm:text-5xl lg:text-6xl text-lumepic-light_black font-bold">
                    20%
                  </span>
                  <span className="mb-1 text-lumepic-grey">{`/ ${t('sale')}`}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-2 gap-0.5">
              <p className="text-lumepic-grey text-sm">
                {t('Lumepic will be free until you have sales.')}
              </p>
            </div>
            <div className="flex flex-col mt-5">
              <p className="text-xl text-lumepic-light_black font-medium">
                {t('What does it include?')}
              </p>
              <ul className="list-none mt-3 flex flex-col gap-1">
                <li className="flex items-center gap-1.5 text-black font-light">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-lumepic-success_dark_green"
                  />
                  {t('Unlimited photo uploads')}
                </li>
                <li className="flex items-center gap-1.5 text-black font-light">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-lumepic-success_dark_green"
                  />
                  {t('AI for face and text recognition')}
                </li>
                <li className="flex items-center gap-1.5 text-black font-light">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-lumepic-success_dark_green"
                  />
                  {t('Stripe payment provider')}
                </li>
              </ul>
            </div>
            <div className="w-full mt-8">
              <Button
                btnType="PrimaryAction"
                onClick={() => {
                  Tracker.goToPhotographersRegister()
                  scrollToElement('photographer-register')
                }}
                extraStyle="w-full"
              >
                {t('Start selling now!')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PhotographersPricing
