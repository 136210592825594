import { makeAutoObservable, runInAction } from 'mobx'
import { FeatureFlag } from 'shared/models/FeatureFlag'
import FeatureFlagService from 'services/FeatureFlagService'

class FeatureFlagsStore {
  private error: any
  public isLoading: boolean
  public featureFlags: FeatureFlag[]
  public photographMinimumPriceForCurrency: number | null

  constructor(private readonly featureFlagService: FeatureFlagService) {
    this.error = null
    this.isLoading = false
    this.featureFlags = []
    this.photographMinimumPriceForCurrency = 1
    makeAutoObservable(this)
    this.fetchFeatureFlags()
  }

  async fetchFeatureFlags(): Promise<FeatureFlag[]> {
    if (this.featureFlags.length > 0) {
      return this.featureFlags
    } else {
      runInAction(() => {
        this.isLoading = true
      })
      try {
        const response = await this.featureFlagService.fetchFeatureFlags()
        runInAction(() => {
          this.featureFlags = response
          this.isLoading = false
        })
        return this.featureFlags
      } catch (e: any) {
        this.error = e?.response
        this.isLoading = false
        return []
      }
    }
  }

  getFeatureFlagValue(featureFlagKey: string): string | boolean | number | undefined {
    if (!featureFlagKey) {
      return undefined
    }
    return this.featureFlags.find((featureFlag) => featureFlag.key === featureFlagKey)?.value
  }

  async setMinimumPhotographPrice(currencyCode: string): Promise<number | null> {
    try {
      const response = await this.featureFlagService.getCurrencyMinimumPhotographPrice(currencyCode)
      runInAction(() => {
        this.photographMinimumPriceForCurrency = response
        this.isLoading = false
      })
      return this.photographMinimumPriceForCurrency
    } catch (e: any) {
      this.error = e?.response
      this.isLoading = false
      return 1
    }
  }
}

export default FeatureFlagsStore
