import axios from './axios'
import * as paths from '../services/Paths'
import { getCountryCodeFromLatLng, parseAxiosErrorResponse } from '../shared/utility'
import { AxiosResponse } from 'axios'
import { Location } from '../shared/models/Location'
import { Country } from 'shared/models/Country'
import { Countries } from '../shared/util/countries'
import RequestLocationInterface from './RequestInterfaces/RequestLocation/RequestLocation.interface'
import { isNil } from 'lodash'
import ConfigService, { ConfigKeys } from 'config'
import IPApiResponseInterface from './RequestInterfaces/Location/IPApiResponseInterface'

export const DefaultCountry = {
  code: 'UY',
  name: 'Uruguay',
  prefix: '+598',
}

export const DefaultLocation = {
  latitude: -34.9011,
  longitude: -56.1645,
}

class LocationService {
  async fetchLocations(countryCode: string): Promise<Location[]> {
    return axios
      .get(paths.getLocationsByCountryCode(countryCode))
      .then((response: AxiosResponse) => response.data.map((loc) => Location.init(loc)))
      .catch(parseAxiosErrorResponse)
  }

  fetchCountries(): Promise<Country[]> {
    return axios
      .get(paths.GET_COUNTRIES)
      .then((response: AxiosResponse) => response.data.map((country) => Country.init(country)))
      .catch(parseAxiosErrorResponse)
  }

  requestNewLocation(data: RequestLocationInterface, token: string): Promise<Location> {
    return axios
      .post(paths.REQUEST_LOCATION, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => Location.init(response.data))
      .catch(parseAxiosErrorResponse)
  }

  //ADMIN REQUESTS

  fetchAdminLocations(token: string, page: number) {
    return axios
      .get(`${paths.ADMIN_LOCATIONS}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => {
        return {
          spots: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  saveLocation(token: string, locationData: Location) {
    return axios
      .post(paths.GET_LOCATIONS, locationData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  editLocation(token: string, locationData: Location) {
    return axios
      .put(paths.updateLocationById(locationData.id), locationData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  deleteLocation(locationId: string, token: string) {
    return axios
      .delete(paths.deleteLocationById(locationId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  async getUserLocationData(): Promise<{ country: Country; latitude: number; longitude: number }> {
    const cachedData = this.getCachedLocationData()
    if (cachedData) return cachedData

    const ipApiData = await this.getLocationFromIpApi()
    if (ipApiData) return ipApiData

    const navigatorData = await this.getLocationFromNavigator()
    if (navigatorData) return navigatorData

    return this.getDefaultLocationData()
  }

  private getCachedLocationData(): {
    country: Country
    latitude: number
    longitude: number
  } | null {
    const cachedCountryCode = localStorage.getItem('userCountryCode')
    if (cachedCountryCode) {
      const country = Countries.find((c) => c.code === cachedCountryCode) || DefaultCountry
      return {
        country: Country.init(country),
        latitude: DefaultLocation.latitude,
        longitude: DefaultLocation.longitude,
      }
    }
    return null
  }

  private async getLocationFromIpApi(): Promise<{
    country: Country
    latitude: number
    longitude: number
  } | null> {
    try {
      const url = ConfigService.getValue(ConfigKeys.IP_API_URL)
      const response = await fetch(url)
      const data: IPApiResponseInterface = await response.json()
      const country =
        Countries.find((country) => country.code === data.countryCode) || DefaultCountry
      const result = {
        country: Country.init(country),
        latitude: data.lat,
        longitude: data.lon,
      }
      this.cacheLocationData(result.country.code)
      return result
    } catch (error) {
      console.error('Error trying to fetch user country from IP API', error)
      return null
    }
  }

  private async getLocationFromNavigator(): Promise<{
    country: Country
    latitude: number
    longitude: number
  } | null> {
    try {
      const response = await getCountryCodeFromLatLng()
      if (!isNil(response)) {
        const country =
          Countries.find((country) => country.code === response.countryCode) || DefaultCountry
        const data = {
          country: Country.init(country),
          latitude: response.lat,
          longitude: response.lng,
        }
        this.cacheLocationData(data.country.code)
        return data
      }
    } catch (e) {
      console.error('Error trying to fetch user location from navigator', e)
    }
    return null
  }

  private getDefaultLocationData(): { country: Country; latitude: number; longitude: number } {
    return {
      country: Country.init(DefaultCountry),
      latitude: DefaultLocation.latitude,
      longitude: DefaultLocation.longitude,
    }
  }

  private cacheLocationData(countryCode: string): void {
    localStorage.setItem('userCountryCode', countryCode)
  }
}

export default LocationService
