import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { Event } from 'shared/models/Event'
import backgroundImage from '../../../../../assets/images/goldenBg1.svg'
import { ModalButtonsDisplay, ModalSize } from '../../../../../components/UI/Modal/Modal'
import Modal from '../../../../../components/UI/Modal'

type EventConditionsModalProps = {
  handleClose: () => void
  event: Event
}

const EventConditionsModal = ({ handleClose, event }: EventConditionsModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
      opened={true}
      onCancel={handleClose}
      displayCloseIcon={false}
      modalSize={ModalSize.MEDIUM_SMALL}
    >
      <div className="w-full">
        <div className="relative overflow-hidden h-14 rounded-t-md">
          <img src={backgroundImage} className="w-full" alt="background" />
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="absolute text-xl cursor-pointer top-2 right-2"
            onClick={handleClose}
          />
        </div>
        <div className="relative h-auto">
          <div className="absolute w-full text-center -top-6">
            <div className="flex flex-col justify-center gap-2">
              <h3 className="mb-0 text-center">
                <span className="p-1 text-2xl font-medium bg-white rounded-md shadow-md">
                  {`${t('Event')}: ${event.name}`}
                </span>
              </h3>
              <span className="text-lumepic-grey text-lg">{t('Why are there conditions?')}</span>
            </div>
          </div>
        </div>
        <div className="w-full bg-bg_details rounded-b-md pt-10 pb-0.5">
          <div className="mx-2.5 mt-2.5 mb-3 p-3.5 rounded-md bg-white">
            <ul className="list-disc px-3">
              <li className="font-light">{t('Event_conditions_info_1')}</li>
              <li className="font-light">{t('Event_conditions_info_2')}</li>
              <li className="font-light">{t('Event_conditions_info_3')}</li>
              <li className="font-light">{t('Event_conditions_info_4')}</li>
            </ul>
            <p className="text-center pt-2.5 font-medium">{t('Event_conditions_info_5')}</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default observer(EventConditionsModal)
