import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { filterTypes } from '../EventDetailsFirstRenderDesktop'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import calendarIcon from '../../../../../../assets/icons/v2/calendarIcon.svg'
import clockIcon from '../../../../../../assets/icons/v2/clockIcon.svg'
import { DropdownOption } from 'components/UI/Dropdown/Dropdown'

type DateAndTimeButtonDesktopProps = {
  selectedFilter: string
  setSelectedFilter: Dispatch<SetStateAction<filterTypes>>
  eventDays: DropdownOption[]
}

const DateAndTimeButtonDesktop = ({
  selectedFilter,
  setSelectedFilter,
  eventDays,
}: DateAndTimeButtonDesktopProps) => {
  const { t } = useTranslation()
  const isMultipleDays = eventDays.length > 1

  return (
    <div
      className={`w-full rounded-lg flex items-center gap-4 p-3 border relative cursor-pointer transition duration-300 animate-[fadeIn_0.1s_ease-in-out] ${
        selectedFilter === filterTypes.DATE_AND_TIME ? 'bg-lumepic-light_black' : 'bg-bg_details'
      }`}
      onClick={() => setSelectedFilter(filterTypes.DATE_AND_TIME)}
    >
      {selectedFilter === filterTypes.DATE_AND_TIME && (
        <div className="absolute right-3 top-3 bg-primary_dark flex items-center justify-center rounded-full p-1">
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
      <div
        className={`rounded-full h-30 flex items-center justify-center p-7 border mb-2 ${
          selectedFilter === filterTypes.DATE_AND_TIME
            ? 'bg-primary_dark border-lumepic-light_black'
            : 'bg-lumepic-medium_grey border-lumepic-dark_grey'
        }`}
      >
        <img src={isMultipleDays ? calendarIcon : clockIcon} className="text-black w-8" />
      </div>
      <div className="flex flex-col items-start">
        <div className="flex items-center gap-1">
          <p
            className={`font-medium text-lg mb-0 mt-0.5 ${
              selectedFilter === filterTypes.DATE_AND_TIME ? 'text-white' : 'text-black'
            }`}
          >
            {t(isMultipleDays ? 'Filter by date and time' : 'Filter by hour')}
          </p>
        </div>
        <p
          className={`text-sm text-center text-lumepic-grey ${
            selectedFilter === filterTypes.DATE_AND_TIME
              ? 'text-lumepic-medium_grey'
              : 'text-lumepic-grey'
          }`}
        >
          {t(
            isMultipleDays
              ? 'This option will allow you filter by date and time'
              : 'This option will allow you filter by hour'
          )}
        </p>
      </div>
    </div>
  )
}

export default DateAndTimeButtonDesktop
