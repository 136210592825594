import React, { useContext, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import 'moment/locale/es'
import Album from '../../shared/models/Album'
import { Photograph } from '../../shared/models/Photograph'
import PhotographModalDesktop from './PhotographModalDesktop'
import PhotographModalMobile from './PhotographModalMobile'
import storesContext from 'providers/storesContext'
import { isNil } from 'lodash'
import Spinner from 'components/UI/Spinner'
import { isMobileDevice } from 'shared/utility'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

ReactModal.setAppElement('#root')

const baseClasses = [
  'modal-base ' +
    'outline-none ' +
    'align-bottom ' +
    'bg-transparent ' +
    'rounded-lg ' +
    'text-left ' +
    'transform ' +
    'transition-all ' +
    'sm:align-middle ' +
    'my-auto ' +
    'border-radius-md ' +
    'overflow-hidden ' +
    'w-screen',
]

const sizeClass = ['w-10/12 h-10/12 max-w-max max-h-max']

type PhotographModalProps = {
  onClose: () => void
  album: Album
  photographs: Photograph[]
  photographToDisplayId: string
  setPhotographToDisplayId: Function
  loadingNextPage?: boolean
  canEdit?: boolean
  onFeed?: boolean
  onDownloadFreePhotograph: (photographId: string) => void
}

const PhotographModal = ({
  onClose = () => {},
  album,
  photographs,
  photographToDisplayId,
  setPhotographToDisplayId,
  loadingNextPage = false,
  canEdit = false,
  onDownloadFreePhotograph,
}: PhotographModalProps) => {
  const { cartStore } = useContext(storesContext)!
  const history = useHistory()
  const [isExpanded, setIsExpanded] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [imageWidth, setImageWidth] = useState<number | undefined>(undefined)
  const [imageHeight, setImageHeight] = useState<number | undefined>(undefined)
  const albumPhotographIds = photographs?.map((photograph) => photograph.id)
  const isOpen = !!photographToDisplayId
  const currentPhotographToDisplay = photographs?.find(
    (photograph) => photograph.id === photographToDisplayId
  )
  useEffect(() => {
    const chatbotElements = document.getElementsByName('Botmaker')

    if (!isNil(chatbotElements) && chatbotElements.length > 0) {
      chatbotElements.forEach((element) => {
        element.style.display = 'none'
      })
    }
    return () => {
      chatbotElements.forEach((element) => {
        element.style.display = 'block'
      })
    }
  }, [])
  useEffect(() => {
    const image = new Image()
    image.src = currentPhotographToDisplay?.url || ''
    image.onload = () => {
      setImageWidth(image.width)
      setImageHeight(image.height)
    }
  }, [currentPhotographToDisplay?.url])
  const handleKeyPress = (e: any) => {
    if (isOpen) {
      if (e?.key === 'ArrowLeft') {
        goBack()
      } else if (e?.key === 'ArrowRight') {
        goNext()
      }
    }
  }
  const goBack = () => {
    const currentIndex = albumPhotographIds.indexOf(photographToDisplayId)
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1
      setPhotographToDisplayId(album.id, albumPhotographIds[newIndex])
    }
  }
  const goNext = () => {
    const currentIndex = albumPhotographIds.indexOf(photographToDisplayId)
    if (currentIndex < albumPhotographIds.length - 1) {
      const newIndex = currentIndex + 1
      setPhotographToDisplayId(album.id, albumPhotographIds[newIndex])
    }
  }

  let imageClass
  if (!imageWidth || !imageHeight) {
    imageClass = `w-full h-auto object-cover object-center ${
      isMobileDevice() ? 'min-h-[30vh] max-h-[54vh]' : 'min-h-[65vh] max-h-[80vh] rounded-l-lg'
    }`
  } else if (imageWidth < imageHeight) {
    imageClass = `w-full h-auto object-cover object-center ${
      isMobileDevice() ? 'max-h-max' : ' min-h-[65vh] max-h-[80vh] rounded-l-lg'
    }`
  } else {
    imageClass = `w-full h-auto object-cover object-center ${
      isMobileDevice() ? 'max-h-[40vh]' : 'min-h-[65vh] max-h-[80vh] border-l-lg'
    }`
  }

  useEffect(() => {
    document.addEventListener(`keydown`, handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  })

  const currentCart = cartStore.getCart()

  const photographsInCart = currentCart.getSinglePhotographCount(album.id, album.event.id)

  const tiersEarned =
    album.event?.quantityDiscount?.getTiersEarned(photographsInCart) ??
    album?.quantityDiscount?.getTiersEarned(photographsInCart)

  const quantityDiscount = album.event?.quantityDiscount ?? album?.quantityDiscount

  const onCloseModal = () => {
    if (photographs.length === 1 && !isNil(album.event.landingPath)) {
      setPhotographToDisplayId(null)
      const eventUrl = `/events/${album.event.landingPath}`
      history.push(eventUrl)
    } else {
      onClose()
    }
  }

  if (cartStore.isLoading) {
    return <Spinner />
  }

  return (
    <ReactModal
      shouldCloseOnOverlayClick
      overlayClassName={{
        base: 'fixed inset-0 z-30 flex justify-center items-start mt-5 modal-overlay-images-base bg-bg_details',
        afterOpen: 'overflow-y-scroll',
        beforeClose: 'modal-overlay-before-close',
      }}
      onRequestClose={onCloseModal}
      closeTimeoutMS={300}
      isOpen={isOpen}
      className={{
        base: [...baseClasses, ...sizeClass].join(' '),
        afterOpen: 'modal-after-open',
        beforeClose: 'modal-before-close',
      }}
    >
      {photographs.length > 0 && !isNil(currentPhotographToDisplay) && (
        <>
          {isMobileDevice() ? (
            <PhotographModalMobile
              album={album}
              setImageLoaded={setImageLoaded}
              currentPhotographToDisplay={currentPhotographToDisplay}
              imageClass={imageClass}
              imageLoaded={imageLoaded}
              onClose={onCloseModal}
              goBack={() => goBack()}
              canEdit={canEdit}
              goNext={() => goNext()}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              currentCart={currentCart}
              photographs={photographs}
              onDownloadFreePhotograph={onDownloadFreePhotograph}
              tiersEarned={tiersEarned}
              quantityDiscount={quantityDiscount}
            />
          ) : (
            <PhotographModalDesktop
              goBack={() => goBack()}
              goNext={() => goNext()}
              setImageLoaded={setImageLoaded}
              currentPhotographToDisplay={currentPhotographToDisplay}
              imageClass={imageClass}
              imageLoaded={imageLoaded}
              onClose={onCloseModal}
              album={album}
              canEdit={canEdit}
              loadingNextPage={loadingNextPage}
              currentCart={currentCart}
              photographs={photographs}
              onDownloadFreePhotograph={onDownloadFreePhotograph}
              tiersEarned={tiersEarned}
              quantityDiscount={quantityDiscount}
            />
          )}
        </>
      )}
    </ReactModal>
  )
}
export default observer(PhotographModal)
