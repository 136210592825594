import { BaseDiscount } from './BaseDiscount'
import { isNil } from 'lodash'
import { QuantityDiscountDisplay } from 'containers/MyAlbums/CreateOrUpdateAlbumSteps/Steps/AddDiscountModal/AddDiscountModal'

export default class DiscountPercentage {
  constructor(dp: any) {
    this.numberOfPictures = dp.numberOfPictures
    this.discountPercentage = dp.discountPercentage
  }

  numberOfPictures: number

  discountPercentage: number
}

export class QuantityDiscount extends BaseDiscount {
  constructor() {
    super()
  }
  id: string

  discountPercentages: DiscountPercentage[]

  display?: QuantityDiscountDisplay

  getTiersEarned(numberOfPictures: number): number {
    return this.discountPercentages.filter(
      (discountPercentage: DiscountPercentage) =>
        discountPercentage.numberOfPictures <= numberOfPictures
    ).length
  }

  static init(quantityDiscount: any): QuantityDiscount | null {
    if (isNil(quantityDiscount)) {
      return null
    }
    const newQuantityDiscount = new QuantityDiscount()
    newQuantityDiscount.id = quantityDiscount.id
    if (!isNil(quantityDiscount.display)) {
      newQuantityDiscount.display = quantityDiscount.display
    }
    newQuantityDiscount.discountPercentages = !isNil(quantityDiscount.discountPercentages)
      ? quantityDiscount.discountPercentages
          .map((dp: any) => new DiscountPercentage(dp))
          .sort((a: DiscountPercentage, b: DiscountPercentage) =>
            a.discountPercentage < b.discountPercentage ? -1 : 1
          )
      : []
    return newQuantityDiscount
  }
}
