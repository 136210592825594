import { t } from 'i18next'
import React from 'react'
import BenefitsFrameDesktop from './components/BenefitsFrameDesktop'
import {
  faClock,
  faCloudArrowUp,
  faGlobeAmericas,
  faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons'
import Button from 'components/UI/Button'
import { scrollToElement } from 'containers/PhotographersLanding/utils/utils'
import Tracker from 'shared/tracking'

const PhotographerBenefitsMobile = () => {
  return (
    <div className="mt-28 bg-white py-20 flex flex-col items-center justify-center gap-4">
      <h3 className="text-center text-black text-2xl font-semibold mb-2">{t('Benefits')}</h3>
      <div className="grid grid-cols-2 mx-6">
        <BenefitsFrameDesktop icon={faCloudArrowUp} title="Upload albums for free" />
        <BenefitsFrameDesktop icon={faGlobeAmericas} title="Sell from anywhere in the world" />
        <BenefitsFrameDesktop icon={faWandMagicSparkles} title="Face and text recognition" />
        <BenefitsFrameDesktop icon={faClock} title="Save time and focus on what matters" />
      </div>
      <Button
        btnType="SecondaryAction"
        onClick={() => {
          Tracker.goToPhotographersRegister()
          scrollToElement('photographer-register')
        }}
      >
        <span className="text-sm">{t('Start selling for free')}</span>
      </Button>
    </div>
  )
}

export default PhotographerBenefitsMobile
