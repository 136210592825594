import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import Dropdown from 'components/UI/Dropdown'
import Checkbox from 'components/UI/Checkbox'
import SkeletonLoaderPhotographCard from 'components/UI/Loaders/Skeleton/SkeletonLoaderPhotographCard'
import {
  PhotographUtils,
  TimeIntervalOptions,
  UnknownPhotographKey,
} from 'shared/util/photographs.utils'
import { formatPhotographCountText, formatUtcToLocaleDate } from 'shared/utility'
import AlbumDetailsStore from '../AlbumDetailsStore'
import { Photograph } from 'shared/models/Photograph'
import AlbumDetailsNoResult from 'containers/MyAlbums/AlbumDetails/components/AlbumDetailsNoResult'
import PhotographModal from 'components/PhotographDetailsModal/PhotographModal'
import FaceRecognitionModal from './FaceRecognitionModal'
import IntervalAccordion from 'components/UI/IntervalAccordion/IntervalAccordion'
import { DropdownOption } from 'components/UI/Dropdown/Dropdown'
import { observer } from 'mobx-react'
import Spinner from 'components/UI/Spinner'
import { feedAlbumDetailById, feedAlbumPhotographDetailById } from 'routing/Paths/feed'
import ItemDetailsFiltersAccordion from 'components/ItemDetails/components/ItemDetailsFiltersAccordion'
import { useHistory } from 'react-router'
import TagFilterModal from './TagFilterModal'
import DateAndTimeFilterModal from './DateAndTimeFilter/DateAndTimeFilterModal'
import ItemDetailsSearchResultMessage from 'components/ItemDetails/components/ItemDetailsSearchResultMessage'
import ItemDetailsInformation from 'components/ItemDetails/components/ItemDetailsInformation'
import { eachDayOfInterval } from 'date-fns'
import ItemDetailsDiscountAccordion from 'components/ItemDetails/components/ItemDetailsDiscountAccordion'
import { getURLWithTagFilters } from '../../ItemDetails/utils/ItemDetailsUtils'

type AlbumDetailsContentProps = {
  initialRender: React.MutableRefObject<boolean>
  albumDetailsStore: AlbumDetailsStore
  photographId: string
  photographBlocksByInterval: Map<string, Photograph[]>
  isBurstMode: boolean
  selectedInterval: DropdownOption
  handleChangeInterval: (value: DropdownOption) => void
  handleBurstModeChange: (check: boolean) => void
  fetchNextPage: () => void
  downloadFreePhotograph: (photographId: string) => void
  setShowBuyPackageButton: React.Dispatch<React.SetStateAction<boolean>>
}

const AlbumDetailsContent = ({
  initialRender,
  albumDetailsStore,
  photographId,
  photographBlocksByInterval,
  isBurstMode,
  selectedInterval,
  handleChangeInterval,
  handleBurstModeChange,
  fetchNextPage,
  downloadFreePhotograph,
  setShowBuyPackageButton,
}: AlbumDetailsContentProps) => {
  const history = useHistory()
  const { t } = useTranslation()
  const [photographToDisplayId, setPhotographToDisplayId] = useState<string | null>(null)
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)
  const [searchInProgress, setSearchInProgress] = useState(false)
  const [showUploadSelfieModal, setShowUploadSelfieModal] = useState(false)
  const [showFilterByTagModal, setShowFilterByTagModal] = useState(false)
  const [showFilterByDateAndTimeModal, setShowFilterByDateAndTimeModal] = useState(false)

  useEffect(() => {
    setPhotographToDisplayId(photographId)
  }, [photographId, albumDetailsStore.isAuthenticated])

  useEffect(() => {
    if (!albumDetailsStore.isLoadingAlbumPhotographs) {
      const index = albumDetailsStore.photographs.findIndex(
        (photograph) => photograph.id === photographToDisplayId
      )
      if (index === -1) {
        setPhotographToDisplayId(null)
      } else if (
        albumDetailsStore.photographs.length > 1 &&
        albumDetailsStore.photographs.length - 1 === index
      ) {
        albumDetailsStore.fetchNextPage()
      }
    }
  }, [photographToDisplayId])

  const translatedTimeIntervalOptions = TimeIntervalOptions.map((option) => ({
    ...option,
    label: t(option.label),
  }))

  const setPhotographModalPhotograph = (albumId: string, photographId: string) => {
    const url = feedAlbumPhotographDetailById(albumId, photographId)
    window.history.replaceState(null, '', url)
    setPhotographToDisplayId(photographId)
  }

  const setIntervalAccordionPhotographModalPhotograph = (photographId: string) => {
    const url = feedAlbumPhotographDetailById(albumDetailsStore.album.id, photographId)
    window.history.replaceState(null, '', url)
    setPhotographToDisplayId(photographId)
  }

  const closeImageModal = () => {
    setPhotographToDisplayId(null)
    const initialUrl = feedAlbumDetailById(albumDetailsStore.album!.id)
    const tagValueInUrl =
      albumDetailsStore.tagIds.length > 0 ? `?tagValue=${albumDetailsStore.tagIds}` : ''
    const urlWithTagIds = initialUrl + tagValueInUrl
    albumDetailsStore.tagIds
      ? window.history.replaceState(null, '', urlWithTagIds)
      : window.history.replaceState(null, '', initialUrl)
    if (albumDetailsStore.photographs.length === 1) {
      albumDetailsStore.fetchPhotographs(true, '')
    }
    PhotographUtils.mantainScrollPositionAfterClosingModal(
      photographToDisplayId,
      albumDetailsStore.photographs
    )
  }

  const updateURLWithFilters = () => {
    const updatedURL = getURLWithTagFilters(albumDetailsStore.tagValue, location)
    history.replace(updatedURL)
  }

  const handleClearFilters = () => {
    initialRender.current = false
    albumDetailsStore.setTagValue('')
    albumDetailsStore.setDateRange([null, null])
    setShowBuyPackageButton(false)
    albumDetailsStore.setIsFilteringByFaceRecognition(false)
    albumDetailsStore.fetchPhotographs(true, '')
    albumDetailsStore.clearPhotographCount()
    updateURLWithFilters()
    setSearchInProgress(false)
  }

  const filterByFaceRecognition = () => {
    initialRender.current = false
    albumDetailsStore.setTagValue('')
    albumDetailsStore.setDateRange([null, null])
    albumDetailsStore.clearPhotographCount()
    albumDetailsStore.setIsFilteringByFaceRecognition(true)
    albumDetailsStore.fetchPhotographs(true, '')
    setSearchInProgress(true)
    setIsAccordionExpanded(false)
  }

  const filterByTagValue = () => {
    initialRender.current = false
    albumDetailsStore.clearPhotographCount()
    albumDetailsStore.setDateRange([null, null])
    albumDetailsStore.fetchPhotographs(true, albumDetailsStore.tagValue.value)
    setShowFilterByTagModal(false)
    updateURLWithFilters()
    setSearchInProgress(true)
    setIsAccordionExpanded(false)
  }

  const filterByDateAndTime = () => {
    initialRender.current = false
    albumDetailsStore.setTagValue('')
    albumDetailsStore.clearPhotographCount()
    albumDetailsStore.fetchPhotographs(true, '')
    setShowFilterByDateAndTimeModal(false)
    setSearchInProgress(true)
    setIsAccordionExpanded(false)
  }

  const albumDays = useMemo(() => {
    const firstDate = albumDetailsStore.album.firstPhotographDate
    const lastDate = albumDetailsStore.album.lastPhotographDate

    if (!firstDate || !lastDate) {
      return []
    }

    const start = new Date(firstDate)
    const end = new Date(lastDate)

    if (end <= start) {
      return []
    }

    return eachDayOfInterval({ start, end }).map((date) => ({
      value: date.toISOString(),
      label: formatUtcToLocaleDate(date),
    }))
  }, [albumDetailsStore.album.firstPhotographDate, albumDetailsStore.album.lastPhotographDate])

  const moreThanFiveDays = useMemo(() => {
    const firstDate = albumDetailsStore.album.firstPhotographDate
      ? new Date(albumDetailsStore.album.firstPhotographDate)
      : null
    const lastDate = albumDetailsStore.album.lastPhotographDate
      ? new Date(albumDetailsStore.album.lastPhotographDate)
      : null
    return firstDate && lastDate
      ? (lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24) > 5
      : false
  }, [albumDetailsStore.album.firstPhotographDate, albumDetailsStore.album.lastPhotographDate])

  const renderFilterAccordion = useMemo(
    () =>
      albumDetailsStore.album.faceRecognitionProcessed ||
      (!isNil(albumDetailsStore.albumTags) && albumDetailsStore.albumTags?.length > 0) ||
      !moreThanFiveDays,
    [
      albumDetailsStore.album.faceRecognitionProcessed,
      albumDetailsStore.albumTags,
      moreThanFiveDays,
    ]
  )

  return (
    <>
      <div className="mb-3 rounded-md  bg-white">
        {!albumDetailsStore.isLoadingAlbumDetails && (
          <ItemDetailsInformation
            album={albumDetailsStore.album}
            isLoading={albumDetailsStore.isLoadingAlbumDetails}
          />
        )}
      </div>
      {!isNil(albumDetailsStore.album.quantityDiscount) && (
        <ItemDetailsDiscountAccordion album={albumDetailsStore.album} />
      )}
      {renderFilterAccordion && (
        <div className="w-full mb-4">
          <ItemDetailsFiltersAccordion
            isAccordionExpanded={isAccordionExpanded}
            setIsAccordionExpanded={setIsAccordionExpanded}
            albumDetailsStore={albumDetailsStore}
            handleClearFilters={handleClearFilters}
            handleOpenUploadSelfieModal={() => setShowUploadSelfieModal(true)}
            handleOpenFilterByTagModal={() => setShowFilterByTagModal(true)}
            handleOpenFilterByDateAndTimeModal={() => setShowFilterByDateAndTimeModal(true)}
            searchInProgress={searchInProgress}
            moreThanFiveDays={moreThanFiveDays}
            days={albumDays}
          />
        </div>
      )}
      <div className="px-4 py-2 bg-white rounded-md shadow-md">
        {/**BURST SECTION */}
        <div className="flex flex-col w-full gap-2 sm:w-1/2 md:w-1/3">
          <div className="flex flex-col gap-1">
            <span className="text-sm text-lumepic-grey">{t('Time interval')}</span>
            <div className="flex items-center sm:gap-2">
              <div className="w-1/2 sm:w-full">
                <Dropdown
                  label={t('Time interval').toString()}
                  onChange={handleChangeInterval}
                  options={translatedTimeIntervalOptions}
                  disabled={isBurstMode}
                  value={selectedInterval}
                  extraStyles={{
                    fieldset: {
                      borderColor: '#636363',
                      color: '#636363',
                      borderWidth: '2px',
                    },
                  }}
                />
              </div>
              <div className="w-1/2 sm:w-full">
                <Checkbox
                  id="burst-mode"
                  label={t('View bursts')}
                  value={isBurstMode}
                  onChange={handleBurstModeChange}
                />
              </div>
            </div>
          </div>
        </div>
        <ItemDetailsSearchResultMessage
          searchInProgress={searchInProgress}
          tagValueLength={0}
          isFilteringByFaceRecognition={false}
          isLoadingEventPhotographs={false}
          photographCount={0}
        />
        {albumDetailsStore.isFilteringByFaceRecognition &&
          !albumDetailsStore.isLoadingAlbumPhotographs && (
            <div className="mx-2 mt-4">
              {t('You’ve appeared in') +
                ' ' +
                formatPhotographCountText(albumDetailsStore.photographCount, t)}
              !
            </div>
          )}
      </div>
      {!albumDetailsStore.isLoadingAlbumPhotographs &&
        !albumDetailsStore.isLoadingAlbumDetails &&
        (!albumDetailsStore || albumDetailsStore.photographs.length === 0) && (
          <AlbumDetailsNoResult />
        )}
      {!albumDetailsStore.isLoadingAlbumDetails && albumDetailsStore.photographs.length > 0 && (
        <InfiniteScroll
          dataLength={albumDetailsStore.photographs.length}
          next={fetchNextPage}
          hasMore={albumDetailsStore.hasMorePages()}
          loader={<></>}
          scrollThreshold={0.8}
          className="overflow-hidden"
        >
          {Array.from(photographBlocksByInterval.keys())
            .sort(PhotographUtils.sortIntervals)
            .map((key) => {
              const photographBlockInterval = photographBlocksByInterval.get(key)
              const burstNumber = key.substring(6)
              const translatedKey = isBurstMode ? `${t('Burst')} ${burstNumber}` : key
              return (
                <IntervalAccordion
                  key={key}
                  index={isBurstMode ? translatedKey : key}
                  unknownPhotographKey={UnknownPhotographKey}
                  setPhotograph={setIntervalAccordionPhotographModalPhotograph}
                  downloadFreePhotograph={downloadFreePhotograph}
                  photographBlockInterval={photographBlockInterval}
                  isInBurstMode={isBurstMode}
                  albumDetailsStore={albumDetailsStore}
                  allPhotographsFromSameDay={true}
                />
              )
            })}
        </InfiniteScroll>
      )}
      {albumDetailsStore.isLoadingAlbumPhotographs && albumDetailsStore.page > 1 && (
        <Spinner divStyles="flex justify-center mt-5 items-center" size={40} />
      )}
      {albumDetailsStore.isLoadingAlbumPhotographs && albumDetailsStore.page === 1 && (
        <SkeletonLoaderPhotographCard />
      )}
      {!isNil(photographToDisplayId) && (
        <PhotographModal
          photographToDisplayId={photographToDisplayId}
          setPhotographToDisplayId={setPhotographModalPhotograph}
          album={albumDetailsStore.album}
          photographs={albumDetailsStore.photographs}
          loadingNextPage={albumDetailsStore.isLoadingAlbumPhotographs}
          onClose={closeImageModal}
          onFeed
          onDownloadFreePhotograph={downloadFreePhotograph}
        />
      )}
      <FaceRecognitionModal
        opened={showUploadSelfieModal}
        onCancel={() => setShowUploadSelfieModal(false)}
        store={albumDetailsStore}
        onSuccess={() => filterByFaceRecognition()}
      />
      <TagFilterModal
        opened={showFilterByTagModal}
        onCancel={() => setShowFilterByTagModal(false)}
        store={albumDetailsStore}
        onSuccess={() => filterByTagValue()}
        handleClearFilters={() => handleClearFilters()}
      />
      <DateAndTimeFilterModal
        opened={showFilterByDateAndTimeModal}
        onCancel={() => setShowFilterByDateAndTimeModal(false)}
        days={albumDays}
        store={albumDetailsStore}
        onSuccess={() => filterByDateAndTime()}
        handleClearFilters={() => handleClearFilters()}
      />
    </>
  )
}

export default observer(AlbumDetailsContent)
