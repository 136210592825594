import InputStore from 'shared/store/InputStore'

export interface ItemDetailsStoreInterface {
  tagValue?: InputStore<string>
  setTagValue: (value: string) => void
  setIsFilteringByFaceRecognition: (filter: boolean) => void
  setDateRange: (range: [string | null, string | null]) => void
}

export interface EventDetailsStore extends ItemDetailsStoreInterface {
  setAlbumsView: (view: boolean) => void
}

export const isEventDetailsStore = (
  store: ItemDetailsStoreInterface
): store is EventDetailsStore => {
  return (store as EventDetailsStore).setAlbumsView !== undefined
}
