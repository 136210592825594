import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/UI/Button'
import Dropdown, { DropdownOption } from 'components/UI/Dropdown/Dropdown'
import { observer } from 'mobx-react'
import { startOfDay, setHours, setMinutes } from 'date-fns'
import EventDetailsStore from 'containers/Events/EventDetails/EventDetailsStore'
import { generateTimeOptions } from 'shared/utility'
import { isNil } from 'lodash'
import AlbumDetailsStore from 'components/AlbumDetails/AlbumDetailsStore'

type DateAndFilterContentProps = {
  days?: DropdownOption[]
  store: AlbumDetailsStore | EventDetailsStore
  onSuccess: () => void
  isDesktopFirstRenderView?: boolean
}

const DateAndFilterContent = ({
  days,
  onSuccess,
  store,
  isDesktopFirstRenderView,
}: DateAndFilterContentProps) => {
  const { t } = useTranslation()

  const timeOptions = generateTimeOptions()

  const initialSelectedDay = useMemo(() => {
    if (!isNil(days) && days.length === 1) {
      return days[0]
    }
    return null
  }, [days])

  const [selectedDay, setSelectedDay] = useState<DropdownOption | null>(initialSelectedDay)
  const [selectedFromTime, setSelectedFromTime] = useState<DropdownOption | null>(null)
  const [selectedToTime, setSelectedToTime] = useState<DropdownOption | null>(null)
  const [timeError, setTimeError] = useState<string | null>(null)

  const handleDayChange = (option: DropdownOption) => {
    setSelectedDay(option)
    setSelectedFromTime(null)
    setSelectedToTime(null)
  }

  const handleFromTimeChange = (option: DropdownOption) => {
    setSelectedFromTime(option)
  }

  const handleToTimeChange = (option: DropdownOption) => {
    setSelectedToTime(option)
  }

  const handleApplyFilter = () => {
    if (selectedDay) {
      const dayStart = startOfDay(new Date(selectedDay.value))
      let dateFrom: string
      let dateTo: string

      if (selectedFromTime && selectedToTime) {
        const [fromHours, fromMinutes] = selectedFromTime.value.split(':').map(Number)
        const [toHours, toMinutes] = selectedToTime.value.split(':').map(Number)

        const fromDate = setMinutes(setHours(dayStart, fromHours), fromMinutes)
        const toDate = setMinutes(setHours(dayStart, toHours), toMinutes)

        if (toDate <= fromDate) {
          setTimeError(t('End time must be later than start time'))
          return
        }

        dateFrom = fromDate.toISOString()
        dateTo = toDate.toISOString()
      } else {
        dateFrom = dayStart.toISOString()
        dateTo = new Date(dayStart.getTime() + 24 * 60 * 60 * 1000 - 1).toISOString()
      }

      setTimeError(null)
      store.setDateRange([dateFrom, dateTo])
      onSuccess()
    }
  }

  return (
    <div className={`w-full rounded-b-md  ${isDesktopFirstRenderView ? 'p-0 mt-0' : 'p-3 mt-4'}`}>
      <div className="flex flex-col space-y-6">
        {!isNil(days) && days.length > 1 && (
          <div className="space-y-1">
            <h4 className="text-lg font-semibold">{t('Select date')}</h4>
            <Dropdown
              label={t('Choose a day')}
              options={days}
              onChange={handleDayChange}
              value={selectedDay ?? undefined}
            />
          </div>
        )}

        {selectedDay && (
          <div className="space-y-1">
            <h4 className="text-lg font-semibold">{t('Select time range')}</h4>
            <div className="flex space-x-4">
              <div className="flex-1">
                <Dropdown
                  label={t('From')}
                  options={timeOptions}
                  onChange={handleFromTimeChange}
                  value={selectedFromTime ?? undefined}
                />
              </div>
              <div className="flex-1">
                <Dropdown
                  label={t('To')}
                  options={timeOptions}
                  onChange={handleToTimeChange}
                  value={selectedToTime ?? undefined}
                  errorMessage={timeError}
                />
              </div>
            </div>
          </div>
        )}

        <Button
          btnType={'PrimaryAction'}
          onClick={handleApplyFilter}
          extraStyle="w-full py-3 mt-4"
          disabled={!selectedDay}
        >
          {t('Search')}
        </Button>
      </div>
    </div>
  )
}

export default observer(DateAndFilterContent)
