import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../../../../components/UI/Modal'
import { ModalSize } from '../../../../../../../components/UI/Modal/Modal'

type DeleteDiscountModalProps = {
  open: boolean
  handleDelete: () => void
  hideConfirmModal: () => void
  isSaving: boolean
}
const DeleteDiscountModal = ({
  open = false,
  handleDelete,
  hideConfirmModal,
  isSaving,
}: DeleteDiscountModalProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Modal
        loadingOk={isSaving}
        onCancel={hideConfirmModal}
        opened={open}
        onSuccess={handleDelete}
        okMessage={t('Delete').toString()}
        isDeleteConfirmation
        modalSize={ModalSize.SMALL}
      >
        <div className="w-full grid grid-rows-1 gap-2 px-4 pt-4 ">
          <div className="text-gray-600">
            {t('Are you sure that you want to delete the discounts?')}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default observer(DeleteDiscountModal)
