import { Currency } from 'shared/util/Currency'

/** Enum representing the types of cart lines. */
export enum CartLineType {
  Package = 'PACKAGE',
  Photograph = 'PHOTOGRAPH',
  Burst = 'BURST',
}

/**
 * Abstract class representing a cart line.
 */
export abstract class CartLine {
  /** The type of the cart line (Photograph or Package). */
  type: CartLineType

  /** The price before discounts of the cart line. */
  subtotalPrice: number

  /** The total price after discounts of the cart line. */
  totalPrice: number

  /** The total discount amount applied to the cart line. */
  discountTotal: number

  /** The currency of the cart line. */
  currency: Currency

  /** The date the item was added to the cart. */
  dateAdded: Date

  /**
   * Creates an instance of CartLine.
   * @param type - The type of the cart line.
   * @param subtotalPrice - The price of the cart line before discounts.
   * @param totalPrice - The price of the cart line after discounts.
   * @param discountTotal - The total discount applied to the cart line.
   * @param currency - The currency of the cart line.
   */
  constructor(
    type: CartLineType,
    subtotalPrice: number,
    totalPrice: number,
    discountTotal: number,
    currency: Currency
  ) {
    this.type = type
    this.subtotalPrice = subtotalPrice
    this.totalPrice = totalPrice
    this.discountTotal = discountTotal
    this.currency = currency
    this.dateAdded = new Date()
  }

  abstract getTitle(): string

  abstract getDetails(): string

  abstract getKey(): string

  abstract getGroupKey(): string
}
