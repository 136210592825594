import React, { useState } from 'react'
import { PieChart } from '@mui/x-charts'
import { useTranslation } from 'react-i18next'
import Toggle, { DisplayMode } from 'components/UI/Toggle/Toggle'
import { isMobileDevice } from 'shared/utility'

type SalesByTypePieChartProps = {
  independentAlbumSales: { salesAmountInUsd: number; salesCount: number }
  eventAlbumsSales: { salesAmountInUsd: number; salesCount: number }
}

const SalesByTypePieChart = ({
  independentAlbumSales,
  eventAlbumsSales,
}: SalesByTypePieChartProps) => {
  const { t } = useTranslation()

  const [pieChartDisplayMode, setPieChartDisplayMode] = useState<DisplayMode>(DisplayMode.USD)

  const pieData = [
    {
      id: 0,
      label: t('Independent Albums'),
      value:
        pieChartDisplayMode === DisplayMode.USD
          ? parseFloat(independentAlbumSales.salesAmountInUsd?.toFixed(2))
          : independentAlbumSales.salesCount,
    },
    {
      id: 1,
      label: t('Event Albums'),
      value:
        pieChartDisplayMode === DisplayMode.USD
          ? parseFloat(eventAlbumsSales.salesAmountInUsd?.toFixed(2))
          : eventAlbumsSales.salesCount,
    },
  ]

  const pieChartPalette = ['#636363', '#DDD29D']

  return (
    <>
      <h2 className="flex font-semibold justify-start items-start text-base text-lumepic-light_black">
        {t('Sale Type')}
      </h2>
      <Toggle displayMode={pieChartDisplayMode} setDisplayMode={setPieChartDisplayMode} />
      <PieChart
        series={[
          {
            data: pieData,
            highlightScope: { faded: 'global', highlighted: 'item' },
          },
        ]}
        margin={{ top: 15, right: 15, bottom: 70, left: 15 }}
        slotProps={{
          legend: {
            direction: 'column',
            position: { vertical: 'bottom', horizontal: 'middle' },
            padding: 0,
            itemMarkWidth: 7,
            itemMarkHeight: 7,
            markGap: 5,
            itemGap: 5,
          },
        }}
        tooltip={{ trigger: 'item' }}
        colors={pieChartPalette}
        height={isMobileDevice() ? 300 : 350}
      />
    </>
  )
}

export default SalesByTypePieChart
