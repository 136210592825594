import React from 'react'
import { observer } from 'mobx-react'
import AddToCartButtonLong from '../../../containers/Cart/components/AddToCartButton/AddToCartButtonLong'
import { Photograph } from '../../../shared/models/Photograph'
import Album from '../../../shared/models/Album'
import Button from 'components/UI/Button'
import { useTranslation } from 'react-i18next'

type PhotographActionsProps = {
  photograph: Photograph
  album: Album
  isFree: boolean
  canEdit: boolean
  onDownloadFreePhotograph: (photographId: string) => void
}

const PhotographActions = ({
  photograph,
  album,
  isFree = false,
  canEdit = false,
  onDownloadFreePhotograph,
}: PhotographActionsProps) => {
  const { t } = useTranslation()

  return (
    <>
      {isFree ? (
        <>
          <Button
            btnType="PrimaryAction"
            extraStyle="w-full"
            onClick={() => onDownloadFreePhotograph(photograph.id)}
          >
            {t('Download')}
          </Button>
        </>
      ) : (
        !canEdit && <AddToCartButtonLong photograph={photograph} album={album} />
      )}
    </>
  )
}

export default observer(PhotographActions)
