import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import OtherSports from './OtherSports'
import { LandingConfigInterface } from 'config/interfaces/config.interface'

type PhotoGalleryMobileProps = {
  onClick: (activityId: string) => void
  config: LandingConfigInterface
}

const PhotoGalleryMobile = ({ onClick, config }: PhotoGalleryMobileProps) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {}, [i18n.language])

  return (
    <div className="py-10">
      <h2 className="mb-2 ml-2 text-2xl font-extrabold text-lumepic-light_black">
        {t('Our most popular sports')}
      </h2>
      <div className="flex flex-col w-full gap-2">
        <div className="flex gap-2">
          <div className="w-1/2 h-landing2">
            <img
              src={config.FEATURED_ACTIVITY_2.mobileAssetName}
              className="object-cover object-top w-full h-full"
              alt="Surf"
              onClick={() => onClick(config.FEATURED_ACTIVITY_2.activityId)}
            />
          </div>
          <div className="w-1/2 h-landing2">
            <img
              src={config.FEATURED_ACTIVITY_3.mobileAssetName}
              className="object-cover object-top w-full h-full"
              alt="Kitesurf"
              onClick={() => onClick(config.FEATURED_ACTIVITY_3.activityId)}
            />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="w-2/3 h-landing2">
            <img
              src={config.FEATURED_ACTIVITY_4.mobileAssetName}
              className="object-cover object-top w-full h-full"
              alt="Rugby"
              onClick={() => onClick(config.FEATURED_ACTIVITY_4.activityId)}
            />
          </div>
          <div className="w-1/3 h-landing2">
            <img
              src={
                i18n.language === 'en'
                  ? config.FEATURED_ACTIVITY_1_EN.mobileAssetName
                  : config.FEATURED_ACTIVITY_1_ES.mobileAssetName
              }
              className="object-cover object-top w-full h-full"
              alt="Marathon"
              onClick={() => onClick(config.FEATURED_ACTIVITY_1_EN.activityId)}
            />
          </div>
        </div>
        <div className="w-full">
          <img
            src={config.FEATURED_ACTIVITY_5.mobileAssetName}
            className="object-cover object-top w-full h-landing2"
            alt="Motocross"
            onClick={() => onClick(config.FEATURED_ACTIVITY_5.activityId)}
          />
        </div>
      </div>
      <div className="mt-3 ml-2">
        <OtherSports slidesPerView={3} onClick={onClick} config={config} />
      </div>
    </div>
  )
}

export default PhotoGalleryMobile
