import React, { useState } from 'react'
import Button from 'components/UI/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import EventDetailsStore from 'containers/Events/EventDetails/EventDetailsStore'
import PrivateItemBlurredImg from 'components/AlbumDetails/components/PrivateAlbum/components/PrivateAlbumAuth/PrivateItemBlurredImg'
import { useFormik } from 'formik'
import { privateItemValidationSchema } from 'components/AlbumDetails/components/PrivateAlbum/components/PrivateAlbumAuth/PrivateAlbumPasswordModalForm/PrivateItemValidationSchema'
import { IconButton, InputAdornment, TextField } from '@mui/material'

type PrivateEventLoginProps = {
  store: EventDetailsStore
}

const PrivateEventLogin = ({ store }: PrivateEventLoginProps) => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const initialValues = {
    password: '',
  }

  const onSubmit = () => {
    store.setPassword(formik.values.password)
    store.fetchEventPhotographs(false, undefined)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: privateItemValidationSchema,
    onSubmit: onSubmit,
  })

  return (
    <>
      <section className="relative flex justify-center mx-4">
        <div
          className="absolute z-20 rounded-lg flex flex-col items-center justify-center mt-20"
          style={{ boxShadow: '0px 0px 70px 30px rgba(0,0,0,0.8)' }}
        >
          <section className="py-3 px-4 bg-white rounded-lg">
            <div className="flex flex-col gap-5 pb-4">
              <h2 className="text-2xl text-center font-bold text-lumepic-black">
                {store.event.name}
              </h2>
              <p className="text-lumepic-light_black text-center text-base">
                {t('Enter the password to see all the photographs')}
              </p>
            </div>
            <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                variant="outlined"
                id="password"
                name="password"
                label={t('Password')}
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faLock} className="text-lumepic-grey" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((prevState) => !prevState)}
                        onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                          event.preventDefault()
                        }}
                        edge="end"
                      >
                        {showPassword ? (
                          <FontAwesomeIcon icon={faEye} className="text-base" />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} className="text-base" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                btnType="PrimaryAction"
                onClick={formik.handleSubmit}
                isLoading={store.isLoadingEventPhotographs}
              >
                <span className="px-4 text-lg">{t('Login')}</span>
              </Button>
            </form>
          </section>
        </div>
        <PrivateItemBlurredImg />
      </section>
    </>
  )
}

export default PrivateEventLogin
