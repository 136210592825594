import React from 'react'
import { Tooltip } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { Photograph } from 'shared/models/Photograph'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

type GallerySinglePhotoProps = {
  photograph: Photograph
  setPhotograph: (photographId: string) => void
  downloadFreePhotograph: (photographId: string) => void
}

const GallerySinglePhoto = ({
  photograph,
  setPhotograph,
  downloadFreePhotograph,
}: GallerySinglePhotoProps) => {
  const { t } = useTranslation()

  return (
    <div key={photograph.id} className="my-1 relative group cursor-pointer overflow-hidden">
      <img
        className="w-full animate-fadeIn block transform transition-transform duration-300 lg:hover:scale-105"
        data-photograph-id={photograph.id}
        src={photograph.thumbnailUrl}
        alt={photograph.id}
        onClick={() => setPhotograph(photograph.id)}
      />
      <div className="w-full absolute bottom-0 right-0 flex items-center justify-end p-2 opacity-100 md:opacity-0 md:group-hover:opacity-100 duration-200 transition-opacity">
        <Tooltip title={t('Download')} arrow>
          <FontAwesomeIcon
            onClick={(e) => {
              e.stopPropagation()
              downloadFreePhotograph(photograph.id)
            }}
            icon={faDownload}
            className="text-sm p-1.5 bg-lumepic-semi_medium_grey rounded-full md:text-base md:p-1.5"
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default observer(GallerySinglePhoto)
