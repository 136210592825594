import { makeAutoObservable, runInAction } from 'mobx'
import Album from 'shared/models/Album'
import { Photograph } from 'shared/models/Photograph'
import AuthStore from 'stores/AuthStore'
import { EventService } from 'services/EventService'
import FeedService, { FeedOrderFields, FeedOrderSorts } from 'services/FeedService'
import { Event } from 'shared/models/Event'
import InputStore from 'shared/store/InputStore'
import { string } from 'yup'
import { Activity } from 'shared/models/Activity'
import { isNil } from 'lodash'
import { TagKeys } from 'services/RequestInterfaces/Tag/Tag.interface'

const DefaultAlbumsPerPage = 100

const DefaultPhotographsPerPage = 30

const DefaultFilteredPhotographsPerNonFreeEvent = 50

const LocalStorageKeys = {
  EventPasswords: 'event_passwords',
}

export default class EventDetailsStore {
  public eventId: string
  public event: Event
  public albums: Album[]
  public albumsPerPage: number
  public albumsCount: number
  public photographs: Photograph[]
  public tagIds: string[]
  public tagValue: InputStore<string>
  public isFilteringByFaceRecognition: boolean
  public albumsView: boolean
  public photographCount: number
  public dateRange: [string | null, string | null]
  public isLoadingEventDetails: boolean
  public isLoadingEventPhotographs: boolean
  public isLoadingEventAlbums: boolean
  public isLoadingFreePhotograph: boolean
  public isLoadingEventActivities: boolean
  public eventNotFound: boolean
  public isAuthenticated: boolean
  public password?: string
  public page: number
  public photographsPerPage: number
  public error: any
  public activity: Activity | null
  public activities: Activity[]
  private tagKey?: TagKeys
  private readonly feedService: FeedService
  private readonly eventService: EventService
  private readonly authStore: AuthStore
  constructor(
    private readonly eventLandingPath: string,
    authStore: AuthStore,
    initialTagIds?: string[],
    initialTagValue?: string
  ) {
    this.resetStore()
    initialTagValue && this.tagValue.setValue(initialTagValue)
    makeAutoObservable(this)
    this.authStore = authStore
    this.eventService = new EventService()
    this.feedService = new FeedService()
  }
  resetStore() {
    this.eventId = ''
    this.event = new Event()
    this.eventNotFound = false
    this.albums = []
    this.albumsCount = 0
    this.albumsPerPage = DefaultAlbumsPerPage
    this.photographsPerPage = DefaultPhotographsPerPage
    this.photographs = []
    this.tagIds = []
    this.tagValue = new InputStore(string().optional())
    this.tagKey = undefined
    this.isFilteringByFaceRecognition = false
    this.albumsView = false
    this.activities = []
    this.activity = null
    this.photographCount = 0
    this.dateRange = [null, null]
    this.isLoadingEventDetails = false
    this.isLoadingEventPhotographs = false
    this.isLoadingEventAlbums = false
    this.isLoadingEventActivities = false
    this.page = 1
    this.error = null

    const password = this.getLocalStoragePassword()
    this.isAuthenticated = !!password
    this.password = password
  }
  //FETCH FUNCTIONS
  async fetchEventDetails(): Promise<void> {
    this.startLoadingEventDetails()
    try {
      const response = await this.feedService.fetchEventDetailsInformationByLandingPath(
        this.eventLandingPath
      )
      runInAction(() => {
        this.eventId = response.id
        this.event = response
        if (this.event.isPrivate) {
          const password = this.getLocalStoragePassword()
          this.isAuthenticated = !!password
          this.password = password
        }
        if (isNil(this.event.activityId)) {
          this.fetchEventActivities()
        }
        this.stopLoadingEventDetails()
      })
    } catch (e: any) {
      this.eventNotFound = e.message === 'Event not found'
      this.stopLoadingEventDetails()
    }
  }
  async fetchEventAlbums() {
    this.startLoadingEventAlbums()

    const limit = this.albumsPerPage
    const offset = (this.page - 1) * this.albumsPerPage

    try {
      const response = await this.feedService.fetchFeedAlbums({
        limit: limit,
        offset: offset,
        order: {
          field: FeedOrderFields.TAKEN_DATE,
          sort: FeedOrderSorts.DESC,
        },
        eventId: this.event.id,
        includeHiddenEventAlbums: true,
        activityId: this.activity?.id ?? undefined,
        dateFrom: this.dateRange[0] ?? undefined,
        dateTo: this.dateRange[1] ?? undefined,
        password: this.password ?? undefined,
      })

      runInAction(() => {
        this.albums = response.albums
        this.albumsCount = response.count
        this.stopLoadingEventAlbums()
      })
    } catch (e) {
      this.stopLoadingEventAlbums()
      this.error = e
    }
  }

  async fetchEventPhotographs(isSearching = false, tagValue?: string): Promise<void> {
    this.startLoadingEventPhotographs()
    if (isSearching) {
      this.page = 1
      this.photographs = []
    }

    if (tagValue) {
      const response = await this.feedService.fetchTag(tagValue)
      if (response) {
        this.tagIds = [response.id]
        this.tagKey = response.key
      } else {
        this.tagIds = []
        this.tagKey = undefined
        this.photographs = []
        this.photographCount = 0
        this.stopLoadingEventPhotographs()
        return
      }
    } else {
      this.tagIds = []
      this.tagKey = undefined
    }

    const limit =
      (this.isFilteringByFaceRecognition || this.tagIds.length > 0) &&
      isSearching &&
      !this.event.isFree
        ? DefaultFilteredPhotographsPerNonFreeEvent
        : this.photographsPerPage
    const offset =
      this.isFilteringByFaceRecognition && !this.event.isFree
        ? (this.page - 1) * DefaultFilteredPhotographsPerNonFreeEvent
        : (this.page - 1) * this.photographsPerPage
    try {
      const response = await this.eventService.fetchEventPhotographs(
        this.event.id,
        limit,
        offset,
        this.tagValue.value,
        this.tagKey,
        this.isFilteringByFaceRecognition ? this.authStore.recognitionImageUrl : undefined,
        this.activity?.id ?? undefined,
        this.dateRange[0] ?? undefined,
        this.dateRange[1] ?? undefined,
        this.authStore.isAuthenticated() ? this.authStore.getLoggedUser().id : undefined,
        this.password ?? undefined
      )
      runInAction(() => {
        if (isSearching) {
          this.photographs = response.items
        } else {
          this.photographs.push(...response.items)
        }
        this.photographCount = response.count
        this.setIsAuthenticated(true)
        this.stopLoadingEventPhotographs()
        this.updatePrivateEventAuth(this.password, true)
      })
    } catch (e: any) {
      if (e.message == 'INVALID_CREDENTIALS') {
        this.updatePrivateEventAuth(undefined, false)
      }
      this.stopLoadingEventPhotographs()
    }
  }

  public setPassword(password: string | undefined) {
    this.password = password
  }

  public getLocalStoragePassword(): string | undefined {
    const passwords = localStorage.getItem(LocalStorageKeys.EventPasswords)
    const eventPasswords = passwords ? JSON.parse(passwords) : {}
    return eventPasswords[this.event.id] || undefined
  }

  private updatePrivateEventAuth(password: string | undefined, authenticate: boolean) {
    if (!isNil(password) && authenticate) {
      this.password = password
      const passwords = localStorage.getItem(LocalStorageKeys.EventPasswords)
      const eventPasswords = passwords ? JSON.parse(passwords) : {}
      eventPasswords[this.event.id] = password
      localStorage.setItem(LocalStorageKeys.EventPasswords, JSON.stringify(eventPasswords))
      this.isAuthenticated = true
    } else {
      this.isAuthenticated = false
      this.password = undefined
      const passwords = localStorage.getItem(LocalStorageKeys.EventPasswords)
      const eventPasswords = passwords ? JSON.parse(passwords) : {}
      delete eventPasswords[this.event.id]
      localStorage.setItem(LocalStorageKeys.EventPasswords, JSON.stringify(eventPasswords))
    }
  }

  async fetchEventActivities() {
    this.startLoadingEventActivities()
    try {
      const response = await this.eventService.fetchEventActivities(this.event.id)
      runInAction(() => {
        this.activities = response
        this.stopLoadingEventActivities()
      })
    } catch (e) {
      this.stopLoadingEventActivities()
      this.error = e
    }
  }

  async updatePhotographCount(albumPhotographs: number) {
    this.photographCount = this.photographCount + albumPhotographs
  }

  async clearPhotographCount() {
    this.photographCount = 0
  }

  // MODIFICATION FUNCTIONS
  startLoadingEventDetails() {
    this.isLoadingEventDetails = true
  }
  stopLoadingEventDetails() {
    this.isLoadingEventDetails = false
  }
  startLoadingEventPhotographs() {
    this.isLoadingEventPhotographs = true
  }
  stopLoadingEventPhotographs() {
    this.isLoadingEventPhotographs = false
  }
  startLoadingEventAlbums() {
    this.isLoadingEventAlbums = true
  }
  stopLoadingEventAlbums() {
    this.isLoadingEventAlbums = false
  }

  startLoadingEventActivities() {
    this.isLoadingEventActivities = true
  }
  stopLoadingEventActivities() {
    this.isLoadingEventActivities = false
  }

  setPage(page: number) {
    if (page < 1) {
      throw Error(`Page number can't be less than 1`)
    }
    this.page = page
    this.fetchEventPhotographs(false, '')
  }

  setDateRange(range: [string | null, string | null]) {
    runInAction(() => {
      this.dateRange = range
    })
  }

  setTagIds(values: string[]) {
    this.tagIds = values
  }
  setTagValue(value: string) {
    this.tagValue.setValue(value)
  }
  setActivity(activityName: string | undefined) {
    const activity = this.activities.find((activity) => activity.name === activityName) || null
    this.activity = activity
  }
  setIsFilteringByFaceRecognition(value: boolean) {
    this.isFilteringByFaceRecognition = value
    this.clearPhotographCount()
  }

  setAlbumsView(value: boolean) {
    this.albumsView = value
    this.clearPhotographCount()
  }

  setIsAuthenticated(value: boolean) {
    this.isAuthenticated = value
  }

  fetchNextPage() {
    this.setPage(this.page + 1)
  }

  hasMorePages() {
    const pageCount = Math.ceil(this.photographCount / this.photographsPerPage)
    const areAllAlbumsLoaded =
      (this.isFilteringByFaceRecognition || this.tagValue.value) && !this.event.isFree
    return this.page < pageCount && !areAllAlbumsLoaded
  }

  async downloadFreePhotograph(photographId: string) {
    this.isLoadingFreePhotograph = true
    try {
      const photograph = await this.feedService.downloadFreePhotograph(photographId)
      runInAction(() => {
        this.error = null
        this.isLoadingFreePhotograph = false
      })
      return photograph
    } catch (e) {
      this.isLoadingFreePhotograph = false
      this.error = e
    }
  }
}
