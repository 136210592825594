import React from 'react'
import { FacebookShareButton as ReactShareFacebookButton, FacebookIcon } from 'react-share'

type FacebookShareButtonProps = {
  url: string
  iconSize: number
}

const FacebookShareButton = (props: FacebookShareButtonProps) => (
  <ReactShareFacebookButton url={props.url}>
    <FacebookIcon size={props.iconSize} round={true} enableBackground="true" />
  </ReactShareFacebookButton>
)

export default FacebookShareButton
