import React, { useState } from 'react'
import * as mobx from 'mobx'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import InputWrapper from '../../../../../../../components/UI/Forms/InputWrapper'
import DiscountPercentage from 'shared/models/QuantityDiscount'
import Button from 'components/UI/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { QuantityDiscountDisplay } from '../../AddDiscountModal'
import Album from 'shared/models/Album'

type DiscountArrayProps = {
  value: DiscountPercentage[]
  onChange: (val: DiscountPercentage[]) => void
  options: { type: QuantityDiscountDisplay; imagePrice: number; album: Album }
}
const DiscountArray = ({ value, onChange, options }: DiscountArrayProps) => {
  const { t } = useTranslation()
  const discountPercentagesCurrentValue = mobx.toJS(value)
  const [discountRange, setDiscountRange] = useState(
    discountPercentagesCurrentValue ? discountPercentagesCurrentValue : []
  )

  const handleChangeNumberOfPictures = (val: number, index: number) => {
    discountRange[index].numberOfPictures = val
    setDiscountRange([...discountRange])
    onChange(discountRange)
  }

  const handleChangeDiscountPercentage = (val: number, index: number) => {
    if (options.type === QuantityDiscountDisplay.PERCENTAGE) {
      discountRange[index].discountPercentage = val
    } else {
      discountRange[index].discountPercentage = Number(
        100 - (val / (discountRange[index].numberOfPictures * options.imagePrice)) * 100
      )
    }
    setDiscountRange([...discountRange])
    onChange(discountRange)
  }

  const handleDeleteDiscountRange = (discountRangeIndex: number) => {
    discountRange.splice(discountRangeIndex, 1)
    setDiscountRange([...discountRange])
    onChange([...discountRange])
  }

  const handleNewDiscountRange = () => {
    if (discountRange.length >= 5) return
    else {
      const discount: DiscountPercentage = {
        numberOfPictures: 0,
        discountPercentage: 0,
      }
      setDiscountRange([...discountRange, discount])
      onChange(discountRange)
    }
  }

  return (
    <div className="overflow-y-auto w-full max-h-50">
      {discountRange.map((arrayObject, index) => (
        <div key={index} className="mb-1">
          <div className="grid grid-cols-7 gap-2 items-center">
            <div className="col-span-3 items-end">
              <InputWrapper
                value={arrayObject.numberOfPictures}
                onChange={(val: number) => {
                  handleChangeNumberOfPictures(val, index)
                }}
                label={t('Number of pictures').toString()}
                type="number"
                onlyNumeric
                labelExtraStyles="text-sm"
              />
            </div>
            <div className="col-span-3 self-end">
              <InputWrapper
                value={
                  options.type === QuantityDiscountDisplay.PERCENTAGE
                    ? Number(arrayObject.discountPercentage).toFixed(0)
                    : (
                        arrayObject.numberOfPictures *
                        options.imagePrice *
                        (1 - arrayObject.discountPercentage / 100)
                      ).toFixed(0)
                }
                onChange={(val: number) => handleChangeDiscountPercentage(val, index)}
                label={
                  options.type === QuantityDiscountDisplay.PERCENTAGE
                    ? t('Discount ( % )').toString()
                    : `${t('Fixed amount').toString()} (${options.album.currency?.toUpperCase()})`
                }
                type="number"
                onlyNumeric
                labelExtraStyles="text-sm"
              />
            </div>
            <div className="col-span-1 self-end">
              <div className="hidden md:block ml-2.5">
                <Button btnType="TertiaryAction" onClick={() => handleDeleteDiscountRange(index)}>
                  {t('Delete')}
                </Button>
              </div>
              <FontAwesomeIcon
                icon={faTrashAlt}
                className="md:hidden text-lumepic-grey h-4 w-4 cursor-pointer"
                onClick={() => handleDeleteDiscountRange(index)}
              />
            </div>
          </div>
        </div>
      ))}
      {discountRange.length < 5 && (
        <div className="flex justify-between mt-3">
          <Button btnType="TertiaryAction" onClick={() => handleNewDiscountRange()}>
            <div className="flex gap-1.5 items-center mb-0.5">
              <p className="mb-0 text-sm font-normal md:text-base">{t('Add range')}</p>
              <FontAwesomeIcon icon={faPlus} className="w-4 h-4 text-black cursor-pointer" />
            </div>
          </Button>
        </div>
      )}
    </div>
  )
}

export default observer(DiscountArray)
