import React from 'react'
import PhotographerOtherUsesDesktop from './PhotographerOtherUsesDesktop'
import PhotographerOtherUsesMobile from './PhotographerOtherUsesMobile'

const PhotographerOtherUses = () => {
  return (
    <>
      <div className="hidden sm:block">
        <PhotographerOtherUsesDesktop />
      </div>
      <div className="block sm:hidden">
        <PhotographerOtherUsesMobile />
      </div>
    </>
  )
}

export default PhotographerOtherUses
