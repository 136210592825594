import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import 'moment/locale/es'
import Modal from '../../UI/Modal'
import Button from 'components/UI/Button'
import ConfigService from '../../../config'
import { ModalButtonsDisplay, ModalSize } from '../../UI/Modal/Modal'
import { FeatureFlags } from 'config/constants/featureFlags'
import { isNil } from 'lodash'
import AuthStore from 'stores/AuthStore'
import storesContext from 'providers/storesContext'
import { getFeatureFlagValue } from 'shared/utility'

type VerifyOrLinkAccountModalProps = {
  authStore: AuthStore
  handleClose: () => void
  handleVerifyEmail: () => void
  open: boolean
  redirectToStripeOnboarding: () => void
}

const VerifyOrLinkAccountModal = ({
  handleClose,
  handleVerifyEmail,
  authStore,
  open,
  redirectToStripeOnboarding,
}: VerifyOrLinkAccountModalProps) => {
  const { t } = useTranslation()
  const { featureFlagsStore } = useContext(storesContext)!

  const enableMPOnboarding = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.ENABLE_MERCADOPAGO_ONBOARDING,
    false
  )

  const enableStripeOnboarding = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.ENABLE_STRIPE_ONBOARDING,
    true
  )

  const user = authStore.getLoggedUser()

  const redirectToMercadoPago = () => {
    window.location.href = ConfigService.buildMercadoPagoCredentials(user.id)
  }

  return (
    <>
      <Modal
        onCancel={handleClose}
        opened={open}
        buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
        modalSize={ModalSize.MEDIUM_SMALL}
      >
        <div className="flex flex-col justify-center py-3">
          <div className="text-gray-800 text-center font-extrabold text-lg">
            {t('To start selling')}
          </div>
          <p className="my-4 text-center">
            {enableMPOnboarding && !user.isVerified && !user.mercadoPagoVerified
              ? t(
                  'Remember that to start selling in Lumepic it is required to verify your email and sync your Lumepic account with MercadoPago'
                )
              : enableMPOnboarding && user.isVerified && !user.mercadoPagoVerified
              ? t(
                  'Remember that to start selling in Lumepic it is required to sync your Lumepic account with MercadoPago'
                )
              : enableStripeOnboarding && !user.isVerified && isNil(user.stripeAccountId)
              ? t(
                  'Remember that to start selling in Lumepic it is required to verify your email and sync your Lumepic account with Stripe'
                )
              : enableStripeOnboarding &&
                !user.isVerified &&
                !isNil(user.stripeAccountId) &&
                !user.stripeOnboardingCompleted
              ? t(
                  'Remember that to start selling in Lumepic it is required to verify your email and finish your onboarding'
                )
              : enableStripeOnboarding &&
                user.isVerified &&
                !isNil(user.stripeAccountId) &&
                !user.stripeOnboardingCompleted
              ? t(
                  'Remember that to start selling in Lumepic it is required to finish your onboarding'
                )
              : !user.isVerified
              ? t('Remember that to start selling in Lumepic it is required to verify your email')
              : t(
                  'Remember that to start selling in Lumepic it is required to sync your Lumepic account with Stripe'
                )}
          </p>
          <div className="flex justify-center w-full gap-2 px-2 align-center">
            {!user.isVerified && (
              <Button btnType="SecondaryAction" onClick={handleVerifyEmail}>
                {t('Verify email')}
              </Button>
            )}
            {!user.mercadoPagoVerified && enableMPOnboarding && (
              <Button btnType="SecondaryAction" onClick={redirectToMercadoPago}>
                {t('Sync to MercadoPago')}
              </Button>
            )}
            {isNil(user.stripeAccountId) && enableStripeOnboarding && (
              <Button btnType="SecondaryAction" onClick={redirectToStripeOnboarding}>
                {t('Sync to Stripe')}
              </Button>
            )}
            {!user.stripeOnboardingCompleted &&
              enableStripeOnboarding &&
              !isNil(user.stripeAccountId) && (
                <Button btnType="SecondaryAction" onClick={redirectToStripeOnboarding}>
                  {t('Finish onboarding')}
                </Button>
              )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default observer(VerifyOrLinkAccountModal)
