import PhotographInterface from '../../services/RequestInterfaces/Photograph/Photograph.interface'
import { Tag } from './Tag'
import { isNil } from 'lodash'

export class Photograph {
  static init(data: PhotographInterface): Photograph {
    const photograph = new Photograph()
    photograph.id = data.id
    photograph.extension = data.extension
    photograph.type = data.type
    photograph.price = data.price
    photograph.width = data.width
    photograph.height = data.height
    photograph.size = data.size
    photograph.url = data.url
    photograph.thumbnailUrl = data.thumbnailUrl
    photograph.albumId = data.albumId
    photograph.isSuspended = data.isSuspended
    photograph.originalFileName = data.originalFileName
    photograph.takenDate = data.takenDate
    photograph.extraInfo = data.extraInfo
    photograph.tags = !isNil(data.tags) ? data.tags.map((tag) => Tag.init(tag)) : []
    photograph.createdAt = data.createdAt
    photograph.updatedAt = data.updatedAt
    photograph.deletedAt = data.deletedAt

    return photograph
  }

  id: string

  extension: string

  type: string

  price: number

  width?: number

  height?: number

  size: number

  url: string

  thumbnailUrl: string

  albumId: string

  isSuspended: boolean

  originalFileName: string

  takenDate: Date

  extraInfo: string

  tags: Tag[]

  createdAt!: Date

  updatedAt!: Date

  deletedAt?: Date

  static clone(data: Photograph): Photograph {
    const clone = new Photograph()
    clone.id = data.id
    clone.extension = data.extension
    clone.type = data.type
    clone.price = data.price
    clone.width = data.width
    clone.height = data.height
    clone.size = data.size
    clone.url = data.url
    clone.thumbnailUrl = data.thumbnailUrl
    clone.albumId = data.id
    clone.isSuspended = data.isSuspended
    clone.originalFileName = data.originalFileName
    clone.takenDate = data.takenDate
    clone.extraInfo = data.extraInfo
    clone.tags = !isNil(data.tags) ? data.tags.map((tag) => Tag.init(tag)) : []
    clone.createdAt = data.createdAt
    clone.updatedAt = data.updatedAt
    clone.deletedAt = data.deletedAt

    return clone
  }
}
