import * as paths from '../../Paths'
import SharedRoutes from '../Shared/routes'
import MyAlbums from '../../../containers/MyAlbums/MyAlbums'
import MyAlbumsAlbumDetail from '../../../containers/MyAlbums/AlbumDetails/'
import MyAccount from '../../../containers/MyAccount/MyAccount'
import Purchases from '../../../containers/Purchases'
import PaymentSuccess from '../../../containers/Payment/PaymentSuccess'
import PaymentFailure from '../../../containers/Payment/PaymentFailure/PaymentFailure'
import Sales from '../../../containers/Sales'

const routes = [
  {
    path: paths.MY_ALBUMS,
    name: 'myAlbums',
    exact: true,
    redirectPath: null,
    component: MyAlbums,
  },
  {
    path: paths.MY_ALBUM_DETAIL,
    name: 'myAlbumDetail',
    exact: false,
    redirectPath: null,
    component: MyAlbumsAlbumDetail,
  },
  {
    path: paths.MY_ACCOUNT,
    name: 'myAccount',
    exact: false,
    redirectPath: null,
    component: MyAccount,
  },
  {
    path: paths.PURCHASES,
    name: 'purchases',
    exact: false,
    redirectPath: null,
    component: Purchases,
  },
  {
    path: paths.PAYMENT_SUCCESS,
    name: 'paymentSuccess',
    exact: false,
    redirectPath: null,
    component: PaymentSuccess,
  },
  {
    path: paths.PAYMENT_FAILURE,
    name: 'paymentFailure',
    exact: false,
    redirectPath: null,
    component: PaymentFailure,
  },
  {
    path: paths.SALES,
    name: 'sales',
    exact: false,
    redirectPath: null,
    component: Sales,
  },
]

export default SharedRoutes.concat(routes)
