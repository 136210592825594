import React from 'react'
import { observer } from 'mobx-react'
import SaleLineItem from './SaleLineItem'
import { PurchaseLineItem } from 'shared/models/PurchaseLineItem'

type SaleRowProps = {
  lineItem: PurchaseLineItem
}
const SaleRowItem = ({ lineItem }: SaleRowProps) => {
  return (
    <div className="flex flex-col w-full gap-3">
      <SaleLineItem lineItem={lineItem} />
    </div>
  )
}

export default observer(SaleRowItem)
