import React, { useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { TextField, MenuItem, InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { GoogleLogin } from '@react-oauth/google'
import { Countries } from 'shared/util/countries'
import Button from 'components/UI/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faGlobe, faLock, faUser } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import PhotographerRegisterStore from '../PhotographerRegisterStore'
import storesContext from 'providers/storesContext'
import { useHistory } from 'react-router'
import * as paths from '../../../../../routing/Paths'
import { observer } from 'mobx-react'
import Tracker from 'shared/tracking'

const validationSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  countryCode: yup.string().required('Country is required'),
})

const PhotographerRegisterForm = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { authStore, locationStore } = useContext(storesContext)!
  const [photographerRegisterStore] = useState(
    () => new PhotographerRegisterStore(authStore, locationStore)
  )

  const handleRegister = async (values) => {
    Tracker.register()
    await photographerRegisterStore.register(values)
    history.push(paths.MY_ACCOUNT)
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      countryCode: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleRegister(values)
    },
  })

  const googleLoginSuccess = (credentialResponse) => {
    photographerRegisterStore.googleAuth(credentialResponse).then(() => {
      authStore.requestPhotographerRole().then(() => {
        history.push(paths.MY_ACCOUNT)
      })
    })
  }

  const googleLoginError = () => {
    toast.error(t('Error on login'), {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  }

  return (
    <form onSubmit={formik.handleSubmit} className="w-full container mx-auto flex justify-center">
      <div className="flex flex-col gap-4 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
        <div className="flex flex-col gap-4 sm:flex-row sm:gap-2.5">
          <TextField
            required
            fullWidth
            id="firstName"
            name="firstName"
            label={t('First Name')}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span className="flex items-center justify-center bg-lumepic-dark_grey p-1 rounded-sm">
                    <FontAwesomeIcon icon={faUser} className="text-white text-sm" />
                  </span>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            fullWidth
            id="lastName"
            name="lastName"
            label={t('Last Name')}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span className="flex items-center justify-center bg-lumepic-dark_grey p-1 rounded-sm">
                    <FontAwesomeIcon icon={faUser} className="text-white text-sm" />
                  </span>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <TextField
          required
          fullWidth
          id="email"
          name="email"
          label={t('Email')}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="flex items-center justify-center bg-lumepic-dark_grey p-1 rounded-sm">
                  <FontAwesomeIcon icon={faEnvelope} className="text-white text-sm" />
                </span>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          required
          fullWidth
          id="password"
          name="password"
          label={t('Password')}
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="flex items-center justify-center bg-lumepic-dark_grey p-1 rounded-sm">
                  <FontAwesomeIcon icon={faLock} className="text-white text-sm" />
                </span>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          required
          fullWidth
          id="countryCode"
          name="countryCode"
          select
          label={t('Country')}
          value={formik.values.countryCode}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
          helperText={formik.touched.countryCode && formik.errors.countryCode}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="flex items-center justify-center bg-lumepic-dark_grey p-1 rounded-sm">
                  <FontAwesomeIcon icon={faGlobe} className="text-white text-sm" />
                </span>
              </InputAdornment>
            ),
          }}
        >
          {Countries.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              <span className={`ml-2 mx-2 fi fi-${country.code.toLowerCase()}`} />
              {country.name.toUpperCase()}
            </MenuItem>
          ))}
        </TextField>
        <Button
          btnType="PrimaryAction"
          onClick={formik.handleSubmit}
          isLoading={photographerRegisterStore.isLoading || authStore.isLoading}
          loadingText={t('Loading')}
        >
          {t('Register')}
        </Button>
        <div className="flex items-center my-2">
          <div className="w-full border-b border-lumepic-dark_grey" />
          <span className="px-2 text-xs text-lumepic-dark_grey">{t('Or')}</span>
          <div className="w-full border-b border-lumepic-dark_grey" />
        </div>
        <div className="flex justify-center">
          <GoogleLogin
            size="large"
            width="150px"
            text="signup_with"
            useOneTap
            onSuccess={googleLoginSuccess}
            onError={googleLoginError}
          />
        </div>
      </div>
    </form>
  )
}

export default observer(PhotographerRegisterForm)
