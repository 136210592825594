import React, { useState, useContext } from 'react'
import { observer } from 'mobx-react'
import StoresContext from '../../../providers/storesContext'
import 'moment/locale/es'
import { useParams, useHistory } from 'react-router-dom'
import UpdatePasswordStore from './UpdatePasswordStore'
import InputWrapper from '../../../components/UI/Forms/InputWrapper'
import { useTranslation } from 'react-i18next'
import Spinner from 'components/UI/Spinner'
import logoWhite from '../../../assets/icons/v2/logo-white.svg'
import forgotPasswordImage from '../../../assets/images/ForgotPasswordImage.svg'
import LoginBackground from '../../../assets/images/LoginBackground.svg'
import logoColored from '../../../assets/icons/v2/logo-colored.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey, faLock } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/UI/Button'
import ServerError from 'components/UI/ServerError'
import { toast } from 'react-toastify'

const ResetPassword = () => {
  const history = useHistory()
  const { authStore } = useContext(StoresContext)!
  const { token } = useParams<{ token: string }>()
  const { t } = useTranslation()

  const [updatePasswordStore] = useState(() => new UpdatePasswordStore(authStore))
  const { isLoading, password, rePassword, error } = updatePasswordStore

  const handleChangePassword = (val: string) => {
    updatePasswordStore.changePassword(val)
  }

  const handleChangeRePassword = (val: string) => {
    updatePasswordStore.changeRePassword(val)
  }

  const handleSubmit = () => {
    updatePasswordStore.requestResetPassword(token).then((result) => {
      if (result !== false) {
        updatePasswordStore.reset()
        toast.success(t('Password reset successfully!'), {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        history.push('/auth')
      }
    })
  }

  const passwordIcon = (
    <span
      className="flex items-center mb-1 ml-px w-5 h-5 rounded-sm"
      style={{ backgroundColor: 'rgb(99, 99, 99)' }}
    >
      <FontAwesomeIcon icon={faLock} className="pl-1 pr-px pb-px text-sm text-white" />
    </span>
  )

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className="min-h-primaryMobile md:min-h-primaryDesktop bg-bg_details md:pt-3 pb-20">
        <div className="flex md:h-80vh md:px-3">
          <div className="w-5/12 hidden md:flex flex-col justify-between rounded-l-lg bg-primary">
            <img src={logoWhite} className="mt-3 ml-3" alt="Logo" width="70px" height="70px" />
            <div className="flex justify-center py-10 pl-12 lg:py-16 lg:pl-20">
              <img src={forgotPasswordImage} alt="photographer" className="w-full xl:w-3/4" />
            </div>
            <p className="mb-3 ml-3.5 text-xl font-semibold text-white">
              {t('Hey! Welcome back') + '!'}
            </p>
          </div>
          <div className="w-full md:w-7/12 flex flex-col items-center justify-start min-h-primaryMobile bg-white rounded-lg md:justify-center md:rounded-l-none md:min-h-full">
            <div className="relative md:hidden">
              <img src={LoginBackground} alt="background" />
              <div className="absolute flex items-center gap-1.5 left-7" style={{ bottom: '47%' }}>
                <img src={logoColored} className="w-8 h-8" alt="logo" />
                <h2 className="mb-0 text-2xl font-semibold text-lumepic-light_black">
                  {t('Reset password')}
                </h2>
              </div>
            </div>
            <div className="w-full px-8 py-4">
              <div className="w-full hidden md:flex gap-1.5 items-center">
                <h2 className="mb-1 text-4xl font-medium text-lumepic-light_black">
                  {t('Reset password')}
                </h2>
                <div className="w-8 h-8 rounded-full flex items-center justify-center bg-primary_light">
                  <FontAwesomeIcon icon={faKey} className="text-lg text-lumepic-light_black" />
                </div>
              </div>
              <p className="hidden mb-3 text-lumepic-light_black md:block">
                {t('Enter a new password')}
              </p>
              {updatePasswordStore.error && <ServerError message={t(error).toString()} />}
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit()
                }}
              >
                <div className="flex flex-col gap-2">
                  <InputWrapper
                    placeholder={'Contraseña'}
                    onChange={handleChangePassword}
                    inputStore={password}
                    fixedunit={passwordIcon}
                    extraClassNames="pl-10"
                    password={true}
                  />
                  <InputWrapper
                    placeholder={'Confirmar Contraseña'}
                    onChange={handleChangeRePassword}
                    inputStore={rePassword}
                    fixedunit={passwordIcon}
                    extraClassNames="pl-10"
                    password={true}
                  />
                </div>
                <Button btnType="PrimaryAction" onClick={() => {}} extraStyle="w-full mt-3">
                  {t('Update')}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(ResetPassword)
