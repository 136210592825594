import axios from './axios'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { AxiosResponse } from 'axios'
import DiscountPercentage from 'shared/models/QuantityDiscount'
import CreateQuantityDiscountRequestInterface from './RequestInterfaces/Discount/CreateQuantityDiscountRequest.interface'
import { DiscountType } from 'shared/models/BaseDiscount'
import NewAlbumPhotographRequest from './RequestInterfaces/Album/NewAlbumPhotographRequest'
import CreateAlbumInterface from './RequestInterfaces/Album/CreateAlbum.interface'
import AlbumInterface from './RequestInterfaces/Album/Album.interface'
import EditAlbumInterface from './RequestInterfaces/Album/EditAlbum.interface'
import Album from '../shared/models/Album'
import { Paginated } from './RequestInterfaces/Paginated'
import FileService from './FileService'
import { QuantityDiscountDisplay } from 'containers/MyAlbums/CreateOrUpdateAlbumSteps/Steps/AddDiscountModal/AddDiscountModal'
import UploadCoverPhotographResponse from './RequestInterfaces/Album/UploadCoverPhotographResponse'
import UploadCoverPhotographRequest from './RequestInterfaces/Album/UploadCoverPhotographRequest'
import { isNil } from 'lodash'
import BatchCreatePhotographsResponse from './RequestInterfaces/Album/BatchUploadPhotographsResponse'

class AlbumService {
  private fileService: FileService
  constructor() {
    this.fileService = new FileService()
  }

  clearAlbumQuantityDiscount(albumId: string, token: string) {
    return axios
      .put(
        paths.clearAlbumQuantityDiscount(albumId),
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  createAlbumQuantityDiscount(
    discountPercentages: DiscountPercentage[],
    albumId: string,
    token: string,
    display: QuantityDiscountDisplay
  ) {
    const payload: CreateQuantityDiscountRequestInterface = {
      type: DiscountType.PURCHASE_QUANTITY,
      discountPercentages,
      albumId,
      display,
    }

    return axios
      .post(paths.DISCOUNT, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response)
      .catch(parseAxiosErrorResponse)
  }

  fetchAlbums(
    limit: number,
    skip: number,
    token: string
  ): Promise<{ albums: Album[]; count: number }> {
    const queryParams = {
      pagination: {
        limit,
        skip,
      },
      order: {
        field: 'CREATED_AT',
        sort: 'DESC',
      },
    }
    return axios
      .get(paths.GET_ALBUMS, {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse<Paginated<AlbumInterface>>) => {
        const albums = response.data.items.map((album) => Album.init(album))
        return { albums, count: response.data.count }
      })
      .catch(parseAxiosErrorResponse)
  }

  deleteAlbum(albumId: string, token: string) {
    return axios
      .delete(paths.deleteAlbum(albumId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  async batchCreatePhotographs(
    images: File[],
    albumId: string,
    token: string
  ): Promise<BatchCreatePhotographsResponse> {
    let hasImagesWithoutMetadata = false
    const payloadPromises = images.map(async (image) => {
      try {
        const metadata = await this.fileService.parseMetadata(image)
        if (isNil(metadata)) {
          hasImagesWithoutMetadata = true
        }
        const photographPayload: NewAlbumPhotographRequest = {
          albumId: albumId,
          type: image.type,
          size: image.size,
          originalFileName: image.name,
          width: metadata?.width ?? metadata?.extraInfo?.ImageWidth,
          height: metadata?.height ?? metadata?.extraInfo?.ImageHeight,
          takenDate:
            metadata?.takenDate ??
            metadata?.extraInfo?.CreateDate ??
            metadata?.extraInfo?.DateTimeOriginal ??
            metadata?.extraInfo?.ModifyDate ??
            new Date(),
          extraInfo: metadata?.extraInfo ?? undefined,
        }
        return photographPayload
      } catch (error) {
        console.error(`Error parsing metadata`, error)
      }
    })
    const payload = await Promise.all(payloadPromises)
    const createPhotographReq = await axios.post(
      paths.BATCH_CREATE_PHOTOGRAPHS,
      { photographs: payload },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { albumId: albumId },
      }
    )
    return { data: createPhotographReq.data, hasImagesWithoutMetadata }
  }

  public async fetchAlbumDetails(albumId: string): Promise<AlbumInterface> {
    return axios
      .get(paths.albumDetails(albumId))
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  public async fetchAlbum(albumId: string, token: string): Promise<AlbumInterface> {
    return axios
      .get(paths.albumDetail(albumId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  createAlbum(data: CreateAlbumInterface, token: string): Promise<AlbumInterface> {
    return axios
      .post(paths.NEW_ALBUM, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  editAlbum(data: EditAlbumInterface, token: string): Promise<AlbumInterface> {
    return axios
      .put(paths.updateAlbumById(data.id), data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  suspendAlbum(albumId: string, token: string): Promise<void> {
    return axios
      .post(paths.suspendAlbumById(albumId), albumId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
  }

  activateAlbum(albumId: string, token: string): Promise<void> {
    return axios
      .post(paths.activateAlbumById(albumId), albumId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  async createCoverPhotograph(
    type: string,
    albumId: string,
    token: string
  ): Promise<UploadCoverPhotographResponse> {
    const photographPayload: UploadCoverPhotographRequest = {
      type,
    }
    return axios
      .post(paths.createCoverPhotograph(albumId), photographPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }
}

export default AlbumService
