import React from 'react'
import PublishedAlbumsBarChart from './components/PublishedAlbumsBarChart'
import SalesByActivityPieChart from './components/SalesByActivityPieChart'

type PhotographerAlbumsInsightsProps = {
  publishedAlbums: { year: number; month: number; albumCount: number }[]
  salesByActivity: { activity: string; salesAmountInUsd: number; salesCount: number }[]
}

const PhotographerAlbumsInsights = ({
  publishedAlbums,
  salesByActivity,
}: PhotographerAlbumsInsightsProps) => {
  return (
    <div className="flex flex-col gap-2 md:flex md:flex-row md:justify-between md:gap-4 md:m-2">
      <div className="w-full p-4 bg-white rounded-lg shadow border border-bg_details border-rounded-lg md:max-h-full">
        <PublishedAlbumsBarChart publishedAlbums={publishedAlbums} />
      </div>
      <div className="w-full p-4 flex flex-col max-h-80 md:w-1/3 md:max-h-full bg-white border border-bg_details border-rounded-lg rounded-lg shadow">
        <SalesByActivityPieChart salesByActivity={salesByActivity} />
      </div>
    </div>
  )
}

export default PhotographerAlbumsInsights
