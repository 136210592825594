import React from 'react'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type PhotographerFactsLineProps = {
  text: string
  icon: IconDefinition
}

const PhotographerFactsLine = ({ text, icon }: PhotographerFactsLineProps) => {
  return (
    <div className="text-lumepic-light_black font-regular sm:font-light text-sm lg:text-lg xl:text-xl text-center">
      <FontAwesomeIcon icon={icon} className="text-primary_dark text-lg mr-1.5 mt-1" />
      {text}
    </div>
  )
}

export default PhotographerFactsLine
