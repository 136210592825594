import React from 'react'
import PhotographerFactsDesktop from './PhotographerFactsDesktop'
import PhotographerFactsMobile from './PhotographerFactsMobile'

const PhotographerFacts = () => {
  return (
    <>
      <div className="hidden sm:block">
        <PhotographerFactsDesktop />
      </div>
      <div className="block sm:hidden">
        <PhotographerFactsMobile />
      </div>
    </>
  )
}

export default PhotographerFacts
