import React from 'react'
import PhotographersHowItWorksMobile from './PhotographersHowItWorksMobile'
import PhotographersHowItWorksDesktop from './PhotographersHowItWorksDesktop'

const PhotographersHowItWorks = () => {
  return (
    <>
      <div className="hidden sm:block">
        <PhotographersHowItWorksDesktop />
      </div>
      <div className="block sm:hidden">
        <PhotographersHowItWorksMobile />
      </div>
    </>
  )
}

export default PhotographersHowItWorks
