import React from 'react'
import Button from 'components/UI/Button'
import ConfigService, { AssetNames } from 'config'
import { scrollToElement } from 'containers/PhotographersLanding/utils/utils'
import { useTranslation } from 'react-i18next'
import Tracker from 'shared/tracking'

const PhotographersHowItWorksMobile = () => {
  const { t } = useTranslation()
  return (
    <div className="pt-16 bg-gradient-to-b from-lumepic-light_black to-lumepic-black relative">
      <h3 className="text-center text-white text-2xl font-semibold mb-4">{t('How it works?')}</h3>
      <div className="relative flex gap-2 py-16 px-4">
        <img
          src={ConfigService.getAsset(
            AssetNames.PHOTOGRAPHERS_LANDING_HOW_IT_WORKS_1,
            'photographers-landing'
          )}
          className="object-cover object-center absolute w-40 -bottom-32 z-10"
          alt="Photographers landing page hero section"
        />
        <div className="w-full flex justify-end">
          <p className="w-1/2 text-white font-light text-center">
            {t('Create an album and upload your photos 100% free')}
          </p>
        </div>
      </div>
      <div className="relative z-20 flex justify-end gap-2 py-16 bg-gradient-to-b from-lumepic-white to-lumepic-medium_grey px-4">
        <div className="w-full flex justify-start">
          <p className="w-1/2 text-black font-light text-center">
            {t('Receive payments directly into your bank account')}
          </p>
        </div>
        <img
          src={ConfigService.getAsset(
            AssetNames.PHOTOGRAPHERS_LANDING_HOW_IT_WORKS_2,
            'photographers-landing'
          )}
          className="object-cover object-center absolute w-40 -bottom-32 z-10"
          alt="Photographers landing page hero section"
        />
      </div>
      <div className="relative z-20 flex gap-2 py-16 bg-gradient-to-b from-lumepic-grey to-lumepic-black px-4">
        <img
          src={ConfigService.getAsset(
            AssetNames.PHOTOGRAPHERS_LANDING_HOW_IT_WORKS_3,
            'photographers-landing'
          )}
          className="object-cover object-center absolute w-40 -bottom-32 z-10"
          alt="Photographers landing page hero section"
        />
        <div className="w-full flex justify-end">
          <p className="w-1/2 text-white font-light text-center">
            {t('Visualize your sales performance in real time and optimize your strategy')}
          </p>
        </div>
      </div>
      <div className="relative z-20 flex justify-center items-center py-16 bg-gradient-to-b from-lumepic-white to-lumepic-medium_grey px-4">
        <Button
          btnType="PrimaryAction"
          onClick={() => {
            Tracker.goToPhotographersRegister()
            scrollToElement('photographer-register')
          }}
        >
          <span className="text-sm font-semibold">{t('Create an account')}</span>
        </Button>
      </div>
    </div>
  )
}

export default PhotographersHowItWorksMobile
