import { makeAutoObservable, runInAction } from 'mobx'
import { Countries } from '../shared/util/countries'
import { Event } from '../shared/models/Event'
import { EventService } from '../services/EventService'
import LocationStore from './LocationStore'
import AuthStore from './AuthStore'

/**
 * EventStore
 * @class EventStore
 * @description Class that handles the global state for events at an application level.
 * Do not confuse with global state class EventsStore!! This class should ONLY contain application level state and logic for events.
 * TODOS:
 * - Pagination.
 */
class EventStore {
  private error: any
  public isLoadingEvents: boolean
  public events: Map<string, Event[]>
  public disabledEvents: Event[]

  constructor(
    private readonly eventService: EventService,
    private readonly locationStore: LocationStore,
    private readonly authStore: AuthStore
  ) {
    this.error = null
    this.isLoadingEvents = false
    this.events = new Map()
    this.disabledEvents = []
    makeAutoObservable(this)
    this.fetchEvents(
      locationStore.userLocation.country.code,
      false,
      this.authStore.isAuthenticated() ? this.authStore.getLoggedUser().id : undefined
    )
  }

  async fetchEvents(countryCode?: string, forceFetch?: boolean, userId?: string): Promise<Event[]> {
    const country = Countries.find((c) => c.code === countryCode)
    if (!country) {
      return []
    }
    if (!forceFetch && this.events.has(country.code)) {
      return this.events.get(country.code) || []
    }
    runInAction(() => {
      this.isLoadingEvents = true
    })
    try {
      const response = await this.eventService.fetchEventsByCountryCode(country.code, userId)
      runInAction(() => {
        this.events.set(country.code, response)
        this.isLoadingEvents = false
        this.disabledEvents = response.filter((event) => !event.accreditationApproved)
      })
      return this.events.get(country.code) || []
    } catch (e: any) {
      this.error = e?.response
      this.isLoadingEvents = false
      return []
    }
  }

  getEvent(eventId?: string): Event | undefined {
    if (!eventId) {
      return undefined
    }
    const events = Array.from(this.events.values()).flat()
    return events.find((e) => e.id === eventId)
  }

  getEventsForCountry(countryCode: string): Event[] {
    return this.events.get(countryCode) || []
  }

  startLoading() {
    this.isLoadingEvents = true
  }

  stopLoading() {
    this.isLoadingEvents = false
  }

  reset() {
    this.error = null
    this.isLoadingEvents = false
  }
}

export default EventStore
