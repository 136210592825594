import ActivityInterface from '../Activity/Activity.interface'
import AlbumInterface from '../Album/Album.interface'
import EventInterface from '../Event/Event.interface'
import UserInterface from '../User/User.interface'

export enum CodeDiscountUnit {
  PERCENTAGE = 'PERCENTAGE',
  CURRENCY = 'CURRENCY',
}

export enum CodeDiscountUsability {
  FIDELITY_CODE = 'FIDELITY_CODE',
  ONE_TIME_USE = 'ONE_TIME_USE',
  UNLIMITED = 'UNLIMITED',
}

export enum PurchaseCodeDiscountValidity {
  ALBUM = 'ALBUM',
  ALBUM_OWNER = 'ALBUM_OWNER',
  BUYER = 'BUYER',
  ACTIVITY = 'ACTIVITY',
  EVENT = 'EVENT',
  UNLIMITED = 'UNLIMITED',
}

export enum CodeDiscountApplication {
  PURCHASE_TOTAL = 'PURCHASE_TOTAL',
  PLATFORM_COMMISSION = 'PLATFORM_COMMISSION',
}

export interface CodeDiscountInterface {
  id: string
  code: string
  amount: number
  description: string
  expirationDate: Date
  unit: CodeDiscountUnit
  usability: CodeDiscountUsability
  application: CodeDiscountApplication
  validity: PurchaseCodeDiscountValidity
  ownerId: string
}

export interface ValidateCodeDiscountInterface {
  discount: CodeDiscountInterface
  photographIds: string[]
}

export interface NewCodeDiscountInterface {
  id: string
  code: string
  isSuspended: boolean
  amount: number
  unit: CodeDiscountUnit
  usability: CodeDiscountUsability
  description: string
  ownerId: string
  validity: PurchaseCodeDiscountValidity
  application: CodeDiscountApplication
  albumId?: string
  buyerId?: string
  activityId?: string
  eventId?: string
  validFrom?: Date
  expirationDate?: Date
  album?: AlbumInterface
  buyer?: UserInterface
  activity?: ActivityInterface
  event?: EventInterface
}
