import React from 'react'
import AlbumCard from './AlbumCard/AlbumCard'
import { Grid } from '@mui/material'
import Album from '../../../shared/models/Album'
import { observer } from 'mobx-react'

type AlbumCardsProps = {
  albums: Album[]
  eventAlbumsView?: boolean
}

const AlbumCards = (props: AlbumCardsProps) => {
  return (
    <>
      {props.albums.length > 0 && (
        <Grid container className="grow pb-4 pt-2" spacing={2}>
          {props.albums.map(
            (album: Album, index) =>
              album.coverPhotographSrc && (
                <Grid xs={12} sm={6} md={6} lg={4} xl={4} key={`${album.id}-${index}`} item>
                  <AlbumCard album={album} eventAlbumsView={props.eventAlbumsView} />
                </Grid>
              )
          )}
        </Grid>
      )}
    </>
  )
}

export default observer(AlbumCards)
