import React from 'react'
import SkeletonSection from './SkeletonSection'

const SkeletonLoaderSalesInsights = () => {
  return (
    <div className="w-full py-4">
      <SkeletonSection />
      <SkeletonSection />
    </div>
  )
}

export default SkeletonLoaderSalesInsights
