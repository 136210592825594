import ConfigInterface, { LandingConfigInterface } from './interfaces/config.interface'

const API_URL = 'https://api.spotted.com.uy'
const WEB_URL = 'https://www.lumepic.com'

const USER_CARTS_DATABASE_URL = 'https://spotted-production-default-rtdb.firebaseio.com/'

const GOOGLE_MAPS_API_KEY = 'AIzaSyBJhRmbLijzvdmTtecaHjW7ElXuZtSb6z0'
const GOOGLE_CLIENT_ID = '499685098094-jldrc4mgj6rl1bnnjj4u6cme6a7aocse.apps.googleusercontent.com'

const LAUNCHDARKLY_CLIENT_SIDE_ID = '65181825ffe5a212c190a229'

const PRINT_PHOTOGRAPH_REQUEST_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSeJiG21xP4DhMUn77lHRPDnkn9vBFYcNGmH5YnPHvhqJu16Yw/viewform'

const USER_EVENT_PHOTOGRAPHS__REQUEST_FORM = 'https://forms.gle/SEvXiYRVGsMjswD56'

const EVENT_REQUEST_FORM = 'https://forms.gle/eYJVip5Ru7UFSs4V9'

const STRIPE_PAYMENT_URL = 'https://buy.stripe.com/00geWNcJm90F02Q001'

const DATE_LOCALE = 'es'

const IP_API_URL = 'https://pro.ip-api.com/json/?key=cb1Meawn3RsccxZ'

const PERMANENT_USER_UPLOADS_BUCKET = 'https://production-user-uploads-perm.s3.amazonaws.com'

const MERCADOPAGO_CLIENT_ID = '2341251549303299'
const MERCADOPAGO_RESPONSE_TYPE = 'code'
const MERCADOPAGO_PLATFORM_ID = 'mp'
const MERCADOPAGO_REDIRECT_URI = 'https://www.lumepic.com/my-account'
const WHATSAPP_CONTACT_US_NUMBER = '59894634746'
const INDEPENDENT_ALBUM_PHOTOGRAPHS_LIMIT = '5000'
const EVENT_ALBUM_PHOTOGRAPHS_LIMIT = '7000'
const LANDING_CONFIG: LandingConfigInterface = {
  FEATURED_ACTIVITY_1_EN: {
    activityId: '9eae4153-70fa-448a-86d1-7e1031c7135b',
    desktopAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/production/Landing-AthleticsDesktop.png',
    mobileAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/production/Landing-AthleticsMobile.png',
  },
  FEATURED_ACTIVITY_1_ES: {
    activityId: '9eae4153-70fa-448a-86d1-7e1031c7135b',
    desktopAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/production/Landing-AtletismosDesktop.png',
    mobileAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/production/Landing-AtletismosMobile.png',
  },
  FEATURED_ACTIVITY_2: {
    activityId: 'b65c6046-be49-4761-a910-be51e391e14d',
    desktopAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/production/Landing-SurfDesktop.png',
    mobileAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/production/Landing-SurfMobile.png',
  },
  FEATURED_ACTIVITY_3: {
    activityId: 'c71d4237-bc4e-40be-adf3-7de232097307',
    desktopAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/production/Landing-KiteDesktop.png',
    mobileAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/production/Landing-KiteMobile.png',
  },
  FEATURED_ACTIVITY_4: {
    activityId: '166d070b-c1c5-4f81-8b45-c4690995883c',
    desktopAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/production/Landing-RugbyDesktop.png',
    mobileAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/production/Landing-RugbyMobile.png',
  },
  FEATURED_ACTIVITY_5: {
    activityId: '88ec585c-b7ae-4467-9212-aae10e6421ab',
    desktopAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/production/Landing-MotocrossDesktop.png',
    mobileAssetName:
      'https://spotted-web-assets.s3.amazonaws.com/production/Landing-MotocrossMobile.png',
  },
  OTHER_FEATURED_ACTIVITIES: [
    {
      activityId: '9a2c30f2-1059-4cb7-a037-607103c26553',
      name: 'Cycling',
    },
    {
      activityId: 'f1099a72-e6c6-4827-b24a-999937e5e99b',
      name: 'Football',
    },
    {
      activityId: '47971625-8813-42e0-9e04-e5021fb2bdb3',
      name: 'Motoring',
    },
    {
      activityId: '57de5646-d5cf-469d-8eaf-b49034430270',
      name: 'Crossfit',
    },
    {
      activityId: 'dbbe75c7-9d92-4ed3-a15a-cbaf654f1d9a',
      name: 'Basketball',
    },
    {
      activityId: '50d98922-5ca4-468b-88bc-0fe198625dc7',
      name: 'Swimming',
    },
  ],
}

const production: ConfigInterface = {
  API_URL,
  WEB_URL,
  DATE_LOCALE,
  GOOGLE_CLIENT_ID,
  GOOGLE_MAPS_API_KEY,
  WHATSAPP_CONTACT_US_NUMBER,
  MERCADOPAGO_CLIENT_ID,
  MERCADOPAGO_RESPONSE_TYPE,
  MERCADOPAGO_PLATFORM_ID,
  MERCADOPAGO_REDIRECT_URI,
  LANDING_CONFIG,
  USER_CARTS_DATABASE_URL,
  LAUNCHDARKLY_CLIENT_SIDE_ID,
  PRINT_PHOTOGRAPH_REQUEST_FORM_URL,
  USER_EVENT_PHOTOGRAPHS__REQUEST_FORM,
  EVENT_REQUEST_FORM,
  STRIPE_PAYMENT_URL,
  PERMANENT_USER_UPLOADS_BUCKET,
  INDEPENDENT_ALBUM_PHOTOGRAPHS_LIMIT,
  EVENT_ALBUM_PHOTOGRAPHS_LIMIT,
  IP_API_URL,
}

export default production
