import { Autocomplete, CircularProgress, Paper, TextField } from '@mui/material'
import { isNil } from 'lodash'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Country } from 'shared/models/Country'
import '/node_modules/flag-icons/css/flag-icons.min.css'

const CustomPaper = (props) => {
  return <Paper {...props} style={{ color: '#636363', fontFamily: 'Euclid Circular A' }} />
}

export type DropdownOption = {
  value: string
  label: string
  path?: string
}

type CountryDropdownProps = {
  availableCountries: Country[]
  selectedValue: Country
  onChange: (option: Country) => void
  dropdownLabel?: string
  isLoading?: boolean
}

const CountryDropdown = ({
  availableCountries,
  dropdownLabel = 'Country',
  selectedValue,
  onChange,
  isLoading = false,
}: CountryDropdownProps) => {
  const { t } = useTranslation()
  const [options] = useState<Country[] | null>(availableCountries)

  const handleChange = (value: DropdownOption | null) => {
    if (!isNil(value)) {
      const country = availableCountries.find((c) => c.code === value.value)
      onChange(country!)
    }
  }

  return (
    <>
      <Autocomplete
        disableClearable={dropdownLabel === t('Country')}
        renderOption={(props, option) => {
          const flagClass = `ml-2 -mr-2 fi fi-${option.value.toLowerCase()}`
          return (
            <li
              {...props}
              key={option.value}
              className="flex -gap-1 w-full cursor-pointer hover:sm:bg-bg_details"
            >
              <span {...props} className={flagClass}></span>
              <span {...props} key={option.value + 'flag'}>
                {t(option.label)}
              </span>
            </li>
          )
        }}
        filterOptions={(options, state) => {
          const inputValue = state.inputValue.toLowerCase()
          return options.filter((option) => {
            const translatedLabel = t(option.label).toLowerCase()
            const originalLabel = option.label.toLowerCase()
            return (
              translatedLabel.includes(inputValue) ||
              originalLabel.includes(inputValue) ||
              option.value.toLowerCase().includes(inputValue)
            )
          })
        }}
        sx={{ width: '100%' }}
        blurOnSelect
        PaperComponent={CustomPaper}
        autoComplete
        className="border-2 rounded-md border-lumepic-grey :hover-none"
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(e: any, v: DropdownOption | null) => {
          handleChange(v)
        }}
        value={{
          value: selectedValue.code,
          label: `${selectedValue.name}`,
        }}
        options={options!
          .map((option) => ({
            value: option.code,
            label: `${option.name}`,
          }))
          .sort((a, b) => -b.label[0].localeCompare(a.label[0]))}
        loading={isLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={dropdownLabel}
            className="hover:border-0"
            sx={{
              input: {
                '&::placeholder': {
                  color: '#636363',
                  fontFamily: 'Euclid Circular A',
                  fontWeight: '500',
                  opacity: 1,
                },
              },
              '& .MuiInputBase-root': {
                padding: '1px 5px',
                background: '#ffffff',
                flexFlow: 'row',
              },
              fieldset: {
                border: '0px',
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <React.Fragment>
                  <span
                    className={`text-xl h-4 -mx-1 fi fi-${selectedValue.code.toLowerCase()}`}
                  ></span>
                  {params.InputProps.startAdornment}
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  )
}

export default observer(CountryDropdown)
