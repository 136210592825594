import i18n from 'i18n'
import * as yup from 'yup'

const phoneRegExp = /^[0-9]{8,13}$/

const lowercaseAndNumbersRegex = /^[a-z0-9._-]+$/
export const updateProfileValidationSchema = () => {
  return yup.object({
    alias: yup
      .string()
      .required(() => i18n.t('Alias is required') as string)
      .matches(lowercaseAndNumbersRegex, () =>
        i18n.t('Only lowercase letters, numbers, periods, hyphens, and underscores are allowed')
      )
      .max(30, () => i18n.t('The alias must have a maximum of 30 characters')),
    firstName: yup.string().required(() => i18n.t('The name is required')),
    lastName: yup.string().required(() => i18n.t('The last name is required')),
    phonePrefix: yup.string().required(() => i18n.t('The prefix is required')),
    phoneNumber: yup.string().matches(phoneRegExp, {
      message: () => i18n.t('Invalid number'),
      excludeEmptyString: true,
    }),
    instagramUsername: yup.string().optional(),
    facebookUsername: yup.string().optional(),
    countryCode: yup.string().optional(),
  })
}
