import { isNil } from 'lodash'
import FeatureFlagInterface from 'services/RequestInterfaces/FeatureFlag/FeatureFlag.interface'

export class FeatureFlag {
  public static init(data?: FeatureFlagInterface): FeatureFlag {
    const featureFlag = new FeatureFlag()
    if (isNil(data)) {
      return featureFlag
    }
    featureFlag.id = data.id
    featureFlag.key = data.key
    featureFlag.type = data.type
    if (featureFlag.type === 'boolean') {
      featureFlag.value = data.value === 'true'
    } else {
      featureFlag.value = data.value
    }

    return featureFlag
  }

  id: string

  key: string

  value: string | boolean | number

  type: string

  public static clone(data: FeatureFlag): FeatureFlag {
    const clone = new FeatureFlag()
    clone.id = data.id
    clone.key = data.key
    clone.value = data.value
    clone.type = data.type

    return clone
  }
}
