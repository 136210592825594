import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Modal, { ModalSize } from 'components/UI/Modal/Modal'
import EditPriceStore from './EditPriceStore'
import ServerError from '../../../../../components/UI/ServerError'
import InputWrapper from '../../../../../components/UI/Forms/InputWrapper'
import { Photograph } from '../../../../../shared/models/Photograph'
import storesContext from 'providers/storesContext'

type EditPriceModalProps = {
  photograph: Photograph
  open: boolean
  onSuccess: (photographId: string) => void
  handleClose: () => void
}

const EditPriceModal = ({ photograph, open, handleClose, onSuccess }: EditPriceModalProps) => {
  const { t } = useTranslation()
  const { authStore } = useContext(storesContext)!
  const [editPriceStore] = useState(() => new EditPriceStore(authStore, photograph))
  const { price, error } = editPriceStore

  const handleChangeImagePrice = (val: number) => {
    editPriceStore.changePrice(val)
  }

  const onHandleEdit = async () => {
    await editPriceStore.updatePrice(photograph.id)
    onSuccess(photograph.id)
  }

  useEffect(() => {
    if (open) {
      editPriceStore.changePrice(photograph.price)
    }
  }, [open, photograph?.price])

  return (
    <Modal
      onSuccess={onHandleEdit}
      onCancel={handleClose}
      loadingOk={editPriceStore.isSaving}
      okMessage={t('Update price').toString()}
      opened={open}
      modalSize={ModalSize.SMALL}
    >
      <div className="w-full grid grid-rows-1 gap-2 pt-4 px-2 mb-2">
        <div className="text-lg text-gray-800 font-extrabold text-center">
          {t('Edit photograph price')}
        </div>
        {error && <ServerError message={t(error).toString()} />}
        <InputWrapper
          placeholder={t('Enter new price').toString()}
          onPressEnter={onHandleEdit}
          onChange={handleChangeImagePrice}
          inputStore={price}
          label={t('Price').toString()}
          onlyNumeric
          autoFocus
        />
      </div>
    </Modal>
  )
}

export default observer(EditPriceModal)
