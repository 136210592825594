import React from 'react'
import { useTranslation } from 'react-i18next'

const PrivateAlbumHeader = () => {
  const { t } = useTranslation()

  return (
    <section className="mb-3 mt-4 rounded-md bg-lumepic-light_black p-3">
      <div className="flex flex-col gap-1">
        <h2 className="text-3xl font-bold text-primary">{t('Private album')}</h2>
        <p className="text-white text-base">
          {t('To be able to see the photographs, you need to enter the password.')}
        </p>
      </div>
    </section>
  )
}

export default PrivateAlbumHeader
