import { Grid, Skeleton } from '@mui/material'
import React from 'react'
import { isMobileDevice } from 'shared/utility'

const SkeletonLoaderFeed = ({ screenBreak = 12 }) => {
  return (
    <>
      <Grid container className="grow pb-4" spacing={2}>
        {[...Array(9)].map((_, index) => (
          <Grid xs={12} sm={12} md={screenBreak} lg={4} xl={4} item key={index}>
            <div className="w-full overflow-hidden flex flex-col gap-2 bg-white rounded-md p-2">
              <Skeleton variant="rounded" height={isMobileDevice() ? 175 : 320} />
              <Skeleton variant="rounded" height={40} />
              {[...Array(4)].map((_, idx) => (
                <div className="flex gap-2 items-center" key={idx}>
                  <Skeleton variant="circular" height={25} width={25} />
                  <Skeleton
                    variant="rounded"
                    height={20}
                    width={idx === 0 ? 150 : idx === 1 ? 200 : idx === 2 ? 130 : 175}
                  />
                </div>
              ))}
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default SkeletonLoaderFeed
