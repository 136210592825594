import React, { useEffect, useState, useContext, useRef } from 'react'
import { observer } from 'mobx-react'
import StoresContext from '../../providers/storesContext'
import SaleItems from './components/SaleItems'
import SalesRowEmpty from './components/SaleRowEmpty'
import { useTranslation } from 'react-i18next'
import PurchaseStore from 'containers/Purchases/PurchaseStore'
import { Purchase, PurchaseStatus } from 'shared/models/Purchase'
import Spinner from 'components/UI/Spinner'
import SkeletonLoaderPurchases from 'components/UI/Loaders/Skeleton/SkeletonLoaderPurchases'
import SaleItemDetails from './components/SaleItemDetails'
import { isNil } from 'lodash'
import { SetPageTitle } from '../../shared/utility'

const Sales = () => {
  SetPageTitle('My Sales')
  const { t } = useTranslation()
  const { authStore } = useContext(StoresContext)!
  const [purchaseStore] = useState(() => new PurchaseStore(authStore))
  const [saleDesktopDetailsOpened, setSaleDesktopDetailsOpened] = useState<Purchase | null>(null)
  const loggedUser = authStore.getLoggedUser()

  const { isLoading } = purchaseStore

  const initialRender = useRef(true)

  const isMdScreen = window.innerWidth > 768

  useEffect(() => {
    if (initialRender.current && purchaseStore.userPurchases.length === 0) {
      purchaseStore
        .fetchPurchases(PurchaseStatus.APPROVED, authStore.getLoggedUser().id)
        .then(() => {
          isMdScreen && setSaleDesktopDetailsOpened(purchaseStore.userPurchases[0])
        })
      initialRender.current = false
    }
  }, [])

  const loadingSpinner = (
    <div className="w-full bg-white rounded-lg mt-2">
      <Spinner divStyles="flex justify-center py-2 items-center" size={40} />
    </div>
  )

  return (
    <>
      <div className="bg-bg_section_titles border-b mb-2 pb-3 pt-4">
        <div className="container mx-auto flex gap-1.5 items-end">
          <h2 className="text-3xl pt-3 pl-3 sm:pl-0 font-extrabold md:text-4xl text-lumepic-light_black">
            {t('My Sales')}
          </h2>
          <span className="text-lg pt-3 text-lumepic-dark_grey font-semibold sm:text-2xl">
            {!isLoading && `(${purchaseStore.purchaseCount})`}
          </span>
        </div>
      </div>
      <div className="h-full bg-bg_details min-h-primaryMobile mb-3 md:w-full md:min-h-primaryDesktop">
        <div className="container mx-auto block md:flex md:gap-5">
          <div
            className={`w-full rounded-lg ${
              !initialRender.current && purchaseStore.userPurchases.length === 0
                ? 'md:w-full'
                : 'md:w-2/5'
            } `}
          >
            <div className="w-full">
              {!isLoading && (
                <div className="text-lumepic-light_black rounded-t-lg bg-white py-2 md:mt-4">
                  <span className="px-3 font-semibold text-xl text-lumepic-light_black">
                    {t('Sales history')}
                  </span>
                </div>
              )}
              {purchaseStore.userPurchases.length > 0 ? (
                <SaleItems
                  purchaseStore={purchaseStore}
                  saleDesktopDetailsOpened={saleDesktopDetailsOpened}
                  setSaleDesktopDetailsOpened={setSaleDesktopDetailsOpened}
                />
              ) : !isLoading ? (
                <SalesRowEmpty />
              ) : null}

              {isLoading && purchaseStore.page > 1 && loadingSpinner}
              {isLoading && purchaseStore.page === 1 && <SkeletonLoaderPurchases />}
            </div>
          </div>
          {isMdScreen &&
            purchaseStore.userPurchases.length > 0 &&
            !isNil(saleDesktopDetailsOpened) && (
              <SaleItemDetails
                sale={saleDesktopDetailsOpened}
                loggedUser={loggedUser}
                saleDesktopDetailsOpened={saleDesktopDetailsOpened}
                index={
                  purchaseStore.purchaseCount -
                  1 -
                  purchaseStore.userPurchases.indexOf(saleDesktopDetailsOpened) +
                  1
                }
              />
            )}
        </div>
      </div>
    </>
  )
}

export default observer(Sales)
