import React, { useState } from 'react'
import Modal from '../Modal'
import { ModalButtonsDisplay, ModalSize } from '../Modal/Modal'
import ShareModalContent from './components/ShareModalContent'

type ShareModalProps = {
  photographId?: string
  albumId?: string
  alias?: string
  closeModal: () => void
  isModalOpened: boolean
}

const ShareModal = ({
  photographId,
  albumId,
  closeModal,
  isModalOpened,
  alias,
}: ShareModalProps) => {
  const [isCopied, setIsCopied] = useState(false)
  const handleCloseModal = () => {
    closeModal()
    setIsCopied(false)
  }

  return (
    <Modal
      onCancel={handleCloseModal}
      opened={isModalOpened}
      modalSize={ModalSize.SMALL}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
    >
      <ShareModalContent
        albumId={albumId}
        photographId={photographId}
        isCopied={isCopied}
        setIsCopied={setIsCopied}
        alias={alias}
      />
    </Modal>
  )
}

export default ShareModal
