import Button from 'components/UI/Button'
import ConfigService, { AssetNames } from 'config'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PhotographersLandingCustomArrow from '../../../../assets/icons/v2/PhotographersLandingCustomArrow.svg'
import PhotographersLandingCustomArrow2 from '../../../../assets/icons/v2/PhotographersLandingCustomArrow2.svg'
import { scrollToElement } from 'containers/PhotographersLanding/utils/utils'
import Tracker from 'shared/tracking'

const PhotographersHowItWorksDesktop = () => {
  const { t } = useTranslation()
  return (
    <div className="bg-gradient-to-b from-lumepic-light_black to-lumepic-black">
      <div className="overflow-hidden">
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 1200 120"
          xmlns="http://www.w3.org/2000/svg"
          style={{ fill: '#ffffff', width: '100%', height: 77 }}
        >
          <path d="M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z" />
        </svg>
      </div>
      <div className="container mx-auto flex flex-col gap-16 pb-32 pt-16">
        <h3 className="text-center text-2xl md:text-3xl lg:text-4xl text-bg_details font-bold">
          {t('How it works?')}
        </h3>

        <div className="flex justify-between gap-4">
          <div className="flex flex-col gap-4 w-64 mt-32">
            <p className="text-white font-light text-center lg:text-lg">
              {t('Create an album and upload your photos 100% free')}
            </p>
            <img
              src={ConfigService.getAsset(
                AssetNames.PHOTOGRAPHERS_LANDING_HOW_IT_WORKS_1,
                'photographers-landing'
              )}
              className="object-cover object-center"
              alt="Photographers landing page hero section"
            />
          </div>
          <img
            src={PhotographersLandingCustomArrow}
            alt="Custom arrow"
            className="w-16 md:w-20 lg:w-28 xl:w-32"
          />
          <div className="flex flex-col items-center gap-4 w-64">
            <img
              src={ConfigService.getAsset(
                AssetNames.PHOTOGRAPHERS_LANDING_HOW_IT_WORKS_2,
                'photographers-landing'
              )}
              className="object-cover object-center"
              alt="Photographers landing page hero section"
            />
            <p className="text-white font-light text-center lg:text-lg">
              {t('Receive payments directly into your bank account')}
            </p>
            <Button
              btnType="PrimaryAction"
              onClick={() => {
                Tracker.goToPhotographersRegister()
                scrollToElement('photographer-register')
              }}
              extraStyle="mt-16 w-full"
            >
              {t('Try now')}
            </Button>
          </div>
          <img
            src={PhotographersLandingCustomArrow2}
            alt="Custom arrow"
            className="w-16 md:w-20 lg:w-28 xl:w-32"
          />
          <div className="flex flex-col gap-4 mt-32 w-64">
            <p className="text-white font-light text-center lg:text-lg">
              {t('Visualize your sales performance in real time and optimize your strategy')}
            </p>
            <img
              src={ConfigService.getAsset(
                AssetNames.PHOTOGRAPHERS_LANDING_HOW_IT_WORKS_3,
                'photographers-landing'
              )}
              className="object-cover object-center"
              alt="Photographers landing page hero section"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PhotographersHowItWorksDesktop
