import {
  CodeDiscountApplication,
  CodeDiscountUnit,
  CodeDiscountUsability,
  PurchaseCodeDiscountValidity,
} from 'services/RequestInterfaces/Discount/CodeDiscount.interface'

export class CreateNewCodeDiscountDto {
  code: string

  amount: number

  unit: CodeDiscountUnit

  usability: CodeDiscountUsability

  description: string

  expirationDate: Date

  validity: PurchaseCodeDiscountValidity

  application: CodeDiscountApplication

  albumId?: string

  buyerEmail?: string

  activityId?: string

  eventId?: string
}
