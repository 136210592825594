import { faBagShopping } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const CartLineEmpty = () => {
  const { t } = useTranslation()
  return (
    <div
      className="rounded px-4 py-6 mt-1 lg:mt-2 w-full flex flex-col gap-2 items-center justify-center border-dashed border-lumepic-grey bg-white"
      style={{ borderWidth: '1px' }}
    >
      <i>
        <FontAwesomeIcon icon={faBagShopping} className="text-3xl text-lumepic-light_black" />
      </i>

      <p className="font-medium mb-0">{t('No items in cart')}</p>
      <p>
        {t('Go to')}{' '}
        <NavLink
          to="/feed/albums"
          className="no-underline border-b-2 border-primary ml-0.5 text-primary hover:text-primary font-semibold"
        >
          {t('albums')}
        </NavLink>
      </p>
    </div>
  )
}

export default CartLineEmpty
