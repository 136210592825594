import React from 'react'
import { useTranslation } from 'react-i18next'
import PhotographerFeature from './components/PhotographerFeature'
import {
  faBolt,
  faChartSimple,
  faCrown,
  faImage,
  faLock,
  faTag,
} from '@fortawesome/free-solid-svg-icons'
import Button from 'components/UI/Button'
import { scrollToElement } from 'containers/PhotographersLanding/utils/utils'
import Tracker from 'shared/tracking'

const PhotographerOtherUsesDesktop = () => {
  const { t } = useTranslation()
  return (
    <div className="w-full bg-white">
      <div className="overflow-hidden">
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 1200 120"
          xmlns="http://www.w3.org/2000/svg"
          style={{ fill: '#333333', width: '100%', height: 77 }}
        >
          <path d="M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z" />
        </svg>
      </div>
      <div className="container mx-auto pb-40 pt-32">
        <h3 className="text-2xl md:text-3xl lg:text-4xl text-lumepic-light_black font-bold mb-16 text-center">
          {t('Other features')}
        </h3>
        <div className="grid grid-cols-3 gap-8">
          <PhotographerFeature
            icon={faTag}
            title={t('Personalized discounts')}
            description={t('Offer quantity, package or burst discounts to boost your sales.')}
          />
          <PhotographerFeature
            icon={faChartSimple}
            title={t('Sales Dashboard')}
            description={t('Access your sales dashboard at any time, intuitive and transparent.')}
          />
          <PhotographerFeature
            icon={faCrown}
            title={t('Fidelity code')}
            description={t(
              'Enjoy 0% commission on sales to clients you bring to Lumepic for one full year'
            )}
          />
          <PhotographerFeature
            icon={faBolt}
            title={t('Photography community')}
            description={t('Connect with other photographers who share your passion.')}
          />
          <PhotographerFeature
            icon={faLock}
            title={t('Private albums')}
            description={t(
              'Limit access to your albums so that they can only be accessed with a password of your choice.'
            )}
          />
          <PhotographerFeature
            icon={faImage}
            title={t('Albums associated to events')}
            description={t(
              'By associating your albums with events, you will get more exposure and, consequently, sales.'
            )}
          />
        </div>
        <div className="w-full flex justify-center mt-16">
          <Button
            btnType="PrimaryAction"
            onClick={() => {
              Tracker.goToPhotographersRegister()
              scrollToElement('photographer-register')
            }}
          >
            {t('Start selling for free')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PhotographerOtherUsesDesktop
