import { observer } from 'mobx-react'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import StoresContext from '../../../providers/storesContext'
import { useTranslation } from 'react-i18next'
import 'moment/locale/es'
import { isNil } from 'lodash'
import BackButton from 'components/UI/Button/BackButton'
import { useQuery } from 'hooks/useQuery'
import Spinner from 'components/UI/Spinner'
import EventDetailsStore from './EventDetailsStore'
import axios from 'services/axios'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import {
  calculateTotalPhotographsPrice,
  formatUtcToLocaleDate,
  getFeatureFlagValue,
  getFirstNumberOfPicturesForDiscount,
  getQtyDiscountPercentageEarned,
  SetPageTitle,
  updatePackageBarVisibility,
} from '../../../shared/utility'
import Tracker from '../../../shared/tracking'
import * as paths from '../../../routing/Paths'
import FaceRecognitionModal from 'components/AlbumDetails/components/FaceRecognitionModal'
import { PhotographUtils } from 'shared/util/photographs.utils'
import { DropdownOption } from 'components/UI/Dropdown/Dropdown'
import Album from 'shared/models/Album'
import SkeletonLoaderPhotographCard from 'components/UI/Loaders/Skeleton/SkeletonLoaderPhotographCard'
import DetailsPackageBar from 'components/AlbumDetails/components/DetailsPackageBar'
import { TagKeys } from '../../../services/RequestInterfaces/Tag/Tag.interface'
import { toast } from 'react-toastify'
import { FeatureFlags } from 'config/constants/featureFlags'
import EventDetailsContent from './components/EventDetailsContent'
import useHideChatBot from 'hooks/useHideChatBot'
import EventCustomBanner from './components/EventCustomBanner'
import TagFilterModal from 'components/AlbumDetails/components/TagFilterModal'
import EventDetailsFirstRender from './components/FirstRenderFiltersView/EventDetailsFirstRender'
import FirstRenderFiltersViewSkeleton from './components/FirstRenderFiltersView/components/FirstRenderFiltersViewSkeleton'
import DateAndTimeFilterModal from 'components/AlbumDetails/components/DateAndTimeFilter/DateAndTimeFilterModal'
import { eachDayOfInterval } from 'date-fns'
import ItemDetailsFilterAccordion from '../../../components/ItemDetails/components/ItemDetailsFiltersAccordion'
import ItemDetailsSearchResultMessage from 'components/ItemDetails/components/ItemDetailsSearchResultMessage'
import ItemDetailsInformation from 'components/ItemDetails/components/ItemDetailsInformation'
import ItemDetailsDiscountAccordion from 'components/ItemDetails/components/ItemDetailsDiscountAccordion'
import PrivateEventSection from './components/FirstRenderFiltersView/components/PrivateEvents/PrivateEventSection'
import { getURLWithTagFilters } from 'components/ItemDetails/utils/ItemDetailsUtils'
import { TagPackageType } from '../../../shared/models/Cart/Cart'

type EventDetailsParams = {
  landingPath: string
  photographId: string
}

const EventDetails = () => {
  useHideChatBot()
  const { t } = useTranslation()
  const query = useQuery()
  const queryTags = query.getAll('tags')
  const queryTagValue = query.get('tagValue')
  const history = useHistory()
  const { authStore, cartStore, featureFlagsStore } = useContext(StoresContext)!
  const { landingPath } = useParams<EventDetailsParams>()
  const [store] = useState(
    () => new EventDetailsStore(landingPath, authStore, queryTags, queryTagValue || undefined)
  )

  const {
    event,
    photographs,
    isLoadingEventDetails,
    isLoadingEventAlbums,
    isLoadingEventPhotographs,
  } = store

  const initialRender = useRef(true)
  const eventDetailsFirstRenderRef = useRef<HTMLDivElement>(null)
  const eventDetailsFiltersRenderRef = useRef<HTMLDivElement>(null)

  const [notFound, setNotFound] = useState(false)
  const [searchInProgress, setSearchInProgress] = useState(false)
  const [photographToDisplayId, setPhotographToDisplayId] = useState<string | null>(null)
  const [showUploadSelfieModal, setShowUploadSelfieModal] = useState(false)
  const [showFilterByTagModal, setShowFilterByTagModal] = useState(false)
  const [showFilterByDateAndTimeModal, setShowFilterByDateAndTimeModal] = useState(false)
  const defaultInterval: DropdownOption = { value: '60', label: '60 minutes' }
  const [selectedInterval] = useState<DropdownOption>(defaultInterval)
  const [showBuyPackageButton, setShowBuyPackageButton] = useState(false)
  const [buyNowPrice, setBuyNowPrice] = useState<number | null>(null)
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)

  const scrollToEventDetailsFirstRender = () => {
    if (initialRender.current) {
      eventDetailsFirstRenderRef.current?.scrollIntoView({ behavior: 'smooth' })
    } else {
      eventDetailsFiltersRenderRef.current?.scrollIntoView({ behavior: 'smooth' })
      setIsAccordionExpanded(true)
    }
  }

  const isFilteringByTag = store.tagIds.length === 1

  const albumIdsInPackage = useMemo(
    () => new Set(photographs.map((photo) => photo.albumId)),
    [photographs]
  )
  const albumsInPackage = useMemo(
    () => store.albums.filter((album) => albumIdsInPackage.has(album.id)),
    [albumIdsInPackage, store.albums]
  )
  const ownerIdsInPackage = useMemo(
    () => new Set(albumsInPackage.map((album) => album.owner.id)),
    [albumsInPackage]
  )

  const memoizedValues = useMemo(() => {
    const totalPhotographsPrice = calculateTotalPhotographsPrice(photographs)
    const totalPhotographsPriceAfterDiscounts = !isNil(event.quantityDiscount)
      ? (1 - getQtyDiscountPercentageEarned(event.quantityDiscount, photographs) / 100) *
        totalPhotographsPrice
      : 0
    const minNumberOfPhotosForQtyDiscount = !isNil(event.quantityDiscount)
      ? getFirstNumberOfPicturesForDiscount(event.quantityDiscount)
      : null

    return {
      totalPhotographsPrice,
      totalPhotographsPriceAfterDiscounts,
      minNumberOfPhotosForQtyDiscount,
    }
  }, [photographs, event.defaultPackagePrice, event.quantityDiscount])

  const {
    totalPhotographsPrice,
    totalPhotographsPriceAfterDiscounts,
    minNumberOfPhotosForQtyDiscount,
  } = memoizedValues

  const photographBlocksByTimeInterval = PhotographUtils.buildPhotographsBlocksByInterval(
    photographs,
    selectedInterval
  )

  if (!isNil(event?.name)) {
    SetPageTitle(event.name)
  }

  if (notFound) {
    return <Redirect to="/" />
  }
  const goBack = () => {
    if (history.length > 2) {
      history.goBack()
    } else {
      history.push(paths.EVENTS)
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        store.fetchEventDetails().then(() => {
          if (!store.event.isPrivate || (store.event.isPrivate && store.isAuthenticated)) {
            store.fetchEventAlbums().then(() => {
              if (!isNil(queryTagValue) || !store.event.filtersView) {
                setSearchInProgress(true)
                initialRender.current = false
                store.fetchEventPhotographs(true, queryTagValue ?? '')
              }
            })
          }
        })
      } catch (error: any) {
        if (error.message === 'Albums not found') {
          setNotFound(true)
        }
      }
    }

    if (initialRender.current) {
      fetchData()
    }
  }, [landingPath, store.isAuthenticated])

  useEffect(() => {
    if (!store.isLoadingEventPhotographs) {
      const index = photographs.findIndex((photograph) => photograph.id === photographToDisplayId)
      if (index === -1) {
        setPhotographToDisplayId(null)
      } else if (photographs.length - 1 === index && !searchInProgress) {
        store.fetchNextPage()
      }
    }
  }, [photographToDisplayId, store.isAuthenticated])

  //PACKAGE PURCHASE

  const handlePackagePurchaseEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.EVENTS_PACKAGE_PURCHASE,
    false
  )

  useEffect(() => {
    const shouldShowPackageBar = updatePackageBarVisibility(
      isFilteringByTag || store.isFilteringByFaceRecognition,
      store,
      handlePackagePurchaseEnabled,
      totalPhotographsPrice,
      totalPhotographsPriceAfterDiscounts,
      minNumberOfPhotosForQtyDiscount,
      ownerIdsInPackage.size
    )
    setShowBuyPackageButton(shouldShowPackageBar.showBuyPackageButton)
    setBuyNowPrice(shouldShowPackageBar.buyNowPrice)
  }, [photographs])

  const handleBuyPackage = () => {
    if (isFilteringByTag || store.isFilteringByFaceRecognition) {
      const photographs = store.photographs.map((photograph) => {
        return {
          id: photograph.id,
        }
      })

      let tagId: string
      if (isFilteringByTag) {
        tagId = store.tagIds[0]
      }
      if (store.isFilteringByFaceRecognition) {
        const faceTag = store.photographs
          .map((photograph) => photograph.tags)
          .reduce((acc, tags) => {
            return acc.filter((tag) => tags.some((t) => t.id === tag.id))
          })
          .find((tag) => tag.key === TagKeys.Face)
        if (isNil(faceTag)) {
          toast.error(t('Face tag not found'))
        }
        tagId = faceTag!.id
      }
      Tracker.addPackagePaymentInformation(photographs, event)
      cartStore
        .createTagPackagePurchase(tagId!, event.currency, undefined, event)
        .then((purchase) => {
          if (!isNil(purchase)) {
            window.location.href = purchase.paymentUrl!
          }
        })
    }
  }

  const handleAddPackage = () => {
    if (isFilteringByTag || store.isFilteringByFaceRecognition) {
      let tagId: string
      if (isFilteringByTag) {
        tagId = store.tagIds[0]
      }

      if (store.isFilteringByFaceRecognition) {
        const faceTag = store.photographs
          .map((photograph) => photograph.tags)
          .reduce((acc, tags) => {
            return acc.filter((tag) =>
              tags.some((t) => t.id === tag.id || t.taggedUserId == tag.taggedUserId)
            )
          })
          .find((tag) => tag.key === TagKeys.Face)
        if (isNil(faceTag)) {
          toast.error(t('Face tag not found'))
        }
        tagId = faceTag!.id
      }
      Tracker.addPackagePaymentInformation(photographs, event)
      cartStore.addTagEventPackage(
        event,
        albumsInPackage,
        store.photographs,
        store.photographCount,
        TagPackageType.Face,
        tagId!
      )
    } else {
      for (const photograph of store.photographs) {
        const album = store.albums.find((album) => album.id === photograph.albumId)
        if (!isNil(album)) {
          cartStore.addPhotograph(photograph, album)
        }
      }
    }
  }

  const updateURLWithFilters = () => {
    const updatedURL = getURLWithTagFilters(store.tagValue, location)
    history.replace(updatedURL)
  }

  const filterByTagValue = () => {
    initialRender.current = false
    store.clearPhotographCount()
    store.setDateRange([null, null])
    store.setDateRange([null, null])
    store.fetchEventPhotographs(true, store.tagValue.value)
    setShowFilterByTagModal(false)
    updateURLWithFilters()
    setSearchInProgress(true)
    setIsAccordionExpanded(false)
    setIsAccordionExpanded(false)
  }

  const filterByFaceRecognition = () => {
    initialRender.current = false
    store.setTagValue('')
    store.setDateRange([null, null])
    store.clearPhotographCount()
    store.setIsFilteringByFaceRecognition(true)
    store.setAlbumsView(false)
    store.fetchEventPhotographs(true, '')
    updateURLWithFilters()
    setSearchInProgress(true)
    setIsAccordionExpanded(false)
  }

  const handleSearchByActivity = () => {
    if (store.albumsView) {
      store.fetchEventAlbums()
    } else {
      store.fetchEventPhotographs(true, '')
    }
    setSearchInProgress(true)
    setIsAccordionExpanded(false)
  }

  const filterByDateAndTime = () => {
    initialRender.current = false
    store.setTagValue('')
    store.clearPhotographCount()
    if (store.albumsView) {
      store.fetchEventAlbums()
    } else {
      store.fetchEventPhotographs(true, '')
    }
    setShowFilterByDateAndTimeModal(false)
    updateURLWithFilters()
    setSearchInProgress(true)
    setIsAccordionExpanded(false)
  }

  const handleClearFilters = () => {
    initialRender.current = false
    store.setTagValue('')
    store.setDateRange([null, null])
    store.setDateRange([null, null])
    setShowBuyPackageButton(false)
    store.setIsFilteringByFaceRecognition(false)
    store.setActivity(undefined)
    if (store.albumsView) {
      store.fetchEventAlbums()
    } else {
      store.fetchEventPhotographs(true, '')
    }
    store.clearPhotographCount()
    updateURLWithFilters()
    setSearchInProgress(false)
  }

  const handleShowAlbumsView = () => {
    initialRender.current = false
    store.setTagValue('')
    store.setAlbumsView(true)
    store.fetchEventAlbums()
    setSearchInProgress(false)
  }

  const findAlbumForPhotograph = (photographId: string): Album | undefined => {
    const photographIndex = store.photographs.findIndex((photo) => photo.id === photographId)
    if (photographIndex === -1) {
      return undefined
    }

    const albumId = store.photographs[photographIndex].albumId

    const album = store.albums.find((album) => album.id === albumId)
    return album
  }

  const eventDays = useMemo(() => {
    const firstDate = store.event.firstPhotographDate
    const lastDate = store.event.lastPhotographDate
    if (!firstDate || !lastDate) {
      return []
    }

    const start = new Date(firstDate)
    const end = new Date(lastDate)

    if (end <= start) {
      return []
    }

    return eachDayOfInterval({ start, end }).map((date) => ({
      value: date.toISOString(),
      label: formatUtcToLocaleDate(date),
    }))
  }, [store.event.firstPhotographDate, store.event.lastPhotographDate])

  const isEventLongerThanFiveDays = useMemo(() => {
    const firstDate = event.firstPhotographDate ? new Date(event.firstPhotographDate) : null
    const lastDate = event.lastPhotographDate ? new Date(event.lastPhotographDate) : null
    return firstDate && lastDate
      ? (lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24) > 5
      : false
  }, [event.firstPhotographDate, event.lastPhotographDate])

  const renderFilterAccordion = useMemo(
    () => store.event.faceRecognitionFilter || event.textFilter || !isEventLongerThanFiveDays,
    [store.event.faceRecognitionFilter, event.textFilter, isEventLongerThanFiveDays]
  )

  const loadingSpinner = (
    <Spinner divStyles="flex items-center justify-center mt-5 items-center" size={40} />
  )
  if (cartStore.isLoading) {
    return <Spinner size={40} />
  }

  return (
    <div className="mt-3 mb-24 overflow-hidden">
      <div className="container mx-auto">
        <div className="mb-2 ml-2">
          <BackButton onClick={goBack} />
        </div>
        {event.isPrivate && !store.isAuthenticated ? (
          <PrivateEventSection store={store} />
        ) : (
          <>
            <div className="rounded-md" onClick={scrollToEventDetailsFirstRender}>
              <ItemDetailsInformation
                isLoading={isLoadingEventDetails}
                event={event}
                albumsCount={store.albumsCount}
                photographCount={store.photographCount}
                filtersView={initialRender.current && store.event.filtersView}
              />
            </div>
            {!isNil(event.quantityDiscount) && <ItemDetailsDiscountAccordion event={event} />}
            {initialRender.current &&
            store.event.filtersView &&
            (!event.isPrivate || (event.isPrivate && store.isAuthenticated)) ? (
              <div ref={eventDetailsFirstRenderRef}>
                <EventDetailsFirstRender
                  handleOpenUploadSelfieModal={() => setShowUploadSelfieModal(true)}
                  handleOpenFilterByTagModal={() => setShowFilterByTagModal(true)}
                  filterByFaceRecognition={filterByFaceRecognition}
                  filterByTagValue={filterByTagValue}
                  handleClearFilters={handleClearFilters}
                  handleShowAlbumsView={handleShowAlbumsView}
                  store={store}
                  moreThanFiveDays={isEventLongerThanFiveDays}
                  filterByDateAndTime={isEventLongerThanFiveDays ? undefined : filterByDateAndTime}
                  handleOpenFilterByDateAndTimeModal={
                    isEventLongerThanFiveDays
                      ? undefined
                      : () => setShowFilterByDateAndTimeModal(true)
                  }
                  eventDays={eventDays}
                />
              </div>
            ) : (
              <>
                {!isLoadingEventDetails ? (
                  <div>
                    {renderFilterAccordion &&
                      (!event.isPrivate || (event.isPrivate && store.isAuthenticated)) && (
                        <div ref={eventDetailsFiltersRenderRef}>
                          <ItemDetailsFilterAccordion
                            isAccordionExpanded={isAccordionExpanded}
                            setIsAccordionExpanded={setIsAccordionExpanded}
                            eventDetailsStore={store}
                            handleClearFilters={handleClearFilters}
                            handleOpenUploadSelfieModal={() => setShowUploadSelfieModal(true)}
                            handleSearchByActivity={handleSearchByActivity}
                            handleOpenFilterByTagModal={() => setShowFilterByTagModal(true)}
                            handleOpenFilterByDateAndTimeModal={() =>
                              setShowFilterByDateAndTimeModal(true)
                            }
                            searchInProgress={searchInProgress}
                            moreThanFiveDays={isEventLongerThanFiveDays}
                            days={eventDays}
                          />
                        </div>
                      )}

                    <ItemDetailsSearchResultMessage
                      searchInProgress={searchInProgress}
                      tagValueLength={store.tagValue.value?.length ?? 0}
                      isFilteringByFaceRecognition={store.isFilteringByFaceRecognition}
                      isLoadingEventPhotographs={store.isLoadingEventPhotographs}
                      photographCount={store.photographCount}
                    />
                  </div>
                ) : (
                  <FirstRenderFiltersViewSkeleton />
                )}
                {(!event.isPrivate || (event.isPrivate && store.isAuthenticated)) && (
                  <EventDetailsContent
                    photographToDisplayId={photographToDisplayId}
                    setPhotographToDisplayId={setPhotographToDisplayId}
                    store={store}
                    findAlbumForPhotograph={findAlbumForPhotograph}
                    searchInProgress={searchInProgress}
                  />
                )}
                {(isLoadingEventAlbums || isLoadingEventPhotographs) && store.page === 1 && (
                  <SkeletonLoaderPhotographCard />
                )}
                {(isLoadingEventAlbums || isLoadingEventPhotographs) &&
                  store.page > 1 &&
                  loadingSpinner}
              </>
            )}
          </>
        )}
      </div>

      <FaceRecognitionModal
        opened={showUploadSelfieModal}
        onCancel={() => setShowUploadSelfieModal(false)}
        store={store}
        onSuccess={() => filterByFaceRecognition()}
      />

      <TagFilterModal
        opened={showFilterByTagModal}
        onCancel={() => setShowFilterByTagModal(false)}
        store={store}
        onSuccess={() => filterByTagValue()}
        handleClearFilters={() => handleClearFilters()}
      />
      <DateAndTimeFilterModal
        opened={showFilterByDateAndTimeModal}
        onCancel={() => setShowFilterByDateAndTimeModal(false)}
        store={store}
        days={eventDays}
        onSuccess={() => filterByDateAndTime()}
        handleClearFilters={() => handleClearFilters()}
      />

      {showBuyPackageButton && !isNil(buyNowPrice) && (
        <DetailsPackageBar
          handleBuyPackage={() => handleBuyPackage()}
          handleAddPackage={() => handleAddPackage()}
          photographBlocksByInterval={photographBlocksByTimeInterval}
          packagePrice={buyNowPrice}
          event={event}
          isFilteringByFaceRecognition={store.isFilteringByFaceRecognition}
          photographCount={store.photographCount}
        />
      )}
      {!isNil(event.customBannerSrc) && (
        <EventCustomBanner
          bannerUrl={event.customBannerSrc}
          eventId={event.id}
          eventName={event.name}
        />
      )}
    </div>
  )
}
export default withErrorHandler(observer(EventDetails), axios)
