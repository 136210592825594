import React, { Dispatch, SetStateAction } from 'react'
import { filterTypes } from '../EventDetailsFirstRenderDesktop'
import selfieIcon from '../../../../../../assets/icons/v2/selfieIcon.svg'
import { faCheck, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

type FaceRecognitionButtonDesktopProps = {
  selectedFilter: string
  setSelectedFilter: Dispatch<SetStateAction<filterTypes>>
}

const FaceRecognitionButtonDesktop = ({
  selectedFilter,
  setSelectedFilter,
}: FaceRecognitionButtonDesktopProps) => {
  const { t } = useTranslation()
  return (
    <div
      className={`w-full rounded-lg flex items-center gap-4 p-3 border relative cursor-pointer transition duration-300 animate-[fadeIn_0.1s_ease-in-out] ${
        selectedFilter === filterTypes.FACE_RECOGNITION ? 'bg-lumepic-light_black' : 'bg-bg_details'
      }`}
      onClick={() => setSelectedFilter(filterTypes.FACE_RECOGNITION)}
    >
      {selectedFilter === filterTypes.FACE_RECOGNITION && (
        <div className="absolute right-3 top-3 bg-primary_dark flex items-center justify-center rounded-full p-1">
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
      <div
        className={`rounded-full h-30 flex items-center justify-center p-4 border mb-2 ${
          selectedFilter === filterTypes.FACE_RECOGNITION
            ? 'bg-primary_dark border-lumepic-light_black'
            : 'bg-lumepic-medium_grey border-lumepic-dark_grey'
        }`}
      >
        <img src={selfieIcon} alt="selfie" />
      </div>
      <div className="flex flex-col items-start">
        <div className="flex items-center gap-1">
          <p
            className={`font-medium text-xl mb-0 mt-0.5 ${
              selectedFilter === filterTypes.FACE_RECOGNITION ? 'text-white' : 'text-black'
            }`}
          >
            {t('Face recognition')}
          </p>
          <FontAwesomeIcon icon={faStar} className="text-primary_dark" />
        </div>
        <p
          className={`text-sm text-center ${
            selectedFilter === filterTypes.FACE_RECOGNITION
              ? 'text-lumepic-medium_grey'
              : 'text-lumepic-grey'
          }`}
        >
          {t('Upload a selfie or a photo with your face')}
        </p>
      </div>
    </div>
  )
}

export default FaceRecognitionButtonDesktop
