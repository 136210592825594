import React from 'react'
import { useTranslation } from 'react-i18next'
import PhotographerRegisterForm from './components/PhotographerRegisterForm'
import { observer } from 'mobx-react'

const PhotographerRegister = () => {
  const { t } = useTranslation()
  return (
    <div className="bg-white py-28 sm:py-44 flex flex-col items-center justify-center px-2">
      <h3 className="text-center text-lumepic-light_black text-2xl sm:text-3xl lg:text-4xl font-semibold mb-4">
        {t('Register')}
      </h3>
      <p className="mb-6 text-lumepic-grey text-center">
        {t('Complete the fields below and try Lumepic!')}
      </p>
      <PhotographerRegisterForm />
    </div>
  )
}

export default observer(PhotographerRegister)
