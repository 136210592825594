import i18next from 'i18next'
import React, { useContext, useState } from 'react'
import { DropdownOption } from '../Dropdown/Dropdown'
import { Icon } from '@iconify/react'
import { Popover } from '@mui/material'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import CountrySwitcherModal from '../CountrySwitcherModal/CountrySwitcherModal'
import '/node_modules/flag-icons/css/flag-icons.min.css'
import storesContext from 'providers/storesContext'
import globeIcon from '../../../assets/icons/v2/globe.svg'

export const LanguageOptions: { [key: string]: DropdownOption } = {
  SPANISH: { label: 'ES', value: 'es' },
  ENGLISH: { label: 'EN', value: 'en' },
}

const LanguageSwitcherDropdown = () => {
  const { locationStore } = useContext(storesContext)!
  const currentLanguage = i18next.language

  const { t } = useTranslation()
  const [openActions, setOpenActions] = useState<HTMLDivElement | null>(null)
  const openPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setOpenActions(event.currentTarget)
  }
  const [openCountrySwitcherModal, setOpenCountrySwitcherModal] = useState(false)

  const handleLanguageChange = (selectedOption: DropdownOption) => {
    i18next.changeLanguage(selectedOption.value)
    localStorage.setItem('language', selectedOption.value)
  }

  const flagIcon = (language: string) => {
    return language === 'es' ? (
      <Icon icon="emojione-v1:flag-for-spain" width="20" height="20" />
    ) : (
      <Icon icon="twemoji:flag-united-kingdom" width="20" height="20" />
    )
  }

  const handleCountrySwitcherClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setOpenCountrySwitcherModal(true)
  }

  return (
    <>
      <div
        className="flex items-center justify-center cursor-pointer hover:bg-lumepic-light_grey p-2 mt-0.5 rounded-[50%]"
        onClick={openPopover}
      >
        <img src={globeIcon} alt="globe" className="w-6 h-6" />
      </div>
      <Popover
        open={!isNil(openActions)}
        onClose={() => setOpenActions(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={openActions}
      >
        <div className="flex flex-col items-start min-w-40">
          <p className="w-full flex justify-center font-medium text-lumepicSm pt-2 pb-1 ">
            {t('Language')}
          </p>
          <div className="flex w-full pt-0.5 pb-3 px-1.5">
            <div
              className={`flex items-center justify-center w-1/2 gap-1 py-1 cursor-pointer border-y border-l rounded-l-md ${
                currentLanguage === 'es' && 'bg-lumepic-light_black text-white'
              }`}
              onClick={() => {
                handleLanguageChange(LanguageOptions.SPANISH)
                setTimeout(() => {
                  setOpenActions(null)
                }, 100)
              }}
            >
              <span>{flagIcon('es')}</span>
              <span className="text-lumepicSm font-medium">ES</span>
            </div>
            <div
              className={`flex items-center justify-center w-1/2 gap-1 py-1 cursor-pointer border-y border-r rounded-r-md ${
                currentLanguage === 'en' && 'bg-lumepic-light_black text-white'
              }`}
              onClick={() => {
                handleLanguageChange(LanguageOptions.ENGLISH)
                setTimeout(() => {
                  setOpenActions(null)
                }, 100)
              }}
            >
              <span>{flagIcon('en')}</span>
              <span className="text-lumepicSm font-medium">EN</span>
            </div>
          </div>
          <div className="w-full flex gap-1 flex-col pt-2 pb-2.5 px-2 border-t">
            <span className="w-full flex justify-center text-lumepicSm text-lumepic-light_black">
              {t('Country')}
            </span>
            <p
              className="w-full flex justify-center items-center gap-0.5 pt-0.5 cursor-pointer p-1 rounded-md border bg-white"
              onClick={(e) => {
                setOpenActions(null)
                handleCountrySwitcherClick(e)
              }}
            >
              <span
                className={`w-3 h-3 mt-px fi fi-${locationStore.userLocation.country.code.toLowerCase()}`}
              />
              <span className="font-medium text-lumepicSm text-black py-px">
                {t(locationStore.userLocation.country.name).toUpperCase()}
              </span>
            </p>
          </div>
        </div>
      </Popover>
      {openCountrySwitcherModal && (
        <CountrySwitcherModal
          open={openCountrySwitcherModal}
          onClose={() => setOpenCountrySwitcherModal(false)}
        />
      )}
    </>
  )
}

export default LanguageSwitcherDropdown
