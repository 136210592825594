import React from 'react'
import PhotoGalleryDesktop from './components/PhotoGalleryDesktop'
import { FEED_ALBUMS } from 'routing/Paths'
import { useHistory } from 'react-router'
import PhotoGalleryMobile from './components/PhotoGalleryMobile'
import production from 'config/production'
import staging from 'config/staging'

const getConfigByEnvironment = (environment: string) => {
  if (environment === 'production') {
    return production.LANDING_CONFIG
  } else {
    return staging.LANDING_CONFIG
  }
}

const PhotoGallery = () => {
  const history = useHistory()

  const environment = process.env.REACT_APP_ENVIRONMENT || 'local'
  const config = getConfigByEnvironment(environment)

  const onClickSport = (activityId: string) => {
    if (activityId !== '') {
      history.push(`${FEED_ALBUMS}?activityId=${activityId}`)
    } else {
      history.push(FEED_ALBUMS)
    }
  }

  return (
    <>
      <div className="hidden sm:block bg-white">
        <PhotoGalleryDesktop onClick={onClickSport} config={config} />
      </div>
      <div className="block sm:hidden">
        <PhotoGalleryMobile onClick={onClickSport} config={config} />
      </div>
    </>
  )
}

export default PhotoGallery
