import { makeAutoObservable, runInAction } from 'mobx'
import FeedService, { FeedOrderFields, FeedOrderSorts } from '../services/FeedService'
import Album from '../shared/models/Album'
import AuthStore from './AuthStore'
import SearchBarStore from '../components/UI/SearchBar/SearchBarStore'

const DefaultAlbumsPerPage = 9

export default class FeedStore {
  public albums: Album[]
  public albumDetails?: Album
  public albumCount: number
  public isLoading: boolean
  public error: any
  public page: number
  public albumsPerPage: number
  public pageCount: number
  private readonly feedService: FeedService
  private readonly authStore: AuthStore
  private readonly searchBarStore: SearchBarStore

  // TODO: make searchbarstore mandatory and remove from storesContext
  constructor(authStore: AuthStore, searchBarStore: SearchBarStore) {
    this.albums = []
    // TODO: remove albumDetails and send to albumDetailsStore
    this.albumDetails = undefined
    this.albumCount = 0
    this.isLoading = false
    this.error = false
    this.page = 0
    this.albumsPerPage = DefaultAlbumsPerPage
    this.pageCount = 1
    makeAutoObservable(this)
    this.feedService = new FeedService()
    this.authStore = authStore
    this.searchBarStore = searchBarStore
  }

  resetStore() {
    this.albums = []
    this.albumDetails = undefined
    this.albumCount = 0
    this.isLoading = false
    this.error = false
    this.page = 0
    this.albumsPerPage = DefaultAlbumsPerPage
    this.pageCount = 1
  }

  resetPagination() {
    this.albumDetails = undefined
    this.isLoading = false
    this.error = false
    this.page = 0
    this.albumsPerPage = DefaultAlbumsPerPage
    this.pageCount = 1
  }

  // TODO: Deprecate.
  async fetchAlbums(isSearching = false) {
    runInAction(() => {
      this.isLoading = true
    })
    if (isSearching) {
      this.page = 0
      this.albums = []
    }
    try {
      const response = await this.feedService.fetchFeedAlbums({
        countryCode: this.searchBarStore.countryCode,
        limit: this.albumsPerPage,
        offset: this.page * this.albumsPerPage,
        order: {
          field: FeedOrderFields.TAKEN_DATE,
          sort: FeedOrderSorts.DESC,
        },
        locationId: this.searchBarStore.location?.id,
        activityId: this.searchBarStore.activity?.id,
        eventId: this.searchBarStore.event?.id,
        dateFrom: this.searchBarStore.dateRange[0],
        dateTo: this.searchBarStore.dateRange[1],
      })
      runInAction(() => {
        if (isSearching) {
          this.albums = response.albums
        } else {
          this.albums.push(...response.albums)
        }

        const { count } = response
        this.albumCount = count
        this.pageCount = Math.ceil(count / this.albumsPerPage)
        this.error = null
        this.isLoading = false
      })
    } catch (e) {
      this.isLoading = false
      this.error = e
    }
  }

  async downloadFreePhotograph(photographId: string) {
    this.isLoading = true
    try {
      const photograph = await this.feedService.downloadFreePhotograph(photographId)
      runInAction(() => {
        this.error = null
        this.isLoading = false
      })
      return photograph
    } catch (e) {
      this.isLoading = false
      this.error = e
    }
  }
  fetchNextPage() {
    this.setPage(this.page + 1)
    this.fetchAlbums()
  }

  private setPage(page: number) {
    if (page < 1) {
      throw Error(`Page number can't be less than 1`)
    }
    this.page = page
  }

  hasMorePages() {
    const pageCount = Math.ceil(this.albumCount / this.albumsPerPage)
    return this.page < pageCount
  }

  clearError() {
    this.error = null
  }
}
