import { useState, useEffect } from 'react'
import { AxiosResponse, AxiosRequestConfig } from 'axios'

export default (httpClient: any) => {
  const [error, setError] = useState<any | null>(null)

  const reqInterceptor = httpClient.interceptors.request.use((req: AxiosRequestConfig) => {
    setError(null)
    return req
  })

  const resInterceptor = httpClient.interceptors.response.use(
    (res: AxiosResponse) => res,
    (err: any) => {
      setError(err)
      throw err
    }
  )

  useEffect(() => {
    httpClient.interceptors.request.eject(reqInterceptor)
    httpClient.interceptors.request.eject(resInterceptor)
  }, [httpClient.interceptors.request, reqInterceptor, resInterceptor])

  const errorConfirmedHandler = (): void => {
    setError(null)
  }

  return [error, errorConfirmedHandler]
}
