import React, { useContext } from 'react'
import Modal from '../Modal'
import { ModalButtonsDisplay, ModalSize } from '../Modal/Modal'
import { Countries } from 'shared/util/countries'
import { useTranslation } from 'react-i18next'
import CountryDropdown from '../Dropdown/CountryDropdown'
import { Country } from 'shared/models/Country'
import StoresContext from 'providers/storesContext'
import { useHistory } from 'react-router'

type CountrySwitcherModalProps = {
  open: boolean
  onClose: () => void
}

const CountrySwitcherModal = ({ open, onClose }: CountrySwitcherModalProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { locationStore, eventStore, authStore } = useContext(StoresContext)!
  const userId = authStore.isAuthenticated() ? authStore.getLoggedUser().id : undefined
  const handleChangeCountry = (country: Country) => {
    locationStore.changeUserLocationCountry(country)
    const locationsPromise = locationStore.fetchLocations(country.code, true)
    const eventsPromise = eventStore.fetchEvents(country.code, false, userId)
    Promise.all([locationsPromise, eventsPromise])
    const updatedUrl = `${location.pathname}?countryCode=${country.code}`
    history.replace(updatedUrl)
  }

  return (
    <Modal
      opened={open}
      onCancel={onClose}
      modalSize={ModalSize.SMALL}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
    >
      <div className="flex flex-col gap-4 p-2">
        <span className="text-lg w-full flex justify-center">{t('Select a country')}</span>
        <CountryDropdown
          availableCountries={Countries}
          selectedValue={locationStore.userLocation.country}
          onChange={handleChangeCountry}
        />
      </div>
    </Modal>
  )
}

export default CountrySwitcherModal
