import React, { useEffect, useState, useContext } from 'react'
import { observer } from 'mobx-react'
import { useHistory, useLocation } from 'react-router-dom'
import StoresContext from '../../../providers/storesContext'
import PaymentStore from '../PaymentStore'
import * as path from '../../../routing/Paths'
import Button from 'components/UI/Button'
import ConfigService, { AssetNames } from '../../../config'
import { isNil } from 'lodash'
import Spinner from 'components/UI/Spinner'
import { FeatureFlags } from 'config/constants/featureFlags'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { getFeatureFlagValue } from 'shared/utility'

const PaymentFailure = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const { authStore, featureFlagsStore } = useContext(StoresContext)!
  const [paymentStore] = useState(() => new PaymentStore(authStore))

  const stripeEnabled = getFeatureFlagValue(featureFlagsStore, FeatureFlags.ENABLE_STRIPE, true)

  const mercadoPagoEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.ENABLE_MERCADOPAGO,
    true
  )

  const goToCart = () => {
    history.push(path.CART)
  }

  const goToFeed = () => {
    history.push(path.FEED_ALBUMS)
  }

  const retryPayment = () => {
    if (!isNil(paymentStore.purchase?.paymentUrl)) {
      window.location.href = paymentStore.purchase!.paymentUrl
    }
  }

  useEffect(() => {
    let purchaseFetchAttempt = false
    if (stripeEnabled) {
      const sessionId = new URLSearchParams(location.search).get('session_id')
      if (!isNil(sessionId)) {
        purchaseFetchAttempt = true
        paymentStore.fetchPurchaseByStripeSession(sessionId).then()
      }
    }
    if (mercadoPagoEnabled && !purchaseFetchAttempt) {
      const purchaseId = new URLSearchParams(location.search).get('external_reference')
      if (!isNil(purchaseId)) {
        purchaseFetchAttempt = true
        paymentStore.fetchPurchase(purchaseId).then()
      }
    }
    if (!purchaseFetchAttempt) {
      toast.error(t('Purchase not found.'), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
  }, [])

  if (paymentStore.isLoading) {
    return <Spinner />
  }

  return (
    <div className="container">
      <div className="mx-auto">
        <h2 className="my-3 text-2xl font-semibold sm:text-4xl text-lumepic-light_black">
          {t('Payment Failure')}
        </h2>
      </div>

      <div className="pt-2 pb-6 mx-6 md:grid md:grid-cols-2 md:gap-8">
        <div className="flex justify-center items-center">
          <img
            loading="lazy"
            className="w-1/2"
            src={ConfigService.getAsset(AssetNames.PAYMENT_FAILURE_BANNER)}
            alt="Payment Declined"
          />
        </div>

        <div>
          <div className="flex flex-col my-3 gap-4 items-center">
            <p className="text-base md:text-lg">
              {t('Something went wrong during payment. Charges not applied.')}
            </p>
            <div className="md:text-sm xl:text-base md:w-1/2">
              <Button extraStyle="mb-4 w-full" onClick={retryPayment} btnType="PrimaryAction">
                {t('Retry payment')}
              </Button>
              <Button extraStyle="mb-4 w-full" onClick={goToCart} btnType="PrimaryAction">
                {t('Go to cart')}
              </Button>
              <Button extraStyle="w-full" onClick={goToFeed} btnType="PrimaryAction">
                {t('Go to feed')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(PaymentFailure)
