import React from 'react'
import { observer } from 'mobx-react'
import Cart from '../../../../../../shared/models/Cart/Cart'
import { CartLine, CartLineType } from '../../../../../../shared/models/Cart/CartLine/CartLine'
import MiniCartPackageLine from './MiniCartPackageLine'
import MiniCartPhotographLine from './MiniCartPhotographLine'
import { PackageCartLine } from '../../../../../../shared/models/Cart/CartLine/PackageCartLine'
import { PhotographCartLine } from '../../../../../../shared/models/Cart/CartLine/PhotographCartLine'

type MiniCartLineProps = {
  cart: Cart
  line: CartLine
  groupPhotorgaphLength?: number
}

const MiniCartLine = (props: MiniCartLineProps) => {
  const packagesDiscountAmount = props.cart.getPackagesDiscountAmount()

  let viewItem
  switch (props.line.type) {
    case CartLineType.Package:
      viewItem = (
        <MiniCartPackageLine
          line={props.line as PackageCartLine}
          packagesDiscountAmount={packagesDiscountAmount}
        />
      )
      break
    case CartLineType.Photograph:
      viewItem = <MiniCartPhotographLine line={props.line as PhotographCartLine} />
      break
    default:
      viewItem = null
      break
  }

  return viewItem
}

export default observer(MiniCartLine)
