export interface Popup {
  label: string
  isActive: boolean
  key: string
  fileName: string
  albumId?: string
  redirectTo?: string
}
// const MaratonDeMontevideoPopup: Popup = {
//   label: 'Maratón de Montevideo',
//   isActive: false,
//   key: 'maraton-montevideo',
//   fileName: 'maraton-montevideo.png',
//   albumId: 'a0b0f0c0-0d5e-43b8-817d-7a7cbf2870e6',
// }

// const Nacional6KPopup: Popup = {
//   label: '6K 124 Club Nacional de fútbol',
//   isActive: false,
//   key: '6k-nacional',
//   fileName: '6k-nacional.png',
//   albumId: '08d6f924-6116-4260-80c5-1e9f34b3652d',
// }

const now = new Date()
const initialDate = new Date(2023, 8, 9, 12)
const finalDate = new Date(2023, 8, 15, 12)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HonrarLaVida5kAndMaratonPDEPopup: Popup = {
  label: '5k Honrar la vida y Maratón de Punta Del Este',
  isActive: now >= initialDate && now <= finalDate,
  key: '5k-honrar-la-vida-maraton-pde',
  fileName: '5k-honrar-la-vida-maraton-pde.png',
  redirectTo: '/events',
}

const LumepicBanner: Popup = {
  label: 'Spotted is Lumepic now',
  isActive: false,
  key: 'lumepic-banner',
  fileName: 'lumepic_banner.png',
}

export const ActivePopup = LumepicBanner
