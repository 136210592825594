import React from 'react'
import Benefit from './components/Benefit'
import data from './components/data'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'

const Benefits = () => {
  const [activeIndex, setActiveIndex] = React.useState<number>(0)
  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    detailsChanged(s) {
      setActiveIndex(s.track.details.rel)
    },
    initial: 0,
  })

  const breadcrumbs = (totalSlides: number, activeIndex: number) => (
    <div className="flex gap-1 items-center">
      {[...Array(totalSlides)].map((_, index) => (
        <div
          key={index}
          className={`${
            index === activeIndex
              ? 'bg-primary w-2.5 h-2.5'
              : 'bg-lumepic-dark_grey opacity-80 w-2 h-2'
          }`}
          style={{ borderRadius: '50%' }}
        />
      ))}
    </div>
  )

  return (
    <div className="w-full bg-bg_details py-4">
      <div className="hidden sm:flex justify-around gap-4 px-14">
        {data.map((item, index) => (
          <Benefit
            src={item.src}
            title={item.title}
            content={item.content}
            isPhotographer={item.isPhotographer}
            key={index}
          />
        ))}
      </div>
      <div className="block px-4 sm:hidden">
        <div ref={sliderRef} className="keen-slider">
          {data.map((item, idx) => (
            <div key={idx} className="keen-slider__slide px-2">
              <Benefit
                src={item.src}
                title={item.title}
                content={item.content}
                isPhotographer={item.isPhotographer}
              />
            </div>
          ))}
        </div>
        <div className="sm:hidden w-full flex justify-center mt-2">
          {breadcrumbs(data.length, activeIndex)}
        </div>
      </div>
    </div>
  )
}

export default Benefits
