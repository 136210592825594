import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { filterTypes } from '../EventDetailsFirstRenderDesktop'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import desktopGrid from '../../../../../../assets/icons/v2/desktopGrid.svg'

type AlbumsViewFilterDesktopProps = {
  selectedFilter: string
  setSelectedFilter: Dispatch<SetStateAction<filterTypes>>
}

const AlbumsViewFilterDesktop = ({
  selectedFilter,
  setSelectedFilter,
}: AlbumsViewFilterDesktopProps) => {
  const { t } = useTranslation()
  return (
    <div
      className={`w-full rounded-lg flex items-center gap-4 p-3 border relative cursor-pointer transition duration-300 animate-[fadeIn_0.1s_ease-in-out] ${
        selectedFilter === filterTypes.ALBUMS_VIEW ? 'bg-lumepic-light_black' : 'bg-bg_details'
      }`}
      onClick={() => setSelectedFilter(filterTypes.ALBUMS_VIEW)}
    >
      {selectedFilter === filterTypes.ALBUMS_VIEW && (
        <div className="absolute right-3 top-3 bg-primary_dark flex items-center justify-center rounded-full p-1">
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
      <div
        className={`rounded-full h-30 flex items-center justify-center p-7 border mb-2 ${
          selectedFilter === filterTypes.ALBUMS_VIEW
            ? 'bg-primary_dark border-lumepic-light_black'
            : 'bg-lumepic-medium_grey border-lumepic-dark_grey'
        }`}
      >
        <img src={desktopGrid} className="text-4xl text-black" />
      </div>
      <div className="flex flex-col items-start">
        <div className="flex items-center gap-1">
          <p
            className={`font-medium text-lg mb-0 mt-0.5 ${
              selectedFilter === filterTypes.ALBUMS_VIEW ? 'text-white' : 'text-black'
            }`}
          >
            {t('Show albums')}
          </p>
        </div>
        <p
          className={`text-sm text-center text-lumepic-grey ${
            selectedFilter === filterTypes.ALBUMS_VIEW
              ? 'text-lumepic-medium_grey'
              : 'text-lumepic-grey'
          }`}
        >
          {t('This option will display the event’s albums')}
        </p>
      </div>
    </div>
  )
}

export default AlbumsViewFilterDesktop
