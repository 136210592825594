import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import StoresContext from '../../../../../providers/storesContext'
import 'moment/locale/es'
import Button from 'components/UI/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Event } from 'shared/models/Event'
import {
  Alert,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import getAssignEventSchema from './AssignEventSchema'
import { useFormik } from 'formik'
import { Countries } from '../../../../../shared/util/countries'
import { Country } from '../../../../../shared/models/Country'
import ConfigService, { ConfigKeys } from 'config'
import { User } from 'shared/models/User'
import Spinner from 'components/UI/Spinner'

type AssignEventProps = {
  handleFinish: (event?: Event) => void
  loggedUser: User
  redirectToStripeOnboarding: () => void
}

const AssignEvent = ({
  handleFinish,
  loggedUser,
  redirectToStripeOnboarding,
}: AssignEventProps) => {
  const { t } = useTranslation()
  const { authStore, locationStore, eventStore } = useContext(StoresContext)!
  const [showRequestEventText, setShowRequestEventText] = useState<boolean>(true)
  const [availableEvents, setAvailableEvents] = useState<Event[]>([])

  const userLocationCountryCode = locationStore.userLocation.country.code
  const userCanSellOnlyInUyCurrency =
    !authStore.getLoggedUser().stripeAccountId ||
    !authStore.getLoggedUser().stripeOnboardingCompleted

  const initialCountryCode = userCanSellOnlyInUyCurrency ? 'UY' : userLocationCountryCode

  const [countryCode, setCountryCode] = useState<string>(initialCountryCode)

  const userId = authStore.getLoggedUser().id

  // Fetch events for country on first render:
  useEffect(() => {
    eventStore.fetchEvents(countryCode, true, userId).then((result) => {
      setAvailableEvents(result)
    })
  }, [countryCode])

  const formik = useFormik({
    initialValues: {
      isEventAlbum: false,
      eventId: '',
    },
    validationSchema: getAssignEventSchema(),
    onSubmit: (values) => {
      const event = availableEvents.find((event) => event.id === values.eventId)
      handleFinish(event)
    },
  })

  const handleChangeIsEventAlbum = (e: React.ChangeEvent<any>) => {
    formik.setFieldValue('isEventAlbum', JSON.parse(e.target.value))
  }
  const handleChangeCountry = (e: React.ChangeEvent<any>) => {
    setCountryCode(e.target.value)
    formik.setFieldValue('eventId', '')
  }

  const handleRequestEventCreation = () => {
    const url = ConfigService.getValue(ConfigKeys.EVENT_REQUEST_FORM)
    window.open(url, '_blank')
  }
  const eventOptions = availableEvents
    .slice()
    .sort((a, b) => {
      const dateA = a.date instanceof Date ? a.date.getTime() : new Date(a.date).getTime() || 0
      const dateB = b.date instanceof Date ? b.date.getTime() : new Date(b.date).getTime() || 0
      return dateB - dateA
    })
    .map((event: Event) => {
      return { value: event.id, label: event.name }
    })

  const isEventDisabled = (eventId: string) => {
    return eventStore.disabledEvents.some((event) => event.id === eventId)
  }

  const countryOptions = Countries.filter((country) => {
    return !userCanSellOnlyInUyCurrency || country.code === 'UY'
  }).map((country: Country) => {
    const flagClass = `ml-2 mx-2 fi fi-${country.code.toLowerCase()}`
    return (
      <MenuItem key={country.code} value={country.code}>
        <span className={flagClass} />
        {country.name.toUpperCase()}
      </MenuItem>
    )
  })

  if (authStore.isLoadingOnboardingRedirection) {
    return (
      <Spinner divStyles="flex justify-center bg-bg_details h-primaryMobile md:h-primaryDesktop items-center" />
    )
  }

  return (
    <div className="grid w-full grid-rows-1 gap-2 pt-2 sm:pl-2 sm:pr-2">
      <div className="mb-2 ml-2 text-2xl font-semibold text-gray-800">{t('New album')}</div>
      {loggedUser.mercadoPagoVerified && !loggedUser.stripeAccountId && (
        <div className="pb-2">
          <Alert severity="warning">
            {t(
              'Mercado Pago is no longer our payment provider! You need to link your account with Stripe, otherwise you’ll not be able to sell'
            )}
            .
            <div className="flex justify-center w-full">
              <Button
                btnType="SecondaryAction"
                onClick={() => redirectToStripeOnboarding()}
                extraStyle="text-sm"
              >
                {t('Sync to Stripe')}
              </Button>
            </div>
          </Alert>
        </div>
      )}
      <div className="py-2 px-3 rounded-md shadow-sm bg-bg_details border">
        <p className="mb-2 text-lg font-medium text-lumepic-light_black">{t('Select an option')}</p>
        <RadioGroup
          id={'isEventAlbum'}
          name={'isEventAlbum'}
          value={formik.values.isEventAlbum}
          onChange={handleChangeIsEventAlbum}
        >
          <FormControlLabel
            value={false}
            control={<Radio />}
            label={t('This album is not associated to an event')}
            sx={{ color: 'text.primary' }}
          />
          <FormControlLabel
            value={true}
            control={<Radio />}
            label={t('This album is associated to an event')}
            sx={{ color: 'text.primary' }}
          />
        </RadioGroup>
      </div>

      {formik.values.isEventAlbum && (
        <div className="flex flex-col mt-3">
          <p className="text-lg font-medium mb-1.5">{t('Select the event')}</p>

          <div className="flex flex-col sm:flex-row gap-2 w-full mt-2.5">
            <div className="w-full sm:w-64">
              <TextField
                fullWidth
                variant="outlined"
                name="countryCode"
                id="countryCode"
                select
                defaultValue={locationStore.userLocation.country?.code}
                label={t('Country')}
                required
                value={countryCode}
                onChange={handleChangeCountry}
              >
                {locationStore.isLoadingUserLocation ? (
                  <MenuItem value={''}>Loading...</MenuItem>
                ) : (
                  countryOptions
                )}
              </TextField>
              <div className="flex sm:items-center gap-1 mt-1.5">
                <FontAwesomeIcon icon={faCircleInfo} className="text-sm ml-0.5 opacity-80" />
                <span className="text-xs text-lumepic-black">{t('Country of the event')}.</span>
              </div>
            </div>
            <div className="w-full">
              <Autocomplete
                options={eventOptions}
                value={eventOptions.find((event) => event.value === formik.values.eventId) || null}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  if (newValue) {
                    formik.setFieldValue('eventId', newValue.value)
                  } else {
                    formik.setFieldValue('eventId', '')
                    setShowRequestEventText(true)
                  }
                }}
                loading={eventStore.isLoadingEvents}
                renderOption={(props, option) => {
                  const isDisabled = isEventDisabled(option.value)
                  return (
                    <Tooltip
                      title={isDisabled ? t('Accreditation required') : ''}
                      placement="top"
                      arrow
                    >
                      <li
                        {...props}
                        style={{
                          opacity: isDisabled ? 0.5 : 1,
                          pointerEvents: isDisabled ? 'none' : 'auto',
                          cursor: isDisabled ? 'not-allowed' : 'default',
                        }}
                      >
                        <Typography color={isDisabled ? 'textSecondary' : 'inherit'}>
                          {option.label}
                        </Typography>
                      </li>
                    </Tooltip>
                  )
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    name="eventId"
                    id="eventId"
                    label={t('Event')}
                    required
                    error={formik.touched.eventId && Boolean(formik.errors.eventId)}
                    helperText={formik.touched.eventId && formik.errors.eventId}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {eventStore.isLoadingEvents ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
              <div className="flex sm:items-center gap-1 mt-1.5">
                <FontAwesomeIcon icon={faCircleInfo} className="text-sm ml-0.5 opacity-80" />
                <span className="text-xs text-lumepic-black">
                  {t('Is the album related to an event? Select it here')}.
                </span>
              </div>
            </div>
          </div>
          {showRequestEventText && (
            <div className="p-2 rounded-md bg-bg_details mt-5 flex items-start gap-3 justify-between">
              <div className="flex flex-col">
                <span className="text-lumepic-light_black font-medium text-sm">
                  {t('Haven’t you found the event?')}
                </span>
                <span className="text-xs mt-1.5 font-light">
                  {t(
                    'If you cannot find the event, you can request that we add it by clicking on the following button. Meanwhile, you can create your album without associating it with an event.'
                  )}
                </span>
                <div>
                  <Button
                    btnType="SecondaryAction"
                    onClick={handleRequestEventCreation}
                    extraStyle="text-xs mt-4"
                  >
                    {t('Request an event')}
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      className="text-lumepic-black ml-1.5"
                    />
                  </Button>
                </div>
              </div>
              <div
                className="ml-2 cursor-pointer rounded-full flex justify-center items-center w-5 h-5 active:bg-lumepic-medium_grey"
                onClick={() => setShowRequestEventText(false)}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
          )}
        </div>
      )}

      <div className="flex justify-end mt-3 mb-2">
        <Button
          btnType="PrimaryAction"
          onClick={formik.handleSubmit}
          extraStyle={`w-full sm:w-min`}
          type={'button'}
        >
          <div className="flex items-center justify-center gap-2">
            <span>{t('Next')}</span>
            <FontAwesomeIcon icon={faArrowRight} className="text-lg" />
          </div>
        </Button>
      </div>
    </div>
  )
}

export default observer(AssignEvent)
