import React, { useState } from 'react'
import { Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FeedOrderFields, FeedOrderQueryParams, FeedOrderSorts } from 'services/FeedService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'
import Checkbox from '../Checkbox'

type SortByComponentProps = {
  handleSortChange: (field: FeedOrderFields, sort: FeedOrderSorts) => void
  sortOrder: FeedOrderQueryParams
}

const SortByComponent = ({ handleSortChange, sortOrder }: SortByComponentProps) => {
  const { t } = useTranslation()
  const [expandOptions, setExpandOptions] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExpandOptions(event.currentTarget)
  }

  const handleClose = () => {
    setExpandOptions(null)
  }

  const handleMenuItemChange = (field: FeedOrderFields, sort: FeedOrderSorts) => {
    handleSortChange(field, sort)
    handleClose()
  }

  const sortOptions = [
    { field: FeedOrderFields.TAKEN_DATE, sort: FeedOrderSorts.DESC, label: t('Date') },
    { field: FeedOrderFields.RATIO, sort: FeedOrderSorts.DESC, label: t('Ratio High To Low') },
    {
      field: FeedOrderFields.NET_SALES_AMOUNT_IN_USD,
      sort: FeedOrderSorts.DESC,
      label: t('Sales Amount High To Low'),
    },
    {
      field: FeedOrderFields.SALES_COUNT,
      sort: FeedOrderSorts.DESC,
      label: t('Sales Count High To Low'),
    },
    { field: FeedOrderFields.ACTIVITY, sort: FeedOrderSorts.ASC, label: t('Activity') },
  ]

  return (
    <div>
      <Button
        onClick={handleClick}
        btnType="SecondaryAction"
        extraStyle="flex gap-2 text-lumepic-light_black"
      >
        <FontAwesomeIcon icon={faSort} />
        {t('Sort')}
      </Button>
      <Menu anchorEl={expandOptions} open={Boolean(expandOptions)} onClose={handleClose}>
        {sortOptions.map(({ field, sort, label }) => (
          <MenuItem key={field}>
            <Checkbox
              value={sortOrder.field === field && sortOrder.sort === sort}
              id={field.toLowerCase()}
              label={label}
              onChange={() => handleMenuItemChange(field, sort)}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default SortByComponent
