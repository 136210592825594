//ADMIN ROUTES
const prefix = '/photographs'

export const PHOTOGRAPHS = `${prefix}`
export const BATCH_CREATE_PHOTOGRAPHS = `${prefix}/batch-upload-url`
export const ADMIN_PHOTOGRAPHS = `${prefix}/admin`

export const photographsByAlbum = () => {
  return `${prefix}`
}
export const deletePhotographById = (photographId: string) => {
  return `${prefix}/${photographId}`
}

export const updatePhotographById = (photographId: string) => {
  return `${prefix}/${photographId}`
}

export const photographsFromDiscountById = (discountId: string) => {
  return `${prefix}/discount/${discountId}/admin`
}

export const downloadFreePhotograph = (photographId: string) => {
  return `/photographs/${photographId}/download-url`
}
