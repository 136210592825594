import React, { useState } from 'react'
import AlbumResultListNoResult from './AlbumResultListNoResult'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import Album from '../../../shared/models/Album'
import MyAlbumsStore from 'containers/MyAlbums/MyAlbumsStore'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import AlbumTableRow from './AlbumListRow/AlbumTableRow'
import { myAlbumsColumns } from '../../../shared/utility'
import { ModalSize } from '../../UI/Modal/Modal'
import Modal from '../../UI/Modal'
import { isNil } from 'lodash'
import SkeletonLoaderMyAlbums from 'components/UI/Loaders/Skeleton/SkeletonLoaderMyAlbums'

type AlbumTableProps = {
  albums: Album[]
  albumCount: number
  page: number
  albumsPerPage: number
  setPage: (page: number) => void
  setRowsPerPage: (rowsPerPage: number) => void
  updateCoverPhotograph: (album: Album) => void
  addImagesToAlbum: (album: Album) => void
  addDiscountToAlbum: (album: Album) => void
  isLoading?: boolean
  myAlbumsStore: MyAlbumsStore
  editAlbum: (album: Album) => void
}

const AlbumTable = ({
  updateCoverPhotograph,
  addImagesToAlbum,
  addDiscountToAlbum,
  albums,
  albumCount,
  page,
  albumsPerPage,
  setRowsPerPage,
  setPage,
  isLoading = false,
  myAlbumsStore,
  editAlbum,
}: AlbumTableProps) => {
  const { t } = useTranslation()
  const [albumToSuspend, setAlbumToSuspend] = useState<Album | null>(null)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
  }

  const publishAlbum = (album: Album) => {
    myAlbumsStore.activateAlbum(album.id).then((success) => {
      if (success) {
        myAlbumsStore.setPage(1)
      }
    })
  }

  const handleSuspendAlbum = () => {
    if (!isNil(albumToSuspend)) {
      myAlbumsStore?.suspendAlbum(albumToSuspend.id).then((success) => {
        if (success) {
          setAlbumToSuspend(null)
          myAlbumsStore?.setPage(1)
        }
      })
    }
  }

  if (isLoading) {
    return <SkeletonLoaderMyAlbums columns={myAlbumsColumns} />
  }

  if (!albums || albums.length === 0) {
    return (
      <div className="pt-10 flex justify-center">
        <AlbumResultListNoResult />
      </div>
    )
  }

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {myAlbumsColumns.map((column) => (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth, padding: '6px' }}>
                    {t(column.label)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {albums.map((album, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <AlbumTableRow
                      key={album.id}
                      columns={myAlbumsColumns}
                      album={album}
                      updateCoverPhotograph={() => updateCoverPhotograph(album)}
                      addImagesToAlbum={() => addImagesToAlbum(album)}
                      addDiscountToAlbum={() => addDiscountToAlbum(album)}
                      editAlbum={() => editAlbum(album)}
                      handleSaveDiscount={() => addDiscountToAlbum(album)}
                      showSuspendConfirmModal={() => setAlbumToSuspend(album)}
                      handlePublishAlbum={() => publishAlbum(album)}
                    />
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={albumCount}
          rowsPerPage={albumsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-selectLabel': {
              marginBottom: '2px',
            },
            '& .MuiTablePagination-displayedRows': {
              marginBottom: '2px',
            },
          }}
        />
      </Paper>
      {albumToSuspend && (
        <Modal
          onCancel={() => setAlbumToSuspend(null)}
          opened={true}
          onSuccess={handleSuspendAlbum}
          okMessage={t('Suspend').toString()}
          isDeleteConfirmation
          modalSize={ModalSize.SMALL}
        >
          <div className="flex p-3">
            <div className="text-gray-600">
              {`${t('Are you sure that you want to suspend album from')} ${
                albumToSuspend?.location.spotName
              }?`}
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default observer(AlbumTable)
