import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputWrapper from 'components/UI/Forms/InputWrapper'
import DiscountCodeFormModalStore from './DiscountCodeFormModalStore'
import StoresContext from 'providers/storesContext'
import Modal from 'components/UI/Modal'
import ServerError from 'components/UI/ServerError'
import { ModalButtonsDisplay } from 'components/UI/Modal/Modal'
import { observer } from 'mobx-react'
import infoIcon from '../../../../assets/icons/v2/information.svg'
import Spinner from 'components/UI/Spinner'

type DiscountCodeRequestFormProps = {
  open: boolean
  handleClose: () => void
  afterSaveDiscount: () => void
}

const DiscountCodeRequestForm = ({
  open,
  handleClose,
  afterSaveDiscount,
}: DiscountCodeRequestFormProps) => {
  const { t } = useTranslation()
  const { authStore } = useContext(StoresContext)!
  const [discountCodeFormModalStore] = useState(() => new DiscountCodeFormModalStore(authStore))

  const handleChangeDefaultCode = (val: string) =>
    discountCodeFormModalStore.changeDiscountCode(val)
  const handleChangeDiscountAmount = (val: number) =>
    discountCodeFormModalStore.changeDiscountAmount(Number(val))

  const { discountCode, discountAmount } = discountCodeFormModalStore

  const handleSave = () => {
    discountCodeFormModalStore.createPersonalDiscount().then((newCodeDiscount) => {
      if (newCodeDiscount) {
        afterSaveDiscount()
      }
    })
  }

  if (discountCodeFormModalStore.isLoading) {
    return <Spinner divStyles="flex justify-center h-full items-center" size={20} />
  }

  return (
    <Modal
      okMessage={t('Request').toString()}
      loadingOk={discountCodeFormModalStore.isLoading}
      onCancel={handleClose}
      onSuccess={handleSave}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
    >
      <div className="w-full grid grid-rows-1 gap-2 px-4 pt-4">
        <div className="text-gray-800 font-extrabold text-lg">{t('Fidelity code')}</div>
        {discountCodeFormModalStore.error && (
          <ServerError message={discountCodeFormModalStore.error.toString()} />
        )}
        <div>
          <InputWrapper
            label={t('Fidelity Code').toString()}
            placeholder={t('Fidelity Code').toString()}
            inputStore={discountCode}
            onChange={handleChangeDefaultCode}
          />
          <div className="flex sm:items-center mt-1.5 mb-1.5">
            <img src={infoIcon} alt="info" className="w-3 h-3 mt-0.5 sm:mt-0" />
            <span className="text-lumepic-grey text-xs ml-0.5 sm:ml-1">
              {t('This is the code users will enter at the purchase moment.')}
            </span>
          </div>
        </div>

        <div>
          <InputWrapper
            label={t('Discount Amount (%)').toString()}
            placeholder={t('Amount').toString()}
            inputStore={discountAmount}
            onChange={handleChangeDiscountAmount}
          />
          <div className="flex sm:items-center mt-1.5 mb-1.5">
            <img src={infoIcon} alt="info" className="w-3 h-3 mt-0.5 sm:mt-0" />
            <span className="text-lumepic-grey text-xs ml-0.5 sm:ml-1">
              {t(
                'This is the total discount percentage that users receive at the time of making the first purchase'
              )}
              .
            </span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default observer(DiscountCodeRequestForm)
