import Modal from 'components/UI/Modal'
import { ModalSize } from 'components/UI/Modal/Modal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NewCodeDiscount } from 'shared/models/NewCodeDiscount'

type DeactivateCodeDiscountModalProps = {
  code: NewCodeDiscount
  onConfirm: () => void
  onClose: () => void
  show: boolean
  loading: boolean
}

const DeactivateCodeDiscountModal = ({
  code,
  onConfirm,
  onClose,
  show,
  loading,
}: DeactivateCodeDiscountModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      onCancel={onClose}
      opened={show}
      modalSize={ModalSize.SMALL}
      onSuccess={onConfirm}
      okMessage={t('Deactivate').toString()}
      isDeleteConfirmation
      loadingOk={loading}
    >
      <div className="flex flex-col items-center justify-center w-full gap-4 my-3">
        <div className="text-xl font-semibold text-center">
          {t('Are you sure you want to deactivate the discount?')}
        </div>
        <div className="flex items-center justify-center px-4">
          {t('In case you confirm, the code will no longer have effect in users purchases', {
            code: code?.code,
          })}
        </div>
      </div>
    </Modal>
  )
}

export default DeactivateCodeDiscountModal
