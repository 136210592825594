export const scrollToElement = (elementId: string) => {
  const element = document.getElementById(elementId)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

export const formatMetric = (value: number): string => {
  if (value < 1000) {
    return value.toString()
  } else if (value < 1000000) {
    return `${Math.ceil(value / 1000)}k`
  } else {
    return `${(Math.ceil(value / 100000) / 10).toFixed(1)}M`
  }
}
