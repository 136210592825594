export const DISCOUNT = '/discounts'

export const ALBUMS_QUANTITY_DISCOUNTS = `${DISCOUNT}/quantity-discounts`
export const PERSONAL_CODE_DISCOUNT = `${DISCOUNT}/personal-discount`
export const discountDetail = (discountId: string) => {
  return `discounts/${discountId}`
}

export const discountByCode = (code: string) => {
  return `code-discounts/${code}/validate`
}

const prefix = '/discounts'
export const updateDiscountByCode = (discountCode: string) => {
  return `${prefix}/${discountCode}`
}
export const deleteDiscount = (discountId: string) => {
  return `${prefix}/${discountId}`
}
export const suspendDiscount = (discountId: string) => {
  return `${prefix}/${discountId}/suspend`
}
export const activateDiscount = (discountId: string) => {
  return `${prefix}/${discountId}/activate`
}
