import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faCalendar, faInfo, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import * as paths from '../../../../routing/Paths'
import { formatUtcToLocaleDate } from '../../../../shared/utility'
import { useTranslation } from 'react-i18next'
import photographerIcon from '../../../../assets/icons/v2/photographer.svg'
import Album from '../../../../shared/models/Album'
import shareIcon from '../../../../assets/icons/v2/share-gold.svg'
import ShareModal from 'components/UI/ShareModal'
import { useHistory } from 'react-router'
import lumepicLogo from '../../../../assets/icons/v2/logo-colored.svg'

type AlbumCardProps = {
  album: Album
  hidePhotographerName?: boolean
  spotTitleExtraStyles?: boolean
  eventAlbumsView?: boolean
}

const AlbumCard = ({
  album,
  hidePhotographerName,
  spotTitleExtraStyles,
  eventAlbumsView,
}: AlbumCardProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [loadingImage, setLoadingImage] = useState(true)
  const [shareModalOpened, setShareModalOpened] = useState(false)

  const handleAlbumClick = (albumId: string) => {
    if (hidePhotographerName || !album.event.id) {
      // Open album details
      history.push(paths.feedAlbumDetailById(albumId))
    } else {
      if (album.event.landingPath === 'valentin-martinez-2023' || eventAlbumsView) {
        history.push(paths.feedAlbumDetailById(albumId))
      } else {
        // Open event details
        history.push(paths.feedEventDetailsByLandingPath(album.event.landingPath))
      }
    }
  }

  const handleImageLoad = () => {
    setLoadingImage(false)
  }

  //TODO: remove carousel and display only cover image.
  const card = (
    <div className="relative w-full h-full" key={album.id}>
      <div className="flex items-center justify-center relative">
        {loadingImage && (
          <div
            className={`flex items-center justify-center absolute w-full h-[200px] md:h-[250px] lg:h-[275px] xl:h-[300px]`}
          >
            <img loading="lazy" src={lumepicLogo} alt="Lumepic Logo" className="w-20 h-20" />
          </div>
        )}
        <img
          loading="lazy"
          onLoad={handleImageLoad}
          onClick={() => {
            handleAlbumClick(album.id)
          }}
          className="flex items-center justify-center cursor-pointer object-cover object-center w-full h-[200px] md:h-[250px] lg:h-[275px] xl:h-[300px]"
          src={album.coverPhotographSrc}
          alt="cover-photograph"
        />
      </div>

      <div className="w-full bg-bg_details opacity-70 rounded-sm cursor-pointer border-lumepic-light_grey absolute bottom-0 flex flex-col items-center justify-center">
        <span
          className="font-lumepicRegular text-center"
          onClick={() => {
            handleAlbumClick(album.id)
          }}
        >
          {!eventAlbumsView && album.event && album.event.name ? (
            <div className="flex flex-col items-center justify-center">
              <span>
                {t('Album from Event')}: {album.event.name}
              </span>
              <span>{album.isFree && t('Free Photographs')}</span>
            </div>
          ) : (
            album.isFree && t('Free Photographs')
          )}
        </span>
      </div>
    </div>
  )

  return (
    <div className="w-full overflow-hidden rounded-lg sm:shadow-lg hover:lg:-translate-y-1 transition duration-300 animate-fadeIn">
      {card}
      <div
        className="relative z-10 flex flex-col bg-lumepic-white"
        onClick={() => handleAlbumClick(album.id)}
      >
        {!eventAlbumsView && (
          <div className="flex items-center justify-between p-2 m-0 cursor-pointer bg-lumepic-light_black">
            <div className="flex items-center max-w-90">
              <FontAwesomeIcon className="text-xl mr-2 ml-1 text-primary" icon={faLocationDot} />
              <div className="truncate whitespace-nowrap">
                {spotTitleExtraStyles ? (
                  <span className="text-sm truncate text-white whitespace-nowrap md:text-base">
                    {album.location.spotName.toUpperCase()}
                  </span>
                ) : (
                  <span className="text-sm truncate text-white whitespace-nowrap md:text-base">
                    {album.location.spotName.toUpperCase()}
                  </span>
                )}
              </div>
            </div>
            <div
              className="relative z-20 flex items-center justify-center rounded-full cursor-pointer w-7 h-7 p-1 hover:lg:scale-[1.15] transition duration-200"
              onClick={(event) => event.stopPropagation()}
            >
              <span onClick={() => setShareModalOpened(true)}>
                <img src={shareIcon} alt="Share" className="w-5" />
              </span>
            </div>
          </div>
        )}

        <div className="px-1 py-1.5 cursor-pointer bg-lumepic-medium_grey">
          {!eventAlbumsView && (
            <p className="flex items-center py-1 px-1.5 m-0">
              <FontAwesomeIcon className="mr-2 ml-0.5 text-xl text-lumepic-grey" icon={faBolt} />
              <span className="text-sm font-light pl-0.5 whitespace-nowrap truncate">
                {t(album.activity.name)}
              </span>
            </p>
          )}
          <p className="flex items-center py-1 pl-2 pr-1 m-0">
            <FontAwesomeIcon className="mr-2 text-xl text-lumepic-grey" icon={faCalendar} />
            <span className="text-sm font-light">{formatUtcToLocaleDate(album.takenDate)}</span>
          </p>

          {!hidePhotographerName && (
            <p className="flex items-center pl-1 pr-2 m-0">
              <img loading="lazy" alt="Photographer" src={photographerIcon} className="w-7 h-7" />
              <span className="text-sm font-light pb-1 pl-0.5">{album.owner.alias}</span>
            </p>
          )}

          <div className="flex items-center pt-0.5 pb-1 px-2 m-0">
            <div className="flex items-center justify-center w-5 h-5 mr-1 rounded-full bg-lumepic-grey">
              <FontAwesomeIcon className="text-xs text-lumepic-medium_grey" icon={faInfo} />
            </div>
            <span
              className="text-sm pl-0.5 font-light whitespace-nowrap truncate"
              style={{ maxWidth: 'calc(100% - 30px)' }}
            >
              {album.description}
            </span>
          </div>
        </div>
      </div>
      <ShareModal
        albumId={album.id}
        closeModal={() => setShareModalOpened(false)}
        isModalOpened={shareModalOpened}
      />
    </div>
  )
}

export default observer(AlbumCard)
