import { MultiSelectOption } from 'components/UI/Multiselect/MultiSelect'
import TagInterface, {
  TagKeys,
  TagSource,
} from '../../services/RequestInterfaces/Tag/Tag.interface'

export class Tag {
  public static init(data: TagInterface): Tag {
    const tag = new Tag()
    tag.id = data.id
    tag.key = data.key
    tag.value = data.value
    tag.source = data.source
    tag.taggedUserId = data.taggedUserId
    tag.photographId = data.photographId
    return tag
  }

  public toMultiSelectOption(): MultiSelectOption {
    return {
      value: this.id,
      label: this.value,
      key: this.key,
    }
  }

  id: string

  key: TagKeys

  value: string

  source: TagSource

  taggedUserId?: string

  photographId: string
}
