import { makeAutoObservable, runInAction } from 'mobx'
import InputStore from '../../shared/store/InputStore'
import ContactUsService from '../../services/ContactUsService'
import { string } from 'yup'

class ContactUsStore {
  private readonly contactUsService: ContactUsService
  public email: InputStore<string>
  public firstName: InputStore<string>
  public lastName: InputStore<string>
  public phone: InputStore<string>
  public message: InputStore<string>
  public isPhotographer: boolean
  public isAthlete: boolean
  public isLoading: boolean
  public roleTypeErrors: any
  public serverError: any
  constructor() {
    this.reset()
    makeAutoObservable(this)
    this.contactUsService = new ContactUsService()
  }

  reset() {
    this.isLoading = false
    this.email = new InputStore(
      string().email('Ingrese un formato de email válido').required('El email es requerido')
    )
    this.firstName = new InputStore(string().required('El nombre es requerido'))
    this.lastName = new InputStore(string().required('El apellido es requerido'))
    this.phone = new InputStore(string().optional())
    this.message = new InputStore(string().required('El mensaje es requerido'))
    this.isPhotographer = false
    this.isAthlete = false
  }

  changeEmail(val: string) {
    this.email.setValue(val.trim())
  }

  changeFirstName(val: string) {
    this.firstName.setValue(val)
  }

  changeLastName(val: string) {
    this.lastName.setValue(val)
  }

  changePhone(val: string) {
    this.phone.setValue(val)
  }

  changeMessage(val: string) {
    this.message.setValue(val)
  }

  changeIsPhotographer() {
    this.isPhotographer = !this.isPhotographer
    this.roleTypeErrors = false
  }

  changeIsAthlete() {
    this.isAthlete = !this.isAthlete
    this.roleTypeErrors = false
  }

  clearErrors() {
    this.email.clearError()
    this.firstName.clearError()
    this.lastName.clearError()
    this.phone.clearError()
    this.message.clearError()
  }

  async validate() {
    this.clearErrors()
    let isValid = true

    if (!(await this.firstName.validate())) {
      isValid = false
    }

    if (!(await this.lastName.validate())) {
      isValid = false
    }

    if (!(await this.email.validate())) {
      isValid = false
    }

    if (!(await this.phone.validate())) {
      isValid = false
    }

    if (!(await this.message.validate())) {
      isValid = false
    }

    return isValid
  }

  async send() {
    if (await this.validate()) {
      runInAction(() => {
        this.isLoading = true
      })

      try {
        const formData = {
          email: this.email.value,
          firstName: this.firstName.value,
          lastName: this.lastName.value,
          phone: this.phone.value,
          message: this.message.value,
          isPhotographer: this.isPhotographer,
          isAthlete: this.isAthlete,
        }
        const response = await this.contactUsService.sendForm(formData)

        runInAction(() => {
          this.isLoading = false
        })
        return response
      } catch (e: any) {
        runInAction(() => {
          const displayedError = this.parseRequestErrors(e.response?.data?.errors || {})

          if (!displayedError) {
            this.serverError = 'Something went wrong, please check the provided data and try again.'
          }

          this.isLoading = false
        })
      }
    }

    return false
  }

  parseRequestErrors(messages: any) {
    const keys = Object.keys(messages)
    let displayedError = false

    keys.forEach((key) => {
      const [error] = messages[key]

      switch (key) {
        case 'email':
          this.email.setError(true, error)
          displayedError = true
          break

        case 'phone':
          this.phone.setError(true, error)
          displayedError = true
          break

        case 'fistName':
          this.firstName.setError(true, error)
          displayedError = true
          break

        case 'lastName':
          this.lastName.setError(true, error)
          displayedError = true
          break

        case 'message':
          this.lastName.setError(true, error)
          displayedError = true
          break

        default:
          break
      }
    })

    return displayedError
  }
}

export default ContactUsStore
