import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import StoresContext from '../../../../../providers/storesContext'
import { useTranslation } from 'react-i18next'
import InputWrapper from '../../../../../components/UI/Forms/InputWrapper'
import DiscountModalStore from './DiscountModalStore'
import DiscountArray from './components/DiscountArray/DiscountArray'
import ServerError from '../../../../../components/UI/ServerError'
import Button from 'components/UI/Button'
import DeleteDiscountModal from './components/DeleteDiscountModal/DeleteDiscountModal'
import DiscountPercentage, { QuantityDiscount } from 'shared/models/QuantityDiscount'
import { toast } from 'react-toastify'
import Album from '../../../../../shared/models/Album'
import { DefaultDiscountRanges } from '../../../../../config'
import { isNil } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Checkbox from 'components/UI/Checkbox'
import Breadcrumb from 'components/UI/Breadcrumb'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import axios from 'services/axios'

export enum QuantityDiscountDisplay {
  PERCENTAGE = 'PERCENTAGE',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
}

type AddDiscountModalProps = {
  album: Album
  handleFinish: (album: Album) => void
  isCreating?: boolean
  closeModal?: () => void
}

const AddDiscountModal = ({ album, handleFinish, isCreating }: AddDiscountModalProps) => {
  const { t } = useTranslation()
  const { authStore } = useContext(StoresContext)!
  const [discountModalStore] = useState(() => new DiscountModalStore(authStore))
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false)
  const [discountType, setDiscountType] = useState(QuantityDiscountDisplay.PERCENTAGE)

  const hideConfirmModal = () => setVisibleConfirmModal(false)
  const showConfirmModal = () => setVisibleConfirmModal(true)

  const handleChangeDiscountRange = (val: DiscountPercentage[]) =>
    discountModalStore.changeDiscountRange(val)

  const { discountRanges } = discountModalStore

  useEffect(() => {
    if (isNil(album.quantityDiscount)) {
      album.quantityDiscount = new QuantityDiscount()
      album.quantityDiscount.discountPercentages = DefaultDiscountRanges
    }
    discountModalStore.changeDiscountRange(album.quantityDiscount.discountPercentages)
  }, [])

  const save = () => {
    discountModalStore.avoidDiscounts
      ? handleFinish(album)
      : discountModalStore.createQuantityDiscount(album.id, discountType).then((result) => {
          if (result) {
            handleFinish(album)
            !isCreating &&
              toast.success(t('Discounts added successfully'), {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              })
          }
        })
  }

  const handleDeleteDiscount = () => {
    discountModalStore.clearDiscount(album.id).then((result) => {
      if (result) {
        hideConfirmModal()
        handleFinish(album)
        toast.success(t('Discounts successfully removed'), {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
    })
  }

  return (
    <>
      <div className="grid w-full grid-rows-1 gap-2 px-4 my-4">
        {isCreating && (
          <div className="sm:hidden mb-3 flex justify-center ml-2">
            <Breadcrumb completedSteps={2} totalSteps={5} />
          </div>
        )}
        {discountModalStore.error && <ServerError message={t(discountModalStore.error)} />}
        <div className="text-xl font-semibold md:text-2xl">{t('Quantity discounts')}</div>
        <div className="text-sm font-normal  md:text-base">
          {t('Albums with discounts tend to have more sales.')}
        </div>
        {!discountModalStore.avoidDiscounts && (
          <>
            <div className="grid items-center w-full grid-cols-7 gap-4 my-3">
              <div
                className={`col-span-3 border-b-2 pb-1.5 cursor-pointer text-center ${
                  discountType === QuantityDiscountDisplay.PERCENTAGE
                    ? 'border-black text-black'
                    : 'border-lumepic-dark_grey text-lumepic-dark_grey'
                }`}
                onClick={() => setDiscountType(QuantityDiscountDisplay.PERCENTAGE)}
              >
                {t('Percentage')}
              </div>
              <div
                className={`col-span-3 border-b-2 pb-1.5 cursor-pointer text-center ${
                  discountType === QuantityDiscountDisplay.FIXED_AMOUNT
                    ? 'border-black text-black'
                    : 'border-lumepic-dark_grey text-lumepic-dark_grey'
                }`}
                onClick={() => setDiscountType(QuantityDiscountDisplay.FIXED_AMOUNT)}
              >
                {t('Fixed amount')}
              </div>
            </div>
            <InputWrapper
              options={{ type: discountType, imagePrice: album.defaultImagePrice, album: album }}
              onChange={handleChangeDiscountRange}
              error={discountRanges.errorMessage}
              value={album.quantityDiscount?.discountPercentages || DefaultDiscountRanges}
              as={DiscountArray}
              autoFocus
              className={'max-h-1/3'}
            />
          </>
        )}
      </div>
      {isCreating && (
        <div className="flex flex-col px-4 mt-4">
          <p className="mb-2 text-sm font-medium text-black md:text-normal">
            {t('Or you can continue without applying any discounts')}
          </p>
          <div className="flex justify-start text-base text-black">
            <Checkbox
              id="Apply Discounts"
              label={t('Don’t apply discounts')}
              value={discountModalStore.avoidDiscounts}
              onChange={() => discountModalStore.changeAvoidDiscounts()}
            />
          </div>
        </div>
      )}
      {!isCreating ? (
        <div className="flex justify-between px-4 mt-4 mb-3">
          <Button btnType="Warning" onClick={() => showConfirmModal()}>
            <span>{t('Delete')}</span>
          </Button>
          <Button
            btnType="PrimaryAction"
            isLoading={discountModalStore.isLoading}
            onClick={() => save()}
          >
            <span>{t('Save')}</span>
          </Button>
        </div>
      ) : (
        <div className="flex justify-between px-4 mb-3 mt-4">
          <div className="hidden sm:block">
            <Breadcrumb completedSteps={2} totalSteps={5} />
          </div>
          <Button
            btnType="PrimaryAction"
            isLoading={discountModalStore.isLoading}
            onClick={() => save()}
            extraStyle="w-full sm:w-min"
          >
            <div className="flex items-center justify-center gap-2">
              <span>{t('Next')}</span>
              <FontAwesomeIcon icon={faArrowRight} className="text-lg" />
            </div>
          </Button>
        </div>
      )}
      <DeleteDiscountModal
        open={visibleConfirmModal}
        handleDelete={handleDeleteDiscount}
        isSaving={discountModalStore.isLoading}
        hideConfirmModal={hideConfirmModal}
      />
    </>
  )
}

export default withErrorHandler(observer(AddDiscountModal), axios)
