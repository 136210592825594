import ConfigService, { AssetNames } from 'config'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PhotographerFactsLine from './components/PhotographerFactsLine'
import Button from 'components/UI/Button'
import { scrollToElement } from 'containers/PhotographersLanding/utils/utils'
import Tracker from 'shared/tracking'
import { faDollarSign, faWifi } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'

const PhotographerFactsDesktop = () => {
  const { t } = useTranslation()
  return (
    <div className="relative w-full py-64 bg-white">
      <div className="absolute left-0 top-0 h-full w-1/4">
        <svg
          className="h-full w-full"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon points="0,100 100,100 0,0" className="fill-primary_dark" />
        </svg>
      </div>
      <div className="container mx-auto w-full flex justify-between gap-3 items-center h-64 relative z-10">
        <img
          src={ConfigService.getAsset(
            AssetNames.PHOTOGRAPHERS_LANDING_FACTS_1,
            'photographers-landing'
          )}
          className="object-cover object-center w-48 lg:w-56 xl:w-64"
          alt="Photographers facts"
        />
        <div className="flex flex-col items-center gap-6 lg:gap-8 xl:gap-10">
          <h3 className="text-2xl md:text-3xl lg:text-4xl text-lumepic-light_black font-bold">
            {t('Why Lumepic?')}
          </h3>
          <div className="flex flex-col items-center gap-2 lg:gap-4 xl:gap-6">
            <PhotographerFactsLine
              text={t('Photographers save up to 20 hours a week by automating sales')}
              icon={faClock}
            />
            <PhotographerFactsLine
              text={t('80% of customers prefer to buy online, without direct interaction')}
              icon={faWifi}
            />
            <PhotographerFactsLine
              text={t('Photographers sell up to 60% more than with direct sales')}
              icon={faDollarSign}
            />
          </div>
          <div className="flex justify-center w-full mt-2">
            <Button
              btnType="PrimaryAction"
              onClick={() => {
                Tracker.goToPhotographersRegister()
                scrollToElement('photographer-register')
              }}
            >
              {t('Try now')}
            </Button>
          </div>
        </div>
        <img
          src={ConfigService.getAsset(
            AssetNames.PHOTOGRAPHERS_LANDING_FACTS_2,
            'photographers-landing'
          )}
          className="object-cover object-center w-48 lg:w-56 xl:w-64"
          alt="Photographers facts"
        />
      </div>
      <div className="absolute right-0 top-0 h-full w-1/4">
        <svg
          className="h-full w-full"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon points="0,0 100,0 100,100" className="fill-primary_dark" />
        </svg>
      </div>
    </div>
  )
}

export default PhotographerFactsDesktop
