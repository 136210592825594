import React from 'react'
import { WhatsappShareButton as ReactShareWhatsappButton } from 'react-share'

type CustomWhatsappShareButtonProps = {
  url: string
  title?: string
  iconSize?: number
  children: any
}

const WhatsappShareButton = (props: CustomWhatsappShareButtonProps) => (
  <ReactShareWhatsappButton url={props.url} title={props.title}>
    {props.children}
  </ReactShareWhatsappButton>
)

export default WhatsappShareButton
