import { faBolt, faCalendar, faDownload, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/UI/Button'
import AddToCartButton from 'containers/Cart/components/AddToCartButton'
import { isNil } from 'lodash'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Purchase, PurchaseStatus } from 'shared/models/Purchase'
import { PurchaseLineItem } from 'shared/models/PurchaseLineItem'
import { PhotographUtils } from 'shared/util/photographs.utils'
import PurchaseStore from '../PurchaseStore'

type PurchaseSingleLineItemProps = {
  purchase: Purchase
  lineItem: PurchaseLineItem
  store: PurchaseStore
  isLoadingMultipleDownloads: boolean
}

const PurchaseSingleLineItem = ({
  purchase,
  lineItem,
  store,
  isLoadingMultipleDownloads,
}: PurchaseSingleLineItemProps) => {
  const { t } = useTranslation()

  const [isLoadingSingleDownload, setIsLoadingSingleDownload] = useState(false)

  const handleClickDownloadPhotograph = async (photographFileName: string) => {
    setIsLoadingSingleDownload(true)
    await PhotographUtils.downloadPurchasedImages(purchase.id, [photographFileName], store, t).then(
      () => {
        setIsLoadingSingleDownload(false)
      }
    )
  }

  const parseAlbumTakenDate = (takenDate: Date) => {
    return moment.utc(takenDate).format('DD/MM/YYYY')
  }

  const desktopAlbumData = (
    <div className="hidden md:flex md:flex-col md:gap-2 md:pl-3">
      {!isNil(lineItem.album) && (
        <>
          <div className="hidden md:flex md:w-56 md:gap-0.5 md:text-lg md:font-semibold">
            <span>{`${t('Album from')} ${parseAlbumTakenDate(lineItem.album.takenDate)} `}</span>
          </div>
          <div className="hidden md:flex md:flex-col md:gap-2 mt-2">
            <span className="text-sm flex items-center">
              <FontAwesomeIcon
                className="text-lg mr-1.5 text-lumepic-dark_grey"
                icon={faLocationDot}
              />
              <div className="truncate whitespace-nowrap">
                <span className="text-sm font-light text-lumepic-dark_grey whitespace-nowrap truncate">
                  {lineItem.album.location?.spotName?.toUpperCase()}
                </span>
              </div>
            </span>
            <span className="text-sm flex items-center">
              <FontAwesomeIcon className="text-lg mr-1.5 text-lumepic-dark_grey" icon={faBolt} />
              <div className="truncate whitespace-nowrap">
                <span className="text-sm font-light text-lumepic-dark_grey whitespace-nowrap truncate">
                  {t(lineItem.album.activity?.name)}
                </span>
              </div>
            </span>
            {!isNil(lineItem.album?.event) && (
              <span className="text-sm flex items-center">
                <FontAwesomeIcon
                  className="text-lg mr-1.5 text-lumepic-dark_grey"
                  icon={faCalendar}
                />
                <div className="truncate whitespace-nowrap">
                  <span
                    className="text-sm pl-0.5 font-light text-lumepic-dark_grey whitespace-nowrap truncate"
                    style={{ maxWidth: 'calc(100% - 30px)' }}
                  >
                    {t(lineItem.album.event.name)}
                  </span>
                </div>
              </span>
            )}
          </div>
        </>
      )}
    </div>
  )

  return (
    <>
      {/* MOBILE */}
      <div className="flex flex-col my-3 md:hidden">
        {!isNil(lineItem.album) && (
          <div className="py-3">
            <span className="flex text-lg font-semibold">
              {t('Album from')} {''}
              {parseAlbumTakenDate(lineItem.album?.createdAt)}
            </span>
            <div className="flex flex-col gap-2 pt-2">
              <span className="text-sm flex items-center">
                <FontAwesomeIcon
                  className="text-lg mr-1.5 text-lumepic-dark_grey"
                  icon={faLocationDot}
                />
                <div className="truncate whitespace-nowrap">
                  <span className="text-sm font-light text-lumepic-dark_grey whitespace-nowrap truncate">
                    {lineItem.album?.location?.spotName?.toUpperCase()}
                  </span>
                </div>
              </span>
              <span className="text-sm flex items-center">
                <FontAwesomeIcon className="text-lg mr-1.5 text-lumepic-dark_grey" icon={faBolt} />
                <div className="truncate whitespace-nowrap">
                  <span className="text-sm font-light text-lumepic-dark_grey whitespace-nowrap truncate">
                    {t(lineItem.album?.activity?.name)}
                  </span>
                </div>
              </span>
              {!isNil(lineItem.album?.event) && (
                <span className="text-sm flex items-center">
                  <FontAwesomeIcon
                    className="text-lg mr-1.5 text-lumepic-dark_grey"
                    icon={faCalendar}
                  />
                  <div className="truncate whitespace-nowrap">
                    <span
                      className="text-sm pl-0.5 font-light text-lumepic-dark_grey whitespace-nowrap truncate"
                      style={{ maxWidth: 'calc(100% - 30px)' }}
                    >
                      {t(lineItem.album?.event.name)}
                    </span>
                  </div>
                </span>
              )}
            </div>
          </div>
        )}
        {lineItem.photographs.map((photograph) => (
          <div key={lineItem.id} className="w-full mt-6">
            <img
              loading="lazy"
              className="w-full object-cover object-center"
              src={photograph.thumbnailUrl}
              alt="Photograph"
            />
            <div className="w-full flex justify-between">
              <span>
                {purchase.status === PurchaseStatus.APPROVED ? (
                  <div
                    className={`w-full flex justify-center ${
                      isLoadingMultipleDownloads && 'opacity-60'
                    }`}
                  >
                    <Button
                      extraStyle="text-sm pt-2 md:text-sm"
                      btnType="TertiaryAction"
                      onClick={() => {
                        isLoadingMultipleDownloads
                          ? () => {}
                          : handleClickDownloadPhotograph(
                              `${photograph.id}.${photograph.extension}`
                            )
                      }}
                      isLoading={isLoadingSingleDownload}
                      loadingText={t('Downloading') + '...'.toString()}
                    >
                      <div className="flex gap-1.5 items-center justify-center">
                        <FontAwesomeIcon icon={faDownload} /> {t('Download')}
                      </div>
                    </Button>
                  </div>
                ) : (
                  <AddToCartButton photograph={photograph} album={lineItem.album} />
                )}
              </span>
              <span className="text-base text-end md:font-semibold">${photograph.price}</span>
            </div>
          </div>
        ))}
      </div>

      {/* DESKTOP */}
      <div className="hidden md:flex md:flex-col md:w-full md:py-3">
        {lineItem.photographs.map((photograph) => (
          <>
            <div className="hidden md:flex md:w-full md:py-3">
              <div key={lineItem.id} className="w-full flex justify-between">
                <div className="flex flex-col md:w-1/2">
                  <div className="w-[12rem] h-[10rem] lg:w-[16rem] lg:h-[12rem] xl:w-[18rem] xl:h-[13rem] flex justify-center">
                    <img
                      loading="lazy"
                      className="max-w-36 max-h-48 object-cover object-center rounded-lg "
                      src={photograph.thumbnailUrl}
                      alt="Photograph"
                    />
                  </div>
                  <span>
                    {purchase.status === PurchaseStatus.APPROVED ? (
                      <div
                        className={`w-full flex justify-center ${
                          isLoadingMultipleDownloads && 'opacity-60'
                        }`}
                      >
                        <Button
                          extraStyle="text-xs pt-2 md:text-sm"
                          btnType="TertiaryAction"
                          onClick={() => {
                            isLoadingMultipleDownloads
                              ? () => {}
                              : handleClickDownloadPhotograph(
                                  `${photograph.id}.${photograph.extension}`
                                )
                          }}
                          isLoading={isLoadingSingleDownload}
                          loadingText={t('Downloading') + '...'.toString()}
                        >
                          <div className="flex gap-1.5 items-center justify-center">
                            <FontAwesomeIcon icon={faDownload} /> {t('Download')}
                          </div>
                        </Button>
                      </div>
                    ) : (
                      <AddToCartButton photograph={photograph} album={lineItem.album} />
                    )}
                  </span>
                </div>

                {desktopAlbumData}

                <div className="w-full flex justify-end">
                  <div className="flex flex-col justify-between">
                    <span className="text-base md:font-semibold">${photograph.price}</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  )
}

export default observer(PurchaseSingleLineItem)
