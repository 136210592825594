import { isNil } from 'lodash'
import InputStore from 'shared/store/InputStore'

export const getURLWithTagFilters = (tagValue: InputStore<string>, location: Location): string => {
  const searchParams = new URLSearchParams()
  if (!isNil(tagValue) && tagValue.value.length > 0) {
    searchParams.append('tagValue', tagValue.value)
  }
  return `${location.pathname}?${searchParams.toString()}`
}
