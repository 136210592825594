import { Currency } from '../../../shared/util/Currency'

export interface PurchasePhotographLineItem {
  photographId: string
}

export enum PurchaseLineItemPackageType {
  Tag = 'TAG',
  Burst = 'BURST',
}

// Base interface for all package types
interface BasePurchasePackageLineItemDto {
  photographIds?: string[] // Optional for all package types
}

// Interface for Burst package type
export interface BurstPackageLineItemDto extends BasePurchasePackageLineItemDto {
  packageType: PurchaseLineItemPackageType.Burst
  burstId: string
  albumId?: string // Optional
  eventId?: string // Optional
}

// Interface for Tag-based packages
export interface TagPackageLineItemDto extends BasePurchasePackageLineItemDto {
  packageType: PurchaseLineItemPackageType.Tag
  tagId: string
}

// Specific Tag package types
export interface EventTagPackageLineItemDto extends TagPackageLineItemDto {
  eventId: string
  albumId?: never // Ensure albumId is not present
}

export interface AlbumTagPackageLineItemDto extends TagPackageLineItemDto {
  albumId: string
  eventId?: never // Ensure eventId is not present
}

// Union type for all possible package types
export type PurchasePackageLineItemDto =
  | BurstPackageLineItemDto
  | EventTagPackageLineItemDto
  | AlbumTagPackageLineItemDto

export type CreatePurchaseLineItem = PurchasePhotographLineItem | PurchasePackageLineItemDto

export default interface CreatePurchaseInput {
  discountCodes: string[]
  lineItems: CreatePurchaseLineItem[]
  printedPhotographs: boolean
  currency: Currency
}
