import CountryInterface from 'services/RequestInterfaces/Country/Country.interface'

export class Country {
  public static init(data: CountryInterface): Country {
    const country = new Country()
    country.code = data.code
    country.name = data.name
    country.prefix = data.prefix

    return country
  }

  code: string

  name: string

  prefix: string

  public static clone(data: Country): Country {
    const clone = new Country()
    clone.code = data.code
    clone.name = data.name
    clone.prefix = data.prefix

    return clone
  }
}
