import React from 'react'
import { faImage } from '@fortawesome/free-regular-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import shareGolden from '../../../assets/icons/v2/shareGolden.svg'
import ConfigService, { AssetNames } from 'config'

const HowItWorksGuideDesktop = () => {
  const { t } = useTranslation()
  return (
    <div className="pt-8 sm:pt-14 bg-white">
      <h2
        className="text-center w-full sm:mb-4 text-lumepic-light_black text-3xl sm:text-4xl"
        style={{ fontWeight: '600' }}
      >
        {t('How does it work?')}
      </h2>
      <div className="py-10 sm:py-20 px-4 sm:px-10 flex sm:flex-col w-full gap-2.5 justify-center sm:bg-bg_details">
        <div className="flex flex-col sm:flex-row justify-around w-4/5 sm:w-full h-60vh sm:h-auto">
          <div className="flex flex-col items-center gap-1.5 w-full sm:w-1/3 h-1/3 sm:h-auto">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="text-lumepic-dark_grey text-3xl" />
            <span className="text-lumepic-grey opacity-90 text-medium text-lg">
              {t('Find yourself')}
            </span>
            <div className="w-full sm:hidden text-center text-lumepic-grey opacity-90 text-sm sm:text-base">
              {t('Find your photos using filters by sport, date and place.')}
            </div>
          </div>
          <div className="flex flex-col items-center gap-1.5 w-full sm:w-1/3 h-1/3 sm:h-auto">
            <FontAwesomeIcon icon={faImage} className="text-lumepic-light_black text-3xl" />
            <span className="text-lumepic-light_black text-medium text-lg">{t('Buy')}</span>
            <div className="w-full sm:hidden text-center text-lumepic-light_black text-sm sm:text-base">
              {t('Pay without complications using any credit or debit card.')}
            </div>
          </div>
          <div className="flex flex-col items-center gap-1.5 w-full sm:w-1/3 h-1/3 sm:h-auto">
            <img src={shareGolden} alt="Share Icon" />
            <span className="text-primary text-medium text-lg">{t('Share!')}</span>
            <div className="w-full sm:hidden text-center text-primary text-sm sm:text-base">
              {t('Immortalize your best moments by sharing your photos with your loved ones.')}
            </div>
          </div>
        </div>

        <img
          src={ConfigService.getAsset(AssetNames.LANDING_PROGRESSBAR_DESKTOP)}
          alt="Progress Bar"
          className="hidden sm:block"
        />

        <div className="hidden sm:flex flex-col sm:flex-row justify-around mt-1 w-1/3 sm:w-full h-60vh sm:h-auto">
          <div className="w-full sm:w-1/3">
            <div className="flex justify-center">
              <div className="w-full sm:w-1/2 text-center text-lumepic-grey opacity-90">
                {t('Find your photos using filters by sport, date and place.')}
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/3">
            <div className="flex justify-center">
              <div className="w-full sm:w-1/2 text-center text-lumepic-light_black">
                {t('Pay without complications using any credit or debit card.')}
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/3">
            <div className="flex justify-center">
              <div className="w-full sm:w-1/2 text-center text-primary">
                {t('Immortalize your best moments by sharing your photos with your loved ones.')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorksGuideDesktop
