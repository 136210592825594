import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons'
import ConfigService from '../../../config'
import { User } from '../../../shared/models/User'
import { useTranslation } from 'react-i18next'
import { FeatureFlags } from '../../../config/constants/featureFlags'
import { isNil } from 'lodash'
import storesContext from 'providers/storesContext'
import { getFeatureFlagValue } from 'shared/utility'

type ToDosSectionProps = {
  user: User
  verifyEmail: () => void
  redirectToStripeLogin: () => void
  redirectToStripeOnboarding: () => void
}

const ToDosSection = ({
  user,
  verifyEmail,
  redirectToStripeLogin,
  redirectToStripeOnboarding,
}: ToDosSectionProps) => {
  const { t } = useTranslation()
  const { featureFlagsStore } = useContext(storesContext)!

  const enableStripeOnboarding = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.ENABLE_STRIPE_ONBOARDING,
    true
  )

  const enableMPOnboarding = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.ENABLE_MERCADOPAGO_ONBOARDING,
    false
  )

  const redirectToMercadoPago = () => {
    const url = ConfigService.buildMercadoPagoCredentials(user.id)
    window.location.href = url
  }

  const { mercadoPagoVerified, isVerified, isEnabledToSell, stripeAccountId } = user

  return (
    <div className="w-full p-3 bg-white border-t-4 border-primary">
      <div className="grid-cols-2 md:grid">
        <div className="col-span-2">
          <div className="flex items-center space-x-2 font-semibold leading-8 text-gray-900">
            <span className="text-green-500">
              <i>
                <FontAwesomeIcon icon={faMoneyCheckAlt} />
              </i>
            </span>
            <span className="tracking-wide">{t('To start selling')}</span>
          </div>
          {!mercadoPagoVerified && !stripeAccountId && (
            <div className="col-span-2">
              <p className="mb-0 font-extrabold">
                {`¡${
                  isVerified
                    ? t(
                        'Remember that to start selling in Lumepic it is required to sync your Lumepic account with Stripe'
                      )
                    : t(
                        'Remember that to start selling in Lumepic it is required to verify your email and sync your Lumepic account with Stripe'
                      )
                }!`}
              </p>
            </div>
          )}
          {enableMPOnboarding && (
            <div className="flex justify-between py-3 text-gray-700 md:items-center">
              <span>{`${t('Link status to Mercadopago')}:`}</span>
              <span className="ml-auto">
                {user.mercadoPagoVerified ? (
                  <div className="flex justify-center">
                    <span className="px-2 py-1 text-sm text-lumepic-black rounded bg-primary">
                      {t('Linked')}
                    </span>
                    <span className="ml-2 text-xl text-green-500">
                      <i>
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </i>
                    </span>
                  </div>
                ) : (
                  <span
                    onClick={redirectToMercadoPago}
                    className="px-2 py-1 text-sm text-lumepic-black rounded bg-primary hover:cursor-pointer"
                  >
                    {t('Sync')}
                  </span>
                )}
              </span>
            </div>
          )}
          {enableStripeOnboarding && isVerified && (
            <div className="flex justify-between py-3 text-gray-700 md:items-center">
              <span>{`${t('Stripe')}:`}</span>
              <span className="ml-auto">
                {isNil(user.stripeAccountId) ? (
                  <span
                    onClick={redirectToStripeOnboarding}
                    className="px-2 py-1 text-sm text-lumepic-black rounded bg-primary hover:cursor-pointer"
                  >
                    {t('Create Stripe Account')}
                  </span>
                ) : user.stripeOnboardingCompleted ? (
                  <div className="flex justify-center">
                    <span
                      onClick={redirectToStripeLogin}
                      className="px-2 py-1 text-sm text-lumepic-black rounded bg-primary hover:cursor-pointer"
                    >
                      {t('Go to Stripe Dashboard')}
                    </span>
                    {isEnabledToSell && (
                      <span className="ml-2 text-xl text-green-500">
                        <i>
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </i>
                      </span>
                    )}
                  </div>
                ) : (
                  <span
                    onClick={redirectToStripeOnboarding}
                    className="px-2 py-1 text-sm text-lumepic-black rounded bg-primary hover:cursor-pointer"
                  >
                    {t('Finish Stripe onboarding')}
                  </span>
                )}
              </span>
            </div>
          )}
          <div className="flex justify-between py-3 text-gray-700 md:items-center">
            <span>{`${t('Email verification')}:`}</span>
            <span className="ml-auto">
              {isVerified ? (
                <div className="flex justify-center">
                  <span className="px-2 py-1 mr-2 text-sm text-lumepic-black rounded bg-primary">
                    {t('Verified')}
                  </span>
                  <span className="text-xl text-green-500">
                    <i>
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </i>
                  </span>
                </div>
              ) : (
                <span
                  onClick={verifyEmail}
                  className="px-2 py-1 text-sm text-lumepic-black rounded bg-primary hover:cursor-pointer"
                >
                  {t('Verify')}
                </span>
              )}
            </span>
          </div>
        </div>
        {(mercadoPagoVerified || stripeAccountId) && !isVerified && (
          <div className="col-span-2">
            <p className="mb-0 font-extrabold">
              {`¡${t(
                'Remember that to start selling in Lumepic it is required to verify your email'
              )}!`}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default ToDosSection
