import * as paths from '../../Paths'
import SharedRoutes from '../Shared/routes'
import MyAccount from '../../../containers/MyAccount/MyAccount'
import Purchases from '../../../containers/Purchases/Purchases'
import PaymentSuccess from '../../../containers/Payment/PaymentSuccess'
import PaymentFailure from '../../../containers/Payment/PaymentFailure/PaymentFailure'

const routes = [
  {
    path: paths.MY_ACCOUNT,
    name: 'myAccount',
    exact: false,
    redirectPath: null,
    component: MyAccount,
  },
  {
    path: paths.PURCHASES,
    name: 'purchases',
    exact: false,
    redirectPath: null,
    component: Purchases,
  },
  {
    path: paths.PAYMENT_SUCCESS,
    name: 'paymentSuccess',
    exact: false,
    redirectPath: null,
    component: PaymentSuccess,
  },
  {
    path: paths.PAYMENT_FAILURE,
    name: 'paymentFailure',
    exact: false,
    redirectPath: null,
    component: PaymentFailure,
  },
]

export default SharedRoutes.concat(routes)
