import React from 'react'

import classes from './Backdrop.module.scss'

type BackdropProps = {
  show: boolean
  type: 'Dark' | 'light' | 'Transparent'
  clicked: (e: any) => void
}

const backdrop = ({ show, type, clicked }: BackdropProps) =>
  show ? <div className={[classes.Backdrop, classes[type]].join(' ')} onClick={clicked} /> : null

export default backdrop
