import { outlinedInputClasses } from '@mui/material'
import { ThemeOptions } from '@mui/material/styles'

// https://zenoo.github.io/mui-theme-creator/
export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#D4C581',
      light: '#E5DCB3',
    },
    secondary: {
      main: '#FFF',
    },
    success: {
      main: '#03A64A',
    },
    info: {
      main: '#636363',
    },
    text: {
      primary: '#333333',
      secondary: '#636363',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': '#636363',
          '--TextField-brandBorderWidth': '2.2px',
          '& label.Mui-focused': {
            color: '#636363',
            fontWeight: '400',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#636363',
          borderWidth: '1.9px',
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: '#636363',
            borderWidth: '2.2px',
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: '#D4C581',
            borderWidth: '2.2px',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Euclid Circular A',
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightBold: 800,
  },
}
