import React from 'react'
import { observer } from 'mobx-react'
import { Purchase } from '../../../shared/models/Purchase'
import PurchaseItem from './PurchaseItem'
import PurchaseStore from '../PurchaseStore'

type PurchaseDetailsProps = {
  purchases: Purchase[]
  purchaseCount: number
  purchaseDesktopDetailsOpened: Purchase | null
  setPurchaseDesktopDetailsOpened: React.Dispatch<React.SetStateAction<Purchase | null>>
  store: PurchaseStore
}

const PurchaseItems = ({
  purchases,
  purchaseCount,
  purchaseDesktopDetailsOpened,
  setPurchaseDesktopDetailsOpened,
  store,
}: PurchaseDetailsProps) => {
  return (
    <div className="rounded-b-lg md:overflow-y-auto md:max-h-[70vh]">
      {purchases.map((purchase, index) => (
        <PurchaseItem
          key={index}
          purchase={purchase}
          index={purchaseCount - index}
          purchaseDesktopDetailsOpened={purchaseDesktopDetailsOpened}
          setPurchaseDesktopDetailsOpened={setPurchaseDesktopDetailsOpened}
          store={store}
        />
      ))}
    </div>
  )
}

export default observer(PurchaseItems)
