import React from 'react'
import c from 'classnames'

const inputBaseClasses = [
  'p-2.5',
  'rounded-md',
  'w-full',
  'focus:ring-2',
  'focus:outline-none',
  'transition-shadow',
]

const inputDefaultClasses = [
  'text-lumepic-black',
  'bg-gray-50',
  'placeholder-lumepic-grey',
  'focus:outline-none',
  'focus:shadow-md',
]

const inputInvertedClasses = [
  'bg-gray-600',
  'text-white',
  'focus:ring-gray-800',
  'focus:ring-opacity-80',
]

const inputErrorClasses = ['border-2, border-warning']

type TextareaProps = {
  onChange: (value: string) => void
  value: string
  inputRef?: React.RefObject<HTMLTextAreaElement> | null
  inverted?: boolean
  error?: boolean
  placeholder?: string
}

const Textarea = ({
  onChange,
  value,
  inputRef = null,
  inverted = false,
  error = false,
  placeholder,
}: TextareaProps) => {
  const inputStyleClasses = !inverted ? inputDefaultClasses : inputInvertedClasses
  const errorClasses = error ? inputErrorClasses : []
  const inputClasses = [...inputBaseClasses, ...inputStyleClasses, ...errorClasses]

  const handleChange = (e: { target: { value: string } }) => {
    onChange(e.target.value)
  }

  return (
    <textarea
      rows={4}
      value={value}
      placeholder={placeholder}
      className={c(inputClasses)}
      onChange={handleChange}
      ref={inputRef}
    />
  )
}

export default Textarea
