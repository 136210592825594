import React, { useEffect, useState, useContext } from 'react'
import { observer } from 'mobx-react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import StoresContext from '../../../providers/storesContext'
import PaymentStore from '../PaymentStore'
import * as path from '../../../routing/Paths'
import PaymentDetail from './components/PaymentDetail'
import Button from 'components/UI/Button'
import { isNil } from 'lodash'
import Tracker from '../../../shared/tracking'
import Spinner from 'components/UI/Spinner'
import { FeatureFlags } from '../../../config/constants/featureFlags'
import { toast } from 'react-toastify'
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../../services/axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { getFeatureFlagValue } from 'shared/utility'

const PaymentSuccess = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const { authStore, cartStore, featureFlagsStore } = useContext(StoresContext)!
  const [paymentStore] = useState(() => new PaymentStore(authStore))

  const stripeEnabled = getFeatureFlagValue(featureFlagsStore, FeatureFlags.ENABLE_STRIPE, true)

  const mercadoPagoEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.ENABLE_MERCADOPAGO,
    true
  )

  const handleContinueShopping = () => {
    if (cartStore.isEmpty()) {
      history.push(path.FEED_ALBUMS)
    } else {
      history.push(path.CART)
    }
  }

  useEffect(() => {
    let purchaseFetchAttempt = false
    if (stripeEnabled) {
      const sessionId = new URLSearchParams(location.search).get('session_id')
      if (!isNil(sessionId)) {
        purchaseFetchAttempt = true
        cartStore.deletePurchasedCart()
        paymentStore.fetchPurchaseByStripeSession(sessionId).then()
      }
    }
    if (mercadoPagoEnabled && !purchaseFetchAttempt) {
      const purchaseId = new URLSearchParams(location.search).get('external_reference')
      if (!isNil(purchaseId)) {
        purchaseFetchAttempt = true
        cartStore.deletePurchasedCart()
        paymentStore.fetchPurchase(purchaseId).then()
      }
    }
    if (!purchaseFetchAttempt) {
      //PATCH FOR NO COST PURCHASES
      const purchaseId = new URLSearchParams(location.search).get('purchase_id')
      if (!isNil(purchaseId)) {
        purchaseFetchAttempt = true
        cartStore.deletePurchasedCart()
        paymentStore.fetchPurchase(purchaseId).then()
      }
    }
    if (!purchaseFetchAttempt) {
      toast.error(t('Purchase not found.'), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
  }, [])

  if (paymentStore.isLoading) {
    return <Spinner />
  }

  if (paymentStore.purchase) {
    Tracker.purchase(paymentStore.purchase)
  }

  return (
    <>
      <div className="bg-bg_section_titles border-b mb-2 pb-3 pt-4">
        <div className="container mx-auto flex gap-1.5 md:gap-3 items-center">
          <h2 className="text-2xl ml-2 font-extrabold md:text-3xl text-lumepic-light_black">
            {t('Purchase Success')}
          </h2>
          <FontAwesomeIcon icon={faCheckCircle} className="text-xl md:text-2xl text-success" />
        </div>
      </div>
      <div className="container mx-auto mt-4 flex flex-col gap-4 md:grid md:grid-cols-8 mb-8">
        <div className="flex flex-col gap-4 md:col-span-3">
          <div className="flex flex-col gap-4 bg-lumepic-light_black p-4 rounded-md">
            <h1 className="text-primary font-bold text-3xl">{t('Thank you for your purchase!')}</h1>
            <p className="text-white text-lumepicSm font-light mb-1">
              {t(
                'Thank you for supporting the photographers and trusting Lumepic. You can also access your photos at any time in My Purchases'
              )}
              .
            </p>
          </div>
          <div className="flex flex-col gap-2 p-4 rounded-md bg-white">
            <p className="font-medium">{t('Are you an Android user?')}</p>
            <p className="text-lumepicSm">
              {t(
                'If you can ´ t find your photos after downloading them, check on your device ´ s gallery'
              )}
            </p>
          </div>
          <div className="w-full hidden md:block">
            <Button onClick={handleContinueShopping} btnType="PrimaryAction" extraStyle="w-full">
              {t('Continue shopping')}
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-4 md:col-span-5">
          {!isNil(paymentStore.purchase) && (
            <PaymentDetail purchase={paymentStore.purchase} store={paymentStore} />
          )}
        </div>
      </div>
    </>
  )
}

export default withErrorHandler(observer(PaymentSuccess), axios)
