import ConfigService, { AssetNames } from 'config'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PhotographerFactsLine from './components/PhotographerFactsLine'
import Button from 'components/UI/Button'
import { scrollToElement } from 'containers/PhotographersLanding/utils/utils'
import Tracker from 'shared/tracking'
import { faDollarSign, faWifi } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'

const PhotographerFactsMobile = () => {
  const { t } = useTranslation()
  return (
    <div className="bg-white py-28 container mx-auto">
      <h3 className="text-center text-lumepic-light_black text-2xl font-semibold mb-12">
        {t('Why Lumepic?')}
      </h3>
      <div className="flex gap-3 items-center px-4">
        <div className="w-1/3">
          <img
            src={ConfigService.getAsset(
              AssetNames.PHOTOGRAPHERS_LANDING_FACTS_1,
              'photographers-landing'
            )}
            className="object-cover object-center"
            alt="Photographer facts"
          />
        </div>
        <div className="w-2/3">
          <PhotographerFactsLine
            text={t('Photographers save up to 20 hours a week by automating sales')}
            icon={faClock}
          />
        </div>
      </div>
      <div className="flex gap-3 items-center px-4 my-10">
        <div className="w-2/3">
          <PhotographerFactsLine
            text={t('80% of customers prefer to buy online, without direct interaction')}
            icon={faWifi}
          />
        </div>
        <div className="w-1/3">
          <img
            src={ConfigService.getAsset(
              AssetNames.PHOTOGRAPHERS_LANDING_FACTS_2,
              'photographers-landing'
            )}
            className="object-cover object-center"
            alt="Photographer facts"
          />
        </div>
      </div>
      <div className="flex gap-3 items-center px-4">
        <div className="w-1/3">
          <img
            src={ConfigService.getAsset(
              AssetNames.PHOTOGRAPHERS_LANDING_FACTS_3,
              'photographers-landing'
            )}
            className="object-cover object-center"
            alt="Photographer facts"
          />
        </div>
        <div className="w-2/3">
          <PhotographerFactsLine
            text={t('Photographers sell up to 60% more than with direct sales')}
            icon={faDollarSign}
          />
        </div>
      </div>
      <div className="flex justify-center w-full mt-10">
        <Button
          btnType="PrimaryAction"
          onClick={() => {
            Tracker.goToPhotographersRegister()
            scrollToElement('photographer-register')
          }}
        >
          {t('Try now')}
        </Button>
      </div>
    </div>
  )
}

export default PhotographerFactsMobile
