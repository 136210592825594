import React, { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'

type NavigationItemProps = {
  link: string
  exact?: boolean
  children: ReactNode
  activeClasses?: string
}

const NavigationItem = ({ link, exact, children, activeClasses }: NavigationItemProps) => (
  <li className={activeClasses}>
    <NavLink to={link} exact={exact}>
      {children}
    </NavLink>
  </li>
)

export default NavigationItem
