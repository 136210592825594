import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/free-solid-svg-icons'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import QuantityDiscount from 'components/AlbumDetails/components/QuantityDiscount'
import Album from 'shared/models/Album'
import { Event } from 'shared/models/Event'
import { observer } from 'mobx-react'

type ItemDetailsDiscountAccordionProps = {
  album?: Album
  event?: Event
}

const ItemDetailsDiscountAccordion = ({ album, event }: ItemDetailsDiscountAccordionProps) => {
  const { t } = useTranslation()
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)

  return (
    <Accordion
      className="w-full md:hidden"
      onChange={(event, expanded) => setIsAccordionExpanded(expanded)}
      sx={{
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        marginTop: '1rem !important',
        '&.MuiAccordion-root': {
          borderRadius: '8px',
          overflow: 'hidden',
          '&:before': {
            display: 'none',
          },
        },
        '& .MuiAccordionSummary-root': {
          backgroundColor: 'white',
          borderRadius: '8px',
          minHeight: '48px !important',
          '&.Mui-expanded': {
            minHeight: '48px !important',
          },
        },
        '& .MuiAccordionSummary-content': {
          margin: '12px 0 !important',
          '&.Mui-expanded': {
            margin: '12px 0 !important',
          },
        },
        '& .MuiAccordionDetails-root': {
          borderTop: isAccordionExpanded ? '1px solid #e0e0e0' : 'none',
          backgroundColor: 'white',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="flex items-center gap-1.5">
          <FontAwesomeIcon icon={faTag} className="text-lumepic-grey text-lg" />
          <header className="lg:text-lg">{t('Discounts')}</header>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <QuantityDiscount album={album} event={event} />
      </AccordionDetails>
    </Accordion>
  )
}

export default observer(ItemDetailsDiscountAccordion)
