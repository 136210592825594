import React, { useRef, useState } from 'react'
import { GoogleMap, Autocomplete, Marker, InfoWindow } from '@react-google-maps/api'
import { useTranslation } from 'react-i18next'
import goldMarkerIcon from '../../../assets/icons/v2/gold-marker.svg'
import redMarkerIcon from '../../../assets/icons/v2/red-marker.svg'
import { Location } from '../../../shared/models/Location'
import { TextField } from '@mui/material'

type LocationSearcherProps = {
  onPlaceChanged: (place: google.maps.places.PlaceResult) => void
  initialCenter?: { lat: number; lng: number }
  existingLocations: Location[]
}

const mapContainerStyle = {
  height: '400px',
  width: 'full',
}

const LocationSearcher = ({
  onPlaceChanged,
  initialCenter,
  existingLocations,
}: LocationSearcherProps) => {
  const { t } = useTranslation()

  const [userHasInteracted, setUserHasInteracted] = useState(false)

  const [center, setCenter] = useState(initialCenter)
  const [zoom, setZoom] = useState(7)
  const [inputValue, setInputValue] = useState('')
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null)
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null)

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    autocompleteRef.current = autocomplete
  }

  const handlePlaceChanged = () => {
    if (autocompleteRef.current !== null) {
      const place = autocompleteRef.current.getPlace()
      setInputValue(place.formatted_address!)
      if (place?.geometry?.location) {
        const { lat, lng } = place.geometry.location
        setCenter({ lat: lat(), lng: lng() })
        onPlaceChanged(place)
        setUserHasInteracted(true)
      }
    }
    setZoomInWithDelay()
  }

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const { latLng } = event
    const lat = latLng?.lat() ?? 0
    const lng = latLng?.lng() ?? 0

    setCenter({ lat, lng })

    const geocoder = new window.google.maps.Geocoder()
    const latlng = { lat, lng }
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        const place = results[0]
        const placeAddress = place.formatted_address
        setInputValue(placeAddress)

        const placeResult: google.maps.places.PlaceResult = {
          ...place,
          geometry: {
            location: latLng ?? undefined,
            viewport: place.geometry?.viewport ?? new google.maps.LatLngBounds(),
          },
        }

        onPlaceChanged(placeResult)
      }
    })
    setZoomInWithDelay()
  }

  const mapRef = useRef<google.maps.Map | null>(null)

  const setZoomInWithDelay = () => {
    setTimeout(() => {
      setZoom(15)
    }, 500)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handleOpenInfoWindowById = (id: string) => {
    setSelectedLocation(id)
  }

  const handlecloseInfoWindow = () => {
    setSelectedLocation(null)
  }

  return (
    <div className="flex flex-col gap-8 mr-2">
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={() => {
          handlePlaceChanged()
        }}
      >
        <div className="flex flex-col w-full gap-2 justify-center">
          <span className="flex justify-start">{t('Search spot in the map').toString()}</span>
          <TextField
            value={inputValue}
            className="w-full placeholder-lumepic-grey"
            onChange={handleInputChange}
            placeholder={t('Search in map').toString()}
          />
        </div>
      </Autocomplete>
      <GoogleMap
        id="searchbox-example"
        mapContainerStyle={mapContainerStyle}
        zoom={zoom}
        center={center}
        onClick={(e) => {
          setUserHasInteracted(true)
          handleMapClick(e)
        }}
        onLoad={(map) => {
          mapRef.current = map
        }}
      >
        {existingLocations.map((location) => {
          const { latitude, longitude } = location
          const lat = parseFloat(latitude.toString())
          const lng = parseFloat(longitude.toString())

          return (
            <Marker
              key={location.id}
              position={{ lat, lng }}
              icon={{
                url: goldMarkerIcon,
                scaledSize: new window.google.maps.Size(35, 35),
              }}
              animation={window.google.maps.Animation.DROP}
              clickable={true}
              onClick={() => handleOpenInfoWindowById(location.id)}
              cursor="pointer"
            >
              {selectedLocation === location.id && (
                <InfoWindow onCloseClick={handlecloseInfoWindow}>
                  <div className="bg-white  shadow-md p-2">
                    <h3 className="text-lg font-extrabold mb-2">{location.spotName}</h3>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          )
        })}

        {userHasInteracted && (
          <Marker
            position={{ lat: center?.lat ?? 0, lng: center?.lng ?? 0 }}
            cursor="pointer"
            draggable={true}
            icon={{
              url: redMarkerIcon,
              scaledSize: new window.google.maps.Size(35, 35),
            }}
            animation={window.google.maps.Animation.DROP}
            zIndex={1}
          />
        )}
      </GoogleMap>
    </div>
  )
}

export default LocationSearcher
