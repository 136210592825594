import { makeAutoObservable, runInAction } from 'mobx'
import PhotographsService from '../../services/PhotographsService'
import PurchaseService from '../../services/PurchaseService'
import { toast } from 'react-toastify'
import AuthStore from '../../stores/AuthStore'
import { Purchase } from '../../shared/models/Purchase'

class PaymentStore {
  private purchaseService: PurchaseService
  private photographService: PhotographsService
  public purchase: Purchase | null
  public isLoading: boolean
  public isDownloadingPhotograph: boolean
  private error: any

  constructor(private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.purchaseService = new PurchaseService()
    this.photographService = new PhotographsService()
    this.authStore = authStore
  }

  reset() {
    this.purchase = null
    this.isLoading = true
    this.error = false
  }

  async fetchPurchase(purchaseId: string): Promise<void> {
    this.startLoading()
    try {
      const purchase = await this.purchaseService.fetchPurchase(
        purchaseId,
        this.authStore.getToken()
      )

      runInAction(() => {
        this.isLoading = false
        this.purchase = purchase
        this.error = null
        this.stopLoading()
      })
    } catch (e) {
      this.error = e
      this.stopLoading()
    }
  }

  async fetchPurchaseByStripeSession(sessionId: string): Promise<void> {
    this.startLoading()
    try {
      const purchase = await this.purchaseService.fetchPurchaseByStripeSessionId(
        sessionId,
        this.authStore.getToken()
      )

      runInAction(() => {
        this.isLoading = false
        this.purchase = purchase
        this.error = null
        this.stopLoading()
      })
    } catch (e) {
      this.error = e
      this.stopLoading()
    }
  }

  async fetchPurchasedPhotographsUrls(purchaseId: string, photographsIds: string[]) {
    this.startDownloadingPhotograph()
    try {
      const photographUrls = await this.photographService.getPurchasedPhotographDownloadUrl(
        this.authStore.getToken(),
        purchaseId,
        photographsIds
      )

      runInAction(() => {
        this.error = null
        this.stopDownloadingPhotograph()
      })
      return photographUrls
    } catch (e: any) {
      this.error = e
      if (e?.message) {
        toast.error(e.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      this.stopDownloadingPhotograph()
    }
  }

  startLoading() {
    this.isLoading = true
  }

  stopLoading() {
    this.isLoading = false
  }

  startDownloadingPhotograph() {
    this.isDownloadingPhotograph = true
  }

  stopDownloadingPhotograph() {
    this.isDownloadingPhotograph = false
  }
}

export default PaymentStore
