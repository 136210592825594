import { toast } from 'react-toastify'

export const Toast = (
  message: string,
  type: 'success' | 'error' | 'info' | 'warning' = 'success'
) => {
  toast[type](message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  })
}
