import { createContext } from 'react'
import AuthStore from '../stores/AuthStore'
import LocationStore from '../stores/LocationStore'
import DiscountStore from '../stores/DiscountStore'
import CartStore from '../stores/CartStore'
import ActivityStore from '../stores/ActivityStore'
import EventStore from '../stores/EventStore'
import FeatureFlagsStore from 'stores/FeatureFlagsStore'

type StoresContext = {
  authStore: AuthStore
  locationStore: LocationStore
  activityStore: ActivityStore
  eventStore: EventStore
  cartStore: CartStore
  discountStore: DiscountStore
  featureFlagsStore: FeatureFlagsStore
}

export default createContext<StoresContext | null>(null)
