import React from 'react'
import Modal from 'components/UI/Modal'
import { observer } from 'mobx-react'
import { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import backgroundImage from '../../../assets/images/goldenBg1.svg'
import { useTranslation } from 'react-i18next'
import Button from 'components/UI/Button'
import InputWrapper from 'components/UI/Forms/InputWrapper'
import {
  ItemDetailsStoreInterface,
  isEventDetailsStore,
} from 'components/ItemDetails/Interfaces/ItemDetailsStore.interface'

type TagFilterModalProps = {
  onCancel: () => void
  opened: boolean
  store: ItemDetailsStoreInterface
  onSuccess: () => void
  handleClearFilters: () => void
}

const TagFilterModal = ({
  onCancel,
  onSuccess,
  opened,
  store,
  handleClearFilters,
}: TagFilterModalProps) => {
  const { t } = useTranslation()

  const handleChangeTagValue = (tagValue: string) => {
    // Use the type guard to check if the store is EventDetailsStore
    if (isEventDetailsStore(store)) {
      store.setAlbumsView(false)
    }
    store.setIsFilteringByFaceRecognition(false)
    store.setDateRange([null, null])
    store.setTagValue(tagValue)

    if (tagValue.trim() === '') {
      handleClearFilters()
    }
  }

  return (
    <Modal
      onCancel={onCancel}
      opened={opened}
      modalSize={ModalSize.MEDIUM_SMALL}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
      displayCloseIcon={false}
    >
      <div className="w-full">
        <div className="relative overflow-hidden h-14 rounded-t-md">
          <img src={backgroundImage} className="w-full" alt="background" />
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="absolute text-xl cursor-pointer top-2 right-2"
            onClick={() => onCancel()}
          />
        </div>
        <div className="relative h-auto">
          <div className="absolute w-full text-center -top-6">
            <div className="flex flex-col justify-center gap-2">
              <h3 className="mb-0 text-center">
                <span className="p-1 text-2xl font-medium bg-white rounded-md shadow-md">
                  {t('Enter a text')}
                </span>
              </h3>
              <span className="text-xs">
                {t('Type your unique number or word that distinguished your outfit on this event.')}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full rounded-b-md pt-16 px-2 pb-4">
          <div className="flex items-end gap-3">
            <InputWrapper
              className="flex-grow-1"
              onChange={handleChangeTagValue}
              value={store.tagValue?.value}
              placeholder={t('e.g. 2083').toString()}
              onPressEnter={onSuccess}
              extraClassNames="truncate"
            />

            <Button btnType={'PrimaryAction'} onClick={onSuccess} extraStyle={`mb-1`}>
              {t('Search')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default observer(TagFilterModal)
