import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import StoresContext from '../../providers/storesContext'
import AlbumsContext from '../../providers/albumsContext'
import MyAlbumsStore from './MyAlbumsStore'
import { useTranslation } from 'react-i18next'
import VerifyOrLinkAccountModal from '../../components/MyAccount/VerifyOrLinkAccount/VerifyOrLinkAccountModal'
import Button from 'components/UI/Button'
import AddIcon from '@mui/icons-material/Add'
import Album from 'shared/models/Album'
import CreateOrUpdateAlbumSteps from './CreateOrUpdateAlbumSteps'
import AlbumTable from '../../components/Album/AlbumTable'
import { toast } from 'react-toastify'
import { Steps } from './CreateOrUpdateAlbumSteps/CreateOrUpdateAlbumSteps'
import { isNil } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { SetPageTitle } from '../../shared/utility'

const InitialCreateOrUpdateAlbumModalState = {
  handleFinish: () => {},
  handleClose: () => {},
  display: false,
  albumToEdit: undefined,
  definedSteps: undefined,
}

const MyAlbums = () => {
  SetPageTitle('My Albums')
  const [showCreateOrUpdateAlbumModal, setShowCreateOrUpdateAlbumModal] = useState<{
    handleFinish: () => void
    handleClose: () => void
    display: boolean
    albumToEdit?: Album
    definedSteps?: Steps[]
  }>(InitialCreateOrUpdateAlbumModalState)
  const { authStore } = useContext(StoresContext)!
  const { t } = useTranslation()
  const [myAlbumsStore] = useState(() => new MyAlbumsStore(authStore))
  const [visibleVerifyOrLinkAccountModal, setVisibleVerifyOrLinkAccountModal] = useState(false)

  const initialRender = useRef(true)

  const loggedUser = authStore.getLoggedUser()

  useEffect(() => {
    if (initialRender.current) {
      myAlbumsStore.fetchAlbums()
      if (!loggedUser) {
        authStore.getUser()
      }
      if (!loggedUser.isEnabledToSell || !loggedUser.isVerified) {
        setVisibleVerifyOrLinkAccountModal(true)
      }
      initialRender.current = false
    }
  })

  const handleFinishCreateOrUpdateAlbum = (relaoad: boolean) => {
    setShowCreateOrUpdateAlbumModal(InitialCreateOrUpdateAlbumModalState)
    if (relaoad) {
      myAlbumsStore.setPage(1)
    }
  }

  const setPage = (page: number) => {
    myAlbumsStore.setPage(page)
  }

  const setAlbumsPerPage = (albumsPerPage: number) => {
    myAlbumsStore.setAlbumsPerPage(albumsPerPage)
  }

  const createNewAlbum = () => {
    setShowCreateOrUpdateAlbumModal({
      handleFinish: () => handleFinishCreateOrUpdateAlbum(true),
      handleClose: () => handleFinishCreateOrUpdateAlbum(false),
      display: true,
      albumToEdit: undefined,
    })
  }

  const updateCoverPhotograph = (album: Album) => {
    setShowCreateOrUpdateAlbumModal({
      handleFinish: () => handleFinishCreateOrUpdateAlbum(true),
      handleClose: () => handleFinishCreateOrUpdateAlbum(false),
      display: true,
      albumToEdit: album,
      definedSteps: [Steps.UploadCoverPhotograph],
    })
  }

  const addImagesToAlbum = (album: Album) => {
    setShowCreateOrUpdateAlbumModal({
      handleFinish: () => handleFinishCreateOrUpdateAlbum(true),
      handleClose: () => handleFinishCreateOrUpdateAlbum(false),
      display: true,
      albumToEdit: album,
      definedSteps: [Steps.AddPhotographsToAlbum],
    })
  }

  const addDiscountToAlbum = (album: Album) => {
    setShowCreateOrUpdateAlbumModal({
      handleFinish: () => handleFinishCreateOrUpdateAlbum(true),
      handleClose: () => handleFinishCreateOrUpdateAlbum(false),
      display: true,
      albumToEdit: album,
      definedSteps: [Steps.AddDiscountsToAlbum],
    })
  }

  const editAlbum = (album: Album) => {
    setShowCreateOrUpdateAlbumModal({
      handleFinish: () => handleFinishCreateOrUpdateAlbum(true),
      handleClose: () => handleFinishCreateOrUpdateAlbum(false),
      display: true,
      albumToEdit: album,
      definedSteps: [Steps.CreateOrUpdateAlbum],
    })
  }

  const requestEmailVerification = () => {
    authStore.requestEmailVerification()
    if (authStore.getLoggedUser()?.isPhotographer) {
      toast.info(t('Please access your email to verify your account'), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }

    setVisibleVerifyOrLinkAccountModal(false)
  }

  const publishAbum = async (album: Album) => {
    await myAlbumsStore.activateAlbum(album.id)
  }

  const redirectToStripeOnboarding = () => {
    authStore.getOnboardingUrl().then((accountUrl) => {
      if (!isNil(accountUrl)) {
        window.location.href = accountUrl
      }
    })
  }

  return (
    <AlbumsContext.Provider value={myAlbumsStore}>
      <div className="h-full min-h-primaryMobile md:min-h-primaryDesktop pb-5 bg-bg_details">
        <div className="container mx-auto">
          <div className="flex justify-between items-center my-2.5 md:my-5 mx-2 sm:mx-0">
            <h2 className="mb-0 text-2xl sm:text-4xl text-lumepic-light_black font-semibold">
              {t('My Albums')}
            </h2>
            <Button
              extraStyle="w-auto hidden md:block"
              btnType="PrimaryAction"
              onClick={() => createNewAlbum()}
            >
              <AddIcon className="mb-0.5" />
              <span>{t('New Album')}</span>
            </Button>
            <button
              className="w-10 h-10 text-white rounded-full md:hidden bg-primary"
              onClick={() => createNewAlbum()}
            >
              <AddIcon />
            </button>
          </div>
          {!loggedUser.isEnabledToSell && (
            <>
              <div className="flex flex-col w-full md:w-1/3 mb-2">
                <span className="flex gap-1 items-end text-xs md:text-sm text-center pb-2">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ color: '#e74141' }}
                    size="xl"
                  />
                  <span className="text-warning">{t('Action required: click')}</span>
                  <span
                    className="text-warning underline cursor-pointer"
                    onClick={() => setVisibleVerifyOrLinkAccountModal(true)}
                  >
                    {t('here')}
                  </span>
                  <span className="text-warning">{t('to start selling')}</span>
                </span>
              </div>
            </>
          )}
          <AlbumTable
            updateCoverPhotograph={(album: Album) => updateCoverPhotograph(album)}
            addImagesToAlbum={(album: Album) => addImagesToAlbum(album)}
            addDiscountToAlbum={(album: Album) => addDiscountToAlbum(album)}
            editAlbum={(album: Album) => editAlbum(album)}
            albums={myAlbumsStore.albums}
            albumCount={myAlbumsStore.albumCount}
            isLoading={myAlbumsStore.isLoading}
            myAlbumsStore={myAlbumsStore}
            page={myAlbumsStore.page}
            albumsPerPage={myAlbumsStore.albumsPerPage}
            setRowsPerPage={setAlbumsPerPage}
            setPage={setPage}
          />
        </div>
      </div>
      {showCreateOrUpdateAlbumModal.display && (
        <CreateOrUpdateAlbumSteps
          handleFinish={showCreateOrUpdateAlbumModal.handleFinish}
          handleClose={showCreateOrUpdateAlbumModal.handleClose}
          open={showCreateOrUpdateAlbumModal.display}
          albumToEdit={showCreateOrUpdateAlbumModal.albumToEdit}
          definedSteps={showCreateOrUpdateAlbumModal.definedSteps}
          publishAlbum={(album: Album) => publishAbum(album)}
          loggedUser={loggedUser} //TODO: delete after MP to Stripe migration finishes
          redirectToStripeOnboarding={redirectToStripeOnboarding} //TODO: delete after MP to Stripe migration finishes
        />
      )}

      <VerifyOrLinkAccountModal
        open={visibleVerifyOrLinkAccountModal}
        handleClose={() => setVisibleVerifyOrLinkAccountModal(false)}
        handleVerifyEmail={requestEmailVerification}
        authStore={authStore}
        redirectToStripeOnboarding={redirectToStripeOnboarding}
      />
    </AlbumsContext.Provider>
  )
}

export default observer(MyAlbums)
