import React, { Suspense, useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'

import './App.scss'
import Layout from './hoc/Layout/Layout'
import storesContext from './providers/storesContext'
import Router from './routing'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'
import { createTheme, ThemeProvider } from '@mui/material'
import { themeOptions } from './themes/mui'
import Banner from './components/UI/Banner'
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk'
import ConfigService, { ConfigKeys } from './config'
import { isNil } from 'lodash'
import { useLoadScript } from '@react-google-maps/api'
import CustomLoader from 'components/UI/Spinner/CustomLoader'
import Maintenance from './containers/Maintenance'

const App = () => {
  const ldClient = useLDClient()
  // Load maps script to have google.maps.Geocoder available
  const mapsScript = useLoadScript({
    googleMapsApiKey: ConfigService.getValue(ConfigKeys.GOOGLE_MAPS_API_KEY),
    libraries: ['places'],
  })
  const { authStore, locationStore, activityStore } = useContext(storesContext)!
  const isAuthenticated = authStore.isAuthenticated()
  const isPhotographer = isAuthenticated && authStore.getLoggedUser().isPhotographer
  const isAthlete = isAuthenticated && authStore.getLoggedUser().isAthlete

  // TEMPORARY REDIRECT: PRODUCTION REBRANDING
  useEffect(() => {
    if (window.location.host === 'www.spotted.com.uy') {
      window.location.href =
        'https://www.lumepic.com' + window.location.pathname + window.location.search
    }
  }, [])

  // TEMPORARY REDIRECT: STAGING REBRANDING
  useEffect(() => {
    if (window.location.host === 'www.staging.spotted.com.uy') {
      window.location.href =
        'https://www.staging.lumepic.com' + window.location.pathname + window.location.search
    }
  }, [])

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-PC38BQ5' })
    ReactGA.initialize('G-X8ZQSNF95B')
  }, [])

  useEffect(() => {
    const js = document.createElement('script')
    js.type = 'text/javascript'
    js.async = true
    js.className = 'relative z-10'
    js.src = 'https://go.botmaker.com/rest/webchat/p/K0GA727CT1/init.js'
    document.body.appendChild(js)

    // Add custom CSS to remove white background
    const css = document.createElement('style')
    css.textContent = `
      .botmaker-chat-widget .botmaker-chat-widget-button {
        background: transparent !important;
        box-shadow: none !important;
      }
    `
    document.head.appendChild(css)

    return () => {
      document.body.removeChild(js)
      document.head.removeChild(css)
    }
  }, [])

  const muiTheme = createTheme(themeOptions)
  // wait until ldClient is ready
  const appIsReady =
    !isNil(ldClient) &&
    mapsScript.isLoaded &&
    !locationStore.isLoadingLocations &&
    !activityStore.isLoading
  if (!appIsReady) {
    return <CustomLoader />
  }

  const appIsInMaintenance = false

  if (appIsInMaintenance) {
    return <Maintenance />
  }

  return (
    <div className="bg-primary">
      <ThemeProvider theme={muiTheme}>
        <Layout>
          <Suspense fallback={<p>Loading...</p>}>
            <Router isPhotographer={isPhotographer} isAthlete={isAthlete} />
          </Suspense>
        </Layout>
        <Banner />
      </ThemeProvider>
    </div>
  )
}

export default withLDProvider({
  clientSideID: ConfigService.getValue(ConfigKeys.LAUNCHDARKLY_CLIENT_SIDE_ID),
})(withRouter(observer(App)))
