import { useKeenSlider } from 'keen-slider/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import data from './components/data'
import PhotographerFeature from './components/PhotographerFeature'
import Button from 'components/UI/Button'
import { scrollToElement } from 'containers/PhotographersLanding/utils/utils'
import Tracker from 'shared/tracking'
const PhotographerOtherUsesMobile = () => {
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = React.useState<number>(0)
  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    detailsChanged(s) {
      setActiveIndex(s.track.details.rel)
    },
    initial: 0,
  })

  const breadcrumbs = (totalSlides: number, activeIndex: number) => (
    <div className="flex gap-1 items-center">
      {[...Array(totalSlides)].map((_, index) => (
        <div
          key={index}
          className={`${
            index === activeIndex
              ? 'bg-primary w-2.5 h-2.5'
              : 'bg-lumepic-dark_grey opacity-80 w-2 h-2'
          }`}
          style={{ borderRadius: '50%' }}
        />
      ))}
    </div>
  )

  return (
    <div className="bg-white py-28 flex flex-col items-center justify-center">
      <h3 className="text-center text-black text-2xl font-semibold mb-8">{t('Other features')}</h3>
      <div ref={sliderRef} className="keen-slider">
        {data.map((item, idx) => (
          <div key={idx} className="keen-slider__slide px-2">
            <div className="grid grid-cols-3">
              {item.map((feature, idx2) => (
                <div key={idx2}>
                  <PhotographerFeature icon={feature.icon} title={t(feature.title)} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="sm:hidden w-full flex justify-center mt-2">
        {breadcrumbs(data.length, activeIndex)}
      </div>
      <div className="mt-10">
        <Button
          btnType="SecondaryAction"
          onClick={() => {
            Tracker.goToPhotographersRegister()
            scrollToElement('photographer-register')
          }}
        >
          {t('Start now')}
        </Button>
      </div>
    </div>
  )
}

export default PhotographerOtherUsesMobile
