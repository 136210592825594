import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faWarning } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { formatPhotographCountText } from '../../../shared/utility'
import { observer } from 'mobx-react'

type ItemDetailsSearchResultMessageProps = {
  searchInProgress: boolean
  tagValueLength: number
  isFilteringByFaceRecognition: boolean
  isLoadingEventPhotographs: boolean
  photographCount: number
}

const ItemDetailsSearchResultMessage = ({
  searchInProgress,
  tagValueLength,
  isFilteringByFaceRecognition,
  isLoadingEventPhotographs,
  photographCount,
}: ItemDetailsSearchResultMessageProps) => {
  const { t } = useTranslation()

  if (
    !searchInProgress ||
    !(tagValueLength > 0 || isFilteringByFaceRecognition) ||
    isLoadingEventPhotographs
  ) {
    return null
  }

  return (
    <>
      {photographCount > 0 ? (
        <span className="text-lumepic-black md:text-lg align-center md:font-semibold ml-2 pt-2 block">
          <FontAwesomeIcon
            icon={faCircleCheck}
            className="text-lumepic-success_dark_green mr-1 mt-2 lg:mt-3"
          />
          {t('You’ve appeared in') + ' ' + formatPhotographCountText(photographCount, t)}!
        </span>
      ) : (
        <span className="text-lumepic-black md:text-lg align-center md:font-medium ml-2 pt-2 block">
          <FontAwesomeIcon icon={faWarning} className="text-lumepic-grey mr-1 mt-2 lg:mt-3" />
          {t('You’ve not appeared in any photos')}
        </span>
      )}
    </>
  )
}

export default observer(ItemDetailsSearchResultMessage)
