import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const PackageLabel = () => {
  const { t } = useTranslation()

  return (
    <div className="w-full flex-col md:flex md:items-end">
      <div className="w-full flex items-center bg-lumepic-light_black rounded-md">
        <div className="bg-primary rounded-l-md p-1">
          <i className="text-white">
            <FontAwesomeIcon icon={faFolderOpen} />
          </i>
        </div>
        <div className="w-full flex justify-center">
          <p className="text-white text-sm font-semibold">{t('Package')}</p>
        </div>
      </div>
    </div>
  )
}

export default PackageLabel
