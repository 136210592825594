import { makeAutoObservable, runInAction } from 'mobx'
import Album from 'shared/models/Album'
import AlbumService from '../../services/AlbumService'
import AuthStore from '../../stores/AuthStore'

export default class MyAlbumsStore {
  public albums: Album[]
  public albumsPerPage: number
  public albumCount: number
  public page: number
  public newAlbum?: Album | null
  public isLoading: boolean
  public newAlbumId?: string | null
  public uploadingImage: boolean
  public error: any
  public uploadedImages: any[]
  private readonly albumService: AlbumService
  constructor(private readonly authStore: AuthStore) {
    this.reset()

    makeAutoObservable(this)
    this.albumService = new AlbumService()
    this.authStore = authStore
  }

  reset() {
    this.albums = []
    this.albumsPerPage = 20
    this.albumCount = 0
    this.page = 1
    this.newAlbum = null
    this.isLoading = false
    this.newAlbumId = null
    this.uploadingImage = false
    this.error = false
    this.uploadedImages = []
  }

  async fetchAlbums(): Promise<void> {
    this.startLoading()
    try {
      const limit = this.albumsPerPage
      const offset = (this.page - 1) * this.albumsPerPage
      const response = await this.albumService.fetchAlbums(limit, offset, this.authStore.getToken())

      runInAction(() => {
        this.isLoading = false
        this.albums = response.albums
        this.albumCount = response.count
        this.error = null
        this.stopLoading()
      })
    } catch (e) {
      this.error = e
      this.stopLoading()
    }
  }

  async deleteAlbum(albumId: string) {
    this.startLoading()
    try {
      const response = await this.albumService.deleteAlbum(albumId, this.authStore.getToken())

      runInAction(() => {
        this.isLoading = false
        this.error = null
        this.stopLoading()
      })
      return {
        success: true,
        result: response,
      }
    } catch (e) {
      this.error = e
      this.stopLoading()
    }
  }

  async suspendAlbum(albumId: string) {
    this.startLoading()
    try {
      const response = await this.albumService.suspendAlbum(albumId, this.authStore.getToken())

      runInAction(() => {
        this.isLoading = false
        this.error = null
        this.stopLoading()
      })
      return {
        success: true,
        result: response,
      }
    } catch (e) {
      this.error = e
      this.stopLoading()
    }
  }

  async activateAlbum(albumId: string) {
    this.startLoading()
    try {
      const response = await this.albumService.activateAlbum(albumId, this.authStore.getToken())

      runInAction(() => {
        this.isLoading = false
        this.error = null
        this.stopLoading()
      })
      return {
        success: true,
        result: response,
      }
    } catch (e) {
      this.error = e
      this.stopLoading()
    }
  }

  setPage(page: number) {
    this.page = page
    this.fetchAlbums()
  }

  setAlbumsPerPage(itemsPerPage: number) {
    this.albumsPerPage = itemsPerPage
    this.page = 1
    this.fetchAlbums()
  }

  startLoading() {
    this.isLoading = true
  }

  stopLoading() {
    this.isLoading = false
  }
}
