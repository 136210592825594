import React, { useContext, useEffect, useState, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import StoresContext from '../../../../providers/storesContext'
import classes from './MiniCart.module.scss'
import AuxHoc from '../../../../hoc/AuxHocs/AuxHoc'
import Backdrop from '../../../../components/UI/Backdrop'
import MiniCartLine from './components/MiniCartLine'
import Button from 'components/UI/Button'
import { AUTH, CART } from '../../../../routing/Paths'
import { useTranslation } from 'react-i18next'
import { Icon } from '@iconify/react'
import { CartLineType } from 'shared/models/Cart/CartLine/CartLine'
import { PackageCartLine } from 'shared/models/Cart/CartLine/PackageCartLine'
import { isNil } from 'lodash'

const MiniCart = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { authStore, cartStore } = useContext(StoresContext)!
  const [isMobile, setIsMobile] = useState(false)
  const cart = cartStore.getCart()
  const cartIsEmpty = cartStore.isEmpty()

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const goTo = (e: any, path: string) => {
    e.preventDefault()
    cartStore.setDisplayMiniCart(false)
    history.push(path)
  }

  const toggleMiniCart = (e: any) => {
    e.preventDefault()
    cartStore.setDisplayMiniCart(!cartStore.displayMiniCart)
  }

  const groupedCartItems = useMemo(() => {
    const packages = cart.lines.filter((line) => line.type === CartLineType.Package)
    const photographs = cart.lines.filter((line) => line.type === CartLineType.Photograph)

    return {
      packages,
      photographs,
    }
  }, [cart.lines])

  const renderCartContent = () => {
    if (cartIsEmpty) {
      return <h2 className="text-lumepic-dark_grey text-center py-4">{t('No Items in Cart')}</h2>
    }

    return (
      <div className="flex flex-col gap-4 w-full ">
        {groupedCartItems.packages.length > 0 && (
          <div className="w-full">
            <div className="bg-gray-50 px-3 py-2 rounded-t">
              <h3 className="text-sm font-semibold text-lumepic-dark_grey">
                {t('Packages')} ({groupedCartItems.packages.length})
              </h3>
            </div>
            <div className="max-h-[40vh] overflow-y-auto">
              {groupedCartItems.packages.map((line, index) => (
                <MiniCartLine key={`package-${index}`} cart={cart} line={line} />
              ))}
            </div>
          </div>
        )}

        {groupedCartItems.photographs.length > 0 && (
          <div className="w-full">
            <div className="bg-gray-50 px-3 py-2 rounded-t">
              <h3 className="text-sm font-semibold text-lumepic-dark_grey">
                {t('Individual Photos')} ({groupedCartItems.photographs.length})
              </h3>
            </div>
            <div className="max-h-[40vh] overflow-y-auto">
              {groupedCartItems.photographs.map((line, index) => (
                <MiniCartLine
                  key={`photo-${index}`}
                  cart={cart}
                  line={line}
                  groupPhotorgaphLength={groupedCartItems.photographs.length}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    )
  }

  const getTotalPhotographsCount = useMemo(() => {
    if (cartIsEmpty) return 0

    return cart.lines.reduce((total, line) => {
      if (line.type === CartLineType.Package) {
        return total + (line as PackageCartLine).quantityOfPhotographs
      }
      return total + 1 // Individual photographs count as 1
    }, 0)
  }, [cart.lines, cartIsEmpty])

  return (
    <>
      <div className={classes.CartNavigationContent} onClick={toggleMiniCart}>
        <div id="counter" className={classes.CartItemsCount}>
          <p>{cartStore.isLoading ? 0 : getTotalPhotographsCount}</p>
        </div>

        <Icon
          icon="fa-solid:shopping-bag"
          color={isMobile ? '#333' : '#636363'}
          width="22"
          height="24"
        />
      </div>

      {cartStore.displayMiniCart && (
        <AuxHoc>
          <Backdrop show type="Transparent" clicked={toggleMiniCart} />
          <div className={classes.MiniCartView}>
            <div className={classes.CartContainer}>
              <div className="w-full flex flex-col justify-start items-center p-2.5 max-h-50 overflow-y-auto">
                {renderCartContent()}
              </div>
            </div>

            <div className="border-t border-gray-200 p-4 bg-white">
              {!isNil(cart.totalPrice) && cart.totalPrice > 0 && (
                <p className="text-lg flex gap-1 justify-end font-medium mb-4">
                  {t('Total')}:{' '}
                  <span className="font-bold">
                    {`${cart.currency?.toUpperCase()} ${cart.totalPrice.toFixed(2)}`}
                  </span>
                </p>
              )}

              <Button
                btnType="PrimaryAction"
                extraStyle="w-full mb-2"
                onClick={(e: any) => goTo(e, CART)}
              >
                {t('View Cart')}
              </Button>

              {!authStore.isAuthenticated() && (
                <Button
                  btnType="SecondaryAction"
                  extraStyle="w-full"
                  onClick={(e: any) => goTo(e, AUTH)}
                >
                  {t('Login')}
                </Button>
              )}
            </div>
          </div>
        </AuxHoc>
      )}
    </>
  )
}

export default observer(MiniCart)
