import React from 'react'
import { useTranslation } from 'react-i18next'

type MetricFrameProps = {
  metric: string
  metricLabel: string
}

const MetricFrame = ({ metric, metricLabel }: MetricFrameProps) => {
  const { t } = useTranslation()
  return (
    <div className="basis-20 bg-lumepic-light_black rounded-md flex flex-col items-center justify-center py-2 px-3 shadow-lg">
      <p className="text-xl sm:text-2xl text-primary_dark lg:text-3xl font-bold">{metric}</p>
      <span className="text-white text-sm md:text-regular lg:text-lg text-center">
        {t(metricLabel)}
      </span>
    </div>
  )
}

export default MetricFrame
