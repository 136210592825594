import { CircularProgress } from '@mui/material'
import React, { memo } from 'react'

type SpinnerProps = {
  color?: 'primary' | 'secondary' | 'info'
  size?: number
  divStyles?: string
  noStyles?: boolean
}

const baseClasses =
  'flex justify-center h-primaryMobile md:h-primaryDesktop items-center bg-bg_details'

const Spinner = ({
  color = 'primary',
  size = 50,
  divStyles = baseClasses,
  noStyles = false,
}: SpinnerProps) => {
  const classes = noStyles ? '' : divStyles
  return (
    <div className={classes}>
      <CircularProgress color={color} size={size} thickness={6} />
    </div>
  )
}

export default memo(Spinner)
