export const FEED = '/feed'
export const HOME_METRICS = '/feed/metrics'
export const PHOTOGRAPHERS_METRICS = '/feed/photographers/metrics'
export const ALBUM_DETAILS = '/'
export const FEED_EVENT_DETAILS = '/feed/events/:id'
export const albumDetails = (albumId: string) => {
  return `/feed/albums/${albumId}`
}
export const albumTags = (albumId: string) => {
  return `/feed/albums/${albumId}/tags`
}
export const albumPhotographs = (albumId: string) => {
  return `/feed/albums/${albumId}/photographs`
}
export const albumPhotograph = (albumId: string, photographId: string) => {
  return `/feed/albums/${albumId}/${photographId}`
}

export const feedPhotographerProfileByAlias = (alias: string) => {
  return `/feed/photographer/${alias}`
}

//TODO: remove unused path
export const feedEventDetails = (eventId: string) => {
  return `/feed/events/${eventId}`
}

export const feedEventDetailsByLandingPath = (eventLandingPath: string) => {
  return `/events/landingPath/${eventLandingPath}`
}

export const feedEventAlbumsPhotographs = (eventId: string) => {
  return `/feed/events/${eventId}/photographs`
}

export const tagByValue = (value: string) => {
  return `/feed/tags/${value}`
}
