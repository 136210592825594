import React from 'react'
import Button from 'components/UI/Button'
import { useTranslation } from 'react-i18next'
import { AUTH, FEED_ALBUMS } from '../../../../../routing/Paths'
import ConfigService, { AssetNames } from 'config'

type HeroSectionMobileProps = {
  goTo: (e: any, path: string) => void
  isAuthenticated: boolean
}

const HeroSectionMobile = ({ goTo, isAuthenticated }: HeroSectionMobileProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col">
      <img
        src={ConfigService.getAsset(AssetNames.LANDING_HERO_MOBILE)}
        className="object-cover object-center w-full h-35vh object-cover object-center"
        alt="Homepage photographer image"
      />
      <div className="w-full flex flex-col justify-center px-3 pt-2 pb-4 bg-lumepic-light_black">
        <h1 className="text-2xl text-white opacity-80 font-semibold">
          {t('The global sports photography platform') + '.'}
        </h1>
        <div className="h-5" />
        <p className="mb-1 text-sm text-white">
          {t('We help athletes to immortalize incredible moments.')}
        </p>
        <p className="mb-0 text-sm text-white">
          {t('We encourage photographers to turn their hobby into a profession.')}
        </p>
        <div className="h-5" />
        <div className="flex w-full justify-center gap-3">
          <Button
            btnType="PrimaryAction"
            extraStyle="w-56"
            onClick={(e: any) => goTo(e, FEED_ALBUMS)}
          >
            <span className="text-sm px-2">{t('See albums')}</span>
          </Button>
          {!isAuthenticated && (
            <span
              className="w-full px-4 text-sm opacity-90 bg-white rounded-lg py-1 flex items-center justify-center"
              onClick={(e: any) => goTo(e, AUTH)}
            >
              {t('Login')}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default HeroSectionMobile
