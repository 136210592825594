import React, { useEffect } from 'react'

import Aux from '../AuxHocs/AuxHoc'
import useHttpErrorHandler from '../../hooks/http-error-handler'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { parseAxiosErrorCode } from '../../shared/utility'

const withErrorHandler = (WrappedComponent: any, axios: any) => {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    const [error, errorConfirmedHandler] = useHttpErrorHandler(axios)
    const { t } = useTranslation()

    useEffect(() => {
      if (error) {
        const errorCode = parseAxiosErrorCode(error)
        toast.error(t(errorCode), {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        errorConfirmedHandler()
      }
    })
    return (
      <Aux>
        <WrappedComponent {...props} />
      </Aux>
    )
  }
}

export default withErrorHandler
