import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import PhotographerMetrics from './Metrics/PhotographerMetrics'
import storesContext from 'providers/storesContext'
import { isNil } from 'lodash'
import MyAccountDashboardStore from './stores/MyAccountDashboardStore'
import PhotographerSales from './SalesInsights/PhotographerSales'
import PhotographerAlbumsInsights from './SalesInsights/PhotographerAlbumsInsights'
import PhotographerAlbumsData from './AlbumInsights/PhotographerAlbumsData'
import { AlbumSearchUrlQueryParams, Filters } from 'components/UI/SearchBar/SearchBar'
import { useQuery } from 'hooks/useQuery'
import { parseDate, photographerDashboardColumns } from 'shared/utility'
import { getLocalDate } from 'shared/util/dates'
import SearchBarStore from 'components/UI/SearchBar/SearchBarStore'
import { FeedOrderFields, FeedOrderQueryParams, FeedOrderSorts } from 'services/FeedService'
import AlbumResultListNoResult from 'components/Album/AlbumTable/AlbumResultListNoResult'
import { useTranslation } from 'react-i18next'
import SaleRowEmpty from 'containers/Sales/components/SaleRowEmpty'
import SkeletonLoaderMyAlbums from 'components/UI/Loaders/Skeleton/SkeletonLoaderMyAlbums'
import SkeletonLoaderMetrics from 'components/UI/Loaders/Skeleton/PhotographerDashboard/SkeletonLoaderMetrics'
import SkeletonLoaderSalesInsights from 'components/UI/Loaders/Skeleton/PhotographerDashboard/SalesInsights/SkeletonLoaderSalesInsights'

type PhotographerDashboardProps = {
  scrollToClients: () => void
}

const PhotographerDashboard = ({ scrollToClients }: PhotographerDashboardProps) => {
  const { t } = useTranslation()

  const MyAccountDashboardFilters = [
    Filters.Country,
    Filters.Location,
    Filters.Activity,
    Filters.Event,
    Filters.DateRange,
  ]

  const { authStore, locationStore, activityStore, eventStore } = useContext(storesContext)!

  const query = useQuery()
  const locationId = query.get(AlbumSearchUrlQueryParams.LocationId) || undefined
  const activityId = query.get(AlbumSearchUrlQueryParams.ActivityId) || undefined
  const eventId = query.get(AlbumSearchUrlQueryParams.EventId) || undefined

  const dateFrom = parseDate(query.get(AlbumSearchUrlQueryParams.DateFrom))
  const dateTo = parseDate(query.get(AlbumSearchUrlQueryParams.DateTo))

  const apiDateFrom = getLocalDate(dateFrom)
  const apiDateTo = getLocalDate(dateTo)

  if (!isNil(apiDateTo)) {
    apiDateTo.setUTCHours(23, 59, 59, 999)
  }

  const dateRange: [Date | null, Date | null] = [apiDateFrom, apiDateTo]
  const [searchBarStore] = useState(
    () =>
      new SearchBarStore(eventStore, activityStore, locationStore, {
        locationId,
        activityId,
        eventId,
        dateRange,
      })
  )

  const [store] = useState(() => new MyAccountDashboardStore(authStore, searchBarStore))

  const {
    photographerMetrics,
    salesInsights,
    publishedAlbums,
    independentAlbumSales,
    eventAlbumSales,
    salesByActivity,
    albums,
    albumCount,
    albumsPerPage,
    page,
    isLoadingMetrics,
    isLoadingAlbumsData,
    isLoadingSalesInsights,
  } = store

  const [sortOrder, setSortOrder] = useState<FeedOrderQueryParams>({
    field: FeedOrderFields.CREATED_AT,
    sort: FeedOrderSorts.DESC,
  })

  useEffect(() => {
    store.getPhotographerMetrics()
    store.getPhotographerSalesInsights()
    store.getPhotographerPublishedAlbums()
    store.getPhotographerSalesByType()
    store.getPhotographerSalesByActivity()
  }, [])

  useEffect(() => {
    store.getPhotographerAlbums(sortOrder)
  }, [sortOrder])

  const handleSearch = () => {
    store.getPhotographerMetrics()
  }

  const setRowsPerPage = (albumsPerPage: number) => {
    store.setAlbumsPerPage(albumsPerPage)
  }

  const handleChangePage = (e, newPage: number) => {
    store.handleChangePage(e, newPage)
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full">
        <div className="w-full bg-white border-t-4 border-primary">
          <span className="flex py-2 font-semibold justify-center text-xl text-lumepic-light_black">
            {t('Insights')}
          </span>
          {!isLoadingMetrics && !isNil(photographerMetrics) ? (
            <PhotographerMetrics
              metrics={photographerMetrics}
              searchBarStore={searchBarStore}
              handleSearch={handleSearch}
              filters={MyAccountDashboardFilters}
              scrollToClients={scrollToClients}
            />
          ) : (
            isLoadingMetrics && <SkeletonLoaderMetrics />
          )}
        </div>
      </div>
      <div className="w-full bg-white border-t-4 border-primary">
        <span className="flex py-2 font-semibold justify-center text-xl text-lumepic-light_black">
          {t('My Albums')}
        </span>
        {!isLoadingAlbumsData && !isNil(albums) && albums.length > 0 ? (
          <>
            <PhotographerAlbumsData
              albums={albums}
              albumCount={albumCount}
              albumsPerPage={albumsPerPage}
              page={page}
              setRowsPerPage={setRowsPerPage}
              handleChangePage={handleChangePage}
              onSortChange={setSortOrder}
              sortOrder={sortOrder}
            />
          </>
        ) : (
          isLoadingAlbumsData && <SkeletonLoaderMyAlbums columns={photographerDashboardColumns} />
        )}
        {!isLoadingAlbumsData && albums.length === 0 && <AlbumResultListNoResult />}
      </div>

      <div className="w-full bg-white border-t-4 border-primary">
        <span className="flex py-2 font-semibold justify-center text-xl text-lumepic-light_black">
          {t('Sales')}
        </span>
        {!isLoadingSalesInsights && !isNil(salesInsights) && salesInsights.length > 0 ? (
          <>
            <PhotographerSales
              salesInsights={salesInsights}
              independentAlbumSales={independentAlbumSales}
              eventAlbumsSales={eventAlbumSales}
            />
            {!isNil(publishedAlbums) && !isNil(salesByActivity) && (
              <PhotographerAlbumsInsights
                publishedAlbums={publishedAlbums}
                salesByActivity={salesByActivity}
              />
            )}
          </>
        ) : (
          isLoadingSalesInsights && <SkeletonLoaderSalesInsights />
        )}
        {!isLoadingSalesInsights && salesInsights.length === 0 && <SaleRowEmpty />}
      </div>
    </div>
  )
}

export default observer(PhotographerDashboard)
