import React, { useState } from 'react'
import { observer } from 'mobx-react'
import Button from 'components/UI/Button'
import { useTranslation } from 'react-i18next'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PurchaseLineItem } from '../../../../shared/models/PurchaseLineItem'
import { PhotographUtils } from 'shared/util/photographs.utils'
import PaymentStore from 'containers/Payment/PaymentStore'

type PaymentProductRowProps = {
  lineItem: PurchaseLineItem
  purchaseId: string
  store: PaymentStore
  isLoadingMultipleDownloads: boolean
}

const PaymentProductRow = ({
  lineItem,
  purchaseId,
  store,
  isLoadingMultipleDownloads,
}: PaymentProductRowProps) => {
  const { t } = useTranslation()
  const [isLoadingSingleDownload, setIsLoadingSingleDownload] = useState(false)

  const handleClickDownloadPhotograph = async (photographFileName: string) => {
    setIsLoadingSingleDownload(true)
    await PhotographUtils.downloadPurchasedImages(purchaseId, [photographFileName], store, t).then(
      () => {
        setIsLoadingSingleDownload(false)
      }
    )
  }

  return (
    <>
      {lineItem.photographs.map((photograph) => (
        <div key={photograph.id} className="flex flex-col max-h-40 items-center md:items-start">
          <div className="items-center relative">
            <img
              loading="lazy"
              className="max-h-28 mx-auto md:max-h-60"
              src={photograph.thumbnailUrl}
              alt={lineItem.album.location.spotName}
            />
          </div>

          <div
            className={`w-full flex justify-center ${isLoadingMultipleDownloads && 'opacity-60'}`}
          >
            <Button
              onClick={() => {
                isLoadingMultipleDownloads
                  ? () => {}
                  : handleClickDownloadPhotograph(`${photograph.id}.${photograph.extension}`)
              }}
              btnType="TertiaryAction"
              extraStyle="text-xs pt-2 md:text-sm"
              isLoading={isLoadingSingleDownload}
              loadingText={t('Downloading') + '...'.toString()}
            >
              <div className="flex gap-1.5 items-center justify-center">
                <FontAwesomeIcon icon={faDownload} /> {t('Download')}
              </div>
            </Button>
          </div>
        </div>
      ))}
    </>
  )
}

export default observer(PaymentProductRow)
