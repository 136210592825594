import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

const UploadMessagesBanner = () => {
  const { t } = useTranslation()

  const messages = [
    `${t('To avoid loading problems, remember to maintain a stable internet connection.')}`,
    `${t(
      "To avoid upload issues, don't move the location of the files on your device while they're uploading."
    )}`,
    `${t('Lumepic works best with the Google Chrome browser.')}`,
    `${t('Upload photos from a computer for better upload performance.')}`,
    `${t(
      'Make it easier for customers to choose by filtering out too many photos of the same person.'
    )}`,
    `${t('Avoid uploading blurry or out-of-focus photos.')}`,
    `${t('After uploading, review the files with errors and try to upload them again.')}`,
    `${t('Share the link to the album on social media to get more sales.')}`,
    `${t(
      'Remember to upload your albums as close to the capture time as possible to get more sales.'
    )}`,
    `${t('Apply attractive discounts to encourage customers to buy more.')}`,
    `${t('Correctly configure the camera schedule so that the photos are sorted correctly.')}`,
    `${t(
      'Remember not to remove the metadata from the photos when editing them so that the system can sort them correctly.'
    )}`,
    `${t('When taking photos, make sure that each photo has its protagonist.')}`,
  ]

  const [messageIndex, setMessageIndex] = useState(0)
  const [position, setPosition] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPosition(-300)
      setTimeout(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length)
        setPosition(100)
        setTimeout(() => {
          setPosition(0)
        }, 50)
      }, 500)
    }, 4000)

    return () => clearInterval(intervalId)
  }, [messages.length])

  return (
    <div className="p-2 mt-2 bg-[rgba(212,197,129,0.2)]  rounded-md overflow-hidden w-full">
      <div
        className="text-lumepic-light_black font-medium text-sm whitespace-nowrap w-full text-center"
        style={{
          transition: 'transform 0.5s ease-in-out',
          transform: `translateY(${position}%)`,
        }}
      >
        {messages[messageIndex]}
      </div>
    </div>
  )
}

export default observer(UploadMessagesBanner)
