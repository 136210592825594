import React, { useEffect, useState, useContext, useRef } from 'react'
import { observer } from 'mobx-react'
import { useHistory, useParams } from 'react-router-dom'
import StoresContext from '../../../providers/storesContext'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import 'moment/locale/es'
import classes from '../../../components/AlbumDetails/AlbumDetails.module.scss'
import Button from '../../../components/UI/Button'
import MyAlbumDetailsStore from './MyAlbumDetailsStore'
import AlbumDetailsNoResult from './components/AlbumDetailsNoResult'
import PhotographModal from '../../../components/PhotographDetailsModal'
import AddDiscountModal from '../CreateOrUpdateAlbumSteps/Steps/AddDiscountModal/AddDiscountModal'
import { toast } from 'react-toastify'
import { Photograph } from 'shared/models/Photograph'
import BackButton from 'components/UI/Button/BackButton'
import EditPriceModal from './components/EditPriceModal'
import { isNil } from 'lodash'
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../../services/axios'
import { Modal, ModalButtonsDisplay, ModalSize } from '../../../components/UI/Modal/Modal'
import MyAlbumDetailsPhotographList from './components/MyAlbumDetailsPhotographList'
import Spinner from 'components/UI/Spinner'
import { feedAlbumPhotographDetailById } from 'routing/Paths'
import { PhotographUtils } from 'shared/util/photographs.utils'

const MyAlbumDetails = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { authStore } = useContext(StoresContext)!
  const [albumDetailsStore] = useState(() => new MyAlbumDetailsStore(id, authStore))
  const [photographToDisplayId, setPhotographToDisplayId] = useState<string | null>(null)
  const [visibleAddDiscountModal, setVisibleAddDiscountModal] = useState(false)
  const [photographToEdit, setPhotographToEdit] = useState<Photograph | undefined>()
  const [photographToDelete, setPhotographToDelete] = useState<Photograph | undefined>()

  const albumDetails = albumDetailsStore.album

  const { isLoadingAlbum, isLoadingPhotographs } = albumDetailsStore

  const photographs = albumDetailsStore.photographs
  const spotName = albumDetails?.location?.spotName || 'Unknown Spot'

  const initialRender = useRef(true)

  const pageCount = Math.ceil(
    albumDetailsStore.photographCount / albumDetailsStore.photographsPerPage
  )

  useEffect(() => {
    if (initialRender.current) {
      albumDetailsStore.fetchAlbum(id)
      albumDetailsStore.fetchAlbumPhotographs(id)
      initialRender.current = false
    }
  }, [])

  useEffect(() => {
    albumDetailsStore.fetchAlbumPhotographs(id)
  }, [albumDetailsStore.page])

  const setPage = (page: number) => {
    albumDetailsStore.setPage(page)
  }

  const showAddDiscountModal = () => {
    setVisibleAddDiscountModal(true)
  }

  const hideAddDiscountModal = () => {
    setVisibleAddDiscountModal(false)
  }

  const handleSaveDiscount = () => {
    albumDetailsStore.fetchAlbum(id)
    hideAddDiscountModal()
  }

  const handleEditPhotographPriceFinish = () => {
    setPhotographToEdit(undefined)
    albumDetailsStore.fetchAlbumPhotographs(id)
  }

  const goBack = () => {
    history.push('/my-albums')
  }

  const closeImageModal = () => {
    setPhotographToDisplayId(null)
    const initialUrl = `/my-albums/albums/${albumDetails.id}`
    history.push(initialUrl)
    PhotographUtils.mantainScrollPositionAfterClosingModal(photographToDisplayId, photographs)
  }

  const setPhotograph = (albumId: string, photographId: string) => {
    const url = feedAlbumPhotographDetailById(albumId, photographId)
    window.history.replaceState(null, '', url)
    setPhotographToDisplayId(photographId)
  }

  const deletePhotograph = () => {
    if (!isNil(photographToDelete)) {
      albumDetailsStore.deletePhotograph(photographToDelete.id).then(() => {
        setPhotographToDelete(undefined)
        albumDetailsStore.fetchAlbumPhotographs(id)
        toast.success(t('Photograph deleted successfully!'), {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
    }
  }

  if (albumDetailsStore.error) {
    goBack()
  }

  return (
    <div className={classes.FullAlbumContainer}>
      <div className="container mx-auto">
        <div className="mt-2 ml-1">
          <BackButton onClick={goBack} />
        </div>
        {isLoadingAlbum ? (
          <div className="flex justify-center pt-16">
            <Spinner noStyles color="secondary" size={20} />
          </div>
        ) : (
          <div className={classes.AlbumInformation + ' p-2'}>
            <h1 className="text-2xl md:text-4xl">{albumDetails?.description}</h1>
            <div className="flex justify-between my-2">
              <div>
                <p>{moment.utc(albumDetails?.takenDate).locale('es').format('DD.MM.YYYY')}</p>
                <p className={classes.AlbumInformationLocation}>{spotName}</p>
              </div>
              <div className="flex items-center">
                {isNil(albumDetailsStore.album.event.quantityDiscountId) && (
                  <Button
                    btnType="PrimaryAction"
                    onClick={showAddDiscountModal}
                    extraStyle="text-sm md:text-base"
                  >
                    {albumDetails?.quantityDiscount ? t('Update discount') : t('Add discount')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
        {!isLoadingPhotographs && photographs?.length === 0 ? (
          <AlbumDetailsNoResult />
        ) : (
          <MyAlbumDetailsPhotographList
            photographs={photographs}
            albumDetails={albumDetails}
            isLoadingPhotographs={isLoadingPhotographs}
            setPage={setPage}
            page={albumDetailsStore.page}
            pageCount={pageCount}
            setPhotograph={setPhotograph}
            setPhotographToEdit={setPhotographToEdit}
            setPhotographToDelete={setPhotographToDelete}
          />
        )}
        {visibleAddDiscountModal && (
          <Modal
            buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
            modalSize={ModalSize.MEDIUM}
            onCancel={() => setVisibleAddDiscountModal(false)}
            opened={true}
          >
            <AddDiscountModal
              handleFinish={handleSaveDiscount}
              closeModal={() => setVisibleAddDiscountModal(false)}
              album={albumDetails}
            />
          </Modal>
        )}
        {!isNil(photographToEdit) && (
          <EditPriceModal
            photograph={photographToEdit}
            open={!isNil(photographToEdit)}
            onSuccess={handleEditPhotographPriceFinish}
            handleClose={() => setPhotographToEdit(undefined)}
          />
        )}

        {!isNil(photographToDelete) && (
          <Modal
            loadingOk={albumDetailsStore.isLoadingPhotographs}
            onSuccess={deletePhotograph}
            onCancel={() => setPhotographToDelete(undefined)}
            okMessage={t('Delete').toString()}
            opened={!isNil(photographToDelete)}
            modalSize={ModalSize.SMALL}
            isDeleteConfirmation
          >
            <div className="px-4 pt-4">{t('Are you sure that you want to delete photograph?')}</div>
          </Modal>
        )}

        {!isNil(photographs) && !isNil(photographToDisplayId) && (
          <PhotographModal
            photographToDisplayId={photographToDisplayId}
            setPhotographToDisplayId={setPhotograph}
            album={albumDetails}
            photographs={photographs}
            onClose={closeImageModal}
            canEdit={true}
            onDownloadFreePhotograph={() => {}}
          />
        )}
      </div>
    </div>
  )
}

export default withErrorHandler(observer(MyAlbumDetails), axios)
