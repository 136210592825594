import React from 'react'
import { observer } from 'mobx-react'
import { Purchase } from '../../../shared/models/Purchase'
import PurchaseStore from '../PurchaseStore'
import { PurchaseLineItem } from 'shared/models/PurchaseLineItem'
import PurchaseSingleLineItem from './PurchaseSingleLineItem'

type PurchaseRowProps = {
  purchase: Purchase
  lineItem: PurchaseLineItem
  isLoadingMultipleDownloads: boolean
  store: PurchaseStore
}
const PurchaseRowItem = ({
  purchase,
  lineItem,
  isLoadingMultipleDownloads,
  store,
}: PurchaseRowProps) => {
  //TODO: Borrar componente que no tiene razón de ser
  return (
    <PurchaseSingleLineItem
      purchase={purchase}
      lineItem={lineItem}
      store={store}
      isLoadingMultipleDownloads={isLoadingMultipleDownloads}
    />
  )
}

export default observer(PurchaseRowItem)
