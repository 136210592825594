import React from 'react'
import c from 'classnames'
import Spinner from '../Spinner'
import { DefaultTFuncReturn } from 'i18next'

type ButtonProps = {
  btnType: 'PrimaryAction' | 'SecondaryAction' | 'TertiaryAction' | 'Warning' | 'Success'
  onClick: Function
  extraStyle?: string
  children?: any
  isLoading?: boolean
  loadingText?: string | DefaultTFuncReturn
  type?: 'submit' | 'button'
  disabled?: boolean
  props?: any
}

const Button = ({
  btnType,
  onClick = () => {},
  extraStyle,
  children,
  isLoading = false,
  loadingText,
  type = 'submit',
  disabled = false,
  ...props
}: ButtonProps) => {
  const baseClasses = ['no-underline', 'flex', 'justify-center', 'text-lumepic-black']
  if (btnType !== 'TertiaryAction') {
    baseClasses.push('px-4', 'rounded-lg', 'items-center')
  }
  if (btnType === 'PrimaryAction') {
    baseClasses.push(
      'bg-primary',
      'hover:shadow-md',
      'ease-out',
      'duration-150',
      'py-2',
      'active:bg-primary_dark'
    )
  }
  if (btnType === 'SecondaryAction') {
    baseClasses.push('bg_white', 'border-solid', 'border-2', 'border-lumepic-black', 'py-1.5')
  }
  if (btnType === 'TertiaryAction') {
    baseClasses.push('border-b-2', 'items-end', 'border-lumepic-black')
  }
  if (isLoading && btnType === 'TertiaryAction') {
    baseClasses.push('border-0')
  }
  if (btnType === 'Success') {
    baseClasses.push('bg-lumepic-success_dark_green', 'py-2')
  }
  if (btnType === 'Warning') {
    baseClasses.push('bg-warning', 'py-2')
  }
  if (disabled) {
    baseClasses.push('opacity-50', 'cursor-not-allowed')
  }
  const buttonClasses = [...baseClasses, extraStyle]

  return (
    <button
      className={c(buttonClasses)}
      onClick={(e: any) => onClick(e)}
      {...props}
      type={type}
      disabled={disabled}
    >
      {isLoading ? (
        <div className="flex items-center gap-1.5">
          <Spinner
            color={
              btnType == 'SecondaryAction' || btnType === 'TertiaryAction' ? 'primary' : 'info'
            }
            size={20}
            divStyles="flex items-center justify-center py-0.5"
          />
          {loadingText && <span>{loadingText}</span>}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full">{children}</div>
      )}
    </button>
  )
}

export default Button
