import React from 'react'
import Button from 'components/UI/Button'
import UploadImageToAlbumStore from '../UploadImageToAlbumStore'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowUp } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import FileInput from 'components/UI/FileInput'
import { FileTypes } from 'components/UI/FileInput/FileInput'
import Album from 'shared/models/Album'
import Breadcrumb from 'components/UI/Breadcrumb'
import { isNil } from 'lodash'
import { Event } from 'shared/models/Event'

const validImageTypes = [FileTypes.PNG, FileTypes.JPG]

type UploadImagesActionsProps = {
  isCreating: boolean
  store: UploadImageToAlbumStore
  album: Album
  handleFinish: (album: Album) => void
  handleFileInput: (files: File[]) => void
  albumEvent: Event | undefined
}

const UploadImagesActions = ({
  isCreating,
  store,
  album,
  handleFinish,
  handleFileInput,
  albumEvent,
}: UploadImagesActionsProps) => {
  const { t } = useTranslation()

  return (
    <>
      {!isCreating ? (
        <div className="flex justify-end mb-3 mt-4">
          {store.imagesSubmitted && store.allImagesUploaded() && (
            <div className="flex gap-2 items-center">
              {store.exceededRetries.length > 0 &&
                store.findFilesWithLessThanThreeRetries() > 0 && (
                  <Button
                    btnType="SecondaryAction"
                    isLoading={store.isLoading}
                    onClick={() => store.retryFailedUploads(true)}
                    extraStyle="w-full"
                  >
                    <span>{`${t('Retry')} ${store.exceededRetries.length} ${t('failed%')}`}</span>
                  </Button>
                )}
              <Button
                btnType="PrimaryAction"
                isLoading={store.isLoading}
                onClick={() => handleFinish(album)}
                extraStyle="w-full sm:w-min"
              >
                <span>{t('Save')}</span>
              </Button>
            </div>
          )}
          {!store.allImagesUploaded() && store.imagesSubmitted && (
            <Button
              btnType="PrimaryAction"
              onClick={() => {}}
              extraStyle="w-full sm:w-min opacity-60"
            >
              <span className="cursor-not-allowed">{t('Save')}</span>
            </Button>
          )}
          {!store.allImagesUploaded() &&
            !store.imagesSubmitted &&
            store.progressInfos.length > 0 && (
              <div className="flex gap-2 items-center">
                <FileInput
                  handleMultipleFiles={handleFileInput}
                  label={t('Select more').toString()}
                  acceptedFileTypes={validImageTypes}
                />
                <Button
                  btnType="PrimaryAction"
                  isLoading={store.isLoading}
                  onClick={() => {
                    store.changeImagesSubmitted(true)
                    store.batchUpload(album)
                  }}
                  extraStyle="w-min"
                >
                  <div className="flex gap-1.5 items-center justify-center">
                    <FontAwesomeIcon icon={faCircleArrowUp} />
                    <span>{t('Upload')}</span>
                  </div>
                </Button>
              </div>
            )}
          {!store.allImagesUploaded() &&
            !store.imagesSubmitted &&
            store.progressInfos.length === 0 && (
              <Button
                btnType="PrimaryAction"
                isLoading={store.isLoading}
                onClick={() => handleFinish(album)}
                extraStyle="w-full sm:w-min"
              >
                <span>{t('Save')}</span>
              </Button>
            )}
        </div>
      ) : (
        <div className="flex justify-between mb-3 mt-4">
          <div className="hidden sm:block">
            <Breadcrumb
              completedSteps={!isNil(albumEvent) && !isNil(albumEvent.quantityDiscount) ? 3 : 4}
              totalSteps={!isNil(albumEvent) && !isNil(albumEvent.quantityDiscount) ? 4 : 5}
            />
          </div>
          {!store.allImagesUploaded() && store.imagesSubmitted && (
            <Button
              btnType="PrimaryAction"
              onClick={() => {}}
              extraStyle="w-full sm:w-min opacity-60"
            >
              <div className="flex gap-2 items-center justify-center cursor-not-allowed">
                <span>{t('Publish')}</span>
              </div>
            </Button>
          )}
          {store.imagesSubmitted && store.allImagesUploaded() && (
            <div className="flex gap-2 items-center">
              {store.exceededRetries.length > 0 &&
                store.findFilesWithLessThanThreeRetries() > 0 && (
                  <Button
                    btnType="SecondaryAction"
                    isLoading={store.isLoading}
                    onClick={() => store.retryFailedUploads(true)}
                    extraStyle="w-full"
                  >
                    <span>{`${t('Retry')} ${store.exceededRetries.length} ${t('failed%')}`}</span>
                  </Button>
                )}
              <Button
                btnType="PrimaryAction"
                isLoading={store.isLoading}
                onClick={() => handleFinish(album)}
                extraStyle="w-full sm:w-min"
              >
                <div className="flex gap-2 items-center justify-center">
                  <span>{t('Publish')}</span>
                </div>
              </Button>
            </div>
          )}
          {!store.allImagesUploaded() &&
            !store.imagesSubmitted &&
            store.progressInfos.length > 0 && (
              <div className="flex gap-2 items-center">
                <FileInput
                  handleMultipleFiles={handleFileInput}
                  label={t('Select more').toString()}
                  acceptedFileTypes={validImageTypes}
                />
                <Button
                  btnType="PrimaryAction"
                  isLoading={store.isLoading}
                  onClick={() => {
                    store.changeImagesSubmitted(true)
                    store.batchUpload(album)
                  }}
                  extraStyle="w-min"
                >
                  <div className="flex gap-1.5 items-center justify-center">
                    <FontAwesomeIcon icon={faCircleArrowUp} />
                    <span>{t('Upload')}</span>
                  </div>
                </Button>
              </div>
            )}
          {!store.allImagesUploaded() &&
            !store.imagesSubmitted &&
            store.progressInfos.length === 0 && (
              <Button
                btnType="PrimaryAction"
                isLoading={store.isLoading}
                onClick={() => handleFinish(album)}
                extraStyle="w-full sm:w-min"
              >
                <div className="flex gap-2 items-center justify-center">
                  <span>{t('Publish')}</span>
                </div>
              </Button>
            )}
        </div>
      )}
    </>
  )
}

export default observer(UploadImagesActions)
