import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import spanishData from './es.data'
import englishData from './en.data'
import Tracker from '../../shared/tracking'
import StoresContext from '../../providers/storesContext'
import LoginRegisterStore from 'containers/Auth/LoginRegisterStore'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { toast } from 'react-toastify'
import i18next from 'i18next'
import { SetPageTitle } from '../../shared/utility'

const TermsAndConditions = () => {
  SetPageTitle('Terms and Conditions')
  Tracker.pageView('/termsAndConditions', 'Terms and Conditions')
  const currentLanguage = i18next.language
  const data = currentLanguage === 'es' ? spanishData : englishData
  const { t } = useTranslation()

  const { authStore, cartStore, locationStore } = useContext(StoresContext)!

  const [loginRegisterStore] = useState(
    () => new LoginRegisterStore(authStore, cartStore, locationStore)
  )

  const googleLoginSuccess = (credentialResponse: CredentialResponse) => {
    return loginRegisterStore.googleAuth(credentialResponse)
  }
  const googleLoginError = () => {
    toast.error(t('Error on login'), {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  }

  return (
    <>
      <div className="bg-bg_section_titles border-b mb-2 pb-3 pt-4">
        <div className="container mx-auto">
          <h2 className="text-2xl pt-3 font-extrabold md:text-4xl text-lumepic-light_black ml-4 sm:ml-0">
            {t('Terms and Conditions')}
          </h2>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="mb-10">
          {data.map((paragraph, index) => (
            <div key={index} className="bg-white pl-2 sm:pl-0 sm:rounded-md">
              <h3 className="mt-6 text-2xl font-semibold text-primary bg-white p-3 rounded-t-md">
                {paragraph.title}
              </h3>
              <ul className="pl-0">
                {paragraph.content?.map((line, subIndex) => (
                  <li
                    className="text-base font-extralight bg-white px-3 pb-3 pt-1 rounded-b-md"
                    key={subIndex}
                  >
                    {line}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        {!authStore.isAuthenticated() && (
          <div className="hidden">
            <GoogleLogin
              size="large"
              width="150px"
              useOneTap
              onSuccess={googleLoginSuccess}
              onError={googleLoginError}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default TermsAndConditions
